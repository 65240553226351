import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const Document = () => {
  return (
    <div>
      <div className="personal">
        <Container>
          <Row>
            <Col className="d-flex justify-content-between">
              <h6>Pan Card</h6>
              <p>xxxxxxxxxxxxx</p>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-between">
              <h6>Driving licence</h6>
              <p>xxxxxxxxxxxxx</p>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-between">
              <h6>Adhar Card</h6>
              <p>xxxxxxxxxxxxx</p>
            </Col>
          </Row>
          <br /> <br />
          <Row>
            <h6>Images</h6>
            <Col md={6} className="d-flex justify-content-between">
              <img src="image/aadhar.png" alt="" className="documental-proof" />
              <img
                src="image/pancard.png"
                alt=""
                className="documental-proof"
              />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Document;
