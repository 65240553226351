import React from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";

const Invoice = () => {
  return (
    <div>
      <Container>
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Invoice Category</Form.Label>
              <Form.Select size="sm">
                <option>Branch</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Billing Type</Form.Label>
              <Form.Select size="sm">
                <option>Branch</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Billing Name</Form.Label>
              <Form.Select size="sm">
                <option>Branch</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Cross Name</Form.Label>
              <Form.Select size="sm">
                <option>Branch</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Invoice Type</Form.Label>
              <Form.Select size="sm">
                <option>Branch</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>From Date</Form.Label>
              <Form.Control type="date" placeholder="" size="sm" />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>To Date</Form.Label>
              <Form.Control type="date" placeholder="" size="sm" />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Bill Number</Form.Label>
              <Form.Control type="text" placeholder="" size="sm" />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <Form.Group className="mb-3">
              <Form.Label>Employee</Form.Label>
              <Form.Select size="sm">
                <option>Select Employee</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={1}>
            <Button className="all-button">Search</Button>
          </Col>
          <Col md={1}>
            <Button className="all-button">Export</Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Invoice;
