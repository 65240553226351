import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Dropdown,
  Form,
  Image,
  Nav,
  Navbar,
  NavDropdown,
} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import logo from "./../Assets/MoxD_PrimaryLogo1.png";
import "././../Styles/dashboardNavbar.css";
import { ToastContainer, toast, Zoom } from "react-toastify";

function DashboardNavbar() {
  const [userData, setUserData] = useState("");
  const [emailUser, setEmailUser] = useState([]);
  const history = useHistory();
  

  const handlebyebyeuser = (e) => {
    toast.success('Logout Successful')
    setTimeout(() => {
      history.push('/login')
      localStorage.clear()
    }, [1000])
    // Storage.Destroyee();
  };
  // console.log(emailUser?.Hub_type, "Hub_type");
  useEffect(async () => {
    // setUserData(JSON.parse(localStorage.getItem("userDetails")));

    const localstorege = localStorage.getItem("userDetails");
    const localstoregeParseData = JSON.parse(localstorege);
    setEmailUser(localstoregeParseData);
    // console.log(localstoregeParseData, "Login User Data");
    const mainData = await localstoregeParseData;

    const one = [mainData.role_masters].map((ele, ind) => {
      setUserData(ele[0].role_name);
    });
  }, []);
 

  return (
    <div className="mb-3">
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        transition={Zoom}
        delay={1000}
        limit={1}
      />
      <Navbar className="dashNav py-0 my-0" expand="lg">
        <Container fluid>
          <Navbar.Brand href="#">
            <Link to="/home">
              <Image src={logo} className="img-logo" width={120} />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: "100px" }}
              navbarScroll
            >
              <Nav.Link>
                <Link className="nav-item" to="/home">
                  Home
                </Link>
              </Nav.Link>
              <Nav.Link>
                <Link className="nav-item" to="/report">
                  Report
                </Link>
              </Nav.Link>
              {userData == "Admin" ? (
                <Nav.Link>
                  <Link className="nav-item" to="/CouponCodeList">
                    Coupon Code
                  </Link>
                </Nav.Link>
              ) : (
                ""
              )}
          
              {userData == "Admin" ? (
                <Nav.Link>
                  <Link className="nav-item" to="/notification">
                    Notification
                  </Link>
                </Nav.Link>
                ) : (
                ""
              )}

          
              {
                // userData == "Admin" ? (
                //   <Nav.Link>
                //     <Link className="nav-item" to="branchlist">
                //       Branchlist
                //     </Link>
                //   </Nav.Link>
                // ) : (
                //   ""
                // )
              }{" "}
              {userData == "Franchise" && emailUser?.Hub_type == "LOCAL" ? (
                <>
                  {/* <Nav.Link>
                    <Link className="nav-item" to="/career">
                      Career
                    </Link>
                  </Nav.Link> */}

                  <NavDropdown
                    id="nav-dropdown"
                    title="Master"
                    className="nav-item tracking"
                    variant=""
                  >
                    <NavDropdown.Item className="bg-primary nav-drop">
                      <Link to="/PinCodemaster" style={{ color: "white" }}>
                        <div>
                          Pin Code Master
                        </div>
                      </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item className="bg-primary nav-drop">
                      <Link to="/Areamaster" style={{ color: "white" }}>
                        <div>
                          Area Master

                        </div>
                      </Link>
                    </NavDropdown.Item>{" "}
                    <NavDropdown.Item className="bg-primary nav-drop">
                      <Link to="/Deliverypartner" style={{ color: "white" }}>
                        <div>
                          Delivery Partner
                        </div>
                      </Link>
                    </NavDropdown.Item>

                    <NavDropdown.Item className="bg-primary nav-drop">
                      <Link to="/Cnote" style={{ color: "white" }}>
                        <div>
                          C-Note Master
                        </div>
                      </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item className="bg-primary nav-drop">
                      <Link to="/complainmaster" style={{ color: "white" }}>
                        <div>
                          complaint Master
                        </div>
                      </Link>
                    </NavDropdown.Item>{" "}
                  </NavDropdown>
                </>
              ) : (
                ""
              )}
              {userData == "Franchise" && emailUser?.Hub_type == "SUPER" ? (
                <NavDropdown
                  id="nav-dropdown"
                  title="Master"
                  className="nav-item tracking"
                  variant="secondary"
                >

                  <NavDropdown.Item className="bg-primary nav-drop">
                    <Link to="/PinCodemaster" style={{ color: "white" }}>
                      <div>
                        Pin Code Master
                      </div>
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item className="bg-primary nav-drop">
                    <Link to="/Areamaster" style={{ color: "white" }}>
                      <div>
                        Area Master

                      </div>
                    </Link>
                  </NavDropdown.Item>{" "}
                  <NavDropdown.Item className="bg-primary nav-drop">
                    <Link to="/Deliverypartner" style={{ color: "white" }}>
                      <div>
                        Delivery Partner
                      </div>
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item className="bg-primary nav-drop">
                    <Link to="/Cnote" style={{ color: "white" }}>
                      <div>
                        C-Note Master
                      </div>
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item className="bg-primary nav-drop">
                    <Link to="/complainmaster" style={{ color: "white" }}>
                      <div>
                        Complaint Master
                      </div>
                    </Link>
                  </NavDropdown.Item>{" "}

                </NavDropdown>

              ) : (
                ""
              )}{" "}
              {userData == "Admin" ? (
                <Nav.Link>
                  <Link className="nav-item" to="/master">
                    Master
                  </Link>
                </Nav.Link>
              ) : (
                ""
              )}


              {
                //  <NavDropdown
                //     id="nav-dropdown"
                //     title="Transaction"
                //     className="nav-item tracking"
                //     variant="secondary"
                //   >
                //     {
                //     // userData == "Admin" ? (
                //     //   <NavDropdown.Item className="bg-primary nav-drop">
                //     //     <Link to="/Allbooking" style={{ color: "white" }}>
                //     //       All Booking
                //     //     </Link>
                //     //   </NavDropdown.Item>
                //     // ) : (
                //     //   ""
                //     // )
                //     }
                //     {userData == "Franchise" && emailUser?.Hub_type == "SUPER" ? (
                //       <>
                //         <NavDropdown.Item className="bg-primary nav-drop">
                //           <Link to="/OgmInward" style={{ color: "white" }}>
                //             OGM Inward
                //           </Link>
                //         </NavDropdown.Item>
                //         <NavDropdown.Item className="bg-primary nav-drop">
                //           <Link to="/OgmOutward" style={{ color: "white" }}>
                //             OGM Outward
                //           </Link>
                //         </NavDropdown.Item>
                //         <NavDropdown.Item className="bg-primary nav-drop">
                //           <Link to="/OutForDelivery" style={{ color: "white" }}>
                //             Out For Delivery
                //           </Link>
                //         </NavDropdown.Item>
                //         <NavDropdown.Item className="bg-primary nav-drop">
                //           <Link to="/LocalInward" style={{ color: "white" }}>
                //             Local Inward
                //           </Link>
                //         </NavDropdown.Item>
                //         <NavDropdown.Item className="bg-primary nav-drop">
                //           <Link to="/LocalOutward" style={{ color: "white" }}>
                //             Local Outward
                //           </Link>
                //         </NavDropdown.Item>
                //         <NavDropdown.Item className="bg-primary nav-drop">
                //           <Link to="/booking" style={{ color: "white" }}>
                //             Booking{" "}
                //           </Link>
                //         </NavDropdown.Item>
                //         <NavDropdown.Item className="bg-primary nav-drop">
                //           <Link to="/Allbooking" style={{ color: "white" }}>
                //             All Booking
                //           </Link>
                //         </NavDropdown.Item>
                //       </>
                //     ) : (
                //       ""
                //     )}
                //     {userData == "Franchise" && emailUser?.Hub_type == "LOCAL" ? (
                //       <>
                //         {/* <NavDropdown.Item className="bg-primary nav-drop">
                //           <Link to="/Cnote" style={{ color: "white" }}>
                //             C-Note Details
                //           </Link>
                //         </NavDropdown.Item>{" "} */}
                //         <NavDropdown.Item className="bg-primary nav-drop">
                //           <Link to="/OutForDelivery" style={{ color: "white" }}>
                //             Out For Delivery
                //           </Link>
                //         </NavDropdown.Item>{" "}
                //         <NavDropdown.Item className="bg-primary nav-drop">
                //           <Link to="/LocalInward" style={{ color: "white" }}>
                //             Local Inward
                //           </Link>
                //         </NavDropdown.Item>
                //         <NavDropdown.Item className="bg-primary nav-drop">
                //           <Link to="/LocalOutward" style={{ color: "white" }}>
                //             Local Outward
                //           </Link>
                //         </NavDropdown.Item>{" "}
                //         <NavDropdown.Item className="bg-primary nav-drop">
                //           <Link to="/booking" style={{ color: "white" }}>
                //             Booking{" "}
                //           </Link>
                //         </NavDropdown.Item>
                //         <NavDropdown.Item className="bg-primary nav-drop">
                //           <Link to="/Allbooking" style={{ color: "white" }}>
                //             All Booking
                //           </Link>
                //         </NavDropdown.Item>
                //       </>
                //     ) : (
                //       ""
                //     )}
                //   </NavDropdown>
              }

              {
                userData == "Franchise" && emailUser?.Hub_type == "SUPER" ? (
                  <NavDropdown
                    id="nav-dropdown"
                    title="Transaction"
                    className="nav-item tracking"
                    variant="secondary"
                  >
                    <NavDropdown.Item className="bg-primary nav-drop">
                      <Link to="/OgmInward" style={{ color: "white" }}>
                        <div>
                          OGM Inward
                        </div>
                      </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item className="bg-primary nav-drop">
                      <Link to="/OgmOutward" style={{ color: "white" }}>
                        <div>
                          OGM Outward
                        </div>
                      </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item className="bg-primary nav-drop">
                      <Link to="/OutForDelivery" style={{ color: "white" }}>
                        <div>
                          Out For Delivery
                        </div>
                      </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item className="bg-primary nav-drop">
                      <Link to="/LocalInward" style={{ color: "white" }}>
                        <div>

                          Local Inward
                        </div>
                      </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item className="bg-primary nav-drop">
                      <Link to="/LocalOutward" style={{ color: "white" }}>
                        <div>
                          Local Outward
                        </div>
                      </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item className="bg-primary nav-drop">
                      <Link to="/booking" style={{ color: "white" }}>
                        <div>
                          Booking{" "}
                        </div>
                      </Link>
                    </NavDropdown.Item>
                    {/* { <NavDropdown.Item className="bg-primary nav-drop">
                      <Link to="/Allbooking" style={{ color: "white" }}>
                        All Booking
                      </Link>
                    </NavDropdown.Item>} */}
                  </NavDropdown>
                ) : ''}

              {
                userData == "Franchise" && emailUser?.Hub_type == "LOCAL" ? (
                  <NavDropdown
                    id="nav-dropdown"
                    title="Transaction"
                    className="nav-item tracking"
                    variant="secondary"
                  >
                    <NavDropdown.Item className="bg-primary nav-drop">
                      <Link to="/OutForDelivery" style={{ color: "white" }}>
                        <div>
                          Out For Delivery
                        </div>
                      </Link>
                    </NavDropdown.Item>{" "}
                    <NavDropdown.Item className="bg-primary nav-drop">
                      <Link to="/LocalInward" style={{ color: "white" }}>
                        <div>

                          Local Inward
                        </div>
                      </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item className="bg-primary nav-drop">
                      <Link to="/LocalOutward" style={{ color: "white" }}>
                        <div>

                          Local Outward
                        </div>
                      </Link>
                    </NavDropdown.Item>{" "}
                    <NavDropdown.Item className="bg-primary nav-drop">
                      <Link to="/booking" style={{ color: "white" }}>
                        <div>
                          Booking{" "}
                        </div>
                      </Link>
                    </NavDropdown.Item>
                    {/* { <NavDropdown.Item className="bg-primary nav-drop">
                      <Link to="/Allbooking" style={{ color: "white" }}>
                        All Booking
                      </Link>
                    </NavDropdown.Item>} */}
                  </NavDropdown>
                ) : ''
              }

            </Nav>
            <div className="d-flex">
              <Button variant="secondary" className="mx-2">
                <Link className="tracking " to="Wallet">
                  Wallet
                </Link>
              </Button>
              <Button variant="secondary">
                <Link className="tracking" to="report">
                  Tracking
                </Link>
              </Button>

              <Dropdown className="d-inline mx-2" autoClose="outside">
                <Dropdown.Toggle id="dropdown-autoclose-outside">
                  {emailUser.Email}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {/* <Dropdown.Item>User</Dropdown.Item> */}
                  <Dropdown.Item
                    // href="/login"
                    onClick={(e) => handlebyebyeuser(e)}
                  >
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div>
        {
          // userData?.map((ele, ind) => {
          //   return (
          //     <div>
          //       <h1>{ele?.type}</h1>
          //     </div>
          //   );
          // })
        }
      </div>
    </div>
  );
}

export default DashboardNavbar;
