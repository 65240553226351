import React, { useEffect, useState } from 'react'
import { Circles } from 'react-loader-spinner';
import { ToastContainer, toast, Zoom } from "react-toastify";
import Apiservice from '../../Services/Apiservice';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Switch from "react-switch";
import { Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";


function CNoteNumber() {
  const [cNoteTabledata, setCnoteTableData] = useState(null)
  const [search, setSearch] = useState("");
  const [filterTabledata, setFilterTabledata] = useState(null);
  const [reloadCurrentPage, setReloadCurrentPage] = useState(1)

  const [dateOne, setDate] = useState("");
  useEffect(() => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    const todayDate = yyyy + "-" + mm + "-" + dd;

    setDate(todayDate);

    // var d = new Date();
    // d.getHours(); // => 9
    // d.getMinutes(); // =>  30
    // d.getSeconds();

    // var hh = d.getHours();
    // var mm = d.getMinutes();

    // const todayTime = hh + ":" + mm;
    // setTime(todayTime);
  }, []);


  // const today = new Date();
  // const date = today.setDate(today.getDate());
  // const defaultValue = new Date(date).toISOString().split('T')[0]
  // console.log(defaultValue,'defaultValue');

  const onSearchClick = () => {

    if (search === "") {
      setFilterTabledata([]);
    } else {
      const mapingData = cNoteTabledata.map((ele, ind) => {
        return ele
      })

      const filterTabledataOne = mapingData.filter(
        (tablerow) => tablerow?.Frenchise?.BusinessName.toLowerCase() == search.toLowerCase()
      );
      setFilterTabledata(filterTabledataOne);
    }
  };

  const [userId, setUserId] = useState(null)
  window.scrollTo(0, 0);
  useEffect(async () => {
    const LocalData = localStorage.getItem("userDetails");
    const frenchise_id = JSON.parse(LocalData).id;
    setUserId(frenchise_id)
    let payload = {
      frenchise_id: frenchise_id
    }
    const cnoteData = await Apiservice.postdata("/booking/c_note_frenchise_list", payload)
    if (cnoteData.error == false) {
      setCnoteTableData(cnoteData.data);
      setRowData(cnoteData.data);
    }
    else {
    }
  }, [reloadCurrentPage])


  const [cNoteFilterFromTo, setCNoteFilterFromTo] = useState({
    from: "",
    to: ""
  })

  const onchangedate = (event) => {
    setCNoteFilterFromTo({ ...cNoteFilterFromTo, [event.target.name]: event.target.value })
  }


  const handelFromToSubmit = async () => {
    const payload = {
      dateFrom: cNoteFilterFromTo.from == '' ? dateOne : cNoteFilterFromTo.from,
      dateTo: cNoteFilterFromTo.to == '' ? dateOne : cNoteFilterFromTo.to,
      frenchise_id: userId
    }

    const cnoteData = await Apiservice.postdata("/booking/c_note_frenchise_list", payload)
    if (cnoteData.error == false && cnoteData.data.length != 0) {
      setCnoteTableData(cnoteData.data)
      setCNoteFilterFromTo('')
      toast.success(cnoteData.message)
    }
    else {
      toast.error('based on date there id no data')
      setCnoteTableData(null)
    }
  }




  const handleSwichChange = async (ele) => {
    const payload = {
      id: ele.id,
    }
    if (ele.status == 'SUCCESS') {
      payload.status = 'PENDING'
    }
    if (ele.status == 'PENDING') {
      payload.status = 'SUCCESS'
    }

    const cNoteApprovedApi = await Apiservice.postdata("/booking/c_note_update", payload)
    if (cNoteApprovedApi.error == false) {
      toast.success(cNoteApprovedApi.message)
      setReloadCurrentPage(reloadCurrentPage + 1)
    }
    else {
      toast.error(cNoteApprovedApi.message)
    }
  }

  const rowHeight = 50;

  const [rowData, setRowData] = useState()

  const [colDefs, setColDefs] = useState(
    [
      {
        headerName: "Sl.No",
        valueGetter: "node.rowIndex + 1",
        filter: true,
        lockPosition: true,
      },
      {
        headerName: "Business Name",
        filter: true,
        field: "order_id",
      },
      // {
      //   headerName: 'Name',
      //   filter: true,
      //   valueGetter: 'data.Frenchises[0].BusinessName',
      // },
      // {
      //   headerName: "Start Number",
      //   filter: true,
      //   valueGetter: 'data.Frenchises[0].MobileNumber',
      // },
      // {
      //   headerName: "End Number",
      //   filter: true,
      //   valueGetter: 'data.Frenchises[0].MobileNumber',
      // },
      // {
      //   headerName: "Quantity",
      //   filter: true,
      //   field: "type",
      // },
      // {
      //   headerName: "Cash Mode",
      //   filter: true,
      //   field: "type",
      // },
      // {
      //   headerName: "Receiving Date",
      //   filter: true,
      //   field: "type",
      // },
      // {
      //   headerName: "Status",
      //   filter: true,
      //   field: "order_status",
      // }
    ])

  const DefaultColumnSetting = {
    sortable: true,
    filter: true,

    floatingFilter: true,
    flex: 1,
    resizable: true,
    minWidth: 120,
  };

  // console.log(cNoteFilterFromTo.from, 'from');
  // console.log(cNoteFilterFromTo.to, 'to');


  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        transition={Zoom}
        delay={1000}
        limit={1}
      />
      <div className="more-link">
        <h6>
          C-Note Details{" "}
        </h6>
      </div>
      <div className="my-3" >
        <div className="card w-100 my-3 p-3" >

          <div className='row' >
            <div className='col' >
              {
                userId == 'Admin' ?
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Label>Select Business Name</Form.Label>
                      <Form.Control
                        type="text"
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6 mt-2">
                      <Button
                        onClick={() => onSearchClick()}
                        className="btn btn-success rounded mx-3 mt-4 "
                      >
                        Search
                      </Button>
                    </div>
                  </div> : <div></div>
              }
            </div>



            <div className='col' >
              {
                <div className="row ">
                  <div className="col-md-5">
                    {" "}
                    <Form.Label>From</Form.Label>
                    <Form.Control
                      type="date"
                      value={cNoteFilterFromTo.from == '' ? dateOne : cNoteFilterFromTo.from}
                      onChange={onchangedate}
                      name="from"
                      format="DD/MM/YYYY"
                    />
                  </div>

                  <div className="col-md-5">
                    <Form.Label>To</Form.Label>
                    <Form.Control
                      type="date"
                      value={cNoteFilterFromTo.to == '' ? dateOne : cNoteFilterFromTo.to}
                      onChange={onchangedate}
                      name="to"
                      format="DD/MM/YYYY"
                    />
                  </div>
                  <div className="col-md-2 mt-3">
                    <button
                      className="btn btn-success rounded mx-3  text-white mt-3"
                      onClick={handelFromToSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              }
            </div>


          </div>
          {
            filterTabledata == null || filterTabledata == [] ? (
              <div>
                {
                  cNoteTabledata ?
                    <table className="table table-bordered table-hover mt-5 text-center">
                      <thead>
                        <tr>
                          <th>Sl.No</th>
                          <th>Branch Name</th>
                          <th>Contact Persion</th>
                          <th>Start Number</th>
                          <th>End Number</th>
                          <th>Quantity</th>
                          <th>Cash Mode</th>
                          <th>Receiving Date</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          cNoteTabledata?.map((ele, ind) => {
                            return (
                              <tr>
                                <td>{ind + 1}</td>
                                <td>{ele?.Frenchise?.city_masters[0]?.city_name}</td>
                                <td>{ele?.Frenchise?.ContactPersion}</td>
                                <td>{ele?.start_no}</td>
                                <td>{ele?.end_no}</td>
                                <td>{ele?.end_no - ele?.start_no + 1}</td>
                                <td>{ele?.cash_mode}</td>
                                <td>{ele?.receive_date}</td>
                                <td>
                                  {" "}
                                  {ele?.status == 'PENDING' &&
                                    <button
                                      // className="w-100"
                                      // variant="primary"
                                      // size="sm"
                                      className='btn btn-primary btn-md rounded'
                                      onClick={() => handleSwichChange(ele)}
                                    >
                                      {
                                        // ele?.status == "PENDING" ? "Approve" : "Reject"
                                        ele?.status == "PENDING" ? "Approve" : "Reject"

                                      }
                                    </button>

                                  }
                                  {ele?.status != 'PENDING' &&
                                    <button
                                      // className="w-100"
                                      // variant="primary"
                                      // size="sm"
                                      // onClick={() => handleSwichChange(ele)}
                                      className='btn btn-success'
                                    >
                                      {/* {
                                        // ele?.status == "PENDING" ? "Approve" : "Reject"
                                        ele?.status == "PENDING" ? "Approve" : "Reject"

                                      } */}
                                      Approved
                                    </button>
                                  }
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table> :
                    <div style={{ marginLeft: '550px', marginTop: '100px' }} >
                      <Circles
                        height="30"
                        width="30"
                        color='grey'
                        ariaLabel='loading'
                      />
                    </div>
                }
              </div>

            ) : (
              <div>
                {
                  filterTabledata ?
                    <table className="table table-bordered table-hover mt-5 text-center">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Business Name</th>
                          <th>Name</th>
                          <th>Start Number</th>
                          <th>End Number</th>
                          <th>Quantity</th>
                          <th>Cash Mode</th>
                          <th>Receiving Date</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          filterTabledata?.map((ele, ind) => {
                            return (
                              <tr>
                                <td>{ind + 1}</td>
                                <td>{ele?.Frenchise?.BusinessName}</td>
                                <td>{ele?.Frenchise?.ContactPersion}</td>
                                <td>{ele?.start_no}</td>
                                <td>{ele?.end_no}</td>
                                <td>{ele?.quantity}</td>
                                <td>{ele?.cash_mode}</td>
                                <td>{ele?.receive_date}</td>
                                <td>
                                  {" "}
                                  <Button
                                    className="w-100"
                                    variant="primary"
                                    size="sm"
                                    onClick={() => handleSwichChange(ele)}
                                  >
                                    {
                                      ele?.status == "PENDING" ? "Approve" : "Reject"
                                    }
                                  </Button>
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table> :
                    <div style={{ marginLeft: '550px', marginTop: '100px' }} >
                      <Circles
                        height="30"
                        width="30"
                        color='grey'
                        ariaLabel='loading'
                      />
                    </div>
                }
              </div>
            )
          }
        </div>
      </div>
      {
        //               <div
        //                 className="ag-theme-alpine"
        //                 style={{ height: "70vh", width: "100%" }}
        //               >
        //                 <AgGridReact
        //                   rowHeight={rowHeight}
        //                   // columnDefs={columns}
        //                   columnDefs={colDefs}
        //                   defaultColDef={DefaultColumnSetting}
        //                   pagination={true}
        //                   paginationPageSize={10}
        //                   // onGridReady={onGridReady}
        //                   rowData={rowData}
        //                 />{" "}
        //               </div>
      }
    </div>
  )
}

export default CNoteNumber