import React, { useState } from 'react'
import {
    Button,
    Card,
    Col,
    FloatingLabel,
    Form,
    Modal,
    Row,
} from "react-bootstrap";

function CustomModal({ showPopupOpen, handleOkClick, handleClosePopup, modalMessage }) {
    return (
        <div>
            <Modal
                show={showPopupOpen}
                onHide={handleClosePopup}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{
                        modalMessage
                    }</Modal.Title>
                </Modal.Header>
                <Modal.Footer className="m-auto">
                    <Button
                        variant="success"
                        className=" rounded"
                        onClick={handleOkClick}
                    >
                        Ok{" "}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default CustomModal