import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Form, Button, Modal, Spinner } from "react-bootstrap";
import "react-multiple-select-dropdown-lite/dist/index.css";
import Apiservice from "../../Services/Apiservice";
import { toast, ToastContainer, Zoom } from "react-toastify";
import { FileText } from "react-bootstrap-icons";
import Select from "react-select";
import { AgGridReact } from "ag-grid-react";
import CustomModal from "../../common/CustomModal";
import CustomDeleteModal from "../../common/CustomDeleteModal";

const Areamaster = () => {
  const [show, setShow] = useState(false);
  const [updateCount, setUpdateCount] = useState(1)

  /*********** Branch Name Dropdown ***********/
  const [listBranchNames, setListBranchNames] = useState([]);
  const [selectedBranchName, setSelectedBranchName] = useState([]);
  const [selectedBranchNameError, setSelectedBranchNameError] = useState([]);


  const [listPincodes, setListPincodes] = useState([])
  const [selectedPincode, setSelectedPincode] = useState([])
  const [selectPincodeEorror, setSelectPincodeErrorr] = useState('')

  const [AreaName, setAreaName] = useState("");
  const [AreaNameError, setAreaNameError] = useState("");
  const [loadstateonsave, setLoadstateonsave] = useState(false);
  const [rowData, setRowData] = useState();


  /* Toast Modal */
  const [showPopupOpen, setShowPopupOpen] = useState(false)
  const [modalMessage, setModalMessage] = useState(null)
  const [pincodeLoader, setPincodeLoader,] = useState(false)

  // delete state
  const [deleteModal, setDeleteModal] = useState({
    loader: false,
    show: false,
    type: "",
    data: {},
  })


  const handleClosePopup = () => {
    setShowPopupOpen(false)
  }
  const handleOkClick = () => {
    setShowPopupOpen(false)
  }

  useEffect(() => {
    if (selectedBranchName.length != 0) {
      const branchId = selectedBranchName[selectedBranchName.length - 1]?.id
      handlePincode(branchId)
    }
  }, [selectedBranchName])

  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setSelectedBranchName([])
    setSelectedBranchNameError('')
    setSelectedPincode([])
    setSelectPincodeErrorr('')
    setAreaName('')
    setAreaNameError('')
  };

  /**  create Area Master **/
  const handlePincode = async (id) => {
    setPincodeLoader(true)
    const payload = {
      city_id: id,
    };
    if (id) {
      let pincode = await Apiservice.postdata(`/booking/listpincodemaster`, payload);
      if (pincode.error == false) {
        setPincodeLoader(false)
        const pincodeDropdown = [];
        const pincodeMaping = pincode.data.map((ele, ind) => {
          pincodeDropdown.push({
            label: ele.pincode,
            value: ele.pincode,
            id: ele.id,
          });
        });
        setListPincodes(pincodeDropdown)
      } else {
        setPincodeLoader(false)
      }
    }
  };


  /****** Branch name dropdown *******/
  const handleGetBranch = async () => {
    const userData = JSON.parse(localStorage.getItem("userDetails"))
    if (userData.Hub_type == 'NULL') {
      let AreaNameData = await Apiservice.postdata(`/admin/allBranchesFranchise`);
      if (AreaNameData.error == false) {
        const pincodeDropdown = [];
        const pincodeMaping = AreaNameData.data.map((ele, ind) => {
          pincodeDropdown.push({
            label: ele.city_name,
            value: ele.city_name,
            id: ele.id,
          });
        });
        setListBranchNames(pincodeDropdown)
      } else {
        console.log("failue");
      }
    } else {
      const payload = {
        city_name: '',
        isNull: false,
        Hub_id: userData.id,
        ApprovedStatus: true,
      }
      let AreaNameData = await Apiservice.postdata(`/User/listCity`, payload);
      if (AreaNameData.error == false) {
        const filterNotAssignedFranchise = AreaNameData?.data && AreaNameData?.data?.filter(item => item.Frenchises != 0)
        const allCityList = filterNotAssignedFranchise.filter((ele, ind) => {
          return ele.city_name == JSON.parse(localStorage.getItem("userDetails"))?.city_masters[0].city_name;
        });
        const pincodeDropdown = [];
        const pincodeMaping = allCityList.map((ele, ind) => {
          pincodeDropdown.push({
            label: ele.city_name,
            value: ele.city_name,
            id: ele.id,
          });
        });
        setListBranchNames(pincodeDropdown)
      } else {
        console.log("failue");
      }
    }
  }

  useEffect(() => {
    handleGetBranch()
  }, [])

  useEffect(() => {
    if (selectedBranchName.length != 0) {
      setSelectedBranchNameError('')
    }
  }, [selectedBranchName])

  useEffect(() => {
    if (selectedPincode?.length != 0) {
      setSelectPincodeErrorr('')
    }
  }, [selectedPincode])

  useEffect(() => {
    if (AreaName != '') {
      setAreaNameError('')
    }
  }, [AreaName])

  const handleAreaName = (e) => {
    setAreaName(e.target.value)
  }

  const handleModalSubmit = async (e) => {
    e.preventDefault()
    if (selectedBranchName.length == 0) {
      setSelectedBranchNameError('Please select branch name')
    }
    if (selectedPincode?.length == 0) {
      setSelectPincodeErrorr('Please select pincode')
    }
    if (AreaName.trim() === '') {
      setAreaNameError('Please eneter area name')
    }

    if (AreaName.trim() != '' && selectedBranchName.length != 0 && selectedPincode?.length != 0) {
      setLoadstateonsave(true);
      const payload = {
        city_id: selectedBranchName[selectedBranchName.length - 1]?.id,
        area_name: AreaName,
        pincode_id: selectedPincode[selectedPincode?.length - 1]?.id,
      };
      const inward = await Apiservice.postdata("/booking/create_area_master", payload);
      if (inward.error == false) {
        setShowPopupOpen(true)
        setModalMessage(inward.message)
        setUpdateCount(updateCount + 1);
        handleClose();
        setLoadstateonsave(false);

        setShow(false);
        setSelectedBranchName([])
        setSelectedBranchNameError('')
        setSelectedPincode([])
        setSelectPincodeErrorr('')
        setAreaName('')
        setAreaNameError('')
      } else {
        // toast.error(inward.message);
        setShowPopupOpen(true)
        setModalMessage(inward.message)
        setLoadstateonsave(false);

        setShow(false);
        setSelectedBranchName([])
        setSelectedBranchNameError('')
        setSelectedPincode([])
        setSelectPincodeErrorr('')
        setAreaName('')
        setAreaNameError('')
      }
    } else {
    }
  };

  useEffect(async () => {
    const userData = localStorage.getItem("userDetails")
    const parseUserData = JSON.parse(userData)
    const payload = {
      city_id: parseUserData?.city_masters[0]?.id,
    };

    if (parseUserData.Hub_type == 'NULL') {
      const ListPincodeGet = await Apiservice.postdata("/booking/list_areamaster");
      if (ListPincodeGet.error == false) {
        setRowData(ListPincodeGet.data);
      } else {
        console.log("get API is not working");
      }
    }

    if (parseUserData.Hub_type == 'SUPER') {
      const ListPincodeGet = await Apiservice.postdata("/booking/list_areamaster", payload);
      if (ListPincodeGet.error == false) {
        setRowData(ListPincodeGet.data);
      } else {
        console.log("get API is not working");
      }
    }
    if (parseUserData.Hub_type == 'LOCAL') {
      const ListPincodeGet = await Apiservice.postdata("/booking/list_areamaster", payload);
      if (ListPincodeGet.error == false) {
        setRowData(ListPincodeGet.data);
      } else {
        console.log("get API is not working");
      }
    }

  }, [updateCount])

  /******* Delete API Integration ******/
  const hendleDeletSubmitApi = async (data) => {
    setDeleteModal({ ...deleteModal, loader: true })
    const payload = {
      id: data?.id,
    };
    let price = await Apiservice.postdata("/booking/deleteareamaster", payload);
    if (price.error == false) {
      setDeleteModal({})
      toast.success(price.message)
      setUpdateCount(updateCount + 1);
    } else {
      setDeleteModal({})
      setUpdateCount(updateCount + 1);
      toast.error(price.message)
    }
  };
  const hendleDeleteModal = (data) => {
    setDeleteModal({})
  }

  const handleClearAddareaModala = () => {
    setSelectedBranchName([])
    setSelectedBranchNameError('')
    setSelectedPincode([])
    setSelectPincodeErrorr('')
    setAreaName('')
    setAreaNameError('')
  }


  const rowHeight = 50;
  const DefaultColumnSetting = {
    sortable: true,
    filter: true,

    floatingFilter: true,
    flex: 1,
    resizable: true,
    minWidth: 120,
  };

  const [colDefs, setColDefs] = useState([
    {
      headerName: "Sl.No",
      valueGetter: "node.rowIndex + 1",
      filter: true,
      lockPosition: true,
    },

    {
      headerName: "Area Name",
      filter: true,
      field: "area_name",
    },
    {
      headerName: "Pin code",
      filter: true,
      valueGetter: "data.pincode_masters[0].pincode",
    },
    // {
    //   headerName: "Edit",
    //   filter: true,
    //   cellRendererFramework: (params) => (
    //     <center
    //     // onClick={() => handleClickdata(params)}
    //     >
    //       <FontAwesomeIcon icon={faEdit} className="iconstyle" />
    //     </center>
    //   ),
    // },
    {
      headerName: "Delete",
      filter: true,
      cellRendererFramework: (params) => (
        <center
          onClick={() => setDeleteModal({ ...deleteModal, show: true, data: params.data, type: "DELETE", deletedmodule: 'Area', deletedname: params?.data?.area_name })}
        >
          <FontAwesomeIcon icon={faTrash} className="iconstyle" />
        </center>
      ),
    },
  ]);

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        transition={Zoom}
        delay={1000}
        limit={1}
      />
      {/* toaster message */}
      <CustomModal showPopupOpen={showPopupOpen}
        handleOkClick={handleOkClick}
        handleClosePopup={handleClosePopup}
        modalMessage={modalMessage}
      />
      {/* delete modal */}
      <CustomDeleteModal
        hendleDeleteModal={hendleDeleteModal}
        deleteModal={deleteModal}
        hendleDeletSubmitApi={hendleDeletSubmitApi}
      ></CustomDeleteModal>
      {show == true ? (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton >
            <h6 className="text-center more-link mt-4">Add Area Name</h6>
          </Modal.Header>
          <form onSubmit={handleModalSubmit} >
            <Modal.Body>
              <div className="col-md-12">
                <label style={{ marginBottom: '5px' }} >Branch Name</label>
                <Select
                  isMulti
                  options={listBranchNames}
                  onChange={setSelectedBranchName}
                  value={selectedBranchName[selectedBranchName.length - 1] || null}
                />
                <span className="text-danger" >{selectedBranchNameError}</span>
              </div>
              <div className="col-md-12" >
                <Form.Group>
                  <Form.Label>Area Name </Form.Label>
                  <Form.Control
                    onChange={handleAreaName}
                    maxLength={20}
                    value={AreaName}
                  ></Form.Control>
                  <span className="text-danger" >{AreaNameError}</span>
                </Form.Group>
              </div>
              <div >
                <diV className="mb-3 d-flex">
                  <div className="col-md-12" >
                    <label style={{ marginBottom: '5px' }} >Select Pincode{" "}
                      {
                        pincodeLoader == true &&
                        // <div style={{ marginTop: '35px', marginLeft: "15px" }} >
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        // </div>
                      }
                    </label>
                    <Select
                      isMulti
                      options={listPincodes}
                      onChange={setSelectedPincode}
                      value={selectedPincode[selectedPincode.length - 1] || null}
                    />
                    <span className="text-danger" >{selectPincodeEorror}</span>
                  </div>

                </diV>
              </div>

            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger btn-md rounded" onClick={handleClearAddareaModala}>
                Clear
              </Button>
              <Button variant="primary  btn-md rounded"
                // onClick={handleModalSubmit}
                type="submit"
              >
                Save{" "}
                {loadstateonsave ? (
                  <Spinner animation="border" variant="Primary" size="sm" />
                ) : (
                  " "
                )}
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      ) : (
        ""
      )}
      <div className="my-3">
        <div className="card w-100">
          <div class="card-body pt-0">
            <div className="row">
              <div className="col-md-3">
                <h6 class="card-title    my-3 bg-light w-auto py-2 rounded ">
                  <FileText /> <b> Area Master</b>
                </h6>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
              </div>
              <div className="col-md-2" style={{ marginTop: '31px' }} >
              </div>
              <div className="col-md-6 text-end mt-4">
                <Button
                  className="btn btn-danger  btn-md  rounded mx-3"
                  onClick={handleShow}
                >
                  Add New Area
                </Button>
              </div>
            </div>

            <div
              className="ag-theme-alpine"
              style={{ height: "70vh", width: "100%", marginTop: "20px" }}
            >
              <AgGridReact
                rowHeight={rowHeight}
                // columnDefs={columns}
                columnDefs={colDefs}
                defaultColDef={DefaultColumnSetting}
                pagination={true}
                paginationPageSize={10}
                // onGridReady={onGridReady}
                rowData={rowData}
              />{" "}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Areamaster;
