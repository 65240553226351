import {
  faAngleDown,
  faArrowAltCircleDown,
  faArrowCircleDown,
  faCopy,
  faSync,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Card, Col, Container, Form, Row, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import Apiservice from "../../Services/Apiservice";
import "../../Style/Dashboard.css";
import All from "./All";
import Delivery from "./Delivery";
import Table from "react-bootstrap/Table";
import axios  from "axios";
import { AgGridReact } from "ag-grid-react";
import CustomDeleteModal from "../../common/CustomDeleteModal";
import { toast } from "react-toastify";
import Ticker from 'react-ticker';
const Homedashboard = () => {
  const [tableData, setGetTableData] = useState([]);
  const [adminData, setAdminData] = useState([]);
  const [userData, setUserData] = useState();
  const [getInTouch, setGetInTouch] = useState([]);
  const [rowData, setRowData] = useState();
  // delete state
  const [deleteModal, setDeleteModal] = useState({
    loader: false,
    show: false,
    type: "",
    data: {},
  })


  // User Data
  useEffect(async () => {
    const localstorege = localStorage.getItem("userDetails");
    const localstoregeParseData = JSON.parse(localstorege);
    console.log(localstoregeParseData, "Login User Data");
    const mainData = await localstoregeParseData;
    const one = [mainData.role_masters].map((ele, ind) => {
      setUserData(ele[0].role_name);
    });
  }, []);


  // For Franchise
  useEffect(async () => {
    const one = await localStorage.getItem("userDetails");
    const localstorege = localStorage.getItem("userDetails");
    const localstoregeParseData = JSON.parse(localstorege);
    const mainData = await localstoregeParseData;

    const one1 = [mainData.role_masters].map((ele, ind) => {
      setUserData(ele[0].role_name);
    });
    const payload = {
      frenchise_id: JSON.parse(one).id,
    };

    const dashboardCount = await Apiservice.postdata("/booking/count", payload);
    if (dashboardCount.error == false) {
      setGetTableData(dashboardCount.data);
    } else {
      console.log("get API is not working");
    }
  }, []);



  // For Admin
  useEffect(async () => {
    const one = await localStorage.getItem("userDetails");
    const localstorege = localStorage.getItem("userDetails");
    const localstoregeParseData = JSON.parse(localstorege);
    const mainData = await localstoregeParseData;
    const one1 = [mainData.role_masters].map((ele, ind) => {
      setUserData(ele[0].role_name);
    });

    const dashboardCount = await Apiservice.postdata("/booking/count");
    if (dashboardCount.error == false) {
      setAdminData(dashboardCount.data);
    } else {
      console.log("get API is not working");
    }

  }, []);


  const [selectM, setSelectM] = useState();
  const selectMonth = async (event) => {
    console.log(event.target.value, "event.target.value");
    setSelectM(event.target.value);
    const one = await localStorage.getItem("userDetails");
    const payload = {
      frenchise_id: JSON.parse(one).id,
      status: selectM,
    };
    const dashboardCount = await Apiservice.postdata("/booking/count", payload);
    console.log(dashboardCount, "dashboardCount");
    if (dashboardCount.error == false) {
      setGetTableData(dashboardCount.data);
      setAdminData(dashboardCount.data);
    } else {
      console.log("get API is not working");
    }
  };

  const handleGetInTouchWithUs = async () => {
    const data = await axios.post("https://moxd-api.moshimoshi.cloud/api/User/get_in_touch_list");
    if (data.status == 200) {
      setRowData(data.data.data);
    } else {
    }
  }
  useEffect(() => {
    handleGetInTouchWithUs()
  }, []);

const [OGMList ,setOGMList] = useState([])
const [NotificationList ,setNotificationList] = useState([])




  const getOGMinwardList = async () => {
    try {
      let franchise = JSON.parse(localStorage.getItem("userDetails"))
      const payload = {
        franchise_id : franchise?.id
      };
  
      const response = await axios.post('https://moxd-api.moshimoshi.cloud/api/booking/franchise_outward_pending', payload);
      setOGMList(response.data.data)
  
    } catch (error) {
      console.error('Error making POST request:', error);
    }
  }
   const getNotificationList = async () => {
    try {
      let franchise = JSON.parse(localStorage.getItem("userDetails"))
      const payload = {
        franchise_id : franchise?.id
      };
  
      const response = await axios.post('https://moxd-api.moshimoshi.cloud/api/admin/get_all_notification', payload);
      setNotificationList(response.data.data)
  
    } catch (error) {
      console.error('Error making POST request:', error);
    }
  }
  
  
  useEffect(() => {
    getOGMinwardList();
    getNotificationList()
  }, []);
  

  const rowHeight = 50;
  const [colDefs, setColDefs] = useState([
    {
      headerName: "Sl.No",
      valueGetter: "node.rowIndex + 1",
      filter: false,
      lockPosition: true,
    },
    {
      headerName: "first name",
      filter: true,
      field: "firstName",
    },
    {
      headerName: "last name",
      filter: true,
      field: "lastName",
    },
    {
      headerName: "email",
      filter: true,
      field: "email",
    },
    {
      headerName: "mobile",
      filter: true,
      field: "mobile",
    },
    {
      headerName: "Delete",
      filter: true,
      cellRendererFramework: (params) => (
        <center onClick={() => setDeleteModal({ ...deleteModal, show: true, data: params.data, type: "DELETE", deletedmodule: 'Contact Person', deletedname: params?.data?.firstName + " " + params?.data?.lastName })} >
          <FontAwesomeIcon icon={faTrash} className="iconstyle" />
        </center>
      )
    },
  ]);

  const DefaultColumnSetting = {
    sortable: true,
    filter: true,
    floatingFilter: true,
    flex: 1,
    resizable: true,
    minWidth: 120,
  };

  /******* Delete API Integration ******/
  const hendleDeletSubmitApi = async (data) => {
    setDeleteModal({ ...deleteModal, loader: true })
    const payload = {
      id: data?.id,
    };
    let price = await Apiservice.postdata("/User/delete_get_in_touch_list", payload);
    if (price.error == false) {
      setDeleteModal({})
      toast.success(price.message)
      handleGetInTouchWithUs()
    } else {
      setDeleteModal({})
      toast.error(price.message)
      handleGetInTouchWithUs()
    }
  };

  const hendleDeleteModal = (data) => {
    setDeleteModal({})
  }

  
  const [isPaused, setIsPaused] = useState(false);
  const handleMouseEnter = () => {
    setIsPaused(true);
  };

  const handleMouseLeave = () => {
    setIsPaused(false);
  };

  return (
    <>
 <div
      className="ticker-container"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Ticker speed={15} move={!isPaused}>
        {() => (
          <>
            <ul>
              {NotificationList?.map((item, itemIndex) => (
                <span
                  key={itemIndex}
                  className={`${
                    item.type === 'allfranchise' ? 'text-danger' : 'text-primary'
                  } `}
                >
                  {item.message}&nbsp;&nbsp;
                </span>
              ))}
            </ul>
          </>
        )}
      </Ticker>
    </div>







      {/* delete modal */}
      <CustomDeleteModal
        hendleDeleteModal={hendleDeleteModal}
        deleteModal={deleteModal}
        hendleDeletSubmitApi={hendleDeletSubmitApi}
      ></CustomDeleteModal>
      <div className="more-link">
        <h6>
          Todays Activity
          <FontAwesomeIcon
            icon={faSync}
            style={{ marginLeft: "4px", fontSize: "12px" }}
          />
        </h6>
      </div>
      <>
        <Row>
          <Col>
            <Card className="shadow w-100">
              <Container>
                <div className="more-link mt-3 mb-2">All Booking</div>
                <div className="center-multi-detail careertabs">
                  <Tabs
                    defaultActiveKey="Personal"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                  >
                    <Tab eventKey="Personal" title="All">
                      <All />
                    </Tab>
                    <Tab eventKey="profile" title="Delivered">
                      Delivered
                    </Tab>
                    <Tab eventKey="Document" title="In Transit">
                      In Transit
                    </Tab>
                  </Tabs>
                </div>
              </Container>
            </Card>
            <Card className="shadow mt-3 p-2 w-100">
              <div className="more-link d-flex justify-content-between"></div>
              <div className="row">
                <div className="col-md-6">
                  <h6 className="mx-2 more-link my-2 fs-5">Details</h6>
                </div>
                <div className="col-md-6">
                  <Form.Select
                    aria-label="Default select example"
                    size="sm"
                    className="more-link  selectcount"
                  >
                    <option name="allCount" value="1">
                      All Count
                    </option>
                    <option name="toadyCount" value="2">
                      Toady Count
                    </option>
                    <option name="lastCount" value="3">
                      Last Count
                    </option>
                  </Form.Select>
                </div>
              </div>

              <div className="d-flex justify-content-between count-box-detail">
                <p>Raise Complaint</p>

                {userData == "Admin" ? (
                  <span className="more-link">
                    {adminData.map((ele, index) => (
                      <span>{ele?.complaintsFrom} </span>
                    ))}
                  </span>
                ) : (
                  <span className="more-link">
                    {tableData.map((ele, index) => (
                      <span>{ele?.complaintsFrom} </span>
                    ))}
                  </span>
                )}

                {adminData?.complaintsFrom == 0 ? <span> 0</span> : ""}
                {tableData?.complaintsFrom == 0 ? <span> 0</span> : ""}
              </div>
              <div className="d-flex justify-content-between count-box-detail">
                <p>Received Complaint</p>

                {userData == "Admin" ? (
                  <span className="more-link">
                    {adminData.map((ele, index) => (
                      <span>{ele?.complaintTo} </span>
                    ))}
                  </span>
                ) : (
                  <span className="more-link">
                    {tableData.map((ele, index) => (
                      <span>{ele?.complaintTo} </span>
                    ))}
                  </span>
                )}
                {adminData?.complaintTo == 0 ? <span> 0</span> : ""}
                {tableData?.complaintTo == 0 ? <span> 0</span> : ""}
              </div>
              <div className="d-flex justify-content-between count-box-detail">
                <p>Delivery Status Dispatched</p>
                {userData == "Admin" ? (
                  <span className="more-link">
                    {adminData.map((ele, index) => {
                      return (
                        <span>
                          {ele[0]?.delivery_status == "Dispatched"
                            ? ele[0]?.count
                            : ""}
                        </span>
                      );
                    })}
                  </span>
                ) : (
                  <span className="more-link">
                    {tableData.map((ele, index) => {
                      return (
                        <span>
                          {ele[0]?.delivery_status == "Dispatched"
                            ? ele[0]?.count
                            : ""}
                        </span>
                      );
                    })}
                  </span>
                )}{" "}
                {/* {adminData?.complaintTo == 0 ? <span> 0</span> : ""} */}
              </div>
              <div className="d-flex justify-content-between count-box-detail">
                <p>Delivery Status Delivered</p>
                {userData == "Admin" ? (
                  <span className="more-link">
                    {adminData.map((ele, index) => (
                      <span>
                        {ele[1]?.delivery_status == "Delivered"
                          ? ele[1]?.count
                          : ""}
                      </span>
                    ))}
                  </span>
                ) : (
                  <span className="more-link">
                    {tableData.map((ele, index) => (
                      <span>
                        {ele[1]?.delivery_status == "Delivered"
                          ? ele[1]?.count
                          : ""}
                      </span>
                    ))}
                  </span>
                )}
              </div>
              <div className="d-flex justify-content-between count-box-detail">
                <p>Delivery Status Door closed</p>
                {userData == "Admin" ? (
                  <span className="more-link">
                    {adminData.map((ele, index) => (
                      <span>
                        {ele[2]?.delivery_status == "Door closed"
                          ? ele[2]?.count
                          : ""}
                      </span>
                    ))}
                  </span>
                ) : (
                  <span className="more-link">
                    {tableData.map((ele, index) => (
                      <span>
                        {ele[2]?.delivery_status == "Door closed"
                          ? ele[2]?.count
                          : ""}
                      </span>
                    ))}
                  </span>
                )}
              </div>
              <div className="d-flex justify-content-between count-box-detail">
                <p>Delivery Status Persion not Available</p>

                {userData == "Admin" ? (
                  <span className="more-link">
                    {adminData.map((ele, index) => (
                      <span>
                        {ele[3]?.delivery_status == "Person not Available"
                          ? ele[3]?.count
                          : ""}
                      </span>
                    ))}
                  </span>
                ) : (
                  <span className="more-link">
                    {tableData.map((ele, index) => (
                      <span>
                        {ele[3]?.delivery_status == "Person not Available"
                          ? ele[3]?.count
                          : ""}
                      </span>
                    ))}
                  </span>
                )}
              </div>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="shadow p-2 ml-5 w-100">
              <div className="text-success">Active Shipment</div>
              <div className="shipmentmap-container m-auto">
                <img
                  src="image/shipmentmap.png"
                  alt=""
                  className="shipmentmap"
                />
              </div>
            </Card>
            <Card className="shadow p-2 w-100 mt-3">
              <div className="text-success">Get in touch with us</div>
              <Row>
                <Col className="col-12 mt-2">
                  <div
                    className="ag-theme-alpine"
                    style={{ height: "30vh", width: "100%", marginTop: "10px" }}
                  >
                    <AgGridReact
                      rowHeight={rowHeight}
                      // columnDefs={columns}
                      columnDefs={colDefs}
                      defaultColDef={DefaultColumnSetting}
                      pagination={true}
                      paginationPageSize={10}
                      // onGridReady={onGridReady}
                      rowData={rowData}
                    />{" "}
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col>
            <Card className="shadow p-2 w-100">
              <div className="more-link d-flex justify-content-around">
                <h6>Status</h6>
                <span>
                  Last month
                  <FontAwesomeIcon
                    icon={faAngleDown}
                    style={{ marginLeft: "4px", fontSize: "12px" }}
                  />
                </span>
              </div>
              <Row className="mt-3">
                <Col className="">
                  <Link
                    // to="/OgmInward"
                    to={`${userData != 'Admin' ? "/OgmInward" : '/home'}`}>
                    <Card
                      className="w-75 p-2 cardbase"
                      style={{ marginLeft: "30px" }}
                    >
                      <div className="d-flex justify-content-around align-items-center">
                        <div className="text-center">
                          {userData == "Admin" ? (
                            <span
                              style={{ color: "#7700F6" }}
                              className="text-center"
                            >
                              {adminData.map((ele, index) => (
                                <span>
                                  {ele?.type == "inward" ? ele?.count : ""}
                                </span>
                              ))}
                            </span>
                          ) : (
                            <span
                              style={{ color: "#7700F6" }}
                              className="text-center"
                            >
                              {tableData.map((ele, index) => (
                                <span>
                                  {ele?.type == "inward" ? ele?.count : ""}
                                </span>
                              ))}
                            </span>
                          )}

                          <br />
                          <span>OGM IN</span>
                        </div>
                      </div>
                      <div className="View-container">
                        <img
                          src="image/graphview.png"
                          alt=""
                          className="subView"
                        />
                      </div>
                    </Card>
                  </Link>
                </Col>
                <Col className="">
                  <Link
                    to={`${userData != 'Admin' ? "/OgmOutward" : '/home'}`}
                  // to="/OgmOutward"
                  >
                    <Card className="w-75 p-2 cardprimary">
                      <div className="d-flex justify-content-around align-items-center">
                        <div className="text-center">
                          {userData == "Admin" ? (
                            <span
                              style={{ color: "orange" }}
                              className="text-center"
                            >
                              {adminData.map((ele, index) => (
                                <span>
                                  {ele?.type == "outward" ? ele?.count : ""}
                                </span>
                              ))}
                            </span>
                          ) : (
                            <span
                              style={{ color: "orange" }}
                              className="text-center"
                            >
                              {tableData.map((ele, index) => (
                                <span>
                                  {ele?.type == "outward" ? ele?.count : ""}
                                </span>
                              ))}
                            </span>
                          )}

                          <br />
                          <span>OGM OUT</span>
                        </div>
                      </div>
                      <div className="View-container">
                        <img
                          src="image/graphview.png"
                          alt=""
                          className="subView"
                        />
                      </div>
                    </Card>
                  </Link>
                </Col>
              </Row>
              <Row className="mt-3">
                <div className="col-md-6">
                  <Link
                    // to="/LocalInward"
                    to={`${userData != 'Admin' ? "/LocalInward" : '/home'}`}
                  >
                    <Card
                      className="w-75 p-2 cardbase "
                      style={{ marginLeft: "30px" }}
                    >
                      <div className="d-flex justify-content-around align-items-center">
                        <div className="text-center">
                          {userData == "Admin" ? (
                            <span
                              style={{ color: "#7700F6" }}
                              className="text-center"
                            >
                              {adminData.map((ele, index) => (
                                <span>
                                  {ele?.type == "local-inward"
                                    ? ele?.count
                                    : ""}
                                </span>
                              ))}
                            </span>
                          ) : (
                            <span
                              style={{ color: "#7700F6" }}
                              className="text-center"
                            >
                              {tableData.map((ele, index) => (
                                <span>
                                  {ele?.type == "local-inward"
                                    ? ele?.count
                                    : ""}
                                </span>
                              ))}
                            </span>
                          )}

                          <br />
                          <span>LOCAL IN</span>
                        </div>
                      </div>
                      <div className="View-container">
                        <img
                          src="image/graphview.png"
                          alt=""
                          className="subView"
                        />
                      </div>
                    </Card>
                  </Link>
                </div>
                <div className="col-md-6">
                  <Link
                    // to="/LocalOutward"
                    to={`${userData != 'Admin' ? "/LocalOutward" : '/home'}`}
                  >
                    <Card className="w-75 p-2 cardprimary">
                      <div className="d-flex justify-content-around align-items-center">
                        <div className="text-center">
                          {userData == "Admin" ? (
                            <span
                              className="text-center"
                              style={{ color: "orange" }}
                            >
                              {adminData.map((ele, index) => (
                                <span>
                                  {ele?.type == "local-outward"
                                    ? ele?.count
                                    : ""}
                                </span>
                              ))}
                            </span>
                          ) : (
                            <span
                              className="text-center"
                              style={{ color: "orange" }}
                            >
                              {tableData.map((ele, index) => (
                                <span>
                                  {ele?.type == "local-outward"
                                    ? ele?.count
                                    : ""}
                                </span>
                              ))}
                            </span>
                          )}


                          <br />
                          <span>LOCAL OUT</span>
                        </div>
                      </div>
                      <div className="View-container">
                        <img
                          src="image/graphview.png"
                          alt=""
                          className="subView"
                        />
                      </div>
                    </Card>
                  </Link>
                </div>
              </Row>
              <Row className=" justify-content-center mt-2 mx-2" >
              <div className="col-md-6">
              {userData === "Admin" ? " "  : <>
              <Link 
                 to={`${userData != 'Admin' ? "/Pending" : '/home'}`} >
              <Card className="w-100 p-2 bg-danger">
                      <div className="d-flex justify-content-around align-items-center">
                        <div className="text-center text-white">
            
                       {OGMList?.length}
                          

                          <br />
                          <span>Pending</span>
                        </div>
                      </div>
                      <div className="View-container mx-auto">
                        <img
                          src="image/graphview.png"
                          alt=""
                          className="subView"
                        />
                      </div>
                    </Card>
                    </Link>
              </>
        
              }
                
                  
                </div>
              </Row>
            </Card>
            
                                 
                                  { userData !== "Admin" &&
                                  <Card className="shadow p-2 w-100 mt-5">
             
                                  <Row className="mt-3">
  <Col>
  <a href="/OgmInward">
  <h3 className="text-primary text-center cursor-pointer">OGM INWARD LIST</h3>

  </a>
    <div className="mt-2" style={{ height: '200px', overflowY: 'scroll' }}>
      <ol>
      <ol>
  {OGMList?.slice().reverse().map((e) => (
    <li key={e?.OGM_no} className="text-secondary">
      <strong className="text-uppercase text-bold">OGM number :</strong> {e?.OGM_no}
      <br />
      <strong className="font-italic">C note number :</strong> {e?.c_node_no}
      <br />
      <strong className="font-italic">Branch :</strong> {e?.Frenchises[0]?.city_masters[0]?.city_name}
    </li>
  ))}
</ol>

      </ol>
    </div>
  </Col>
</Row>

             
         
           </Card>
           
                                  }
         
           
          </Col>
      

        </Row>
      </>
    </>
  );
};

export default Homedashboard;
