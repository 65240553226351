import React, { useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faEdit,
  faPlusSquare,
  faSync,
} from "@fortawesome/free-solid-svg-icons";
import Apiservice from "../../Services/Apiservice";
import { Button, Card, Col, Form, Modal, Row, Spinner, Table } from "react-bootstrap";
import { toast, ToastContainer, Zoom } from "react-toastify";
const Allbooking = () => {
  const [showUser, setshowUser] = useState(false);
  const [dataoptions, setdataoptions] = useState([]);
  const [received_id, setreceived_id] = useState("");

  /** Date States**/
  const [rowData, setRowData] = useState()
  const [dateOne, setDate] = useState("");
  const [cNoteFilterFromTo, setCNoteFilterFromTo] = useState({
    from: "",
    to: ""
  })
  const [dateFieldErrorFrom, setDateFieldErrorFrom] = useState('')
  const [dateFieldErrorTo, setDateFieldErrorTo] = useState('')
  const [submitDateLoader, setSubmitDateLoader] = useState(false)


  const rowHeight = 50;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const LocalData = localStorage.getItem("userDetails");
  const LocalData1 = JSON.parse(LocalData);

  useEffect(() => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    const todayDate = yyyy + "-" + mm + "-" + dd;

    // setDate(todayDate);

    setCNoteFilterFromTo({
      ...cNoteFilterFromTo,
      from: todayDate,
      to: todayDate
    })

    // var d = new Date();
    // d.getHours(); // => 9
    // d.getMinutes(); // =>  30
    // d.getSeconds();

    // var hh = d.getHours();
    // var mm = d.getMinutes();

    // const todayTime = hh + ":" + mm;
    // setTime(todayTime);
  }, []);

  // const onGridReady = (params) => {
  //   Apiservice.postdata("/booking/all_booking").then((response) => {
  //     params.api.applyTransaction({
  //       add: response.data,
  //     });
  //     params.columnApi.autoSizeColumns();
  //   });
  // };

  useEffect(async () => {
    const LocalData = localStorage.getItem("userDetails");
    const LocalData1 = JSON.parse(LocalData);
    if (LocalData1.Hub_type == 'NULL') {
      const payload = {
        with_cnote: true
      }
      const apiData = await Apiservice.postdata("/booking/all_booking", payload)
      if (apiData.error == false) {
        setRowData(apiData.data)
      } else {
        console.log('Failure');
      }
    }

    if (LocalData1.Hub_type == 'SUPER') {
      const payload = {
        trans_frenchise_id: LocalData1.id
      }
      const apiData = await Apiservice.postdata("/booking/all_booking", payload)
      if (apiData.error == false) {
        setRowData(apiData.data)
      } else {
        console.log('Failure');
      }
    }

    if (LocalData1.Hub_type == 'LOCAL') {
      const payload = {
        trans_frenchise_id: LocalData1.id
      }
      const apiData = await Apiservice.postdata("/booking/all_booking", payload)
      if (apiData.error == false) {
        setRowData(apiData.data)
      } else {
        console.log('Failure');
      }
    }



  }, [])

  const handleCloseUser = () => {
    setshowUser(false);
  };
  const handleshowUser = (cnote_id, id) => {
    setshowUser(true);
    setreceived_id(id);
    Apiservice.postdata("/booking/c_note_list", {
      frenchise_id: LocalData1.id,
    }).then((response) => {
      if (!response.error) {
        setdataoptions(response.data);
        setshowUser(true);
      } else {
        toast.warn("Error");
      }
    });
  };
  const handleCnoteAssignment = (payloads) => {
    console.log("got called");
    let data = { id: received_id, c_node_no: payloads };
    Apiservice.postdata("/booking/assign_c_node", data).then((response) => {
      if (!response.error) {
        setshowUser(false);
        toast.success("C-Note Assigned");
        handleReloadCnoteTable()
      } else {
        toast.warn("Error");
      }
    });
  };

  const handleReloadCnoteTable = async () => {
    const apiData = await Apiservice.postdata("/booking/all_booking")
    if (apiData.error == false) {
      setRowData(apiData.data)
    } else {
      console.log('fale');
    }
  }


  const onchangedate = (event) => {
    setCNoteFilterFromTo({ ...cNoteFilterFromTo, [event.target.name]: event.target.value })
  }
  const handelFromToSubmit = async (e) => {
    e.preventDefault()
    if (cNoteFilterFromTo.from != '' && cNoteFilterFromTo.to != '') {
      setSubmitDateLoader(true)
      const payload = {
        dateFrom: cNoteFilterFromTo.from == '' ? dateOne : cNoteFilterFromTo.from,
        dateTo: cNoteFilterFromTo.to == '' ? dateOne : cNoteFilterFromTo.to
      }
      const cnoteData = await Apiservice.postdata("/booking/all_booking", payload)
      if (cnoteData.error == false && cnoteData.data.length != 0) {
        setSubmitDateLoader(false)
        setRowData(cnoteData?.data)
        toast.success(cnoteData.message)
      }
      else {
        setSubmitDateLoader(false)
        toast.error('No data was found within this date range')
        setRowData(null)

      }
    } else {

      if (cNoteFilterFromTo.from == '') {
        setDateFieldErrorFrom('please select from date')
      }

      if (cNoteFilterFromTo.to == '') {
        setDateFieldErrorTo('please select to date')
      }

    }
  }


  const DefaultColumnSetting = {
    sortable: true,
    filter: true,

    floatingFilter: true,
    flex: 1,
    resizable: true,
    minWidth: 120,
  };

  const columns = [
    {
      headerName: "Sl.No",
      valueGetter: "node.rowIndex + 1",
      filter: false,
      lockPosition: true,
    },
    {
      headerName: "Branch Name",
      filter: true,
      valueGetter: "data.Frenchises[0].city_masters[0].city_name",
    },
    {
      headerName: "Date of Booking",
      filter: true,
      field: "createdAt",
    },
    {
      headerName: "Order Id",
      filter: true,
      field: "order_id",
    },
    {
      headerName: "C-Note",
      filter: true,
      field: "c_node_no",
      cellRendererFramework: (params) =>
        params.value == null ? (
          <FontAwesomeIcon
            color={"orange"}
            icon={faPlusSquare}
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleshowUser(params.value, params.data.id);
            }}
          />
        ) : (
          params.value
        ),
    },
    {
      headerName: "Pickup Address",
      filter: true,
      field: "pickup_address",
    },
    {
      headerName: "Delivery Address",
      filter: true,
      field: "delivery_address",
    },
    {
      headerName: "Deliver Status",
      filter: false,
      field: "delivery_status",
    },
    {
      headerName: "Payment Status",
      filter: false,
      field: "payment_status",
    },
  ];


  const getRowStyle = (params) => {
    if (params?.data?.delivery_status == 'Delivered') {
      return {
        color: 'white',
        background: '#20c997'
      };
    }
    else {
      return {
        color: 'white',
        background: '#d63384'
      };
    }
  };

  useEffect(() => {
    if (cNoteFilterFromTo.from != '') {
      setDateFieldErrorFrom('')
    }
    if (cNoteFilterFromTo.to != '') {
      setDateFieldErrorTo('')
    }
  }, [cNoteFilterFromTo])


  const handleDateClear = () => {
    const todayDate = '' + "-" + '' + "-" + '';
    setDate(todayDate);
    setCNoteFilterFromTo({
      ...cNoteFilterFromTo,
      from: "",
      to: ""
    })
  }

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        transition={Zoom}
        delay={1000}
        limit={1}
      />
      <div className="more-link" style={{ marginTop: '20px' }} >
        <h6>All Booking </h6>
      </div>
      <Card className="shadow rounded w-100 p-3">
        <div>

          <div className='col' >
            <form onSubmit={handelFromToSubmit} >
              <div className="row">
                <div className="col-md-4" >
                </div>
                <div className="col-md-8" >
                  <div className="row" >
                    <div className="col-md-4">
                      {" "}
                      <Form.Label>From Date</Form.Label>
                      <Form.Control
                        type="date"
                        value={cNoteFilterFromTo.from == '' ? dateOne : cNoteFilterFromTo.from}
                        onChange={onchangedate}
                        name="from"
                        format="DD/MM/YYYY"
                      />
                      <span className="text-danger" >{dateFieldErrorFrom}</span>

                    </div>

                    <div className="col-md-4">
                      <Form.Label>To Date</Form.Label>
                      <Form.Control
                        type="date"
                        value={cNoteFilterFromTo.to == '' ? dateOne : cNoteFilterFromTo.to}
                        onChange={onchangedate}
                        name="to"
                        format="DD/MM/YYYY"
                      />
                      <span className="text-danger" >{dateFieldErrorTo}</span>
                    </div>
                    <div className="col-md-4 mt-3">
                      <button
                        className="btn btn-danger rounded mx-3  text-white mt-3"
                        type="reset"
                        onClick={handleDateClear}
                      >
                        Clear
                      </button>
                      <button
                        className="btn btn-success rounded mx-3  text-white mt-3"
                        // onClick={handelFromToSubmit}
                        type='submit'
                      >
                        Submit {" "}
                        {submitDateLoader ? (
                          <Spinner
                            animation="border"
                            variant="Primary"
                            size="sm"
                          />
                        ) : (
                          ""
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>


        <div
          className="ag-theme-alpine"
          style={{ height: "70vh", width: "100%", marginTop: '30px' }}
        >
          <AgGridReact
            rowHeight={rowHeight}
            columnDefs={columns}
            defaultColDef={DefaultColumnSetting}
            pagination={true}
            paginationPageSize={10}
            enableCellChangeFlash={true}
            getRowStyle={getRowStyle}
            // onGridReady={onGridReady}
            rowData={rowData}
          />
          <Modal
            show={showUser}
            onHide={handleCloseUser}
            backdrop="static"
            keyboard={false}
            centered
            size="lg"
          >
            <h6 className="text-center more-link mt-4">Assign C-note</h6>
            <Modal.Body>
              <Form.Label>Select Cnote</Form.Label>
              <Row>
                {dataoptions.map((opt) => {
                  return (
                    <>
                      <Col md={1} className="">
                        <Form.Check
                          inline
                          label={opt.c_note_no}
                          name="group1"
                          type="radio"
                          id={`inline-radio-1`}
                          onClick={() => {
                            handleCnoteAssignment(opt.c_note_no);
                          }}
                        />
                      </Col>
                    </>
                  );
                })}
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseUser}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </Card>
    </>
  );
};

export default Allbooking;
