import { faSearch, faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import Career from "./Career";
import Franchise from "./Franchise";

const Careermaster = () => {
  const [choice, setchoice] = useState(1);

  const handleSelection = (e) => {
    setchoice(e.target.value);
  };
  return (
    <>
      <div className="more-link">
        <h6>
          Transaction
          <FontAwesomeIcon
            icon={faSync}
            style={{ marginLeft: "4px", fontSize: "12px" }}
          />
        </h6>
      </div>
      <Card className="shadow rounded p-2 w-100">
        <div className="master">
          <Row>
            <Col md={6}>
              <div className="leftedge d-flex justify-content-space">
                <Form.Select
                  aria-label="Default select example"
                  size={"sm"}
                  className="selectsizesmall"
                  onChange={handleSelection}
                >
                  <option value="1">Delivery Partner</option>
                  <option value="2">Franchise</option>
                </Form.Select>
              </div>
            </Col>
            <Col md={6}>
              <div className="rightedge d-flex">
                <div className="wrapper d-flex">
                  <input type="text" />
                  <FontAwesomeIcon
                    icon={faSearch}
                    style={{ marginLeft: "122px" }}
                  />
                </div>
                <Form.Select
                  aria-label="Default select example"
                  size={"sm"}
                  className="selectsizesmall"
                >
                  <option>Open this select menu</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
        <hr />
        {choice == 1 ? <Career /> : ""}
        {choice == 2 ? <Franchise /> : ""}

        {/* Component */}
        <div className="more-link">
          <h6>Load more</h6>
        </div>
      </Card>
    </>
  );
};

export default Careermaster;
