import React, { useEffect, useRef, useState } from "react";
import { FileText } from "react-bootstrap-icons";
import Apiservice from "../../Services/Apiservice";
import { ToastContainer, toast, Zoom } from "react-toastify";
import { Card, Table, Image, Spinner } from "react-bootstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import Select from "react-select";
import { useReactToPrint } from "react-to-print";
import logo from "../../Assets//MoxD_PrimaryLogo1.png";
import Loader from "react-spinner-loader";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TimePicker from 'react-time-picker';


function OgmOutward() {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState(new Date().toLocaleTimeString())
  // useEffect(() => {
  //   // Function to get the current time and set AM/PM
  //   const getCurrentTimeWithAMPM = () => {
  //     const now = new Date();
  //     const hours = now.getHours();
  //     const minutes = now.getMinutes();
  //     let ampm = 'AM';
  //     let formattedHours = hours;

  //     if (hours >= 12) {
  //       ampm = 'PM';
  //       formattedHours = hours === 12 ? 12 : hours - 12;
  //     }

  //     formattedHours = formattedHours < 10 ? `0${formattedHours}` : formattedHours;
  //     const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

     
  //     return `${formattedHours}:${formattedMinutes} ${ampm}`;

  //   };

  //   // Set the initial time as the current time with AM/PM
  //   setSelectedTime(getCurrentTimeWithAMPM());
  // }, []);

  
  


  const [cNoteNumber, setCNoteNumber] = useState("");
  const [userDetails, setUserDetailes] = useState({});
  const [pageReload, setPageReload] = useState(1);
  const [userData, setUserData] = useState();
  const [cNoteData, setCNoteData] = useState('');
  const [cNoteDataError, setCNoteDataError] = useState('');
  const [outwardListPrintingData, setOutwardListPrintingData] = useState([])
  const [allBranchList, setAllBranchList] = useState([]);
  const [selectedBranchName, setSelectedBranchName] = useState([]);
  const [selectedBranchNameError, setSelectedBranchNameError] = useState('')
  const [ogmOutwardSubmitLoader, setOgmOutwardSubmitLoader] = useState(false)
  const [screenLoader, setScreenLoader] = useState(false)
  const [defaultWeight , setDefaultWeight] = useState('100')



  const main = {
    width: "100%",
    marginRight: "auto",
    marginLeft: "8px",
  };
  const bigEle = {
    border: "2px solid black",
  };
  const componentRef = useRef();
  const handelprint = useReactToPrint({ content: () => componentRef.current });

  useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem("userDetails")));
  }, []);

  const handleChange = (ele) => {
    setCNoteData(ele)
  }


  useEffect(async () => {
    let AreaNameData = await Apiservice.postdata(`/User/listCity`, {
      city_name: "",
      isNull: false,
    });
    if (AreaNameData.error == false) {
      const areaData = [];
      const areaMapData = AreaNameData.data.map((ele, ind) => {
        areaData.push({
          label: ele.city_name,
          value: ele.city_name,
          id: ele,
        });
      });
      setAllBranchList(areaData);
    } else {
      console.log("wrong");
    }
  }, []);

  useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem("userDetails")));
  }, []);


  useEffect(() => {
    const LocalData = localStorage.getItem("userDetails");
    const frenchise_id = JSON.parse(LocalData).id;
    var one = [];
    if (cNoteData.includes("\n")) {
      one = cNoteData.split("\n");
    } else {
      one = cNoteData.split(" ");
    }
    const conte = [];
    const data = one.map((ele) => {
      if (ele.includes("\n")) {
        var one1 = ele.split("\n");
        console.log(one1);
      } else {
        var one2 = ele.split(" ");
        console.log(one2);
      }
      if (ele !== "") {
        conte.push(ele);
      }
    });
    setCNoteNumber(conte)
  }, [cNoteData])

  const handleSubmitOutWard = async () => {
    const LocalData = localStorage.getItem("userDetails");
    const frenchise_id = JSON.parse(LocalData).id;

    if (selectedBranchName.length == 0) {
      setSelectedBranchNameError('Please select option')
    }

    if (cNoteNumber.length == 0) {
      setCNoteDataError('Please enter cnote')
    }
    if(defaultWeight === ''){
      alert('add weight')
    }

    if (cNoteNumber.length != 0 && selectedBranchName.length != 0 ) {
      const currentDate = new Date(); // Get the current date and time
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Adding 1 to month because it's zero-based
      const day = String(currentDate.getDate()).padStart(2, '0');
      const hours = String(currentDate.getHours()).padStart(2, '0');
      const minutes = String(currentDate.getMinutes()).padStart(2, '0');
      const seconds = String(currentDate.getSeconds()).padStart(2, '0');
      
      const formattedTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;// Format it as "YYYY-MM-DD HH:MM:SS"
      const payloadOgmOutward = {
        c_node_no: cNoteNumber,
        from_frenchise_id: frenchise_id,
        to_franchise_id: selectedBranchName[0]?.id?.Frenchises[0]?.id,
        type: "outward",
        defaultWeight:defaultWeight,
        // Include selected date
        outward_date: formattedTime, // Include selected time
      };
  
      setOgmOutwardSubmitLoader(true)
      const outwardApi = await Apiservice.postdata(
        "/booking/bulkAssginOGMOut",
        payloadOgmOutward
      );
      if (outwardApi.error == false) {
        setOgmOutwardSubmitLoader(false)
        toast.success(outwardApi.message);
        setCNoteNumber("");
        setPageReload(pageReload + 1);
        setSelectedBranchName([])
        setCNoteData('')

      } else {
        toast.error(outwardApi.message);
        setOgmOutwardSubmitLoader(false)
        setPageReload(pageReload + 1);
        setCNoteNumber("");
        setSelectedBranchName([])
        setCNoteData('')
      }
    }
    else {

    }
  };

  const [rowData, setRowData] = useState();
  useEffect(async () => {
    // window.scrollTo(0, 0);
    const LocalData = localStorage.getItem("userDetails");
    const frenchise_id = JSON.parse(LocalData);
    setUserDetailes(frenchise_id);
    const payload = {
      id: frenchise_id.id,
      status: "outward",
      type: "outward",
    };
    const outwardApi = await Apiservice.postdata(
      "/booking/inout_list",
      payload
    );

    if (outwardApi.error == false) {
      setRowData(outwardApi?.data);
    } else {
    }
  }, [pageReload]);

  const rowHeight = 50;
  const DefaultColumnSetting = {
    sortable: true,
    filter: true,

    floatingFilter: true,
    flex: 1,
    resizable: true,
    minWidth: 120,
  };

  const [colDefs, setColDefs] = useState([
    {
      headerName: "Sl.No",
      valueGetter: "node.rowIndex + 1",
      filter: true,
      lockPosition: true,
    },
    // {
    //   headerName: "OGM_NO",
    //   filter: true,
    //   field: "ogm_no",
    //   cellStyle: function (params) {
    //     if (params) {
    //       return {
    //         color: 'blue',
    //         // backgroundColor: 'green'
    //       };
    //     } else {
    //       return null;
    //     }
    //   }
    // },
    {
      headerName: "OGM_NO",
      filter: true,
      field: "ogm_no",
      cellRenderer: 'ButtonCellRenderer'
    },
    // {
    //   headerName: "BusinessName",
    //   filter: true,
    //   valueGetter: "data.Frenchises[0].BusinessName",
    // },

    // {
    //   headerName: "MobileNumber",
    //   filter: true,
    //   valueGetter: "data.Frenchises[0].MobileNumber",
    // },
    {
      headerName: "BusinessName",
      filter: true,
      cellRendererFramework: (params) => (<center>
        <p>{params?.data?.Frenchises ? params?.data?.Frenchises[0]?.BusinessName : params?.data?.Frenchise?.BusinessName}</p>
      </center>)
    },
    {
      headerName: "MobileNumber",
      filter: true,
      cellRendererFramework: (params) => (<center>
        <p>{params?.data?.Frenchises ? params?.data?.Frenchises[0]?.MobileNumber : params?.data?.Frenchise?.MobileNumber}</p>
      </center>)
    },

    {
      headerName: "Type",
      filter: true,
      field: "type",
    },
    {
      headerName: "Status",
      filter: true,
      field: "order_status",
    },
    {
      headerName: "Outward Date",
      filter: true,
      field: "outward_date",
    },
  ]);
  const getRowStyle = (params) => {
    if (params?.data?.order_status == 'success') {
      return {
        color: 'white',
        background: '#20c997'
      };
    }
    else {
      return {
        color: 'white',
        background: '#d63384'
      };
    }
  };

  function ButtonCellRenderer(props) {
    const onClick = async () => {
      setScreenLoader(true)
      const { data } = props;
      const payload = {
        OGM_no: data?.ogm_no,
      };
      const getOGMOutwardList = await Apiservice.postdata("/booking/tracking_by_id", payload);
      if (getOGMOutwardList.error == false) {
        setScreenLoader(false)
        setOutwardListPrintingData(getOGMOutwardList.data)
        handelprint();
      } else {
        setScreenLoader(false)
      }

    };
    return <b style={{ color: "blue" }} onClick={onClick}>{props.data.ogm_no}</b>;
  }


  const cellClickedHandler = async (e) => {
    const payload = {
      OGM_no: e?.data?.ogm_no,
    };
    const getOGMOutwardList = await Apiservice.postdata("/booking/tracking_by_id", payload);
    if (getOGMOutwardList.error == false) {
      setOutwardListPrintingData(getOGMOutwardList.data)
      handelprint();
    } else {
      console.log('iiiiiiiiii');
    }
  }



  useEffect(() => {
    if (selectedBranchName.length != 0) {
      setSelectedBranchNameError('')
    }
  }, [selectedBranchName])

  useEffect(() => {
    if (cNoteData != '') {
      setCNoteDataError('')
    }
  }, [cNoteData])


  const handleClear = () => {
    setSelectedBranchName([])
    setSelectedBranchNameError('')
    setCNoteDataError('')
    setCNoteData('')
  }

  // console.log(outwardListPrintingData && outwardListPrintingData[0]);

  const handledefaultweight = (e)=> {
setDefaultWeight(e.target.value)
  }

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        transition={Zoom}
        delay={1000}
        limit={1}
      />
      <div className="more-link">
        <h6>
          OGM Outward{" "}
          {/* <FontAwesomeIcon
            icon={faSync}
            style={{ marginLeft: "4px", fontSize: "12px" }}
          /> */}
        </h6>
      </div>

      {/* Loader */}
      <div style={{ position: 'absolute', zIndex: 9999 }} >
        <Loader
          show={screenLoader}
          type="body"
          stack="vertical"
          message="Loading Data"
        />
      </div>

      {/* <div className="row" >
        <div className="col-md-3 ">
          <label for="formFile" class="form-label">
            C-Note Number
          </label>
          <textarea
            type="text"
            class="form-control form-control-md"
            value={cNoteData}
            onChange={(e) => handleChange(e.target.value)}
          />
          <span className="text-danger">{cNoteDataError}</span>
        </div>
        <div className="col-md-3" style={{ marginTop: "33px" }} >
          <button className="btn btn-danger" >Clear</button>
          <button className="btn btn-success" style={{ marginLeft: '20px' }} >Search</button>
        </div>
      </div> */}

      <div className="my-3">
        <div className="card w-100 my-3">
          <div class="card-body pt-0">
            <div className="row">
              <h6 class="card-title    my-3 bg-light w-auto py-2 rounded ">
                <FileText /> <b> OGM Outward</b>
              </h6>
            </div>

            <div className="row my-2 mt-4">
              <div className="col-md-2 ">
                <label for="formFile" class="form-label">
                  C-Note Number
                </label>
                <textarea
                  type="text"
                  class="form-control form-control-md"
                  value={cNoteData}
                  onChange={(e) => handleChange(e.target.value)}
                />
                <span className="text-danger">{cNoteDataError}</span>
              </div>
              <div className="col-md-2">
                <label>Branch Name</label>
                <div className="mt-2">
                  <Select
                    isMulti
                    options={allBranchList}
                    value={selectedBranchName[selectedBranchName.length - 1] || null}
                    onChange={setSelectedBranchName}
                  />
                </div>
                <span className="text-danger" >{selectedBranchNameError}</span>
              </div>
              <div className="col-md-3 " >
  <p>Date</p>

  <DatePicker
  className="form-control"
    selected={selectedDate}
    onChange={(date) => setSelectedDate(date)}
  />
</div>
<div className="col-md-2">
<p>Default Weight in Grms</p>

<input
type="number"
className="form-control"
name="defaultWeight"
value={defaultWeight}
onChange={(e)=>handledefaultweight(e)}
 />
</div>
<div className="col-md-2 ">
  <p>Time</p>
  <TimePicker
  className="form-control"
        value={selectedTime}
        onChange={(time) => setSelectedTime(time)}
        format="hh:mm"
      />
</div>  

              <div className="col-md-2 mt-4">
                <button className="btn btn-danger rounded mt-2" onClick={handleClear} > Cancel</button>
                <button
                  className="btn btn-success rounded mx-3 mt-2"
                  // onClick={handleOgmOutwardSubmit}
                  onClick={handleSubmitOutWard}
                >
                  Submit{" "}
                  {ogmOutwardSubmitLoader ? (
                    <Spinner
                      animation="border"
                      variant="Primary"
                      size="sm"
                    />
                  ) : (
                    ""
                  )}
                </button>
              </div>
            </div>
            <div
              className="ag-theme-alpine"
              style={{ height: "70vh", width: "100%" }}
            >
              <AgGridReact
                rowHeight={rowHeight}
                columnDefs={colDefs}
                defaultColDef={DefaultColumnSetting}
                pagination={true}
                paginationPageSize={10}
                rowData={rowData && rowData}
                getRowStyle={getRowStyle}
                // onCellClicked={cellClickedHandler}
                frameworkComponents={{
                  ButtonCellRenderer
                }}
              />{" "}
            </div>
          </div>
        </div>
      </div>

      {/* From and To Address */}
      <div>
        {
          <div
            ref={componentRef}
            className="container-fluid print-div"
            style={main}
          >
            {
              <div className="p-2 m-0" style={bigEle}>

                <div>
                  {
                    outwardListPrintingData && outwardListPrintingData[0] && outwardListPrintingData[0].OGMs
                      ?
                      <div className="row m-1" style={{ border: '2px solid black', borderRadius: "5px" }} >
                        <div className="col" >
                          <div>
                            <div className="mt-3 mb-1" >
                              <b className="reports-origin-title">Origin</b>
                            </div>
                            <b className="reports-titles" >Branch Name:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.OGMs[0]?.order_trackings[0].FranchiseFrom?.Frenchises[0].city_masters[0].city_name}</b><br></br>
                            <b className="reports-titles"  >Address:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.OGMs[0]?.order_trackings[0].FranchiseFrom?.Frenchises[0].Address}</b><br></br>
                            <b className="reports-titles"  >Pincode:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.OGMs[0]?.order_trackings[0].FranchiseFrom?.Frenchises[0].Pincode}</b><br></br>
                            <b className="reports-titles"  >State Name:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.OGMs[0]?.order_trackings[0].FranchiseFrom?.Frenchises[0].State}</b><br></br>
                            <b className="reports-titles"  >Mobile No:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.OGMs[0]?.order_trackings[0].FranchiseFrom?.Frenchises[0].MobileNumber}</b><br></br>
                          </div>
                        </div>
                        <div className="col"  >
                          <div>
                            <div className="mt-3 mb-1" >
                              <b className="reports-origin-title">Destination</b>
                            </div>
                            <b className="reports-titles"  >Branch Name:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.OGMs[0]?.order_trackings[0].FranchiseTo?.Frenchises[0].city_masters[0].city_name}</b><br></br>
                            <b className="reports-titles"  >Address:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.OGMs[0]?.order_trackings[0].FranchiseTo?.Frenchises[0].Address}</b><br></br>
                            <b className="reports-titles" >Pincode:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.OGMs[0]?.order_trackings[0].FranchiseTo?.Frenchises[0].Pincode}</b><br></br>
                            <b className="reports-titles" >State Name:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.OGMs[0]?.order_trackings[0].FranchiseTo?.Frenchises[0].State}</b><br></br>
                            <b className="reports-titles"  >Mobile No:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.OGMs[0]?.order_trackings[0].FranchiseTo?.Frenchises[0].MobileNumber}</b><br></br>
                          </div>
                        </div>
                      </div>
                      :
                      <div className="row m-1" style={{ border: '2px solid black', borderRadius: "5px" }} >
                        <div className="col" >
                          <div>
                            <div className="mt-3 mb-1" >
                              <b className="reports-origin-title">Origin</b>
                            </div>
                            <b className="reports-titles" >Branch Name:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.order_trackings[0].FranchiseFrom?.Frenchises[0].city_masters[0].city_name}</b><br></br>
                            <b className="reports-titles"  >Address:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.order_trackings[0].FranchiseFrom?.Frenchises[0].Address}</b><br></br>
                            <b className="reports-titles"  >Pincode:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.order_trackings[0].FranchiseFrom?.Frenchises[0].Pincode}</b><br></br>
                            <b className="reports-titles"  >State Name:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.order_trackings[0].FranchiseFrom?.Frenchises[0].State}</b><br></br>
                            <b className="reports-titles"  >Mobile No:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.order_trackings[0].FranchiseFrom?.Frenchises[0].MobileNumber}</b><br></br>
                          </div>
                        </div>
                        <div className="col"  >
                          <div>
                            <div className="mt-3 mb-1" >
                              <b className="reports-origin-title">Destination</b>
                            </div>
                            <b className="reports-titles"  >Branch Name:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.order_trackings[0].FranchiseTo?.Frenchises[0].city_masters[0].city_name}</b><br></br>
                            <b className="reports-titles"  >Address:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.order_trackings[0].FranchiseTo?.Frenchises[0].Address}</b><br></br>
                            <b className="reports-titles" >Pincode:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.order_trackings[0].FranchiseTo?.Frenchises[0].Pincode}</b><br></br>
                            <b className="reports-titles" >State Name:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.order_trackings[0].FranchiseTo?.Frenchises[0].State}</b><br></br>
                            <b className="reports-titles"  >Mobile No:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.order_trackings[0].FranchiseTo?.Frenchises[0].MobileNumber}</b><br></br>
                          </div>
                        </div>
                      </div>
                  }
                </div>
                {
                  outwardListPrintingData && outwardListPrintingData[0] && outwardListPrintingData[0].OGMs
                    ?
                    <div className="row m-3">
                      <div className="col" >
                        <b className="reports-titles" >OGM Number:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.OGMs[0]?.OGM_no}</b>
                      </div>
                      <div className="col" >
                        <b className="reports-titles" >Manifest Date:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.OGMs[0]?.order_trackings[0].updatedAt}</b>
                      </div>
                    </div>
                    :
                    <div className="row m-3">
                      <div className="col" >
                        <b className="reports-titles" >OGM Number:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.OGM_no}</b>
                      </div>
                      <div className="col" >
                        <b className="reports-titles" >Manifest Date:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.order_trackings[0].updatedAt}</b>
                      </div>
                    </div>
                }
                <div style={{ marginTop: "10px" }}>
                  <div className="row" style={{ marginLeft: "0px" }}>
                    <h6 class="card-title my-3 bg-light w-auto py-2 rounded ">
                      <FileText size={25} /> <b className="reports-titles" >Courier Details</b>
                    </h6>
                  </div>
                  <Table
                    className="m-0 text-center"
                    style={{ border: "2px solid black" }}
                    bordered
                    hover
                  >
                    <thead
                      style={{
                        backgroundColor: "#5f7780",
                        color: "#FFFFFF",
                        fontSize: "14px",
                      }}
                    >
                      <tr>
                        <th>Sl.No</th>
                        {/* <th>OGM Number</th> */}
                        <th>C-note Number</th>
                        <th>Order Id</th>
                        <th>Weight</th>
                        <th>No of Pcs</th>
                        <th>Delivery District</th>
                        <th>Delivery State</th>
                        <th>Delivery Pincode</th>
                        <th>Remarks</th>
                      </tr>
                    </thead>
                    <tbody style={{ fontSize: "14px" }}>
                      {
                        outwardListPrintingData.length && (Object.keys(outwardListPrintingData[0]).includes('OGMs')) == true &&
                        outwardListPrintingData.map((ele, ind) => {
                          return <tr>
                            <td>{ind + 1}</td>
                            {/* <td>{ele.OGMs[0]?.OGM_no || 'null'}</td> */}
                            <td>{ele.OGMs[0]?.c_node_no || 'null'}</td>
                            <td>{ele?.order_id || 'null'}</td>
                            <td>{ele?.weight || 'null'}</td>
                            <td>{ele?.no_pcs || 'null'}</td>
                            <td>{ele.OGMs[0]?.order_trackings[0]?.FranchiseTo?.Frenchises[0]?.District || 'null'}</td>
                            <td>{ele.OGMs[0]?.order_trackings[0]?.FranchiseTo?.Frenchises[0]?.State || 'null'}</td>
                            <td>{ele.OGMs[0]?.order_trackings[0]?.FranchiseTo?.Frenchises[0]?.Pincode || 'null'}</td>
                            <td>{ele?.remarks || 'null'}</td>
                          </tr>
                        })
                      }

                      {
                        outwardListPrintingData.length && (Object.keys(outwardListPrintingData[0]).includes('OGMs')) != true &&
                        outwardListPrintingData.map((ele, ind) => {
                          return <tr>
                            <td>{ind + 1}</td>
                            {/* <td>{ele?.OGM_no || 'null'}</td> */}
                            <td>{ele?.c_node_no || 'null'}</td>
                            <td>{ele?.order_id || 'null'}</td>
                            <td>{ele?.weight || 'null'}</td>
                            <td>{ele?.no_pcs || 'null'}</td>
                            <td>{ele?.order_trackings[0]?.FranchiseTo?.Frenchises[0]?.District || 'null'}</td>
                            <td>{ele?.order_trackings[0]?.FranchiseTo?.Frenchises[0]?.State || 'null'}</td>
                            <td>{ele?.order_trackings[0]?.FranchiseTo?.Frenchises[0]?.Pincode || 'null'}</td>
                            <td>{ele?.remarks || 'null'}</td>
                          </tr>
                        })
                      }

                    </tbody>
                  </Table>
                </div>
                <div style={{ textAlign: 'end' }} >
                  <b style={{ fontSize: '15px', marginRight: '10px' }} >POWERED BY MOXD</b>
                </div>
              </div>
            }
          </div>
        }
      </div>






    </div>
  );
}

export default OgmOutward;
