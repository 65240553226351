import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Modal, Row, Table, Image, Spinner } from "react-bootstrap";
import { FileText } from "react-bootstrap-icons";
import { ToastContainer, toast, Zoom } from "react-toastify";
import { useForm } from "react-hook-form";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import DateTimePicker from "react-datetime-picker";
import Apiservice from "../../Services/Apiservice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomButton from "../../common/CustomButton";
import { useReactToPrint } from "react-to-print";
import logo from "../../Assets//MoxD_PrimaryLogo1.png";
import Loader from "react-spinner-loader";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TimePicker from 'react-time-picker';


export const productsGenerator = (quantity) => {
  const items = [];
  for (let i = 0; i < quantity; i++) {
    items.push({ id: i, name: `Item name ${i}`, price: 2100 + i });
  }
  return items;
};

const products = productsGenerator(100);

const columns = [
  {
    dataField: "id",
    text: "Product ID",
    sort: true,
  },
  {
    dataField: "name",
    text: "Product Name",
    sort: true,
  },
  {
    dataField: "price",
    text: "Product Price",
  },
];

/** validation **/
function LocalInward() {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState(new Date().toLocaleTimeString())
  const [ogmNumber, setOgmNumber] = useState("");
  const [ogmNumberError, setOgmNumberError] = useState('')
  const [value, onChange] = useState(new Date());
  const [inwardTable, setInwardTable] = useState([]);

  const [ogmList, setOgmList] = useState([]);
  const [cNodedata, setCNoteData] = useState({});
  const [inOutList, setInOutList] = useState([]);
  const [ogmDetialsPop, setOgmDetailsPop] = useState(false);
  const [totalWeight, setTotalWeight] = useState("");
  const [disableInput, setDisableInput] = useState([]);
  const [acceptCount, setAcceptCount] = useState([]);
  const [cnote, setCnote] = useState([]);
  const [cnoteNumber, setCNoteNumber] = useState([]);
  const [textArea, setTextArea] = useState('');
  const [textAreaError, setTextAreaError] = useState('');

  const [textArea1, setTextArea1] = useState([]);
  const [textArea2, setTextArea2] = useState([]);

  const [ogmNumberFilter, setOgmNumberFilter] = useState("");
  const [cnotevisibile, setCnotevisibile] = useState('none');
  const [userData, setUserData] = useState();
  const [outwardListPrintingData, setOutwardListPrintingData] = useState([])
  const [localInwardSubmitLoadr, setLocalInwardSubmitLoadr] = useState(false)
  const [finalSubmitLoader, setFinalSubmitLoader] = useState(false)
  const [verifyLoader, setVerifyLoader] = useState(false)
  const [screenLoader, setScreenLoader] = useState(false)




  const main = {
    width: "100%",
    marginRight: "auto",
    marginLeft: "8px",
  };
  const bigEle = {
    border: "2px solid black",
  };
  const componentRef = useRef();
  const handelprint = useReactToPrint({ content: () => componentRef.current });

  useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem("userDetails")));
  }, []);


  useEffect(() => {
    window.scrollTo(0, 0);
    getInOutList();
    let user = JSON.parse(localStorage.getItem("userDetails"));
  }, []);

  const getInOutList = async () => {
    let user = await JSON.parse(localStorage.getItem("userDetails"));
    let data = {
      id: user.id,
      status: "inward",
      type: "local-inward"
    };
    let res = await Apiservice.inOutList(data);
    // const res = await Apiservice.postdata("/booking/inwordListsss",data);
    if (!res.error) {
      setInOutList(res.data);
      setRowData(res.data);
    } else {
      console.log("something went");
    }
  };

  // const {
  //   register,
  //   handleSubmit,
  //   formState: { errors },
  // } = useForm();

  const handlechange = (ele) => {
    setOgmNumber(ele)
    setOgmNumberFilter(ele);

  }
  const handleSubmitOgm = (data) => {
    const str = ogmNumber;
    // setOgmNumberFilter(ogmNumber);
    const res = str.split(" ");
    const mainData = [];
    res.map((ele, ind) => {
      if (ele != "") {
        mainData.push(ele);
      }
    });
    handleInwardApi(mainData);
  };

  const onError = (error) => {
    console.log(error, "error");
  };
  /******** inward Api Integration *********/
  // const handleInwardApi = async (ogmnunber) => {
  //   const one = await localStorage.getItem('userDetails');
  //   const inwardPayload = {
  //     ogm_no: ogmnunber,
  //     id: JSON.parse(one).id,
  //     status: 'inward'

  //   };
  //   const inward = await Apiservice.postdata(
  //     "/booking/inwordListsss",
  //     inwardPayload
  //   );
  //   console.log(inward, "inward");
  //   if (inward.error == false) {
  //     setInwardTable(inward.data);
  //     toast.success(inward.message);
  //     setOgmNumber('')
  //   } else {
  //     toast.error(inward.message);
  //     setOgmNumber("");
  //   }
  // };

  const handleInwardApi = async (ogmnunber) => {
    if (ogmnunber.length == 0) {
      setOgmNumberError('Please enter ogm number')
    } else {
      const userID = await localStorage.getItem("userDetails");
      const ParseduserID = JSON.parse(userID);
      const inwardPayload = {
        ogm_no: ogmnunber,
        frenchise_id_to: ParseduserID.id,
        type: 'local-outward'
      };
      setLocalInwardSubmitLoadr(true)
      const inward = await Apiservice.postdata(
        "/booking/inwordList",
        inwardPayload
      );
      if (inward.error == false) {
        setLocalInwardSubmitLoadr(false)
        console.log(inwardPayload);
        setInwardTable(inward.data);
        toast.success(inward.message);
        setOgmNumber("");
        setCnotevisibile('block');
        const one = [];
        const cnote = inward.data.map((ele, ind) => {
          one.push(ele.c_node_no);
        });
        setCNoteData(one);
      } else {
        setLocalInwardSubmitLoadr(false)
        toast.error(inward.message);
        setOgmNumber("");
        setCnotevisibile('none');
      }

    }



  };

  useEffect(() => {
    handleInwardApi();
  }, []);

  const getOgmList = async (ogmInput) => {
    let ogm = await Apiservice.getInWordList({
      ogm_no: ogmInput,
    });
    if (!ogm.error) {
      setOgmList(ogm.data);
    } else {
      console.log("the ogm list is", ogm);
    }
  };

  const togglePop = (ogm) => {
    setOgmDetailsPop(!ogmDetialsPop);
    setDisableInput("");
    setAcceptCount("");
    setCnote("");
    getOgmList(ogm);
  };

  const handleOgmInput = (e) => {
    setCNoteData(e.target.value);
    // let tmpcnote = { ...cNodedata };
    // tmpcnote[id] = e.target.value;
    // setCNoteData(tmpcnote);
  };

  const handleSubmitData = async () => {
    setFinalSubmitLoader(true)
    const currentDate = new Date(); // Get the current date and time
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Adding 1 to month because it's zero-based
    const day = String(currentDate.getDate()).padStart(2, '0');
    const hours = String(currentDate.getHours()).padStart(2, '0');
    const minutes = String(currentDate.getMinutes()).padStart(2, '0');
    const seconds = String(currentDate.getSeconds()).padStart(2, '0');
    const formattedTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;// Format it as "YYYY-MM-DD HH:MM:SS"
    const payload = {
      from_frenchise_id:
        inwardTable[0].order_trackings[0].frenchise_id_from_trans,
      to_franchise_id: inwardTable[0].order_trackings[0].frenchise_id_to_trans,
      ogm_no: inwardTable[0].OGM_no,
      c_note: cNodedata,
      type: "local-inward",
      inward_date:formattedTime,
    };
    let inwordSubmit = await Apiservice.postdata(
      "/booking/inwordSubmit",
      payload
    );
    if (!inwordSubmit.error) {
      setFinalSubmitLoader(false)
      toast.success("Successfully Submited");
      setOgmDetailsPop(false);
      window.location.reload()
    } else {
      setFinalSubmitLoader(false)
      toast.error(inwordSubmit.message);
      setOgmDetailsPop(false);
    }
  };

  const [showButton, setShowButton] = useState(false)
  const [showverify, setShowverify] = useState(false);

  const applVrrification = async () => {
 
    let data = {
      ogm_no: ogmNumberFilter,
      c_node_no: textArea1,
  
    };
    setVerifyLoader(true)

    let vdata = await Apiservice.verifyNode(data);
    if (!vdata.error) {
      // toast.success(`C Note ${ele.c_node_no} verification done`);
      // setDisableInput([...disableInput, ind]);
      // setAcceptCount([...acceptCount, { count: cNodedata }]);
      // setCnote([...cnote, cNodedata]);
      // getOgmList();
      setVerifyLoader(false)
      toast.success(vdata.data);
      setShowButton(true)
    } else {
      setVerifyLoader(false)
      toast.error(vdata.message);
      console.log("someting went wrong");
    }
  };

  const handleTextArea = (e) => {
    setTextArea(e);
  };

  useEffect(() => {
    var one = [];
    if (textArea && textArea?.includes("\n")) {
      one = textArea && textArea?.split("\n");
    } else {
      one = textArea && textArea?.split(" ");
    }
    const conte = [];
    const data = one && one?.map((ele) => {
      if (ele.includes("\n")) {
        var one1 = ele?.split("\n");
        console.log(one1);
      } else {
        var one2 = ele?.split(" ");
      }
      if (ele !== "") {
        conte.push(+ele);
      }
    });
    setTextArea2(conte);
  }, [textArea])

  const handleSubmitCnote = () => {
    if (textArea == '') {
      setTextAreaError('Please enter cnote number')
    } else {
      setTextArea1(textArea2);
      setShowverify(true);
      setTextArea('')
    }
  };

  const rowHeight = 50;

  const [rowData, setRowData] = useState()
  const DefaultColumnSetting = {
    sortable: true,
    filter: true,

    floatingFilter: true,
    flex: 1,
    resizable: true,
    minWidth: 120,
  };

  const [colDefs, setColDefs] = useState(
    [
      {
        headerName: "#",
        valueGetter: "node.rowIndex + 1",
        filter: true,
        lockPosition: true,
      },
      {
        headerName: "OGM_NO",
        filter: true,
        field: "ogm_no",
        cellRenderer: 'ButtonCellRenderer'
      },
      // {
      //   headerName: 'BusinessName',
      //   filter: true,
      //   valueGetter: 'data?.Frenchises[0]?.BusinessName'
      // },
      // {
      //   headerName: "MobileNumber",
      //   filter: true,
      //   valueGetter: 'data?.Frenchises[0]?.MobileNumber'
      // },
      {
        headerName: "BusinessName",
        filter: true,
        cellRendererFramework: (params) => (<center>
          <p>{params?.data?.Frenchises ? params?.data?.Frenchises[0]?.BusinessName : params?.data?.Frenchise?.BusinessName}</p>
        </center>)
      },
      {
        headerName: "MobileNumber",
        filter: true,
        cellRendererFramework: (params) => (<center>
          <p>{params?.data?.Frenchises ? params?.data?.Frenchises[0]?.MobileNumber : params?.data?.Frenchise?.MobileNumber}</p>
        </center>)
      },
      {
        headerName: "Type",
        filter: true,
        field: "type",
      },
      {
        headerName: "Status",
        filter: true,
        field: "order_status",
      },
      {
        headerName: "Inward Date",
        filter: true,
        field: "inward_date",
      }

    ])


  const getRowStyle = (params) => {
    if (params?.data?.order_status == 'success') {
      return {
        color: 'white',
        background: '#20c997'
      };
    }
    else {
      return {
        color: 'white',
        background: '#d63384'
      };
    }
  };

  function ButtonCellRenderer(props) {
    const onClick = async () => {
      setScreenLoader(true)
      const { data } = props;
      const payload = {
        OGM_no: data?.ogm_no,
      };
      const getOGMOutwardList = await Apiservice.postdata("/booking/tracking_by_id", payload);
      if (getOGMOutwardList.error == false) {
        setScreenLoader(false)
        setOutwardListPrintingData(getOGMOutwardList.data)
        handelprint();
      } else {
        setScreenLoader(false)
      }

    };
    return <b style={{ color: "blue" }} onClick={onClick}>{props.data.ogm_no}</b>;
  }



  const cellClickedHandler = async (e) => {
    const payload = {
      OGM_no: e?.data?.ogm_no,
    };
    const getOGMOutwardList = await Apiservice.postdata("/booking/tracking_by_id", payload);
    if (getOGMOutwardList.error == false) {
      setOutwardListPrintingData(getOGMOutwardList.data)
      handelprint();
    } else {
      console.log('iiiiiiiiii');
    }
  }

  const handleOgmClear = () => {
    setOgmNumber('')
  }

  const handleClearCnoteNumber = () => {
    setTextArea('')
  }

  useEffect(() => {
    if (ogmNumber != '') {
      setOgmNumberError('')
    }
  }, [ogmNumber])

  useEffect(() => {
    if (textArea != '') {
      setTextAreaError('')
    }
  }, [textArea])

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        transition={Zoom}
        delay={1000}
        limit={1}
      />

      {/* Loader */}
      <div style={{ position: 'absolute', zIndex: 9999 }} >
        <Loader
          show={screenLoader}
          type="body"
          stack="vertical"
          message="Loading Data"
        />
      </div>

      <div className="more-link">
        <h6>
          Local Inward{" "}
        </h6>
      </div>
      <div className="my-3">
        <div className="card w-100 my-3">
          <div class="card-body pt-0">
            <div className="row">
              <h6 class="card-title    my-3 bg-light w-auto py-2 rounded ">
                <FileText /> <b>Local Inward</b>
              </h6>
            </div>
            <div className="row my-2 mt-4">
              <div className="col-md-3">
                <label for="formFile" class="form-label">
                  OGM Number
                </label>
                <textarea
                  type="text"
                  value={ogmNumber}
                  class="form-control form-control-md"
                  onChange={(e) => handlechange(e.target.value)}
                />
                <span className="text-danger" >{ogmNumberError}</span>
              </div>
              <div className="col-md-3 mt-4">
                <div className="mt-2">
                  <button
                    className="btn btn-danger btn-md rounded "
                    onClick={handleOgmClear}
                  >Clear</button>
                  <button
                    // title="Submit"
                    // colour="success"
                    // onClick={handleSubmit(onSubmit, onError)}
                    className="btn btn-primary btn-md rounded"
                    onClick={handleSubmitOgm}
                    style={{ marginLeft: '15px' }}
                  >
                    Submit{" "}
                    {localInwardSubmitLoadr ? (
                      <Spinner
                        animation="border"
                        variant="Primary"
                        size="sm"
                      />
                    ) : (
                      ""
                    )}
                  </button>
                </div>
              </div>

              <div className="col-md-3 " style={{ display: cnotevisibile }}  >
  <p>Date</p>

  <DatePicker
  className="form-control"
    selected={selectedDate}
    onChange={(date) => setSelectedDate(date)}
  />
</div>
<div className="col-md-2 " style={{ display: cnotevisibile }} >
  <p>Time</p>
  <TimePicker
  className="form-control"
        value={selectedTime}
        onChange={(time) => setSelectedTime(time)}
        format="hh:mm"
      />
</div>  


              <div style={{ display: cnotevisibile }} className="col-md-3">
                <label for="formFile" class="form-label">
                  C-Note Number
                </label>
                <textarea
                  type="text"
                  class="form-control form-control-md"
                  value={textArea}
                  onChange={(e) => handleTextArea(e.target.value)}
                />
                <span className="text-danger" >{textAreaError}</span>
              </div>
              <div style={{ display: cnotevisibile }} className="col-md-3 mt-4">
                <div className="mt-2">
                  <CustomButton
                    title="Clear"
                    colour="danger"
                    onClick={handleClearCnoteNumber}
                  />
                  <CustomButton
                    title="Submit"
                    colour="success"
                    onClick={handleSubmitCnote}
                  />
                </div>
              </div>
            </div>

            {
              <div className="mt-3">
                <table className="table table-bordered table-hover text-center">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Order ID</th>
                      <th>C Note</th>
                      <th>OGM Number</th>
                      <th>Weight</th>
                      {
                        //  <td className="table-headings">Action</td>
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {inwardTable.map((e, i) => {
                      // console.log(+e.c_node_no,'e.c_node_no');

                      return (
                        <tr
                          className={
                            textArea1 == ""
                              ? ""
                              : textArea1.includes(+e.c_node_no) == false
                                ? "bg-secondary"
                                : "bg-warning"
                          }
                        >
                          <td>{i + 1}</td>
                          <td>{e.order_transaction ? e.order_transaction.order_id : " "}</td>
                          <td>{e.c_node_no}</td>
                          <td>{e.OGM_no}</td>
                          <td>{e.order_transaction ? e.order_transaction.weight : " "}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div className="row">
                  <div className="col-8"></div>
                  <div className="col-2">
                    {
                      showButton == true ?
                        <button
                          className=" btn btn-primary btn-md rounded "
                          onClick={handleSubmitData}
                        >
                          Submit{" "}
                          {finalSubmitLoader ? (
                            <Spinner
                              animation="border"
                              variant="Primary"
                              size="sm"
                            />
                          ) : (
                            ""
                          )}
                        </button> : ''
                    }
                  </div>
                  <div className="col-2">
                    {showverify == true ?
                      <button
                        className=" btn btn-outline-success rounded "
                        onClick={applVrrification}
                      >
                        Verify{" "}
                        {verifyLoader ? (
                          <Spinner
                            animation="border"
                            variant="Primary"
                            size="sm"
                          />
                        ) : (
                          ""
                        )}
                      </button> : ''
                    }
                  </div>
                </div>
              </div>
            }
            {/* <div className="my-3 mt-5">
              <table size="sm" className="table table-bordered table-hover text-center">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>BusinessName</th>
                    <th>MobileNumber</th>
                    <th>Type</th>
                    <th>OGM_NO</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {inOutList.map((e, i) => {
                    return (
                      <tr>
                        <td>{i + 1}</td>
                        <td>{e["Frenchises.BusinessName"]}</td>
                        <td>{e["Frenchises.MobileNumber"]}</td>
                        <td>{e.type}</td>
                        <td>{e.ogm_no}</td>
                        <td>{e.order_status}</td>
                        <td></td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div> */}
            <div
              className="ag-theme-alpine"
              style={{ height: "70vh", width: "100%", marginTop: "30px" }}
            >
              <AgGridReact
                rowHeight={rowHeight}
                columnDefs={colDefs}
                defaultColDef={DefaultColumnSetting}
                pagination={true}
                paginationPageSize={10}
                rowData={rowData}
                getRowStyle={getRowStyle}
                // onCellClicked={cellClickedHandler}
                frameworkComponents={{
                  ButtonCellRenderer
                }}
              />{" "}
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={ogmDetialsPop}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <h6 className="text-center more-link mt-4">Inver Ogm Details</h6>
        <Modal.Body>
          {ogmList.length > 0 ? (
            <div>
              <Row>
                <Col>
                  <table className="table table-bordered table-hover text-center">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Order ID</th>
                        <th>C Note</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ogmList.map((e, i) => {
                        return (
                          <tr key={e.id}>
                            <td>{i + 1}</td>
                            <td>{e.id}</td>
                            <td>
                              {e.active ? (
                                e.c_node_no
                              ) : (
                                <input
                                  className=""
                                  onChange={(event) => handleOgmInput(event)}
                                  disabled={disableInput.includes(i)}
                                  placeholder="C note Number"
                                />
                              )}
                            </td>
                            <td>
                              {e.active ? (
                                <span className="verified-text">Verified</span>
                              ) : (
                                <button className="verify-conote-number">
                                  Verify
                                </button>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>

                  <div className="row justify-content-end">
                    <div className="col-4">
                      <span className="weight-input-field">Total Weight:</span>
                      <span>
                        {" "}
                        <td>
                          <input
                            type="number"
                          // onChange={(e) => setTotalWeight(e.target.value)}
                          />
                        </td>
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
              {/* <button className="CreateOgm-button">Create OGM</button> */}
            </div>
          ) : (
            "No Record Found"
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            variant="secondary"
            className="btn btn-secondary"
          // onClick={handleSubmitData}
          >
            Submit
          </button>
          <Button variant="secondary" onClick={togglePop}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>


      {/* From and To Address */}
      <div>
        {
          <div
            ref={componentRef}
            className="container-fluid print-div"
            style={main}
          >
            {
              <div className="p-2 m-0" style={bigEle}>

                <div>
                  {
                    outwardListPrintingData && outwardListPrintingData[0] && outwardListPrintingData[0].OGMs
                      ?
                      <div className="row m-1" style={{ border: '2px solid black', borderRadius: "5px" }} >
                        <div className="col" >
                          <div>
                            <div className="mt-3 mb-1" >
                              <b className="reports-origin-title">Origin</b>
                            </div>
                            <b className="reports-titles" >Branch Name:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.OGMs[0]?.order_trackings[0].FranchiseFrom?.Frenchises[0].city_masters[0].city_name}</b><br></br>
                            <b className="reports-titles"  >Address:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.OGMs[0]?.order_trackings[0].FranchiseFrom?.Frenchises[0].Address}</b><br></br>
                            <b className="reports-titles"  >Pincode:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.OGMs[0]?.order_trackings[0].FranchiseFrom?.Frenchises[0].Pincode}</b><br></br>
                            <b className="reports-titles"  >State Name:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.OGMs[0]?.order_trackings[0].FranchiseFrom?.Frenchises[0].State}</b><br></br>
                            <b className="reports-titles"  >Mobile No:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.OGMs[0]?.order_trackings[0].FranchiseFrom?.Frenchises[0].MobileNumber}</b><br></br>
                          </div>
                        </div>
                        <div className="col"  >
                          <div>
                            <div className="mt-3 mb-1" >
                              <b className="reports-origin-title">Destination</b>
                            </div>
                            <b className="reports-titles"  >Branch Name:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.OGMs[0]?.order_trackings[0].FranchiseTo?.Frenchises[0].city_masters[0].city_name}</b><br></br>
                            <b className="reports-titles"  >Address:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.OGMs[0]?.order_trackings[0].FranchiseTo?.Frenchises[0].Address}</b><br></br>
                            <b className="reports-titles" >Pincode:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.OGMs[0]?.order_trackings[0].FranchiseTo?.Frenchises[0].Pincode}</b><br></br>
                            <b className="reports-titles" >State Name:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.OGMs[0]?.order_trackings[0].FranchiseTo?.Frenchises[0].State}</b><br></br>
                            <b className="reports-titles"  >Mobile No:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.OGMs[0]?.order_trackings[0].FranchiseTo?.Frenchises[0].MobileNumber}</b><br></br>
                          </div>
                        </div>
                      </div>
                      :
                      <div className="row m-1" style={{ border: '2px solid black', borderRadius: "5px" }} >
                        <div className="col" >
                          <div>
                            <div className="mt-3 mb-1" >
                              <b className="reports-origin-title">Origin</b>
                            </div>
                            <b className="reports-titles" >Branch Name:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.order_trackings[0].FranchiseFrom?.Frenchises[0].city_masters[0].city_name}</b><br></br>
                            <b className="reports-titles"  >Address:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.order_trackings[0].FranchiseFrom?.Frenchises[0].Address}</b><br></br>
                            <b className="reports-titles"  >Pincode:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.order_trackings[0].FranchiseFrom?.Frenchises[0].Pincode}</b><br></br>
                            <b className="reports-titles"  >State Name:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.order_trackings[0].FranchiseFrom?.Frenchises[0].State}</b><br></br>
                            <b className="reports-titles"  >Mobile No:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.order_trackings[0].FranchiseFrom?.Frenchises[0].MobileNumber}</b><br></br>
                          </div>
                        </div>
                        <div className="col"  >
                          <div>
                            <div className="mt-3 mb-1" >
                              <b className="reports-origin-title">Destination</b>
                            </div>
                            <b className="reports-titles"  >Branch Name:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.order_trackings[0].FranchiseTo?.Frenchises[0].city_masters[0].city_name}</b><br></br>
                            <b className="reports-titles"  >Address:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.order_trackings[0].FranchiseTo?.Frenchises[0].Address}</b><br></br>
                            <b className="reports-titles" >Pincode:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.order_trackings[0].FranchiseTo?.Frenchises[0].Pincode}</b><br></br>
                            <b className="reports-titles" >State Name:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.order_trackings[0].FranchiseTo?.Frenchises[0].State}</b><br></br>
                            <b className="reports-titles"  >Mobile No:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.order_trackings[0].FranchiseTo?.Frenchises[0].MobileNumber}</b><br></br>
                          </div>
                        </div>
                      </div>
                  }
                </div>
                {
                  outwardListPrintingData && outwardListPrintingData[0] && outwardListPrintingData[0].OGMs
                    ?
                    <div className="row m-3">
                      <div className="col" >
                        <b className="reports-titles" >OGM Number:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.OGMs[0]?.OGM_no}</b>
                      </div>
                      <div className="col" >
                        <b className="reports-titles" >Manifest Date:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.OGMs[0]?.order_trackings[0].updatedAt}</b>
                      </div>
                    </div>
                    :
                    <div className="row m-3">
                      <div className="col" >
                        <b className="reports-titles" >OGM Number:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.OGM_no}</b>
                      </div>
                      <div className="col" >
                        <b className="reports-titles" >Manifest Date:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.order_trackings[0].updatedAt}</b>
                      </div>
                    </div>
                }
                <div style={{ marginTop: "10px" }}>
                  <div className="row" style={{ marginLeft: "0px" }}>
                    <h6 class="card-title my-3 bg-light w-auto py-2 rounded ">
                      <FileText size={25} /> <b className="reports-titles" >Courier Details</b>
                    </h6>
                  </div>
                  <Table
                    className="m-0 text-center"
                    style={{ border: "2px solid black" }}
                    bordered
                    hover
                  >
                    <thead
                      style={{
                        backgroundColor: "#5f7780",
                        color: "#FFFFFF",
                        fontSize: "14px",
                      }}
                    >
                      <tr>
                        <th>Sl.No</th>
                        {/* <th>OGM Number</th> */}
                        <th>C-note Number</th>
                        <th>Order Id</th>
                        <th>Weight</th>
                        <th>No of Pcs</th>
                        <th>Delivery District</th>
                        <th>Delivery State</th>
                        <th>Delivery Pincode</th>
                        <th>Remarks</th>
                      </tr>
                    </thead>
                    <tbody style={{ fontSize: "14px" }}>
                      {
                        outwardListPrintingData.length && (Object.keys(outwardListPrintingData[0]).includes('OGMs')) == true &&
                        outwardListPrintingData.map((ele, ind) => {
                          return <tr>
                            <td>{ind + 1}</td>
                            {/* <td>{ele.OGMs[0]?.OGM_no || 'null'}</td> */}
                            <td>{ele.OGMs[0]?.c_node_no || 'null'}</td>
                            <td>{ele?.order_id || 'null'}</td>
                            <td>{ele?.weight || 'null'}</td>
                            <td>{ele?.no_pcs || 'null'}</td>
                            <td>{ele.OGMs[0]?.order_trackings[0]?.FranchiseTo?.Frenchises[0]?.District || 'null'}</td>
                            <td>{ele.OGMs[0]?.order_trackings[0]?.FranchiseTo?.Frenchises[0]?.State || 'null'}</td>
                            <td>{ele.OGMs[0]?.order_trackings[0]?.FranchiseTo?.Frenchises[0]?.Pincode || 'null'}</td>
                            <td>{ele?.remarks || 'null'}</td>
                          </tr>
                        })
                      }

                      {
                        outwardListPrintingData.length && (Object.keys(outwardListPrintingData[0]).includes('OGMs')) != true &&
                        outwardListPrintingData.map((ele, ind) => {
                          return <tr>
                            <td>{ind + 1}</td>
                            {/* <td>{ele?.OGM_no || 'null'}</td> */}
                            <td>{ele?.c_node_no || 'null'}</td>
                            <td>{ele?.order_id || 'null'}</td>
                            <td>{ele?.weight || 'null'}</td>
                            <td>{ele?.no_pcs || 'null'}</td>
                            <td>{ele?.order_trackings[0]?.FranchiseTo?.Frenchises[0]?.District || 'null'}</td>
                            <td>{ele?.order_trackings[0]?.FranchiseTo?.Frenchises[0]?.State || 'null'}</td>
                            <td>{ele?.order_trackings[0]?.FranchiseTo?.Frenchises[0]?.Pincode || 'null'}</td>
                            <td>{ele?.remarks || 'null'}</td>
                          </tr>
                        })
                      }

                    </tbody>
                  </Table>
                </div>
                <div style={{ textAlign: 'end' }} >
                  <b style={{ fontSize: '15px', marginRight: '10px' }} >POWERED BY MOXD</b>
                </div>
              </div>
            }
          </div>
        }
      </div>



    </div>
  );
}

export default LocalInward;
