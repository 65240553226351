import React, { useState } from "react";
import { Route, Switch } from "react-router-dom";

import Transactionmaster from "../pages/Transaction/TransactionMaster";
import Accountmaster from "../pages/Account/AccountMaster";
import Mastertracking from "../pages/Tracking/MasterTracking";
import Branchlist from "../pages/BranchList/Branchlist";
import Complainmaster from "../pages/Complain/ComplainMaster";
import Reportmaster from "../pages/Reports/ReportMaster";
import Careermaster from "../pages/Career/CareerMaster";
import Homedashboard from "../pages/DashBoardHome/Homedashboard";
import Master from "../pages/Setupmaster/Master";
import Areamaster from "../pages/Setupmaster/Areamaster";
import Pincodemaster from "../pages/Setupmaster/PinCodemaster";
import ProtectedRoute from "./ProtectedRoute";
import Ogm from "../pages/Ogm/Ogm";
import OgmInward from "../pages/Transaction/OgmInward";
import OgmOutward from "../pages/Transaction/OgmOutward";
import Notification from "../pages/Notification/Notification";
import OutForDelivery from "../pages/Transaction/OutForDelivery";
import Booking from "../pages/Transaction/Booking";
import FastBooking from "../pages/Transaction/FastBooking";
import ReturnToOrigin from "../pages/Transaction/ReturnToOrigin";
import BookingList from "../pages/Transaction/BookingList";
import Allbooking from "../pages/Transaction/AllBooking";
import wallet from "../pages/wallet";
import CNoteNumber from "../pages/Transaction/CNoteNumber";
import LocalInward from "../pages/Transaction/LocalInward";
import LocalOutward from "../pages/Transaction/LocalOutward";
import CouponCodeList from "../pages/CouponCode/CouponCodeList";
import MasterDeliveryPartner from "../pages/Setupmaster/MasterDeliveryPartner";
import Pending from "../pages/Transaction/Pending"
const ViewRouter = () => {
  return (
    <Switch>
      {/* <ProtectedRoute path='/details' exact component={Details} /> */}
      <ProtectedRoute exact path="/home" component={Homedashboard} />
      <ProtectedRoute exact path="/" component={Homedashboard} />
      <ProtectedRoute exact path="/master" component={Master} />{" "}
      <ProtectedRoute exact path="/transaction" component={Transactionmaster} />
      <ProtectedRoute exact path="/accountmaster" component={Accountmaster} />
      <ProtectedRoute exact path="/tracking" component={Mastertracking} />
      <ProtectedRoute exact path="/branchlist" component={Branchlist} />
      <ProtectedRoute exact path="/complainmaster" component={Complainmaster} />
      <ProtectedRoute exact path="/report" component={Reportmaster} />
      <ProtectedRoute exact path="/career" component={Careermaster} />
      <ProtectedRoute exact path="/ogm" component={Ogm} />
      <ProtectedRoute exact path="/booking" component={Booking} />
      <ProtectedRoute exact path="/notification" component={Notification} />
      <ProtectedRoute exact path="/bookinglist" component={BookingList} />
      <ProtectedRoute exact path="/fastbooking" component={FastBooking} />
      <ProtectedRoute exact path="/Cnote" component={CNoteNumber} />
      <ProtectedRoute exact path="/ogminward" component={OgmInward} />
      <ProtectedRoute exact path="/ogmoutward" component={OgmOutward} />
      <ProtectedRoute exact path="/outfordelivery" component={OutForDelivery} />
      <ProtectedRoute exact path="/LocalInward" component={LocalInward} />
      <ProtectedRoute exact path="/Pending" component={Pending} />

      <ProtectedRoute exact path="/LocalOutward" component={LocalOutward} />
      <ProtectedRoute exact path="/returntoorigin" component={ReturnToOrigin} />
      <ProtectedRoute exact path="/Areamaster" component={Areamaster} />
      <ProtectedRoute
        exact
        path="/Deliverypartner"
        component={MasterDeliveryPartner}
      />
      <ProtectedRoute exact path="/Pincodemaster" component={Pincodemaster} />
      <ProtectedRoute exact path="/Allbooking" component={Allbooking} />
      <ProtectedRoute exact path="/Wallet" component={wallet} />
      <ProtectedRoute exact path="/CouponCodeList" component={CouponCodeList} />
    </Switch>
  );
};

export default ViewRouter;
