import React from "react";
import { Form } from "react-bootstrap";
import { FileText } from "react-bootstrap-icons";
import Select from "react-select";
import { ToastContainer, toast, Zoom } from "react-toastify";

const Type = [
  { label: "Branch", value: "Branch" },
  { label: "Local", value: "Local" },
  { label: "Sagar", value: "Sagar" },
  { label: "Rohit", value: "Rohit" },
  { label: "Ram", value: "Ram" },
];

const ForwardTo = [
  { label: "Bhopal", value: "Bhopal" },
  { label: "Maharashtra", value: "Maharashtra" },
  { label: "Kerala", value: "Kerala" },
];

const Reason = [
  { label: "Incoming", value: "Incoming" },
  { label: "Outcoming", value: "Outcoming" },
];
function ReturnToOrigin() {
  const handleFastBookingSave = () => {
    console.log("handleFastBookingSave");
  };
  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        transition={Zoom}
        delay={1000}
        limit={1}
      />
      <div className="my-3">
        <div className="card w-100">
          <div class="card-body pt-0">
            <div className="row ">
              <h6 class="card-title my-3 bg-light w-auto py-2 rounded ">
                <FileText /> <b>Return To Origin</b>
              </h6>
            </div>
            <div className="row"></div>
            <div className="row my-2">
              <div className="col-md-3">
                <Form.Label>Type</Form.Label>
                <Select options={Type} />
              </div>

              <div className="col-md-3 ">
                <label for="formFile" class="form-label">
                  Ref.No
                </label>
                <input type="text" class="form-control form-control-md" />
              </div>
              <div className="col-md-3">
                <Form.Label>Forward To</Form.Label>
                <Select options={ForwardTo} />
              </div>
              <div className="col-md-3 ">
                <label for="formFile" class="form-label">
                  Agent/Delivery Boy
                </label>
                <input type="text" class="form-control form-control-md" />
              </div>
            </div>
            <div className="row my-2 mt-4">
              <div className="col-md-3">
                <label for="formFile" class="form-label">
                  Date
                </label>
                <input type="date" class="form-control form-control-md" />
              </div>
              <div className="col-md-3">
                <label for="formFile" class="form-label">
                  Time
                </label>
                <input type="time" class="form-control form-control-md" />
              </div>
              <div className="col-md-3">
                <label for="formFile" class="form-label">
                  CN No
                </label>
                <input type="text" class="form-control form-control-md" />
              </div>
              <div className="col-md-3 ">
                <Form.Label>Mode</Form.Label>
                <Select options={Reason} />
              </div>
            </div>
            <div className="row my-2 mt-4">
              {" "}
              <div className="col-md-3 ">
                <label for="formFile" class="form-label">
                  No.Pcs
                </label>
                <input type="text" class="form-control form-control-md" />
              </div>
              <div className="col-md-3 ">
                <label for="formFile" class="form-label">
                  Remarks
                </label>
                <textarea type="text" class="form-control form-control-md" />
              </div>
            </div>
            <div className="row text-center">
              <div className="row my-2 mt-4 ">
                <div className="col-md-12">
                  <button className="btn btn-danger rounded "> Add</button>
                  <button
                    className="btn btn-success rounded mx-3"
                    onClick={handleFastBookingSave}
                  >
                    Clear List
                  </button>
                </div>
              </div>
            </div>
            {
              //  <div className="row" >
              //   <div className="col-md-3 ">
              //     <label for="formFile" class="form-label">
              //       Remarks
              //     </label>
              //     <textarea type="text" class="form-control form-control-md" />
              //   </div>
              //   <div className="row my-2 mt-4">
              //     <div className="col-md-12">
              //       <button className="btn btn-danger rounded">
              //         {" "}
              //         Cancel
              //       </button>
              //       <button className="btn btn-success rounded mx-3" onClick={handleFastBookingSave} > Save</button>
              //     </div>
              //   </div>
              // </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReturnToOrigin;
