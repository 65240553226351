import React from "react";
import { Route, Redirect } from "react-router-dom";

export const isLogin = () => !!localStorage.getItem("userDetails");

const ProtectedRoute = ({ component: Component, ...rest }) => {

  const checkAccsess = (path) => {
    if (path !== '/') {
      let userData = JSON.parse(localStorage.getItem('userDetails'));
      let obj = userData.role_mappings.find(o => o.module_master.module_name === path.substring(1));
      if (obj)
        return true
      else
        return true
    }
    return true
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isLogin() ? checkAccsess(rest.path) ? (
          <Component {...props} />
        ) : <Redirect
          to="/home"
        />
          : (
            <Redirect
              to="/login" />
          )
      }
    />
  );
}

export default ProtectedRoute;
