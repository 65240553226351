import React from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Stylecareer.css";
import { faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import { Button, Col, Container, Modal, Row, Tab, Tabs } from "react-bootstrap";
import { useState } from "react";
import Personal from "./Personal";
import Vehicle from "./Vehicle";
import Document from "./Document";
import Bank from "./Bank";
const Career = () => {
  const [showComplain, setshowComplain] = useState(false);
  const handleCloseComplain = () => setshowComplain(false);
  const handleShowComplain = () => setshowComplain(true);
  const rowHeight = 50;
  let data = [
    {
      Name: "abc",
      Type: "abc",

      City: "abc",
      PinCode: "abc",
      MobileNo: "abc",
      KYC_Verified: "abc",
      Action: false,
    },
    {
      Name: "abc",
      Type: "abc",
      MobileNo: "abc",
      City: "abc",
      PinCode: "abc",

      KYC_Verified: "abc",
      Action: true,
    },
    {
      Name: "abc",
      Type: "abc",
      MobileNo: "abc",
      City: "abc",
      PinCode: "abc",

      KYC_Verified: "abc",
      Action: false,
    },
  ];
  const onGridReady = (params) => {
    console.log("My Grid");
    /* axios.get(`${ApiUrl}/fetchAllProgramMasterDetail`).then((Response) => {
          params.api.applyTransaction({
            add: Response.data.slice(0).reverse(),
          });
          console.log(Response.data);
          params.columnApi.autoSizeColumns();
        }); */
    params.api.applyTransaction({
      add: data,
    });
  };
  const DefaultColumnSetting = {
    sortable: true,
    filter: true,
    flex: 1,
    floatingFilter: true,
    flex: 1,
    resizable: true,
    minWidth: 120,
  };

  const columns = [
    {
      headerName: "#",
      valueGetter: "node.rowIndex + 1",
      filter: false,
      lockPosition: true,
    },
    {
      headerName: "Name",
      filter: false,
      field: "Name",
    },
    {
      headerName: "Type",
      filter: false,
      field: "Type",
    },
    {
      headerName: "MobileNo",
      filter: false,
      field: "MobileNo",
    },
    {
      headerName: "City",
      filter: false,
      field: "City",
    },
    {
      headerName: "PinCode",
      filter: false,
      field: "PinCode",
    },
    {
      headerName: "KYC_Verified",
      filter: false,
      field: "KYC_Verified",
    },
    {
      headerName: "Detail",
      filter: false,
      cellRendererFramework: (params) => (
        <center>
          <Link>
            <FontAwesomeIcon icon={faEye} onClick={handleShowComplain}>
              {" "}
            </FontAwesomeIcon>{" "}
          </Link>{" "}
        </center>
      ),
    },

    {
      headerName: "Action",
      field: "master_id",
      filter: false,
      cellRendererFramework: (params) => (
        <center>
          <Link>
            <FontAwesomeIcon icon={faEdit}> </FontAwesomeIcon>{" "}
          </Link>{" "}
        </center>
      ),
    },
  ];
  return (
    <>
      <div
        className="ag-theme-alpine"
        style={{ height: "70vh", width: "100%" }}
      >
        <AgGridReact
          rowHeight={rowHeight}
          columnDefs={columns}
          defaultColDef={DefaultColumnSetting}
          pagination={true}
          paginationPageSize={10}
          onGridReady={onGridReady}
        />{" "}
      </div>{" "}
      <Modal
        show={showComplain}
        onHide={handleCloseComplain}
        backdrop="static"
        keyboard={false}
        centered
        size="xl"
      >
        <h6 className="text-center more-link mt-4">Detail</h6>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                <div className="description-image">
                  <img
                    src="image/Newuser.jpg"
                    alt=""
                    className="description-container"
                  />

                  <div className="description-about">
                    <p>
                      {" "}
                      <span>Sivaji Pulluru</span>
                    </p>
                    <p>Delivery Partner</p>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="description-about">
                  <p>
                    {" "}
                    <span>MobileNo:</span>000000000
                  </p>
                  <p>
                    <span>Language:</span>Eng
                  </p>
                </div>
              </Col>
              <Col>
                <div className="description-about">
                  <p>
                    {" "}
                    <span>City :</span>Ranchi
                  </p>
                  <p>
                    <span>Pincode:</span>844001
                  </p>
                </div>
              </Col>
              <Col>
                <div className="description-about">
                  <p>
                    {" "}
                    <span>KYC Verified:</span>True
                  </p>
                  <p>
                    <span>Pincode:</span>Eng
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="careertabs">
                  <Tabs
                    defaultActiveKey="Personal"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                  >
                    <Tab eventKey="Personal" title="Personal">
                      <Personal />
                    </Tab>
                    <Tab eventKey="profile" title="Vehicle Details">
                      <Vehicle />
                    </Tab>
                    <Tab eventKey="Document" title="Document">
                      <Document />
                    </Tab>
                    <Tab eventKey="Bank" title="Bank Account Detail">
                      <Bank />
                    </Tab>
                  </Tabs>
                </div>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseComplain}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Career;
