import React, { useEffect, useState } from 'react'
import Apiservice from '../../Services/Apiservice';
import { toast, ToastContainer, Zoom } from "react-toastify";
import Select from "react-select";

function ModuleMaster() {
    const [pincodeData, setPicodeData] = useState([]);
    const [branchIdList, setBranchIdList] = useState("");

    useEffect(async () => {
        const pincode = await Apiservice.postdata(`/User/module_master_list`);
        if (pincode.error == false) {
            const pincodeDropdown = [];
            const pincodeMaping = pincode.data.map((ele, ind) => {
                pincodeDropdown.push({
                    label: ele.module_name,
                    value: ele.module_name,
                    id: ele.id,
                });
            });
            setPicodeData(pincodeDropdown);
        } else {
            console.log("failue");
        }
    }, [])


    const handleChangeBranchList = (e) => {
        setBranchIdList(e);
    };


    return (
        <div>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar
                transition={Zoom}
                delay={1000}
                limit={1}
            />
            <div className="my-3" >
                <div className="card w-100" >
                    <div class="card-body pt-0" >
                        <div className="row" >
                            <div className="col-md-4">
                                {/* <label>Module Names</label>
                                <div className="mt-2" >
                                    <Select
                                        options={pincodeData}
                                        onChange={handleChangeBranchList}
                                        value={branchIdList}
                                    />
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default ModuleMaster