import React from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";

const Unbilledinvoice = () => {
  return (
    <>
      <Container>
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>From Date</Form.Label>
              <Form.Control type="date" size="sm" />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>To Date</Form.Label>
              <Form.Control type="date" size="sm" />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>CNote</Form.Label>
              <Form.Control type="text" size="sm" />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Select</Form.Label>
              <Form.Select size="sm">
                <option></option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Select Franchise</Form.Label>
              <Form.Select size="sm">
                <option></option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Select Product</Form.Label>
              <Form.Select size="sm">
                <option></option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Employee</Form.Label>
              <Form.Select size="sm">
                <option></option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Select Type</Form.Label>
              <Form.Select size="sm">
                <option></option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <Form.Group className="mb-3">
              <Form.Label>Select Mode</Form.Label>
              <Form.Select size="sm">
                <option></option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={4} className="d-flex justify-content-between">
            <Button className="all-button">Search</Button>
            <Button className="all-button ">Export To Excel</Button>
            <Button className="all-button ">Export TO CSV</Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Unbilledinvoice;
