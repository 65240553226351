import React, { useEffect, useState } from "react";
import "../../Style/Ogm.css";
import {
  faEdit,
  faRupeeSign,
  faSearch,
  faSync,
} from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Card,
  Col,
  Form,
  Modal,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import "../../Style/Ogm.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast, ToastContainer, Zoom } from "react-toastify";

import Apiservice from "../../Services/Apiservice";
import context from "react-bootstrap/esm/AccordionContext";

const Invert = () => {
  const [ogmNumber, setOgmNumber] = useState();
  const [ogmList, setOgmList] = useState([]);
  const [cNodedata, setCNoteData] = useState({});
  const [inOutList, setInOutList] = useState([]);
  const [ogmDetialsPop, setOgmDetailsPop] = useState(false);
  const [totalWeight, setTotalWeight] = useState("");
  const [disableInput, setDisableInput] = useState([]);
  const [acceptCount, setAcceptCount] = useState([]);
  const [cnote, setCnote] = useState([]);

  useEffect(() => {
    getInOutList();
  }, []);

  const getOgmList = async (ogmInput) => {
    let ogm = await Apiservice.getInWordList({
      ogm_no: ogmInput,
    });
    if (!ogm.error) {
      setOgmList(ogm.data);
    } else {
      console.log("the ogm list is", ogm);
    }
  };


  const togglePop = (ogm) => {
    setOgmDetailsPop(!ogmDetialsPop);
    setDisableInput("");
    setAcceptCount("");
    setCnote("");
    getOgmList(ogm);
  };

  const handleOgmNumber = (e) => {
    setOgmNumber(e.target.value);
  };

  const handleOgmInput = (e) => {
    setCNoteData(e.target.value);
    // let tmpcnote = { ...cNodedata };
    // tmpcnote[id] = e.target.value;
    // setCNoteData(tmpcnote);
  };

  const applVrrification = async (ele, ind) => {
    let data = {
      ogm_no: inOutList[0].ogm_no,
      c_node_no: cNodedata,
    };

    let vdata = await Apiservice.verifyNode(data);
    if (!vdata.error) {
      toast.success(`C Note ${cNodedata} verification done`);
      setDisableInput([...disableInput, ind]);
      setAcceptCount([...acceptCount, { count: cNodedata }]);
      setCnote([...cnote, cNodedata]);
      getOgmList();
    } else {
      toast.error(`C Note ${cNodedata} Not verified`);
      console.log("someting went wrong");
    }
  };

  const getInOutList = async () => {
    let user = JSON.parse(localStorage.getItem("userDetails"));
    // let data = {
    //   ogm_no: ["OGM_10074"],
    //   id: user.id,
    //   status: "inward",
    // };
    let data = {
      id: user.id,
      status: "inward",
    };
    let res = await Apiservice.inOutList(data);
    // const res = await Apiservice.postdata("/booking/inwordListsss",data);
    if (!res.error) {
      setInOutList(res.data);
    } else {
      console.log("something went");
    }
  };

  const handleSubmitData = async () => {
    let inwordSubmit = await Apiservice.postdata("/booking/inwordSubmit", {
      frenchise_id_from: inOutList[0].frenchise_id_from,
      frenchise_id_to: inOutList[0].frenchise_id_to,
      ogm_no: inOutList[0].ogm_no,
      total_weight: totalWeight,
      total_count: ogmList.length,
      accept_count: acceptCount.length,
      c_note: cnote,
    });
    console.log(inwordSubmit, "inwordSubmit");
    if (!inwordSubmit.error) {
      toast.success("Successfully Submited");
      setOgmDetailsPop(false);
    } else {
      toast.error("Not Submited");
      setOgmDetailsPop(false);
    }
  };


  console.log(ogmList, "test");
  return (
    <>
      <Card className="shadow rounded p-2 w-100">
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar
          transition={Zoom}
          delay={1000}
          limit={1}
        />

        <div className="outward-list-container">
          <p>Invert Assign List</p>


          <Table hover size="sm"  className="text-center">
            <thead>
              <tr>
                <td className="table-headings">#</td>
                <td className="table-headings">OGM Number</td>
                <td className="table-headings">Business Name</td>
                <td className="table-headings">Mobile Number</td>
                <td className="table-headings">Status</td>
                <td className="table-headings">Action</td>
              </tr>
            </thead>
            <tbody>
              {inOutList.map((e, i) => {
                return (
                  <tr key={e.ogm_no}>
                    <td>{i + 1}</td>
                    <td>{e.ogm_no}</td>
                    <td>{e["Frenchises.BusinessName"]}</td>
                    <td>{e["Frenchises.MobileNumber"]}</td>
                    <td>{e.order_status}</td>
                    <td>
                      <FontAwesomeIcon
                        onClick={() => togglePop(e.ogm_no)}
                        icon={faEdit}
                        className="iconstyle"
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>

        </div>
      </Card>
      <Modal
        show={ogmDetialsPop}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <h6 className="text-center more-link mt-4">Inver Ogm Details</h6>
        <Modal.Body>
          {ogmList.length > 0 ? (
            <div>
              <Row>
                <Col>
                  <Table hover size="sm"  className="text-center">
                    <thead>
                      <tr>
                        <td className="table-headings">#</td>
                        <td className="table-headings">Order ID</td>
                        <td className="table-headings">C Note</td>
                        <td className="table-headings">Action</td>
                      </tr>
                    </thead>
                    <tbody>
                      {ogmList.map((e, i) => {
                        return (
                          <tr key={e.id}>
                            <td>{i + 1}</td>
                            <td>{e.id}</td>
                            <td>
                              {e.active ? (
                                e.c_node_no
                              ) : (
                                <input
                                  className=""
                                  onChange={(event) => handleOgmInput(event)}
                                  disabled={disableInput.includes(i)}
                                  placeholder="C note Number"
                                />
                              )}
                            </td>
                            <td>
                              {e.active ? (
                                <span className="verified-text">Verified</span>
                              ) : (
                                <button
                                  onClick={() => applVrrification(e, i)}
                                  className="verify-conote-number"
                                >
                                  Verify
                                </button>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                  <div className="row justify-content-end">
                    <div className="col-4">
                      <span className="weight-input-field">Total Weight:</span>
                      <span>
                        {" "}
                        <td>
                          <input
                            type="number"
                            onChange={(e) => setTotalWeight(e.target.value)}
                          />
                        </td>
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
              {/* <button className="CreateOgm-button">Create OGM</button> */}
            </div>
          ) : (
            "No Record Found"
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            variant="secondary"
            className="btn btn-secondary"
            onClick={handleSubmitData}
          >
            Submit
          </button>
          <Button variant="secondary" onClick={togglePop}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>


    </>
  );
};

export default Invert;
