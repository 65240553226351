import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { FileText } from "react-bootstrap-icons";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import Select from "react-select";
import { useForm } from "react-hook-form"
import { ToastContainer, toast, Zoom } from "react-toastify";

const Type = [
  { label: "Parcel", value: "Parcel" },
  { label: "Document", value: "Document" },
];

const SelectOrderTerm = [
  { label: "Parcel", value: "Parcel" },
  { label: "Document", value: "Document" },
];

const WeightPackage = [
  { label: "Parcel", value: "Parcel" },
  { label: "Document", value: "Document" },
];

const SelectState = [
  { label: "Parcel", value: "Parcel" },
  { label: "Document", value: "Document" },
];

const SelectDestination = [
  { label: "Parcel", value: "Parcel" },
  { label: "Document", value: "Document" },
];
function FastBooking() {
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [fastBookingDate, setFastBookingDate] = useState("");
  const [fastBookingTime, setFastBookingTime] = useState("");
  const [fastBooking, setfastBooking] = useState({
    refno: ""
  })

  const { register, handleSubmit } = useForm()

  /************* Satet Management *************/
  const handleFastBooking = (key, e) => {
    setfastBooking({ ...fastBooking, [key]: e.target.value })
  }
  /********** Fast Booking Validation **********/
  const onSubmit = (data) => {
    //  console.log(data,'submit');
  }
  const onError = (error) => {
    //  console.log(error,'error');
  }

  console.log(fastBooking, 'fastBooking');

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        transition={Zoom}
        delay={1000}
        limit={1}
      />
      <div className="my-3">
        <div className="card w-100 my-3">
          <div class="card-body pt-0">
            <div className="row">
              <h6 class="card-title    my-3 bg-light w-auto py-2 rounded ">
                <FileText /> <b> Import Fast Booking</b>
              </h6>
            </div>
            <div className="row my-1">
              <div className="col-md-3">
                <Form.Label>Type</Form.Label>
                <Select options={Type} />
              </div>
              <div className="col-md-3">
                <label for="formFile" class="form-label">
                  Choose file input
                </label>
                <input
                  class="form-control form-control-md"
                  type="file"
                  id="formFile"
                />
              </div>
              <div className="col-md-3 text-center pt-4">
                <a className="btn btn-success rounded">Import Booking</a>
              </div>
              <div className="col-md-3 text-center pt-4">
                {
                  //  <a class="btn btn-danger rounded">
                  //   Dowload Upload Sample File
                  // </a>
                  <ReactHtmlTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button btn btn-danger rounded mb-3"
                    table="table-to-xls"
                    filename="tablexls"
                    sheet="tablexls"
                    buttonText="Dowload Upload Sample File"
                  />
                }
                <div className="d-none">
                  <table className="table text-center" id="table-to-xls">
                    <thead className="thead-dark">
                      <tr>
                        <th>Date</th>
                        <th>Type</th>
                        <th>Awb No.</th>
                        <th>Ref No</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        // result.apiData?.data?.data?.getBlogPostsV2?.map((ele, ind) => {
                        //   return (
                        //     <tr>
                        //       <td>{JSON.parse(ele.content).title}</td>
                        //       <td>{ele.ID}</td>
                        //       <td></td>
                        //       <td></td>
                        //     </tr>
                        //   )
                        // }
                        // )
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card w-100">
          <div class="card-body pt-0">
            <div className="row ">
              <h6 class="card-title    my-3 bg-light w-auto py-2 rounded ">
                <FileText /> <b> Fast Booking</b>
              </h6>
            </div>
            <div className="row"></div>
            <div className="row my-2">
              <div className="col-md-3">
                <Form.Label>Select Order Term</Form.Label>
                <Select options={SelectOrderTerm} />
              </div>
              <div className="col-md-3">
                <label for="formFile" class="form-label">
                  Date
                </label>
                <input
                  type="date"
                  value={date}
                  onChange={(e) => setFastBookingDate(e.target.value)}
                  class="form-control form-control-md"
                />
              </div>
              <div className="col-md-3 ">
                <label for="formFile" class="form-label">
                  Time
                </label>
                <input
                  type="time"
                  value={time}
                  onChange={(e) => setFastBookingTime(e.target.value)}
                  class="form-control form-control-md"
                />
              </div>
              <div className="col-md-3 ">
                {" "}
                <Form.Label>Weight Package</Form.Label>
                <Select options={WeightPackage} />
              </div>
            </div>
            <div className="row my-2 mt-4">
              <div className="col-md-3 ">
                <label for="formFile" class="form-label">
                  Ref. No.
                </label>
                <input type="text" class="form-control form-control-md" {...register('refno', {
                  onChange: (e) => handleFastBooking('refno', e),
                  require: 'Please Enter Ref No'
                })} />
              </div>
              <div className="col-md-3">
                <label for="formFile" class="form-label">
                  C-Note No
                </label>
                <input type="text" class="form-control form-control-md" />
              </div>
              <div className="col-md-3">
                <label for="formFile" class="form-label">
                  Mobile Number
                </label>
                <input type="text" class="form-control form-control-md" />
              </div>
              <div className="col-md-3 ">
                <Form.Label>Select State</Form.Label>
                <Select options={SelectState} />
              </div>{" "}
            </div>
            <div className="row my-2 mt-4">
              <div className="col-md-3 ">
                <label for="formFile" class="form-label">
                  Consignee
                </label>
                <input type="text" class="form-control form-control-md" />
              </div>
              <div className="col-md-3 ">
                <label for="formFile" class="form-label">
                  Remarks
                </label>
                <input type="text" class="form-control form-control-md" />
              </div>{" "}
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-3 ">
                    <label for="formFile" class="form-label">
                      No. Pcs.
                    </label>
                    <input type="text" class="form-control form-control-md" />
                  </div>
                  <div className="col-md-3 ">
                    <label for="formFile" class="form-label">
                      Wt. (Kgs)
                    </label>
                    <input type="text" class="form-control form-control-md" />
                  </div>
                  <div className="col-md-3 ">
                    <label for="formFile" class="form-label">
                      Wt. (Gms)
                    </label>
                    <input type="text" class="form-control form-control-md" />
                  </div>
                  <div className="col-md-3 ">
                    <label for="formFile" class="form-label">
                      Ch. Wt
                    </label>
                    <input type="text" class="form-control form-control-md" />
                  </div>{" "}
                </div>
              </div>
            </div>
            <div className="row my-2 mt-4">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-6 ">
                    <label for="formFile" class="form-label">
                      Select From Party
                    </label>
                    <input type="text" class="form-control form-control-md" />
                  </div>
                  <div className="col-md-6 ">
                    <Form.Label>Select Destination</Form.Label>
                    <Select options={SelectDestination} />
                  </div>
                </div>
              </div>
            </div>

            <div className="row my-2 mt-4 text-center">
              <div className="col-md-12">
                <button className="btn btn-danger rounded mx-3"> Cancel</button>
                <button
                  className="btn btn-success rounded"
                  onClick={handleSubmit(onSubmit, onError)}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FastBooking;
