import Select from "react-select";
import React, { useState, useRef , useMemo } from "react";
import { useEffect } from "react";
import { Button, Form, Modal, Table, Image, Spinner } from "react-bootstrap";
import { FileText } from "react-bootstrap-icons";
import { useForm } from "react-hook-form";
import { ToastContainer, toast, Zoom } from "react-toastify";
import DateTimePicker from "react-datetime-picker";
import "../../Style/Booking.css";
import "../../Style/transactions.css";
import Apiservice from "../../Services/Apiservice";
import { Link } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import logo from "../../Assets//MoxD_PrimaryLogo1.png";
import axios from "axios";

function Booking(props) {
  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState("");
  const [bookingLoader, setBookingLoader] = useState(false)
  const [bookingFormData, setBookingFormData] = useState({
    bookingType: "NORMAL BOOKING",
    cnNo: "",
    bookingDate: "",
    bookingTime: "",
    bookingMode: "Air",
    gstNo: "",
    payMode: "",
    senderName: "",
    senderMobileNO: "",
    senderAddress: "",
    senderPincode: "",
    receiverName: "",
    receiverMobileNO: "",
    receiverAddress: "",
    receiverPincode: "",
    state: "",
    district: "",
    weightKgs: "0",
    weightGms: 100,
    packageValue: "",
    payAmount: "",
    length: "",
    breadth: "",
    width: "",
    freight: "",
    docket: "",
    cod: "",
    hamali: "",
    fuel_charge: "",
    noPcs: "",
    remarks: "",
    insuranceTax: '',
    gstTax: '',
    courierPhoto: ""
  });

  const [bookingFormDataError, setBookingFormDataError] = useState({
    bookingType: "",
    cnNo: "",
    bookingDate: "",
    bookingTime: "",
    bookingMode: "",
    gstNo: "",
    payMode: "",
    senderName: "",
    senderMobileNO: "",
    senderAddress: "",
    senderPincode: "",
    receiverName: "",
    receiverMobileNO: "",
    receiverAddress: "",
    receiverPincode: "",
    state: "",
    district: "",
    weightKgs: '',
    weightGms: '',
    packageValue: "",
    payAmount: "",
    length: "",
    breadth: "",
    width: "",
    freight: "",
    docket: "",
    cod: "",
    hamali: "",
    fuel_charge: "",
    noPcs: "",
    remarks: "",
    insuranceTax: '',
    gstTax: '',
    courierPhoto: "",
    orderCategory:""
  });

  const [stateData, setStateData] = useState({
    State: "",
    District: ""
  });
  const [loginData, setLoginData] = useState([]);
  const [receiverpincode1, setReceiverpinCode1] = useState("");
  const [smShow, setSmShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const [getAmountLoader, setGetAmountLoader] = useState(false)
  const [receiverPincodeLoader, setReceiverPincodeLoader] = useState(false)
  const [bookingSubmitLoader, setBookingSubmitLoader] = useState(false)
  const [editSubmitLoader, setEditSubmitLoader] = useState(false)



  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const mode = 'payAmount'
    if (bookingFormData.bookingMode == 'Air') {
      setBookingFormData({
        ...bookingFormData,
        [mode]: '',
      });
    }

    if (bookingFormData.bookingMode == 'Surface') {
      setBookingFormData({
        ...bookingFormData,
        [mode]: '',
      });
    }
  }, [bookingFormData.bookingMode]);

  /******  Booking API Integration ******/

  const handleChangeBooking = (key, ele) => {
    handlePincodeData(key, ele);
    setBookingFormData({
      ...bookingFormData,
      [key]: ele,
    });
    setBookingFormDataError({
      ...bookingFormDataError,
      [key]: null,
    });

    // const mode = 'payAmount'
    // if (ele == 'Air') {
    //   setBookingFormData({
    //     ...bookingFormData,
    //     [mode]: '',
    //   });
    // }

    // if (ele == 'Surface') {
    //   setBookingFormData({
    //     ...bookingFormData,
    //     [mode]: '',
    //   });
    // }

  };

  const handlePincodeData = async (key, ele) => {
    if (key === "receiverPincode") {
      const payloadPin = {
        pin: ele,
      };
      if (ele.length == "6") {
        setReceiverPincodeLoader(true)
        const pincode = await Apiservice.serchPin(payloadPin);
        setReceiverpinCode1("");
        if (pincode.error == false) {
          setReceiverPincodeLoader(false)
          setStateData(pincode.data);
        } else {
          toast.error(pincode.message);
          setReceiverPincodeLoader(false)
        }
      } else {
        setReceiverPincodeLoader(false)
        setReceiverpinCode1("pincode should be 6 digits");
      }
    }
  };

  const bookingFormDataValidation = () => {
    const {
      bookingType,
      bookingTime,
      bookingDate,
      cnNo,
      payMode,
      bookingMode,
      gstNo,
      senderName,
      senderMobileNO,
      senderAddress,
      senderPincode,
      receiverName,
      receiverMobileNO,
      receiverAddress,
      receiverPincode,
      weightKgs,
      weightGms,
      packageValue,
      payAmount,
      noPcs,
      remarks,
    } = bookingFormData;

    const textReg = /[a-zA-Z]/

    const bookingNewErrors = {};
    // if (bookingType == '') {
    //   bookingNewErrors.bookingType = 'Please select booking type'
    // }

    if (bookingDate == "") {
      bookingNewErrors.bookingDate = "Please select date";
    }
    if (bookingTime == "") {
      bookingNewErrors.bookingTime = "Please select time";
    }

    if (cnNo == "") {
      bookingNewErrors.cnNo = "Please enter cnote numbr";
    } else if (cnNo && cnNo.length > 10) {
      bookingNewErrors.cnNo = "C-note number should below 10 numbers";
    }
    // if (payMode == '') {
    //   bookingNewErrors.payMode = 'Please select payMode'
    // }

    // if (gstNo == '') {
    //   bookingNewErrors.gstNo = 'Please enter GST number'
    // } else if (gstNo && gstNo.length != 15) {
    //   bookingNewErrors.gstNo = 'GST Number should be 15 digits'
    // }

    // if (senderName == "") {
    //   bookingNewErrors.senderName = "Please enter sender Name";
    // }

    // if (senderName == '') {
    //   bookingNewErrors.senderName = ''
    // }
    // else if (!textReg.test(senderName)) {
    //   bookingNewErrors.senderName = 'Name should be text type'
    // }


    // if (senderName && !textReg.test(senderName)) {
    //   bookingNewErrors.senderName = 'Name should be text type'
    // } else if (senderName && senderName.length > 50) {
    //   bookingNewErrors.senderName = "Please enter below the 50 charecters";
    // }

    // if (senderMobileNO == "") {
    //   bookingNewErrors.senderMobileNO = "Please enter mobile number";
    // } else if (senderMobileNO && senderMobileNO.length != 10) {
    //   bookingNewErrors.senderMobileNO = "Please enter mobile number 10 digits ";
    // }

    // if (senderMobileNO && senderMobileNO.length != 10) {
    //   bookingNewErrors.senderMobileNO = "Please enter MobileNO 10 digits ";
    // }

    // if (senderAddress == "") {
    //   bookingNewErrors.senderAddress = "Please enter sender address";
    // }
    if (senderPincode == "") {
      bookingNewErrors.senderPincode = "Please enter sender pincode";
    } else if (senderPincode && senderPincode.length != 6) {
      bookingNewErrors.senderPincode = "Pin code should be 6 digits";
    }

    // if (receiverName == '') {
    //   bookingNewErrors.receiverName = ''
    // }
    // else if (!textReg.test(receiverName)) {
    //   bookingNewErrors.receiverName = 'name should be text type'
    // }


    // if (receiverName && !textReg.test(receiverName)) {
    //   bookingNewErrors.receiverName = 'Name should be text type'
    // }

    // else if (receiverName && receiverName.length > 50) {
    //   bookingNewErrors.receiverName = "Please enter below the 50 charecters";
    // }
    // if (receiverMobileNO == "") {
    //   bookingNewErrors.receiverMobileNO = " ";
    // } else if (receiverMobileNO && receiverMobileNO.length != 10) {
    //   bookingNewErrors.receiverMobileNO = "Please enter MobileNO 10 digits ";
    // }

    // if (receiverMobileNO && receiverMobileNO.length != 10) {
    //   bookingNewErrors.receiverMobileNO = "Please enter MobileNO 10 digits ";
    // }

    // if (receiverAddress == "") {
    //   bookingNewErrors.receiverAddress = "Please enter receiver address";
    // }


    if (receiverPincode == "") {
      bookingNewErrors.receiverPincode = "Please enter receiver pincode";
    } else if (receiverPincode && receiverPincode.length > 6) {
      bookingNewErrors.receiverPincode = "Pin code should be 6 digits";
    }



    // const weightKgsToString = weightKgs.toString()
    // const weightKgsStartingIndex = weightKgsToString.slice(0, 1)
    // if (!weightKgs) {
    //   bookingNewErrors.weightKgs = "Please enter weight kgs";
    // } else if (weightKgs && weightKgsStartingIndex == '0' && weightKgs.length > 1) {
    //   bookingNewErrors.weightKgs = "Dont start with 0";
    // } else if (weightKgs && weightKgs.length > 3) {
    //   bookingNewErrors.weightKgs = "Please enter below 1000 kgs";
    // }

    // const weightGmsToString = weightGms.toString()
    // const weightGmsStartingIndex = weightGmsToString.slice(0, 1)
    // if (!weightGms) {
    //   bookingNewErrors.weightGms = "Please enter weight Gms";
    // } else if (weightGms && weightGms.length == 2 || weightGms.length == 1) {
    //   bookingNewErrors.weightGms = "Please enter above 100 grams";
    // } else if (weightGms && weightGms.length > 3) {
    //   bookingNewErrors.weightGms = "Please enter below 1000 grams";
    // }
    // else if (weightGms && weightGmsStartingIndex == '0' && weightGms.length > 1) {
    //   bookingNewErrors.weightGms = "Dont start with 0";
    // }



    const weightKgsToString = weightKgs.toString()
    const weightKgsStartingIndex = weightKgsToString.slice(0, 1)

    const weightGmsToString = weightGms.toString()
    const weightGmsStartingIndex = weightGmsToString.slice(0, 1)

    if (!weightKgs) {
      bookingNewErrors.weightKgs = "Please enter weight in Kgs";
    } else if (weightKgs && weightKgsStartingIndex == '0' && weightGmsStartingIndex == '0') {
      bookingNewErrors.weightKgs = "Please enter proper weight";
    }


    if (!weightGms) {
      bookingNewErrors.weightGms = "Please enter weight in grams";
    } else if (weightGms && weightKgsStartingIndex == '0' && weightGmsStartingIndex == '0') {
      bookingNewErrors.weightGms = "Please enter proper weight";
    }




    // if (packageValue == "") {
    //   bookingNewErrors.packageValue = "Please enter package value";
    // }
    // else if (packageValue && packageValue.length > 8) {
    //   bookingNewErrors.packageValue = "Please enter below the 8 digits";
    // }

    // if (payAmount == "") {
    //   bookingNewErrors.payAmount = "Please enter pay amount";
    // } else if (payAmount && payAmount.length > 8) {
    //   bookingNewErrors.payAmount = "Please enter below 8 digits ";
    // }
    // if (noPcs == "") {
    //   bookingNewErrors.noPcs = "Please enter no pcs";
    // } else if (noPcs && noPcs.length > 3) {
    //   bookingNewErrors.noPcs = "Please enter below 3 digits";
    // }
    // if (remarks == '') {
    //   bookingNewErrors.remarks = 'Please enter remarks'
    // }
    return bookingNewErrors;
  };

  const handleBookingSubmit = (e) => {
    e.preventDefault()
    const bookingFormDataValidationObject = bookingFormDataValidation();
    if (Object.keys(bookingFormDataValidationObject).length > 0) {
      setBookingFormDataError(bookingFormDataValidationObject);
    } else {
      setSmShow(true);
      setBookingFormDataError({
        ...bookingFormDataError,
        ['payAmount']: ''
      })
    }
  };

  const onClicksucess = () => {
    setSmShow(false);
    // setLgShow(true);
    handleBookingSubmitOk();
  };

  const handleBookingSubmitOk = async () => {
    // const bookingPayload = {
    //   booking_type: bookingFormData.bookingType,
    //   // pickup_date: `${bookingFormData?.bookingDate}.${bookingFormData?.bookingTime}`,
    //   booking_date: `${bookingFormData?.bookingDate}.${bookingFormData?.bookingTime}`,
    //   c_node_no: bookingFormData.cnNo,
    //   payment_mode: bookingFormData.payMode,
    //   booking_mode: bookingFormData.bookingMode,
    //   gst_no: bookingFormData.gstNo,
    //   pickup_name: bookingFormData.receiverName,
    //   pickup_mobile: bookingFormData.receiverMobileNO,
    //   pickup_pincode: bookingFormData.receiverPincode,
    //   pickup_address: bookingFormData.receiverAddress,
    //   delivery_name: bookingFormData.senderName,
    //   delivery_mobile: bookingFormData.senderMobileNO,
    //   delivery_address: bookingFormData.senderAddress,
    //   delivery_pincode: bookingFormData.senderPincode,
    //   delivery_state: stateData.State,
    //   delivery_district: stateData.District,
    //   weight: `${bookingFormData.weightKgs}.${bookingFormData.weightGms}`,
    //   package_value: bookingFormData.packageValue,
    //   amount: bookingFormData.payAmount,
    //   frenchise_id: loginData.id,
    //   no_pcs: bookingFormData.noPcs,
    //   remarks: bookingFormData.remarks,
    //   length: bookingFormData.length,
    //   breadth: bookingFormData.breadth,
    //   width: bookingFormData.width,
    //   freight: bookingFormData.freight,
    //   docket: bookingFormData.docket,
    //   cod: bookingFormData.cod,
    //   hamali: bookingFormData.hamali,
    //   fuel_charge: bookingFormData.fuel_charge,
    //   image: bookingFormData?.courierPhoto
    // };

    const formData = new FormData()
    if (bookingFormData.bookingType !== null && bookingFormData.bookingType !== "") {
      formData.append("booking_type", bookingFormData.bookingType);
  }
  
  if (
      bookingFormData?.bookingDate &&
      bookingFormData?.bookingTime &&
      bookingFormData.bookingDate !== null &&
      bookingFormData.bookingTime !== null
  ) {
      formData.append("booking_date", `${bookingFormData.bookingDate}.${bookingFormData.bookingTime}`);
  }
  
  if (bookingFormData.cnNo !== null && bookingFormData.cnNo !== "") {
      formData.append("c_node_no", bookingFormData.cnNo);
  }
  
  if (bookingFormData.payMode !== null && bookingFormData.payMode !== "") {
      formData.append("payment_mode", bookingFormData.payMode);
  }
  
  if (bookingFormData.bookingMode !== null && bookingFormData.bookingMode !== "") {
      formData.append("booking_mode", bookingFormData.bookingMode);
  }
  
  if (bookingFormData.gstNo !== null && bookingFormData.gstNo !== "") {
      formData.append("gst_no", bookingFormData.gstNo);
  }
  
  if (bookingFormData.senderName !== null && bookingFormData.senderName !== "") {
      formData.append("pickup_name", bookingFormData.senderName);
  }
  
  if (bookingFormData.senderMobileNO !== null && bookingFormData.senderMobileNO !== "") {
      formData.append("pickup_mobile", bookingFormData.senderMobileNO);
  }
  
  if (bookingFormData.senderPincode !== null && bookingFormData.senderPincode !== "") {
      formData.append("pickup_pincode", bookingFormData.senderPincode);
  }
  
  if (bookingFormData.senderAddress !== null && bookingFormData.senderAddress !== "") {
      formData.append("pickup_address", bookingFormData.senderAddress);
  }
  
  if (bookingFormData.receiverName !== null && bookingFormData.receiverName !== "") {
      formData.append("delivery_name", bookingFormData.receiverName);
  }
  
  if (bookingFormData.receiverMobileNO !== null && bookingFormData.receiverMobileNO !== "") {
      formData.append("delivery_mobile", bookingFormData.receiverMobileNO);
  }
  
  if (bookingFormData.receiverAddress !== null && bookingFormData.receiverAddress !== "") {
      formData.append("delivery_address", bookingFormData.receiverAddress);
  }
  
  if (bookingFormData.receiverPincode !== null && bookingFormData.receiverPincode !== "") {
      formData.append("delivery_pincode", bookingFormData.receiverPincode);
  }
  
  if (stateData.State !== null && stateData.State !== "") {
      formData.append("delivery_state", stateData.State);
  }
  
  if (stateData.District !== null && stateData.District !== "") {
      formData.append("delivery_district", stateData.District);
  }
  
  if (bookingFormData.weightKgs !== null || bookingFormData.weightGms !== null) {
      const weight = `${bookingFormData.weightKgs}.${bookingFormData.weightGms}`;
      formData.append("weight", weight);
  }
  
  if (bookingFormData.packageValue !== null && bookingFormData.packageValue !== "") {
      formData.append("package_value", bookingFormData.packageValue);
  }
  
  if (bookingFormData.payAmount !== null && bookingFormData.payAmount !== "") {
      formData.append("amount", bookingFormData.payAmount);
  }
  
  if (loginData.id !== null && loginData.id !== "") {
      formData.append("frenchise_id", loginData.id);
  }
  
  if (bookingFormData.noPcs !== null && bookingFormData.noPcs !== "") {
      formData.append("no_pcs", bookingFormData.noPcs);
  }
  
  if (bookingFormData.remarks !== null && bookingFormData.remarks !== "") {
      formData.append("remarks", bookingFormData.remarks);
  }
  
  if (bookingFormData.length !== null && bookingFormData.length !== "") {
      formData.append("length", bookingFormData.length);
  }
  
  if (bookingFormData.breadth !== null && bookingFormData.breadth !== "") {
      formData.append("breadth", bookingFormData.breadth);
  }
  
  if (bookingFormData.width !== null && bookingFormData.width !== "") {
      formData.append("width", bookingFormData.width);
  }
  
  if (bookingFormData.freight !== null && bookingFormData.freight !== "") {
      formData.append("freight", bookingFormData.freight);
  }
  
  if (bookingFormData.docket !== null && bookingFormData.docket !== "") {
      formData.append("docket", bookingFormData.docket);
  }
  
  if (bookingFormData.cod !== null && bookingFormData.cod !== "") {
      formData.append("cod", bookingFormData.cod);
  }
  
  if (bookingFormData.hamali !== null && bookingFormData.hamali !== "") {
      formData.append("hamali", bookingFormData.hamali);
  }
  
  if (bookingFormData.fuel_charge !== null && bookingFormData.fuel_charge !== "") {
      formData.append("fuel_charge", bookingFormData.fuel_charge);
  }
  
  if (bookingFormData.courierPhoto !== null && bookingFormData.courierPhoto !== "") {
      formData.append("image", bookingFormData.courierPhoto);
  }
  
  if (bookingFormData.orderCategory !== null && bookingFormData.orderCategory !== "") {
      formData.append("orderCategory", bookingFormData.orderCategory);
  }
  
  if (selectedCity !== null && selectedCity !== "") {
      formData.append("city_name", selectedCity);
  }
  

   

    setBookingLoader(true)

    const bookingApi = await Apiservice.postdata("/booking/createbooking", formData);
    setBookingSubmitLoader(true)

    if (bookingApi.error == false) {
      setBookingLoader(false)
      setLgShow(true);
      toast.success(bookingApi.message);
      setBookingSubmitLoader(false)

      handelprint();
      setBookingFormData({
        ...bookingFormData,
        bookingType: "NORMAL BOOKING",
        cnNo: "",
        bookingDate: "",
        bookingTime: "",
        bookingMode: "Air",
        gstNo: "",
        payMode: "",
        senderName: "",
        senderMobileNO: "",
        senderAddress: "",
        senderPincode: "",
        receiverName: "",
        receiverMobileNO: "",
        receiverAddress: "",
        receiverPincode: "",
        state: "",
        district: "",
        weightKgs: "0",
        weightGms: 100,
        packageValue: "",
        payAmount: "",
        length: "",
        breadth: "",
        width: "",
        freight: "",
        docket: "",
        cod: "",
        hamali: "",
        fuel_charge: "",
        noPcs: "",
        remarks: "",
        insuranceTax: '',
        gstTax: ''
      });
    } else {
      setBookingLoader(false)
      setLgShow(true);
      toast.error(bookingApi.message);
      setBookingSubmitLoader(false)

    }
  };

  console.log(bookingFormData)
  const handleReset = () => {
    setBookingFormData({
      ...bookingFormData,
      bookingType: "NORMAL BOOKING",
      cnNo: "",
      bookingDate: "",
      bookingTime: "",
      bookingMode: "Air",
      gstNo: "",
      payMode: "",
      senderName: "",
      senderMobileNO: "",
      senderAddress: "",
      senderPincode: "",
      receiverName: "",
      receiverMobileNO: "",
      receiverAddress: "",
      receiverPincode: "",
      state: "",
      district: "",
      weightKgs: "0",
      weightGms: 100,
      packageValue: "",
      payAmount: "",
      length: "",
      breadth: "",
      width: "",
      freight: "",
      docket: "",
      cod: "",
      hamali: "",
      fuel_charge: "",
      noPcs: "",
      remarks: "",
      insuranceTax: '',
      gstTax: '',
      orderCategory:'',

    });
    setStateData({
      ...stateData,
      State: "",
      District: ""
    });
  }


  const handleClose = () => {
    setLgShow(false);
    window.scrollTo(0, 0);
    setBookingFormData({
      ...bookingFormData,
      bookingType: "NORMAL BOOKING",
      cnNo: "",
      bookingDate: "",
      bookingTime: "",
      bookingMode: "Air",
      gstNo: "",
      payMode: "",
      senderName: "",
      senderMobileNO: "",
      senderAddress: "",
      senderPincode: "",
      receiverName: "",
      receiverMobileNO: "",
      receiverAddress: "",
      receiverPincode: "",
      state: "",
      district: "",
      weightKgs: "0",
      weightGms: 100,
      packageValue: "",
      payAmount: "",
      length: "",
      breadth: "",
      width: "",
      freight: "",
      docket: "",
      cod: "",
      hamali: "",
      fuel_charge: "",
      noPcs: "",
      remarks: "",
      insuranceTax: '',
      gstTax: '',
      orderCategory:'',
    });
    setStateData({
      ...stateData,
      State: "",
      District: ""
    });
  };

  const handleCancelClear = () => {
    setSmShow(false);
    setBookingFormData({
      ...bookingFormData,
      bookingType: "NORMAL BOOKING",
      cnNo: "",
      bookingDate: "",
      bookingTime: "",
      bookingMode: "Air",
      gstNo: "",
      payMode: "",
      senderName: "",
      senderMobileNO: "",
      senderAddress: "",
      senderPincode: "",
      receiverName: "",
      receiverMobileNO: "",
      receiverAddress: "",
      receiverPincode: "",
      state: "",
      district: "",
      weightKgs: "0",
      weightGms: 100,
      packageValue: "",
      payAmount: "",
      length: "",
      breadth: "",
      width: "",
      freight: "",
      docket: "",
      cod: "",
      hamali: "",
      fuel_charge: "",
      noPcs: "",
      remarks: "",
      insuranceTax: '',
      gstTax: '',
      orderCategory:''
    });
    setStateData({
      ...stateData,
      State: "",
      District: ""
    });
  };

  const handleGetAmount = async () => {
    if (bookingFormData.senderPincode.length == 6 && bookingFormData.receiverPincode.length == 6) {
      setGetAmountLoader(true)
      const payload = {
        from_pincode: bookingFormData.senderPincode,
        to_pincode: bookingFormData.receiverPincode,
        to_weight: `${bookingFormData.weightKgs}.${bookingFormData.weightGms}`,
      };
      const pincode = await Apiservice.postdata("/User/get_rate", payload);
      if (pincode.error == false) {
        setGetAmountLoader(false)
        if (bookingFormData.bookingMode == 'Air') {
          const payAmountData = "payAmount";
          setBookingFormData({
            ...bookingFormData,
            [payAmountData]: pincode.data.air_amount,
          });
        }

        if (bookingFormData.bookingMode == 'Surface') {
          const payAmountData = "payAmount";
          setBookingFormData({
            ...bookingFormData,
            [payAmountData]: pincode.data.surface_amount == null ? '0' : pincode.data.surface_amount,
          });
        }
        toast.success(pincode.message);
      } else {
        toast.error(pincode.message);
        setGetAmountLoader(false)
      }
    } else {
      toast.error('Please enter From Pincode and To Pincode');
      setBookingFormData({
        ...bookingFormData,
        payMode: ''
      })
    }
  };


  const showingDates = () => {
    var dtToday = new Date();
    var month = dtToday.getMonth() + 1;
    var day = dtToday.getDate();
    var year = dtToday.getFullYear();
    if (month < 10) month = "0" + month.toString();
    if (day < 10) day = "0" + day.toString();
    var hideDates = year + "-" + month + "-" + day;
    return hideDates;
  }

  useEffect(() => {
    const todayDate = String(date.getDate()).padStart(2, "0");
    const todayMonth = String(date.getMonth() + 1).padStart(2, "0");
    const todayYear = date.getFullYear();
    const one = todayYear + "-" + todayMonth + "-" + todayDate;

    // date.getHours(); // => 9
    // date.getMinutes(); // =>  30
    // d.getSeconds();

    var hh = date.getHours();
    var mm = date.getMinutes();

    if ((mm?.toString()?.length == 1)) {
      var mm = '0' + mm
    }

    const todayTime = hh + ":" + mm;
    setBookingFormData({
      ...bookingFormData,
      ['bookingTime']: todayTime,
      ['bookingDate']: one
    });

  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    const one = localStorage.getItem("userDetails");
    setLoginData(JSON.parse(one));
  }, []);

  const {
    register: register1,
    handleSubmit: handleSubmit1,
    formState: { errors: errors1 },
  } = useForm();
  const onSubmit1 = (data) => {
    console.log(data, "onSubmit1");
  };
  const onError1 = (error) => {
    console.log(error, "error1");
  };

  // const [cnoteData, setCnoteData] = useState([]);

  // const handleGetCNote = async () => {
  //   const one = await localStorage.getItem("userDetails");
  //   const payload = {
  //     frenchise_id: JSON.parse(one).id,
  //   };

  // const ListPincodeGet = await Apiservice.postdata(
  //   "/booking/c_note_list",
  //   payload
  // );
  // if (ListPincodeGet.error == false) {
  //   toast.success(ListPincodeGet.message);
  //   setCnoteData(ListPincodeGet.data[0].c_note_no);
  //   setCnNoError("");
  // } else {
  //   console.log("get API is not working");
  // }
  // };


  /*********  Edit Booking *********/
  const [cnoteNumberData, setCnoteNumberData] = useState(
    {
      editCnoteNumber: ''
    }
  )
  const [cnoteNumberDataError, setCnoteNumberDataError] = useState({
    editCnoteNumber: ''
  })
  const handelEditCnote = (key, ele) => {
    setCnoteNumberData({
      ...cnoteNumberData, [key]: ele
    })
    setCnoteNumberDataError({
      ...cnoteNumberDataError, [key]: null
    })
  }
  const handleErrorCnote = () => {
    const { editCnoteNumber } = cnoteNumberData
    const newCnoteError = {}

    if (editCnoteNumber == '') {
      newCnoteError.editCnoteNumber = 'Please enter cnote number'
    } else if (editCnoteNumber && editCnoteNumber.length > 10) {
      newCnoteError.editCnoteNumber = 'C-note should be below the 10 digits'
    }
    return newCnoteError
  }

  const handelEditBooking = async (e) => {
    e.preventDefault();
    const handleError = handleErrorCnote();
    if (Object.keys(handleError).length > 0) {
      setCnoteNumberDataError(handleError)
    } else {
      const payload = {
        c_node_no: cnoteNumberData.editCnoteNumber,
      };
      setEditSubmitLoader(true)
      const bookingdata = await Apiservice.postdata("/booking/list_transaction", payload);
      if (bookingdata.error == false && bookingdata.data.length != 0) {
        toast.success(bookingdata.message)
        setEditSubmitLoader(false)

        /*** Spit the weight    ***/
        // var numberData = 1234.5678;
        var stringNumber = bookingdata.data.weight.toString()
        var weightkgs = stringNumber.split(".")[0];
        var weightGms = stringNumber.split(".")[1];

        setBookingFormData({
          ...bookingFormData,
          bookingType: bookingdata.data.bookingType,
          cnNo: bookingdata.data.c_node_no,
          payMode: bookingdata.data.payment_mode,
          bookingMode: bookingdata.data.booking_mode,
          gstNo: bookingdata.data.gst_no,
          senderName: bookingdata.data.delivery_name,
          senderMobileNO: bookingdata.data.delivery_mobile,
          senderAddress: bookingdata.data.delivery_address,
          senderPincode: bookingdata.data.delivery_pincode,
          receiverName: bookingdata.data.pickup_name,
          receiverMobileNO: bookingdata.data.pickup_mobile,
          receiverAddress: bookingdata.data.pickup_address,
          receiverPincode: bookingdata.data.pickup_pincode,
          weightKgs: weightkgs,
          weightGms: weightGms,
          packageValue: bookingdata.data.package_value,
          payAmount: bookingdata?.data?.amount,
          // bookingDate: bookingdata.data.pickup_date,
          // bookingTime: bookingdata.data.pickup_date,
          noPcs: bookingdata.data.no_pcs,
          remarks: bookingdata.data.remarks,

          length: bookingdata.data.length,
          breadth: bookingdata.data.breadth,
          width: bookingdata.data.width,
          freight: bookingdata.data.freight,
          docket: bookingdata.data.docket,
          cod: bookingdata.data.cod,
          hamali: bookingdata.data.hamali,
          fuel_charge: bookingdata.data.fuel_charge,


        });
        setStateData({
          ...stateData,
          State: bookingdata.data.delivery_state,
          District: bookingdata.data.delivery_district
        });

        // setCnoteNumberData({
        //   ...cnoteNumberData,
        //   editCnoteNumber: ''
        // })

      } else {
        toast.error(`c-note number ${cnoteNumberData.editCnoteNumber}. Booking is not available.`)
        setEditSubmitLoader(false)
        setBookingFormData({
          ...bookingFormData,
          bookingType: "NORMAL BOOKING",
          cnNo: "",
          bookingDate: "",
          bookingTime: "",
          bookingMode: "Air",
          gstNo: "",
          payMode: "",
          senderName: "",
          senderMobileNO: "",
          senderAddress: "",
          senderPincode: "",
          receiverName: "",
          receiverMobileNO: "",
          receiverAddress: "",
          receiverPincode: "",
          state: "",
          district: "",
          weightKgs: "0",
          weightGms: 100,
          packageValue: "",
          payAmount: "",
          length: "",
          breadth: "",
          width: "",
          freight: "",
          docket: "",
          cod: "",
          hamali: "",
          fuel_charge: "",
          noPcs: "",
          remarks: "",
          insuranceTax: '',
          gstTax: ''
        });
        setStateData({
          ...stateData,
          State: '',
          District: ''
        });

        // setCnoteNumberData({
        //   ...cnoteNumberData,
        //   editCnoteNumber: ''
        // })

      }
    }


  };

  const main = {
    width: "100%",
    marginRight: "auto",
    marginLeft: "8px",
  };
  const bigEle = {
    border: "2px solid black",
  };

  const componentRef = useRef();
  const handelprint = useReactToPrint({ content: () => componentRef.current });



  const [insuranceInputDisable, setInsuranceInputDisable] = useState(false)
  const handleInsuranceradioClickYes = () => {
    setInsuranceInputDisable(false)

  }
  const handleInsuranceradioClickNo = () => {
    setInsuranceInputDisable(true)
  }


  useEffect(() => {
    if (insuranceInputDisable == true) {
      setBookingFormData({
        ...bookingFormData,
        ['insuranceTax']: ''
      })
    }
  }, [insuranceInputDisable])


  const [gstInputDisable, setGstInputDisable] = useState(false)
  const handleGstradioClickYes = () => {
    setGstInputDisable(false)

  }
  const handleGstradioClickNo = () => {
    setGstInputDisable(true)
  }

  useEffect(() => {
    if (gstInputDisable == true) {
      setBookingFormData({
        ...bookingFormData,
        ['gstTax']: ''
      })
    }
  }, [gstInputDisable])

  const [parseTotalPrice, setParseTotalPrice] = useState(0)
  useEffect(() => {
    const measurements = +bookingFormData.length * +bookingFormData.breadth * +bookingFormData.width / 5000
    const remaining = +bookingFormData.freight + +bookingFormData.docket + +bookingFormData.cod + +bookingFormData.hamali + +bookingFormData.fuel_charge

    const parseMeasurements = parseFloat(measurements).toFixed(2)
    const totalPrice = measurements + remaining
    const parseTotalPrice = parseFloat(totalPrice).toFixed(2)

    const one = +bookingFormData.payAmount + (+bookingFormData.gstTax / 100) * +bookingFormData.payAmount + (+bookingFormData.insuranceTax / 100) * +bookingFormData.payAmount
    const two = +parseTotalPrice

    const three = one + two

    document.getElementById('parseMeasurements').innerHTML = `₹ ${parseMeasurements}`
    document.getElementById('totalPrice').innerHTML = `₹ ${parseTotalPrice}`
    setParseTotalPrice(parseFloat(three).toFixed(2))

  }, [bookingFormData])

  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getUserCity = async () => {
    try {
      const payload = {
        isNull: true,
        // Add other properties to the payload if needed
      };

      const response = await axios.post('https://moxd-api.moshimoshi.cloud/api/User/listCity', payload);

      if (response.status === 200) {
        setCities(response?.data?.data || []);
      } else {
        console.error('Unexpected status:', response.status);
        // Handle non-200 status codes if needed
      }
    } catch (error) {
      console.error('Error making POST request:', error);
      setError('An error occurred while fetching cities.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserCity();
  }, []);

  const handleCitySelect = (cityName) => {
    setSelectedCity(cityName);
    setSearchTerm(cityName); // Fill the search input with the selected city name
  };

  const filteredCities = useMemo(() =>
    cities.filter(city =>
      city.city_name.toLowerCase().includes(searchTerm.toLowerCase())
    ),
    [cities, searchTerm]
  );

  return (
    <div>
      {/* <button onClick={handelprint} >Click</button> */}
      <Modal show={smShow} onHide={() => setSmShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Are You Confirm to Booking ?</Modal.Title>
        </Modal.Header>
        <Modal.Footer className="m-auto">
          <Button
            variant="danger"
            className=" rounded"
            onClick={handleCancelClear}
          >
            Cancel
          </Button>
          <Button
            variant="success"
            className=" rounded"
            onClick={onClicksucess}
          >
            Ok{" "}
            {bookingLoader ? (
              <Spinner
                animation="border"
                variant="Primary"
                size="sm"
              />
            ) : (
              ""
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* success */}
      <Modal
        size="sm"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Sended Request
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="danger" className=" rounded">
            <Link to={"/bookinglist"} className="text-white">
              Cancel
            </Link>
          </Button>
          <Button variant="primary" onClick={handleClose} className=" rounded">
            Continuous Booking
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        transition={Zoom}
        delay={1000}
        limit={1}
      />
      <div className="more-link">
        <h6>
          Booking Transaction{" "}
        </h6>
      </div>
      <div className="d-flex" >
        <div className="col-md-3" style={{ marginLeft: '17px' }}>
          <h6 class="card-title my-3 bg-light w-auto py-2 rounded ">
            <FileText /> <b>Edit Booking</b>
          </h6>
        </div>
        <div className="col-md-6" style={{ marginTop: "16px" }}>
          <form onSubmit={handelEditBooking} >
            <div className="d-flex" >
              <div className="col-6">
                <input
                  name="cnno"
                  type="number"
                  class="form-control form-control-md"
                  placeholder=" C-Note Number search"
                  value={cnoteNumberData.editCnoteNumber}
                  onChange={(event) => handelEditCnote('editCnoteNumber', event.target.value)}
                  autoComplete="off"
                  maxLength={10}
                  onInput={(e) => {
                    if (e.target.value.length > e.target.maxLength) {
                      e.target.value = e.target.value.slice(
                        0,
                        e.target.maxLength
                      )
                    }
                  }}
                  onWheelCapture={(e) => e.target.blur()}
                />
                <span className="text-danger">{cnoteNumberDataError.editCnoteNumber}</span>
              </div>
              <div className="col-1">
              </div>
              <div className="col-2" >
                <button
                  className="btn btn-success btn-lg rounded"
                  // onClick={handelEditBooking}
                  type='submit'
                  style={{ padding: '3px 10px 3px 10px' }}
                >
                  Edit{" "}
                  {editSubmitLoader ? (
                    <Spinner
                      animation="border"
                      variant="Primary"
                      size="sm"
                    />
                  ) : (
                    ""
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="col-md-3" style={{ marginTop: "15px" }}>
          <button className="btn btn-danger rounded mx-3 btn-md text-white float-end"
          >
            <Link to={"/bookinglist"} className="text-white">
              {" "}
              Booking List
            </Link>
          </button>
        </div>
      </div>


      <form onSubmit={handleBookingSubmit} >
        <div className="my-3">
          <div className="card w-100">
            <div class="card-body pt-0">
              <div className="row">
                <div className="col-md-3">
                  <h6 class="card-title    my-3 bg-light w-auto py-2 rounded ">
                    <FileText /> <b> Booking</b>
                  </h6>
                </div>
                {/* <div className="col-md-6" style={{ marginTop: "16px" }}>
                <form onSubmit={handelEditBooking} >
                  <div className="d-flex" >
                    <div className="col-6">
                      <input
                        name="cnno"
                        type="number"
                        class="form-control form-control-md"
                        placeholder=" C-Note Number search"
                        value={cnoteNumberData.editCnoteNumber}
                        onChange={(event) => handelEditCnote('editCnoteNumber', event.target.value)}
                        autoComplete="off"
                        maxLength={10}
                        onInput={(e) => {
                          if (e.target.value.length > e.target.maxLength) {
                            e.target.value = e.target.value.slice(
                              0,
                              e.target.maxLength
                            )
                          }
                        }}
                        onWheelCapture={(e) => e.target.blur()}
                      />
                      <span className="text-danger">{cnoteNumberDataError.editCnoteNumber}</span>
                    </div>
                    <div className="col-1">
                    </div>
                    <div className="col-2" >
                      <button
                        className="btn btn-success rounded"
                        // onClick={handelEditBooking}
                        type='submit'
                        style={{ padding: '3px 10px 3px 10px' }}
                      >
                        Edit{" "}
                        {editSubmitLoader ? (
                          <Spinner
                            animation="border"
                            variant="Primary"
                            size="sm"
                          />
                        ) : (
                          ""
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div> */}
                {/* <div className="col-md-3" style={{ marginTop: "15px" }}>
                <button className="btn btn-danger rounded mx-3  text-white float-end" style={{ padding: '3px 10px 3px 10px' }}
                >
                  <Link to={"/bookinglist"} className="text-white">
                    {" "}
                    Booking List
                  </Link>
                </button>
              </div> */}
              </div>

              <div className="row my-2">
                <div className="col-md-3">
                  <Form.Label>Booking Type</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    value={bookingFormData.bookingType}
                    onChange={(e) =>
                      handleChangeBooking("bookingType", e.target.value)
                    }
                  >
                    <option value="NORMAL BOOKING">NORMAL BOOKING</option>
                  </Form.Select>
                  <span className="text-danger">
                    {bookingFormDataError.bookingType}
                  </span>
                </div>
                {
                  //  <div className="col-md-3 ">
                  //   <Form.Label>Package Type</Form.Label>
                  //   <Form.Select
                  //     aria-label="Default select example"
                  //     value={bookingFormData.packageType}
                  //     onChange={(e) =>
                  //       handleBookigDate("packageType", e.target.value)
                  //     }
                  //   >
                  //     <option>Select Package Type</option>
                  //     <option value="1">DOCUMENT</option>
                  //     <option value="2">PARCEL</option>
                  //   </Form.Select>{" "}
                  //   <span className="text-danger">{packageTypeError}</span>
                  // </div>
                }
                <div className="col-md-3">
                  <label for="formFile" class="form-label">
                    C-Note Number
                  </label>
                  <input
                    className="form-control form-control-md"
                    onChange={(e) => handleChangeBooking("cnNo", e.target.value)}
                    // value={cnoteData}
                    value={bookingFormData.cnNo}
                    onWheelCapture={(e) => e.target.blur()}
                    type="number"
                    maxLength={10}
                    onInput={(e) => {
                      if (e.target.value.length > e.target.maxLength) {
                        e.target.value = e.target.value.slice(0,
                          e.target.maxLength
                        )
                      }
                    }}
                  />{" "}
                  <span className="text-danger">{!bookingFormData.cnNo && bookingFormDataError.cnNo}</span>
                  {
                    // </div>
                    // <div className="col-md-6">
                    // <div className="row">
                    // <div className="col-md-6 mt-4">
                    //   <button onClick={handleGetCNote} className="btn btn-danger">
                    //     get CNote
                    //   </button>
                    // </div>
                    // </div>
                  }
                </div>
                <div className="col-md-3 ">
                  <label for="formFile" class="form-label">
                    Date
                  </label>
                  <input
                    name="bookingDate"
                    type="date"
                    class="form-control form-control-md"
                    onChange={(e) => handleChangeBooking("bookingDate", e.target.value)}
                    value={bookingFormData.bookingDate}
               
                  />
                  <span className="text-danger">
                    {!bookingFormData?.bookingDate && bookingFormDataError.bookingDate}
                  </span>
                </div>

                <div className="col-md-3 ">
                  <label for="formFile" class="form-label">
                    Time
                  </label>
                  <input
                    name="bookingTime"
                    type="time"
                    class="form-control form-control-md"
                    onChange={(e) => handleChangeBooking("bookingTime", e.target.value)}
                    value={bookingFormData.bookingTime}
                  />
                  <span className="text-danger">
                    {!bookingFormData?.bookingTime && bookingFormDataError?.bookingTime}
                  </span>
                </div>
              </div>
              <div className="row my-2 mt-4">
                <div className="col-md-3 ">
                  <Form.Label>Booking Mode</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    value={bookingFormData.bookingMode}
                    name="bookingMode"
                    onChange={(e) =>
                      handleChangeBooking("bookingMode", e.target.value)
                    }
                  >
                    <option value="Air">Air</option>
                    <option value="Surface">Surface</option>
                  </Form.Select>{" "}
                  <span className="text-danger">
                    {bookingFormDataError.bookingMode}
                  </span>
                </div>
                <div className="col-md-3 ">
                  <label for="formFile" class="form-label">
                    GST Number
                  </label>
                  <input
                    name="gstNo"
                    type="text"
                    class="form-control form-control-md"
                    onChange={(e) => handleChangeBooking("gstNo", e.target.value)}
                    value={bookingFormData.gstNo.toUpperCase()}
                    onWheelCapture={(e) => e.target.blur()}
                    maxLength={15}
                    autoComplete="off"
                  />
                  <span className="text-danger">
                    {!bookingFormData?.gstNo && bookingFormDataError.gstNo}
                  </span>
                </div>

                <div className="col-md-3 ">
                  <Form.Label>Pay Mode</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    value={bookingFormData.payMode}
                    name="payMode"
                    onChange={(e) =>
                      handleChangeBooking("payMode", e.target.value)
                    }
                  >
                    <option>Pay Mode</option>
                    <option value="Credit">Credit</option>
                    <option value="Online">Online</option>
                  </Form.Select>{" "}
                  <span className="text-danger">
                    {bookingFormDataError.payMode}
                  </span>
                </div>

                <div className="col-md-3 ">
                  <Form.Label>Type</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    value={bookingFormData.type}
                    name="orderCategory"
                    onChange={(e) =>
                      handleChangeBooking("orderCategory", e.target.value)
                    }
                  >
                    <option>Type</option>
                    <option value="Parcel">Parcel</option>
                    <option value="Document">Document</option>
                  </Form.Select>{" "}
                  <span className="text-danger">
                    {bookingFormDataError.payMode}
                  </span>
                </div>

              </div>
            </div>
          </div>

          <div className="card w-100 mt-3">
            <div class="card-body pt-0">
              <div className="row ">
                <h6 class="card-title    my-3 bg-light w-auto py-2 rounded ">
                  <FileText /> <b>User Detailes</b>
                </h6>
              </div>
              <div className="row my-2">
                <div className="col-md-3 ">
                  <label for="formFile" class="form-label">
                    Sender Name
                  </label>
                  <input
                    type="text"
                    onChange={(e) =>
                      handleChangeBooking("senderName", e.target.value)
                    }
                    class="form-control form-control-md"
                    value={bookingFormData.senderName}
                  />
                  <span className="text-danger">
                    {!bookingFormData?.senderName && bookingFormDataError.senderName}
                  </span>
                </div>
                <div className="col-md-3 ">
                  <label for="formFile" class="form-label">
                    Sender Mobile No
                  </label>
                  <input
                    type="number"
                    onChange={(e) =>
                      handleChangeBooking("senderMobileNO", e.target.value)
                    }
                    class="form-control form-control-md"
                    value={bookingFormData.senderMobileNO}
                    onWheelCapture={(e) => e.target.blur()}
                    maxLength={10}
                    onInput={(e) => {
                      if (e.target.value.length > e.target.maxLength) {
                        e.target.value = e.target.value.slice(0,
                          e.target.maxLength
                        )
                      }
                    }}
                    autoComplete='off'
                  />
                  <span className="text-danger">
                    {!bookingFormData?.senderMobileNO && bookingFormDataError.senderMobileNO}
                  </span>
                </div>
                <div className="col-md-3 ">
                  <label for="formFile" class="form-label">
                    Sender Address
                  </label>
                  <input
                    type="text"
                    onChange={(e) =>
                      handleChangeBooking("senderAddress", e.target.value)
                    }
                    class="form-control form-control-md"
                    value={bookingFormData.senderAddress}
                  />{" "}
                  <span className="text-danger">
                    {!bookingFormData?.senderAddress && bookingFormDataError.senderAddress}
                  </span>
                </div>{" "}
                <div className="col-md-3 ">
                  <label for="formFile" class="form-label">
                    Sender Pincode
                  </label>
                  <input
                    type="number"
                    onChange={(e) =>
                      handleChangeBooking("senderPincode", e.target.value)
                    }
                    class="form-control form-control-md"
                    value={bookingFormData.senderPincode}
                    maxLength={6}
                    onInput={(e) => {
                      if (e.target.value.length > e.target.maxLength) {
                        e.target.value = e.target.value.slice(
                          0,
                          e.target.maxLength
                        )
                      }
                    }}
                    onWheelCapture={(e) => e.target.blur()}
                  />{" "}
                  <span className="text-danger">
                    {bookingFormDataError.senderPincode}
                  </span>
                </div>
              </div>
              <div className="row my-2 mt-4">
                <div className="col-md-3 ">
                  <label for="formFile" class="form-label">
                    Receiver Name
                  </label>
                  <input
                    type="text"
                    onChange={(e) =>
                      handleChangeBooking("receiverName", e.target.value)
                    }
                    class="form-control form-control-md"
                    value={bookingFormData.receiverName}
                  />
                  <span className="text-danger">
                    {bookingFormDataError.receiverName}
                  </span>
                </div>
                <div className="col-md-3 ">
                  <label for="formFile" class="form-label">
                    Receiver Mobile No
                  </label>
                  <input
                    type="number"
                    onChange={(e) =>
                      handleChangeBooking("receiverMobileNO", e.target.value)
                    }
                    class="form-control form-control-md"
                    value={bookingFormData.receiverMobileNO}
                    onWheelCapture={(e) => e.target.blur()}
                    maxLength={10}
                    onInput={(e) => {
                      if (e.target.value.length > e.target.maxLength) {
                        e.target.value = e.target.value.slice(0,
                          e.target.maxLength
                        )
                      }
                    }}
                  />{" "}
                  <span className="text-danger">
                    {!bookingFormData?.receiverMobileNO && bookingFormDataError.receiverMobileNO}
                  </span>
                </div>
                <div className="col-md-3 ">
                  <label for="formFile" class="form-label">
                    Receiver Address
                  </label>
                  <input
                    type="text"
                    onChange={(e) =>
                      handleChangeBooking("receiverAddress", e.target.value)
                    }
                    class="form-control form-control-md"
                    value={bookingFormData.receiverAddress}
                  />{" "}
                  <span className="text-danger">
                    {!bookingFormData?.receiverAddress && bookingFormDataError.receiverAddress}
                  </span>
                </div>{" "}
                <div className="col-md-3">
                  <label for="formFile" class="form-label">
                    Receiver Pincode
                  </label>
                  <input
                    type="number"
                    class="form-control form-control-md"
                    onChange={(e) =>
                      handleChangeBooking("receiverPincode", e.target.value)
                    }
                    value={bookingFormData.receiverPincode}
                    maxLength={6}
                    onInput={(e) => {
                      if (e.target.value.length > e.target.maxLength) {
                        e.target.value = e.target.value.slice(
                          0,
                          e.target.maxLength
                        )
                      }
                    }}
                    onWheelCapture={(e) => e.target.blur()}
                  />

                  {receiverPincodeLoader ? (
                    <Spinner
                      animation="border"
                      variant="Primary"
                      size="sm"
                    />
                  ) : (
                    ""
                  )}

                  <span className="text-danger">
                    {" "}
                    {!bookingFormData?.receiverPincode && bookingFormDataError.receiverPincode}
                  </span>
                  <span className="text-danger"> {receiverpincode1}</span>
                </div>

              </div>
              <div className="row my-2 mt-4">
                <div className="col-md-3 ">
                  <label for="formFile" class="form-label">
                    State
                  </label>
                  <input
                    value={stateData.State}
                    type="text"
                    class="form-control form-control-md"
                    disabled
                  />
                </div>
                <div className="col-md-3 ">
                  <label for="formFile" class="form-label">
                    District
                  </label>
                  <input
                    type="text"
                    value={stateData.District}
                    class="form-control form-control-md"
                    disabled
                  />
                </div>
                <div className="col-md-3">
                <label for="formFile" class="form-label">
                    Select City
                  </label>
              <input
        type="text"
        className="form-control mb-2"
        placeholder="Search cities..."
        value={searchTerm}
        onChange={(e) => {
          setSearchTerm(e.target.value);
          if (e.target.value === '') {
            setSelectedCity('');
          }
        }}
        aria-label="Search cities"
      />

{searchTerm && !selectedCity && !loading && !error && filteredCities.map(city => (
  <div
    key={city.id}
    className="card mb-2"
    style={{ cursor: 'pointer' }}
    onClick={() => handleCitySelect(city.city_name)}
  >
    <div className="card-body">{city.city_name}</div>
  </div>
))}
      {loading && <p>Loading...</p>}

      {error && <p>Error: {error}</p>}

     
              </div>
              <div className="col-md-3 ">
              {selectedCity && <p className="mt-2">Selected City: <strong>{selectedCity}</strong></p>}
              </div>
              </div>

            
              

            </div>
          </div>

          {/********* Courier Details ********/}
          <div className="card w-100 mt-3">
            <div class="card-body pt-0">
              <div className="row ">
                <h6 class="card-title    my-3 bg-light w-auto py-2 rounded ">
                  <FileText /> <b>Courier Details</b>
                </h6>
              </div>
              <div className="row"></div>
              <div className="row my-2">
                <div className="col-md-3 ">
                  <label for="formFile" class="form-label">
                    Weight(Kgs)
                  </label>
                  <input
                    type="number"
                    onChange={(e) =>
                      handleChangeBooking("weightKgs", e.target.value)
                    }
                    class="form-control form-control-md"
                    value={bookingFormData.weightKgs}
                    maxLength={3}
                    onInput={(e) => {
                      if (e.target.value.length > e.target.maxLength) {
                        e.target.value = e.target.value.slice(
                          0,
                          e.target.maxLength
                        )
                      }
                    }}
                    onWheelCapture={(e) => e.target.blur()}
                  />{" "}
                  <span className="text-danger">
                    {bookingFormDataError.weightKgs}
                  </span>
                </div>
                <div className="col-md-3 ">
                  <label for="formFile" class="form-label">
                    Weight(Grams)
                  </label>
                  <input
                    type="number"
                    onChange={(e) =>
                      handleChangeBooking("weightGms", e.target.value)
                    }
                    class="form-control form-control-md"
                    value={bookingFormData.weightGms}
                    maxLength={3}
                    onInput={(e) => {
                      if (e.target.value.length > e.target.maxLength) {
                        e.target.value = e.target.value.slice(
                          0,
                          e.target.maxLength
                        )
                      }
                    }}
                    onWheelCapture={(e) => e.target.blur()}

                  />{" "}
                  <span className="text-danger">
                    {bookingFormDataError.weightGms}
                  </span>
                </div>
                <div className="col-md-3 ">
                  <label for="formFile" class="form-label">
                    Package Value(₹)
                  </label>
                  <input
                    type="number"
                    onChange={(e) =>
                      handleChangeBooking("packageValue", e.target.value)
                    }
                    class="form-control form-control-md"
                    value={bookingFormData.packageValue}
                    maxLength={10}
                    onInput={(e) => {
                      if (e.target.value.length > e.target.maxLength) {
                        e.target.value = e.target.value.slice(
                          0,
                          e.target.maxLength
                        )
                      }
                    }}
                    onWheelCapture={(e) => e.target.blur()}
                  />
                  <span className="text-danger">
                    {!bookingFormData?.packageValue && bookingFormDataError.packageValue}
                  </span>
                </div>
                <div className="col-md-2">
                  <label for="formFile" class="form-label">
                    Pay Amount(₹)
                  </label>
                  <input
                    type="number"
                    onChange={(e) =>
                      handleChangeBooking("payAmount", e.target.value)
                    }
                    class="form-control form-control-md"
                    value={bookingFormData.payAmount}
                    maxLength={10}
                    onInput={(e) => {
                      if (e.target.value.length > e.target.maxLength) {
                        e.target.value = e.target.value.slice(
                          0,
                          e.target.maxLength
                        )
                      }
                    }}
                    onWheelCapture={(e) => e.target.blur()}
                  />{" "}
                  <span className="text-danger">
                    {!bookingFormData?.payAmount && bookingFormDataError.payAmount}
                  </span>
                </div>
                <div className="col-md-1 d-flex justify-content-between" style={{ marginTop: '30px' }} >
                  <button className="btn btn-secondary" type="reset" onClick={handleGetAmount}>
                    Get Amount
                    {getAmountLoader ? (
                      <Spinner
                        animation="border"
                        variant="Primary"
                        size="sm"
                      />
                    ) : (
                      ""
                    )}
                  </button>

                </div>
              </div>


              <div className="row my-2 mt-4" >
                <div className="col-md-3 ">
                  <label for="formFile" class="form-label">
                    Length(cm)
                  </label>
                  <input
                    type="number"
                    onChange={(e) =>
                      handleChangeBooking("length", e.target.value)
                    }
                    class="form-control form-control-md"
                    value={bookingFormData.length}
                    maxLength={10}
                    onInput={(e) => {
                      if (e.target.value.length > e.target.maxLength) {
                        e.target.value = e.target.value.slice(
                          0,
                          e.target.maxLength
                        )
                      }
                    }}
                    onWheelCapture={(e) => e.target.blur()}

                  />{" "}
                  <span className="text-danger">
                    {bookingFormDataError.length}
                  </span>
                </div>
                <div className="col-md-3 ">
                  <label for="formFile" class="form-label">
                    Breadth(cm)
                  </label>
                  <input
                    type="number"
                    onChange={(e) =>
                      handleChangeBooking("breadth", e.target.value)
                    }
                    class="form-control form-control-md"
                    value={bookingFormData.breadth}
                    maxLength={10}
                    onInput={(e) => {
                      if (e.target.value.length > e.target.maxLength) {
                        e.target.value = e.target.value.slice(
                          0,
                          e.target.maxLength
                        )
                      }
                    }}
                    onWheelCapture={(e) => e.target.blur()}

                  />{" "}
                  <span className="text-danger">
                    {bookingFormDataError.breadth}
                  </span>
                </div>
                <div className="col-md-3 ">
                  <label for="formFile" class="form-label">
                    Width(cm)
                  </label>
                  <input
                    type="number"
                    onChange={(e) =>
                      handleChangeBooking("width", e.target.value)
                    }
                    class="form-control form-control-md"
                    value={bookingFormData.width}
                    maxLength={10}
                    onInput={(e) => {
                      if (e.target.value.length > e.target.maxLength) {
                        e.target.value = e.target.value.slice(
                          0,
                          e.target.maxLength
                        )
                      }
                    }}
                    onWheelCapture={(e) => e.target.blur()}

                  />{" "}
                  <span className="text-danger">
                    {bookingFormDataError.width}
                  </span>
                </div>
                <div className="col-md-3 ">
                  <label for="formFile" class="form-label">
                    Freight
                  </label>
                  <input
                    type="number"
                    onChange={(e) =>
                      handleChangeBooking("freight", e.target.value)
                    }
                    class="form-control form-control-md"
                    value={bookingFormData.freight}
                    maxLength={10}
                    onInput={(e) => {
                      if (e.target.value.length > e.target.maxLength) {
                        e.target.value = e.target.value.slice(
                          0,
                          e.target.maxLength
                        )
                      }
                    }}
                    onWheelCapture={(e) => e.target.blur()}

                  />{" "}
                  <span className="text-danger">
                    {bookingFormDataError.freight}
                  </span>
                </div>
              </div>

              <div className="row my-2 mt-4" >
                <div className="col-md-3 ">
                  <label for="formFile" class="form-label">
                    Docket CH
                  </label>
                  <input
                    type="number"
                    onChange={(e) =>
                      handleChangeBooking("docket", e.target.value)
                    }
                    class="form-control form-control-md"
                    value={bookingFormData.docket}
                    maxLength={10}
                    onInput={(e) => {
                      if (e.target.value.length > e.target.maxLength) {
                        e.target.value = e.target.value.slice(
                          0,
                          e.target.maxLength
                        )
                      }
                    }}
                    onWheelCapture={(e) => e.target.blur()}

                  />{" "}
                  <span className="text-danger">
                    {bookingFormDataError.docket}
                  </span>
                </div>
                <div className="col-md-3 ">
                  <label for="formFile" class="form-label">
                    COD
                  </label>
                  <input
                    type="number"
                    onChange={(e) =>
                      handleChangeBooking("cod", e.target.value)
                    }
                    class="form-control form-control-md"
                    value={bookingFormData.cod}
                    maxLength={10}
                    onInput={(e) => {
                      if (e.target.value.length > e.target.maxLength) {
                        e.target.value = e.target.value.slice(
                          0,
                          e.target.maxLength
                        )
                      }
                    }}
                    onWheelCapture={(e) => e.target.blur()}

                  />{" "}
                  <span className="text-danger">
                    {bookingFormDataError.cod}
                  </span>
                </div>
                <div className="col-md-3 ">
                  <label for="formFile" class="form-label">
                    Hamali
                  </label>
                  <input
                    type="number"
                    onChange={(e) =>
                      handleChangeBooking("hamali", e.target.value)
                    }
                    class="form-control form-control-md"
                    value={bookingFormData.hamali}
                    maxLength={4}
                    onInput={(e) => {
                      if (e.target.value.length > e.target.maxLength) {
                        e.target.value = e.target.value.slice(
                          0,
                          e.target.maxLength
                        )
                      }
                    }}
                    onWheelCapture={(e) => e.target.blur()}

                  />{" "}
                  <span className="text-danger">
                    {bookingFormDataError.hamali}
                  </span>
                </div>
                <div className="col-md-3 ">
                  <label for="formFile" class="form-label">
                    Fuel Charges
                  </label>
                  <input
                    type="number"
                    onChange={(e) =>
                      handleChangeBooking("fuel_charge", e.target.value)
                    }
                    class="form-control form-control-md"
                    value={bookingFormData.fuel_charge}
                    maxLength={4}
                    onInput={(e) => {
                      if (e.target.value.length > e.target.maxLength) {
                        e.target.value = e.target.value.slice(
                          0,
                          e.target.maxLength
                        )
                      }
                    }}
                    onWheelCapture={(e) => e.target.blur()}

                  />{" "}
                  <span className="text-danger">
                    {bookingFormDataError.fuel_charge}
                  </span>
                </div>
              </div>



              <div className="row my-2 mt-4">
                <div className="col-md-3 ">
                  <label for="formFile" class="form-label">
                    No of Pcs{" "}
                  </label>
                  <input
                    type="number"
                    onChange={(e) => handleChangeBooking("noPcs", e.target.value)}
                    class="form-control form-control-md"
                    value={bookingFormData.noPcs}
                    maxLength={10}
                    onInput={(e) => {
                      if (e.target.value.length > e.target.maxLength) {
                        e.target.value = e.target.value.slice(
                          0,
                          e.target.maxLength
                        )
                      }
                    }}
                    onWheelCapture={(e) => e.target.blur()}
                  />
                  {""}
                  <span className="text-danger">
                    {!bookingFormData.noPcs && bookingFormDataError.noPcs}
                  </span>
                </div>
                <div className="col-md-3 ">
                  <label for="formFile" class="form-label">
                    Remarks
                  </label>
                  <textarea
                    type="text"
                    onChange={(e) =>
                      handleChangeBooking("remarks", e.target.value)
                    }
                    class="form-control form-control-md"
                    value={bookingFormData.remarks}
                  />{" "}
                  <span className="text-danger">
                    {!bookingFormData.remarks && bookingFormDataError.remarks}
                  </span>
                </div>

                <div className="col-md-3">
                  <label for="formFile" class="form-label">
                    Insurance Charges{" "}
                  </label>
                  <div className="row">
                    <div className="col-6">
                      <div className="row" >
                        <div class="form-check col-md-6" >
                          <input class="form-check-input" type="radio"
                            // name="flexRadioDefault"
                            // id="flexRadioDefault2"
                            checked={insuranceInputDisable == false && true}
                            onClick={handleInsuranceradioClickYes} />
                          <label class="form-check-label"
                          // for="flexRadioDefault2"
                          >
                            Yes
                          </label>
                        </div>
                        <div class="form-check col-md-6" >
                          <input class="form-check-input" type="radio"
                            // name="flexRadioDefault"
                            // id="flexRadioDefault1"
                            checked={insuranceInputDisable == true && true}
                            onClick={handleInsuranceradioClickNo} />
                          <label class="form-check-label"
                          // for="flexRadioDefault1"
                          >
                            No
                          </label>
                        </div>

                      </div>
                    </div>
                    <div className="col-6">
                      <input
                        type="number"
                        onChange={(e) =>
                          handleChangeBooking("insuranceTax", e.target.value)
                        }
                        class="form-control form-control-md"
                        disabled={insuranceInputDisable == true ? true : false}
                        value={bookingFormData.insuranceTax}
                        onWheelCapture={(e) => e.target.blur()}
                        maxLength={5}
                        onInput={(e) => {
                          if (e.target.value.length > e.target.maxLength) {
                            e.target.value = e.target.value.slice(0,
                              e.target.maxLength
                            )
                          }
                        }}
                      />{" "}
                      <span className="text-danger">
                        {!bookingFormData?.insuranceTax && bookingFormDataError?.insuranceTax}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <label for="formFile" class="form-label">
                    GST Charges{" "}
                  </label>
                  <div className="row">
                    <div className="col-6">
                      <div className="row" >
                        <div class="form-check col-md-6" >
                          <input class="form-check-input" type="radio"
                            // name="flexRadioDefault1"
                            // id="flexRadioDefault4"
                            checked={gstInputDisable == false && true}
                            onClick={handleGstradioClickYes} />
                          <label class="form-check-label"
                          // for="flexRadioDefault4"
                          >
                            Yes
                          </label>
                        </div>
                        <div class="form-check col-md-6" >
                          <input class="form-check-input" type="radio"
                            // name="flexRadioDefault1"
                            // id="flexRadioDefault3"
                            checked={gstInputDisable == true && true}
                            onClick={handleGstradioClickNo} />
                          <label class="form-check-label"
                          // for="flexRadioDefault3"
                          >
                            No
                          </label>
                        </div>

                      </div>
                    </div>
                    <div className="col-6">
                      <input
                        type="number"
                        onChange={(e) => handleChangeBooking("gstTax", e.target.value)}
                        class="form-control form-control-md"
                        disabled={gstInputDisable == true ? true : false}
                        value={bookingFormData.gstTax}
                        onWheelCapture={(e) => e.target.blur()}
                        maxLength={5}
                        onInput={(e) => {
                          if (e.target.value.length > e.target.maxLength) {
                            e.target.value = e.target.value.slice(0,
                              e.target.maxLength
                            )
                          }
                        }}
                      />{" "}
                      <span className="text-danger">
                        {!bookingFormData?.gstTax && bookingFormDataError?.gstTax}
                      </span>
                    </div>
                  </div>
                </div>

              </div>
              <div className="row my-2 mt-4">
                <div className="col-md-3 ">
                  <label class="form-check-label">Parcel Photo</label>
                  <input type='file' name="courierPhoto" onChange={(e) => handleChangeBooking("courierPhoto", e.target.files[0])} />
                </div>
                {
                  bookingFormData?.courierPhoto
                    ?
                    <div style={{ marginTop: "10px" }} >
                      <img style={{ borderRadius: "10px" }} src={bookingFormData?.courierPhoto && URL.createObjectURL(bookingFormData?.courierPhoto)} width='100px' height="100px" />
                      <span style={{ position: "relative", left: "-3px", bottom: "45px" }} onClick={() => handleChangeBooking("courierPhoto", "")} >X</span>
                    </div>
                    :
                    ''
                }
              </div>


              <div className="row my-2 mt-4 text-center">
                <div className="col-md-6">
                  <button className="btn btn-danger rounded mx-3" type="reset" onClick={handleReset}
                  > Reset</button>
                  <button
                    className="btn btn-success rounded"
                    id="#myBtn"
                  >
                    Save
                    {bookingSubmitLoader ? (
                      <Spinner
                        animation="border"
                        variant="Primary"
                        size="sm"
                      />
                    ) : (
                      ""
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div onClick={  handelprint()}>click</div> */}
      </form>
   

      {
        <div
          ref={componentRef}
          className="container-fluid print-div"
          style={main}
        >
          {
            <div className="p-1 m-0" style={bigEle}>
             
              <div style={{ border: '2px solid black', borderRadius: "5px" }} >
                <div style={{ marginLeft: "5px" }}>
                  <h3 class="card-title    my-1 bg-light w-auto py-1 rounded ">
                    <b>Courier Booking Details</b>
                  </h3>
                   <div>
                <div className="row m-1" style={{ border: '2px solid black', borderRadius: "5px" }} >
                <div className="row">
  <div className="col">
    <div>
      <b>Address:</b><span>{" "}{loginData && loginData?.Address}</span><br></br>
      <b>Branch Name:</b><span>{" "}{loginData?.city_masters && loginData?.city_masters[0]?.city_name}</span><br></br>
    </div>
  </div>
  <div className="col">
    <div>
      <b>Pincode:</b><span>{" "}{loginData && loginData.Pincode}</span><br></br>
      <b>State Name:</b><span>{" "}{loginData && loginData?.State}</span><br></br>
    </div>
  </div>
  <div className="col">
    <div>
      <b>Mobile No:</b><span>{" "}{loginData && loginData?.MobileNumber}</span><br></br>
    </div>
  </div>
</div>
                </div>
              </div>
                </div>
                <div className="row " style={{ marginLeft: "5px" }}>
                  <h6 class="card-title    my-1 bg-light w-auto py-1 rounded ">
                    <FileText /> <b>Booking</b>
                  </h6>
                </div>
                <Table
                  className="m-0 text-center"
                  style={{ border: "2px solid black" }}
                  bordered
                  hover
                >
                  <thead
                    style={{
                      backgroundColor: "#5f7780",
                      color: "#FFFFFF",
                      fontSize: "14px",
                    }}
                  >
                    <tr>
                      <th>Sr.No</th>
                      <th>Booking Type</th>
                      <th>Date and Time</th>
                      <th>C-Note Number</th>
                      <th>Pay Mode</th>
                      <th>Booking Mode</th>
                      <th>Contact Number</th>
                      <th>Booking Address</th>
                      <th>GST Number</th>
                      <th>Order Category</th>
                      <th>City Name</th>
                    </tr>
                  </thead>
                  <tbody style={{ fontSize: "14px" }}>
                    <tr>
                      <td>1</td>
                      <td>{bookingFormData.bookingType}</td>
                      <td>
                        {bookingFormData.bookingDate} {bookingFormData.bookingTime}
                      </td>
                      <td>{bookingFormData.cnNo}</td>
                      <td>{bookingFormData.payMode}</td>
                      <td>{bookingFormData.bookingMode}</td>
                      <td>{bookingFormData.senderMobileNO}</td>
                      <td>{bookingFormData.senderAddress}</td>
                      <td>{bookingFormData.gstNo}</td>
                      <td>{bookingFormData.orderCategory}</td>
                      <td>{selectedCity}</td>


                    </tr>
                  </tbody>
                </Table>

                <div style={{ marginTop: "10px" }}>
                  <div className="row " style={{ marginLeft: "5px" }}>
                    <h6 class="card-title  my-1 bg-light w-auto py-1 rounded ">
                      <FileText /> <b>From & To</b>
                    </h6>
                  </div>
                  <Table
                    className="m-0 text-center"
                    style={{ border: "2px solid black" }}
                    bordered
                    hover
                  >
                    <thead
                      style={{
                        backgroundColor: "#5f7780",
                        color: "#FFFFFF",
                        fontSize: "14px",
                      }}
                    >
                      <tr>
                        <th>SrN</th>
                        <th>Sender Name</th>
                        <th>Mobile No</th>
                        <th>Address</th>
                        <th>Pincode</th>
                        <th>Receiver Name</th>
                        <th>Mobile</th>
                        <th>Address</th>
                        <th>Receiver Pincode</th>
                        <th>State</th>
                        <th>District</th>
                      </tr>
                    </thead>
                    <tbody style={{ fontSize: "14px" }}>
                      <tr>
                        <td>1</td>
                        <td>{bookingFormData.senderName}</td>
                        <td>{bookingFormData.senderMobileNO}</td>
                        <td>{bookingFormData.senderAddress}</td>
                        <td>{bookingFormData.senderPincode}</td>
                        <td>{bookingFormData.receiverName}</td>
                        <td>{bookingFormData.receiverMobileNO}</td>
                        <td>{bookingFormData.receiverAddress}</td>
                        <td>{bookingFormData.receiverPincode}</td>
                        <td>{stateData.State}</td>
                        <td>{stateData.District}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <div className="row" style={{ marginLeft: "5px" }}>
                    <h6 class="card-title    my-1 bg-light w-auto py-1 rounded ">
                      <FileText /> <b>Courier Details</b>
                    </h6>
                  </div>
                  <Table
                    className="m-0 text-center"
                    style={{ border: "2px solid black" }}
                    bordered
                    hover
                  >
                    <thead
                      style={{
                        backgroundColor: "#5f7780",
                        color: "#FFFFFF",
                        fontSize: "14px",
                      }}
                    >
                      <tr>
                        <th>Sr.No</th>
                        <th>Weight(kgs)</th>
                        <th>Weight(Gms)</th>
                        <th>Package Value</th>
                        <th>Pay Amout</th>
                        <th>No of Pcs</th>
                        <th>Insurance Charges</th>
                        <th>Gst Charges</th>
                        <th>Remarks</th>
                      </tr>
                    </thead>
                    <tbody style={{ fontSize: "14px" }}>
                      <tr>
                        <td>1</td>
                        <td>{bookingFormData.weightKgs}{" "}kgs</td>
                        <td>{bookingFormData.weightGms}{" "}Gms</td>
                        <td>{bookingFormData.packageValue}</td>
                        <td>{bookingFormData.payAmount}</td>
                        <td>{bookingFormData.noPcs}</td>
                        <td>{bookingFormData.insuranceTax == '' ? 'No' : 'yes'}</td>
                        <td>{bookingFormData.gstTax == '' ? 'No' : 'yes'}</td>
                        <td>{bookingFormData.remarks}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>


                <div >
                  <div className="row" style={{ marginLeft: "5px" }}>
                    <h6 class="card-title    my-1 bg-light w-auto py-1 rounded ">
                      <FileText /> <b>Courier Charges</b>
                    </h6>
                  </div>
                  <Table
                    className="m-0 text-center"
                    style={{ border: "2px solid black" }}
                    bordered
                    hover
                  >
                    <thead
                      style={{
                        backgroundColor: "#5f7780",
                        color: "#FFFFFF",
                        fontSize: "14px",
                      }}
                    >
                      <tr>
                        <th>Sr.No</th>
                        <th>Total Tax</th>
                        <th>Total Charges</th>
                      </tr>

                    </thead>
                    <tbody style={{ fontSize: "14px" }}>

                      <tr>
                        <td>1</td>
                        <td><b >LENGHT X BREADTH X WIDTH / 5000</b></td>
                        <td><b id='parseMeasurements' >₹{" "}{ }</b></td>
                      </tr>

                      <tr>
                        <td>2</td>
                        <td><b >FREIGHT</b></td>
                        <td><b>₹{" "}{parseFloat(+bookingFormData.freight || 0).toFixed(2)}</b></td>
                      </tr>

                      <tr>
                        <td>3</td>
                        <td><b >DOCKET CH</b></td>
                        <td><b>₹{" "}{parseFloat(+bookingFormData.docket || 0).toFixed(2)}</b></td>
                      </tr>

                      <tr>
                        <td>4</td>
                        <td><b>COD</b></td>
                        <td><b>₹{" "}{parseFloat(+bookingFormData.cod || 0).toFixed(2)}</b></td>
                      </tr>

                      <tr>
                        <td>5</td>
                        <td><b >HAMALI</b></td>
                        <td><b>₹{" "}{parseFloat(+bookingFormData.hamali || 0).toFixed(2)}</b></td>
                      </tr>

                      <tr>
                        <td>6</td>
                        <td><b >FUEL CHARGE</b></td>
                        <td><b>₹{" "}{parseFloat(+bookingFormData.fuel_charge || 0).toFixed(2)}</b></td>
                      </tr>


                      <tr>
                        <td>7</td>
                        <td><b>TOTAL</b></td>
                        <td><b id='totalPrice' ></b></td>
                      </tr>


                      <tr>
                        <td>8</td>
                        <td><b style={{ color: "blue" }}>Booking</b></td>
                        <td><b>₹{" "}{parseFloat(bookingFormData.payAmount == '' ? 0 : bookingFormData.payAmount).toFixed(2)}</b></td>
                      </tr>

                      <tr>
                        <td>9</td>
                        <td><b style={{ color: "blue" }}>GST@{bookingFormData.gstTax == '' ? 0 : bookingFormData.gstTax}%</b></td>
                        <td><b>₹{" "}{parseFloat(bookingFormData.gstTax == '' ? 0 : (+bookingFormData.gstTax / 100) * bookingFormData.payAmount).toFixed(2)}</b></td>
                      </tr>

                      <tr>
                        <td>10</td>
                        <td><b style={{ color: "blue" }}>Insurance@{bookingFormData.insuranceTax == '' ? 0 : bookingFormData.insuranceTax}%</b></td>
                        <td><b>₹{" "}{parseFloat(bookingFormData.insuranceTax == '' ? 0 : (+bookingFormData.insuranceTax / 100) * bookingFormData.payAmount).toFixed(2)}</b></td>
                      </tr>
                      <tr>
                        <td>11</td>
                        <td><b>Grand Total Amount:</b></td>
                        <td><b>₹{" "}{parseTotalPrice}</b></td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
                <div style={{ textAlign: 'end' }} >
                  <b style={{ fontSize: '15px', marginRight: '10px' }} >POWERD BY MOXD</b>
                </div>

              </div>
            </div>
            
          }
        </div>
      }



    </div>
  );
}

export default Booking;
