import { faUserAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import {
  Card,
  Col,
  Container,
  FloatingLabel,
  Form,
  Row,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import Apiservice from "../Services/Apiservice";

const Register = ({ handleregister }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [registerdata, setregisterdata] = useState({});
  const handleRegister = () => {
    handleregister();
  };
  const handlename = (e) => {
    setregisterdata({ ...registerdata, Name: e.target.value });
  };
  const handleemail = (e) => {
    setregisterdata({ ...registerdata, Email: e.target.value });
  };
  const handlenumber = (e) => {
    setregisterdata({ ...registerdata, MobileNumber: e.target.value });
  };
  const handlepassword = (e) => {
    setregisterdata({ ...registerdata, Password: e.target.value });
  };
  const handleSubmission = (e) => {
    Apiservice.postdata("/register", registerdata).then((val) => {
      console.log(val, "vvv");
      if (val.error == false) {
      }
    });
  };

  return (
    <Row className="justify-content-center mt-5">
      <Col md={6}>
        <div className="user">
          <Card className="p-3 shadow w-100">
            <Container>
              <h1 className="mt-4 signin-title more-link">
                <FontAwesomeIcon
                  icon={faUserAlt}
                  color="orange"
                ></FontAwesomeIcon>{" "}
                Register
              </h1>
              <hr />
              <Row>
                <Col md={6}>
                  <form action="" onSubmit={handleSubmit(handleSubmission)}>
                    <div className="form-signin">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Name"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Name"
                          onChange={handlename}
                          {...register("Name", { required: true })}
                        />
                        <small className="text-danger">
                          {errors.Name?.type === "required" &&
                            "Name  is required"}
                        </small>
                      </FloatingLabel>
                      <FloatingLabel
                        controlId="floatingEmail"
                        label="Email"
                        onChange={handleemail}
                        className="mb-3"
                      >
                        <Form.Control
                          type="email"
                          placeholder="Email"
                          {...register("Email", { required: true })}
                        />
                        <small className="text-danger">
                          {errors.Email?.type === "required" &&
                            "Email  is required"}
                        </small>
                      </FloatingLabel>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Phone Number"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Phone Number"
                          onChange={handlenumber}
                          {...register("Phone_Number", { required: true })}
                        />
                        <small className="text-danger">
                          {errors.Phone_Number?.type === "required" &&
                            "Number  is required"}
                        </small>
                      </FloatingLabel>
                      <FloatingLabel
                        controlId="floatingPassword"
                        label="Password"
                        onChange={handlepassword}
                      >
                        <Form.Control
                          type="password"
                          placeholder="Password"
                          {...register("Password", { required: true })}
                        />
                        <small className="text-danger">
                          {errors.Password?.type === "required" &&
                            "Password  is required"}
                        </small>
                      </FloatingLabel>
                      <div className="d-flex justify-content-between mt-4">
                        <button className="all-button   btn-md rounded">Register</button>{" "}
                        <span className="more-link" onClick={handleRegister}>
                          Login
                        </span>
                      </div>
                    </div>
                  </form>
                </Col>

                <Col md={6} className="right-border">
                  <div className="circularlog">
                    <img src="MoxD_PrimaryLogo1.png" alt="" />
                  </div>
                </Col>
              </Row>
            </Container>
          </Card>
        </div>
      </Col>
    </Row>
  );
};

export default Register;
