import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Table,
} from "react-bootstrap";
import "../../Styles/Setupmaster.css";
const Usermaster = () => {

  const [rowData, setRowdata] = useState()
  useEffect(async () => {
    // const apiData = await Apiservice.getFranchiseList()
    // if (apiData.error == false) {
    //   setRowData(apiData.data)
    // } else {
    //   console.log('fale');
    // }
  }, [])


  const rowHeight = 50;
  const DefaultColumnSetting = {
    sortable: true,
    filter: true,

    floatingFilter: true,
    flex: 1,
    resizable: true,
    minWidth: 120,
  };

  const [colDefs, setColDefs] = useState(
    [
      {
        headerName: "#",
        valueGetter: "node.rowIndex + 1",
        filter: true,
        lockPosition: true,
      },
      {
        headerName: 'State',
        filter: true,
        field: "State",
      },
      {
        headerName: "District",
        filter: true,
        field: "District",
      },
      {
        headerName: "Business Name",
        filter: true,
        field: "BusinessName",
      },
      {
        headerName: "Name",
        filter: true,
        field: "ContactPersion",
      }])

  return (
    <>
      <Container>
        <Row>
          {/* { <Col>
            <Table hover size="sm">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Rate</th>
                  <th>Full Name</th>
                  <th>Role</th>
                  <th>Mobile No.</th>
                  <th>Remark</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>12</td>
                  <td>XYZ</td>
                  <td>Main Branch User</td>
                  <td>2365147890</td>
                  <td>Average</td>
                  <td>
                    <FontAwesomeIcon icon={faEdit} className="iconstyle" />
                  </td>
                </tr>
                <tr>
                  <td>1</td>
                  <td>12</td>
                  <td>XYZ</td>
                  <td>Main Branch User</td>
                  <td>2365147890</td>
                  <td>Average</td>
                  <td>
                    <FontAwesomeIcon icon={faEdit} className="iconstyle" />
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>} */}
          <div
            className="ag-theme-alpine"
            style={{ height: "70vh", width: "100%" }}
          >
            <AgGridReact
              rowHeight={rowHeight}
              // columnDefs={columns}
              columnDefs={colDefs}
              defaultColDef={DefaultColumnSetting}
              pagination={true}
              paginationPageSize={10}
              // onGridReady={onGridReady}
              rowData={rowData}
            />{" "}
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Usermaster;
