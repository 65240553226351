import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table, Spinner, Modal } from "react-bootstrap";
import { toast, ToastContainer, Zoom } from "react-toastify";
import { Circles } from "react-loader-spinner";
import Apiservice from "../../Services/Apiservice";
import Switch from "react-switch";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FileText } from "react-bootstrap-icons";


function FrenchiseRateMaster() {
  const [cnoteWise, setCnoteWise] = useState(false);
  const [weightWise, setWeightWise] = useState(false);
  const [cNoteForm, setCnoteForm] = useState({});
  const [weightForm, setWeightForm] = useState({});
  const [transactionType, setTransactionType] = useState({});
  const [FrechiseTable, setFrenchiseTable] = useState(null);
  const [status, setStatus] = useState(null);
  const [loadstate, setloadstate] = useState(false);
  const [loadstatetwo, setloadstatetwo] = useState(false);
  const [showEditable, setShowEditable] = useState(false);
  const [FormFieldErrors, setFormFieldErrors] = useState({
    weight: "",
    amount_weight: "",
    c_note_count: "",
    amount_c_note: "",
  });
  const [editableData, setEditableData] = useState({
    delivery_type: "",
    weight: "",
    amount_weight: "",
    c_note_count: "",
    amount_c_note: "",
    status: "",
    id: "",
  });

  const [formFields, setFormFields] = useState({
    weight: "",
    amount_weight: "",
    c_note_count: "",
    amount_c_note: "",
  });

  const today = new Date();
  const date = today.setDate(today.getDate());
  const defaultValue = new Date(date).toISOString().split("T")[0];

  useEffect(async () => {
    const FrenchiseRateList = await Apiservice.postdata(
      "/admin/frenchise_rate_master_list"
    );
    if (FrenchiseRateList.error == false) {
      // toast.success(client.message)
      setFrenchiseTable(FrenchiseRateList.data);
      setRowData(FrenchiseRateList.data);
    } else {
      console.log("API is not working");
    }
  }, []);

  const handleRadioClick1 = () => {
    setCnoteWise(true);
    setWeightWise(false);
    setWeightForm({
      ...weightForm,
      weightWeight: "",
      weightAmount: "",
    });
    setStatus(false);
  };

  // const textswitch = {
  //   display:"flex",
  //   justifyContent:'center',
  //   alignItems:'center',
  //   height:"100%",
  //   fontSize:10,
  //   color:"#fff",
  //   paddingLeft:5,
  //   paddingRight:5
  // }

  const handleRadioClick2 = () => {
    setWeightWise(true);
    setCnoteWise(false);
    setCnoteForm({
      ...cNoteForm,
      cNoteCout: "",
      cnoteAmout: "",
    });
    setStatus(true);
  };

  const handleCnoteChange = (key, ele) => {
    setCnoteForm({
      ...cNoteForm,
      [key]: ele,
    });
  };

  const handleWeightChange = (key, ele) => {
    setWeightForm({
      ...weightForm,
      [key]: ele,
    });
  };

  const handleTransactionChange = (key, ele) => {
    setTransactionType({
      ...transactionType,
      [key]: ele,
    });
  };

  const handleeditratedetails = (data) => {
    setShowEditable(!showEditable);
    setEditableData(data);
  };

  const handlenewrates = (type, event) => {
    setEditableData({ ...editableData, [type]: event.target.value });

    const p = { ...editableData };
    p[type] = event.target.value;
    setFormFields(p);

    setFormFieldErrors({
      ...FormFieldErrors,
      [type]: null,
    });
  };

  const ValidatingFormFields = () => {
    const { weight, amount_weight, c_note_count, amount_c_note } = formFields;
    const weightReg = /[0-9]/;
    const amountweightReg = /[0-9]/;
    const cnotecountReg = /[0-9]/;
    const cnoteamountReg = /[0-9]/;
    const newErrors = {};
    if (weight == "") {
      newErrors.weight = "Please enter Weight";
    } else if (!weightReg.test(weight) && weight) {
      newErrors.weight = "please enter number";
    }

    if (amount_weight == "") {
      newErrors.amount_weight = "Please enter Weight Amount";
    } else if (!amountweightReg.test(amount_weight) && amount_weight) {
      newErrors.amount_weight = "Please enter Number";
    }

    if (c_note_count == "") {
      newErrors.c_note_count = "Please enter C-note Count";
    } else if (!cnotecountReg.test(c_note_count) && c_note_count) {
      newErrors.c_note_count = "Please enter Number";
    }

    if (amount_c_note == "") {
      newErrors.amount_c_note = "Please enter C-note Amount";
    } else if (!cnoteamountReg.test(amount_c_note) && amount_c_note) {
      newErrors.amount_c_note = "Please enter Number";
    }

    return newErrors;
  };

  const updateEditSubmit = async () => {
    const validationObject = ValidatingFormFields();

    console.log(validationObject, "validationObject");

    if (Object.keys(validationObject).length > 0) {
      setFormFieldErrors(validationObject);
    } else {
      const payload = {
        delivery_type: editableData.delivery_type,
        weight: editableData.weight,
        amount_weight: editableData.amount_weight,
        c_note_count: editableData.c_note_count,
        amount_c_note: editableData.amount_c_note,
        status: editableData.status,
        id: editableData.id,
      };

      const updateEditSubmitapi = await Apiservice.postdata(
        "/booking/editfrenchise_rate_master",
        payload
      );
      if (updateEditSubmitapi.error == false) {
        toast.success(updateEditSubmitapi.message);
        setShowEditable(!showEditable);
      } else {
        toast.error(updateEditSubmitapi.message);
      }
    }
  };

  const handledeleteClick = async (data) => {
    const payload = {
      id: data.id,
    };

    const handledeleteClickapi = await Apiservice.postdata(
      "/booking/deletefrenchiserate_masters",
      payload
    );

    if (handledeleteClickapi.error == false) {
      toast.success(handledeleteClickapi.message);
    } else {
      toast.error(handledeleteClickapi.message);
    }
  };

  const handleFrenchiseSubmit = async () => {
    setloadstate(true);
    if (status === true) {
      const payload = {
        delivery_type: transactionType.transactionType,
      };

      payload.status = status;
      payload.weight = weightForm.weightWeight;
      payload.amount_weight = weightForm.weightAmount;
      payload.c_note_count = 0;
      payload.c_note_amount = 0;

      const FrenchiseRateMasterApi = await Apiservice.postdata(
        "/admin/frenchise_rate_master",
        payload
      );
      setloadstate(false);
      if (FrenchiseRateMasterApi.error == false) {
        toast.success(FrenchiseRateMasterApi.message);
        window.location.reload();
      } else {
        toast.error(FrenchiseRateMasterApi.message);
      }
    } else {
      const payload = {
        delivery_type: transactionType.transactionType,
      };

      payload.status = status;
      payload.c_note_count = cNoteForm.cNoteCout;
      payload.c_note_amount = cNoteForm.cnoteAmout;
      payload.weight = 0;
      payload.amount_weight = 0;

      const FrenchiseRateMasterApi = await Apiservice.postdata(
        "/admin/frenchise_rate_master",
        payload
      );
      setloadstate(false);
      if (FrenchiseRateMasterApi.error == false) {
        toast.success(FrenchiseRateMasterApi.message);
        window.location.reload();
      } else {
        toast.error(FrenchiseRateMasterApi.message);
      }
    }
  };

  const [cNoteFilterFromTo, setCNoteFilterFromTo] = useState({});
  const onchangedate = (event) => {
    setCNoteFilterFromTo({
      ...cNoteFilterFromTo,
      [event.target.name]: event.target.value,
    });
  };
  const handelFromToSubmit = async () => {
    setloadstatetwo(true);
    const payload = {
      dateFrom: cNoteFilterFromTo.from,
      dateTo: cNoteFilterFromTo.to,
    };
    const cnoteData = await Apiservice.postdata(
      "/admin/frenchise_rate_master_list",
      payload
    );
    setloadstatetwo(false);
    if (cnoteData.error == false) {
      setFrenchiseTable(cnoteData.data);
      setRowData(cnoteData.data);
    } else {
    }
  };

  const handlecancel = () => {
    setShowEditable(!showEditable);
  };

  const handleclear = () => {
    setEditableData({
      delivery_type: editableData.delivery_type,
      weight: "",
      amount_weight: "",
      c_note_count: "",
      amount_c_note: "",
      status: editableData.status,
      id: editableData.id,
    });
  };

  const rowHeight = 50;

  const [rowData, setRowData] = useState();

  const [colDefs, setColDefs] = useState([
    {
      headerName: "Sl.No",
      valueGetter: "node.rowIndex + 1",
      filter: true,
      lockPosition: true,
    },
    {
      headerName: "Type",
      filter: true,
      field: "delivery_type",
    },
    {
      headerName: "C-Note Amount",
      filter: true,
      field: "amount_c_note",
    },
    {
      headerName: "Weight Amount",
      filter: true,
      field: "amount_weight",
    },
    {
      headerName: "C-Note Count",
      filter: true,
      field: "c_note_count",
    },
    {
      headerName: "Weight",
      filter: true,
      field: "weight",
    },
    {
      headerName: "CreatedAt",
      filter: true,
      field: "createdAt",
    },
    {
      headerName: "UpdatedAt",
      filter: true,
      field: "updatedAt",
    },
    {
      headerName: "Status",
      filter: true,
      cellRendererFramework: (params) => (
        <center>
          <Button
            style={{ width: "100px", textAlign: "center" }}
            variant="primary"
            size="sm"
          >
            {params.data.status == false ? "Weight Vice" : "C-note Vice"}
          </Button>
        </center>
      ),
    },
    {
      headerName: "Edit",
      filter: true,
      cellRendererFramework: (params) => (
        <center>
          <FontAwesomeIcon
            onClick={() => handleeditratedetails(params.data)}
            icon={faEdit}
            className="iconstyle"
          />
        </center>
      ),
    },
    {
      headerName: "Delete",
      filter: true,
      cellRendererFramework: (params) => (
        <center>
          <FontAwesomeIcon
            onClick={() => handledeleteClick(params.data)}
            icon={faTrash}
            className="iconstyle"
          />
        </center>
      ),
    },
  ]);

  const DefaultColumnSetting = {
    sortable: true,
    filter: true,

    floatingFilter: true,
    flex: 1,
    resizable: true,
    minWidth: 120,
  };

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        transition={Zoom}
        delay={1000}
        limit={1}
      />
      <div className="my-3">
        <div className="card w-100">
          <div class="card-body pt-0">
            <div className="col-md-3">
              <h6 class="card-title    my-3 bg-light w-auto py-2 rounded ">
                <FileText /> <b>Frnachise Rate Master</b>
              </h6>
            </div>
            <div className="row mt-4">
              <div className="col-md-3">
                <Form.Group>
                  <Form.Label>Transaction Type</Form.Label>
                  <Form.Select
                    onChange={(e) =>
                      handleTransactionChange("transactionType", e.target.value)
                    }
                    value={transactionType.transactionType}
                  >
                    <option>Select Transaction</option>
                    <option value="OGM-Inward">OGM-Inward</option>
                    <option value="OGM-Outward">OGM-Outward</option>
                    <option value="Local-Inward">Local-Inward</option>
                    <option value="Local-Outward">Local-Outward</option>
                    <option value="Out For Delivery">Out For Delivery</option>
                    <option value="Delivery Update">Delivery Update</option>
                    <option value="COD Parcel">COD Parcel</option>
                  </Form.Select>
                </Form.Group>
              </div>
              <div className="col-md-2 mt-4 pt-3">
                <Form.Group>
                  <Form.Check
                    type="radio"
                    name="group1"
                    label="C-Note Vice"
                    onClick={handleRadioClick1}
                  ></Form.Check>
                </Form.Group>
              </div>
              <div className="col-md-2 mt-4 pt-3">
                <Form.Group>
                  <Form.Check
                    type="radio"
                    name="group1"
                    label="Weight Vice"
                    onClick={handleRadioClick2}
                  ></Form.Check>
                </Form.Group>
              </div>
              <div className="col-md-6"></div>
            </div>

            {cnoteWise == true ? (
              <div className="row mt-4 ml-2">
                <div className="col-md-3">
                  <Form.Label>Enter C-Note Count</Form.Label>
                  <Form.Group>
                    <Form.Control
                      style={{ textAlign: "right" }}
                      value={cNoteForm.cNoteCout}
                      label="Enter C-Note Count"
                      onChange={(e) =>
                        handleCnoteChange("cNoteCout", e.target.value)
                      }
                      type="number"
                    ></Form.Control>
                  </Form.Group>
                </div>
                <div className="col-md-3">
                  <Form.Label>Enter C-Note Amount</Form.Label>
                  <Form.Group>
                    <Form.Control
                      style={{ textAlign: "right" }}
                      value={cNoteForm.cnoteAmout}
                      label="Enter Amount"
                      type="number"
                      onChange={(e) =>
                        handleCnoteChange("cnoteAmout", e.target.value)
                      }
                    ></Form.Control>
                  </Form.Group>
                </div>
              </div>
            ) : (
              ""
            )}

            {weightWise == true ? (
              <div className="row mt-4 ml-2">
                <div className="col-md-3">
                  <Form.Group>
                    <Form.Label>Enter Weight</Form.Label>
                    <Form.Control
                      style={{ textAlign: "right" }}
                      value={weightForm.weightWeight}
                      label="Enter C-Note Count"
                      onChange={(e) =>
                        handleWeightChange("weightWeight", e.target.value)
                      }
                      type="number"
                      placeholder="0.0"
                    ></Form.Control>
                  </Form.Group>
                </div>
                <div className="col-md-3">
                  <Form.Group>
                    <Form.Label>Enter Amount</Form.Label>
                    <Form.Control
                      style={{ textAlign: "right" }}
                      value={weightForm.weightAmount}
                      label="Enter Amount"
                      onChange={(e) =>
                        handleWeightChange("weightAmount", e.target.value)
                      }
                      type="number"
                      placeholder="0.0"
                    ></Form.Control>
                  </Form.Group>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="row mt-4 ml-2">
              <div className="col-md-3">
                <Button
                  onClick={handleFrenchiseSubmit}
                  className="btn btn-prmary rounded"
                >
                  {loadstate ? (
                    <Spinner animation="border" variant="Primary" size="sm" />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </div>
            </div>
          </div>

          <div className="row ">
            <div className="col-md-3">
              {" "}
              <Form.Label>From</Form.Label>
              <Form.Control
                type="date"
                value={cNoteFilterFromTo.from}
                onChange={onchangedate}
                name="from"
                format="DD/MM/YYYY"
              />
            </div>

            <div className="col-md-3">
              <Form.Label>To</Form.Label>
              <Form.Control
                type="date"
                value={cNoteFilterFromTo.to}
                onChange={onchangedate}
                name="to"
                format="DD/MM/YYYY"
                defaultValue={defaultValue}
              />
            </div>
            <div className="col-md-2 mt-3">
              <button
                className="btn btn-success rounded mx-3  text-white mt-3"
                onClick={handelFromToSubmit}
              >
                {loadstatetwo ? (
                  <Spinner animation="border" variant="Primary" size="sm" />
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </div>

          <div className="">
            <Row className="mt-5">
              {FrechiseTable ? (
                <Col>
                  {/* <Table bordered hover size="sm">
                      <thead>
                        <tr>
                          <th>Sl.No</th>
                          <th>Type</th>
                          <th>C-Note Amount</th>
                          <th>Weight Amount</th>
                          <th>C-Note Count</th>
                          <th>Weight</th>
                          <th>CreatedAt</th>
                          <th>UpdatedAt</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          FrechiseTable.map((e, i) => {
                            return (
                              <tr id={i}>
                                <td>{i + 1}</td>
                                <td>{e.delivery_type}</td>
                                <td>{e.amount_c_note}</td>
                                <td>{e.amount_weight}</td>
                                <td>{e.c_note_count}</td>
                                <td>{e.weight}</td>
                                <td>{e.createdAt}</td>
                                <td>{e.updatedAt}</td>
                                <td>
                                  <Switch
                                    // handleDiameter={25}
                                    // width={95}
                                    // checkedIcon = {
                                    //   <div style={textswitch}>Weight Vice</div>
                                    // }
                                    // uncheckedIcon = {
                                    //   <div style={textswitch}>C-note Vice</div>
                                    // }
                                    checked={e.status} />
                                  {
                                    e.status == false ?
                                      (<span
                                      >Weight Vice</span>) : (
                                        <span >C-note Vice</span>)
                                  }

                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </Table> */}

                  <div
                    className="ag-theme-alpine"
                    style={{ height: "70vh", width: "100%" }}
                  >
                    <AgGridReact
                      rowHeight={rowHeight}
                      // columnDefs={columns}
                      columnDefs={colDefs}
                      defaultColDef={DefaultColumnSetting}
                      pagination={true}
                      paginationPageSize={10}
                      // onGridReady={onGridReady}
                      rowData={rowData}
                    />{" "}
                  </div>
                </Col>
              ) : (
                <div
                  style={{
                    marginLeft: "550px",
                    marginTop: "100px",
                    marginBottom: "50px",
                  }}
                >
                  <Circles
                    height="30"
                    width="30"
                    color="grey"
                    ariaLabel="loading"
                  />
                </div>
              )}
            </Row>
          </div>
        </div>
      </div>

      <Modal
        show={showEditable}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <h6 className="text-center more-link mt-4">
          Edit Franchise Rate-Master
        </h6>
        <Modal.Body>
          <div className="new-price-add-container">
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Type</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Delivery type"
                    size="sm"
                    value={editableData.delivery_type}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>C-Note Amount</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Distance To"
                    onChange={(e) => handlenewrates("amount_c_note", e)}
                    size="sm"
                    value={editableData.amount_c_note}
                  />
                  <span className="text-danger">
                    {FormFieldErrors.amount_c_note}
                  </span>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Weight Amount</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Air Amount"
                    onChange={(e) => handlenewrates("amount_weight", e)}
                    size="sm"
                    value={editableData.amount_weight}
                  />
                  <span className="text-danger">
                    {FormFieldErrors.amount_weight}
                  </span>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>C-Note Count</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Surface Amount"
                    onChange={(e) => handlenewrates("c_note_count", e)}
                    size="sm"
                    value={editableData.c_note_count}
                  />
                  <span className="text-danger">
                    {FormFieldErrors.c_note_count}
                  </span>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Weight</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Surface Amount"
                    onChange={(e) => handlenewrates("weight", e)}
                    size="sm"
                    value={editableData.weight}
                  />
                  <span className="text-danger">{FormFieldErrors.weight}</span>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Button
                  className="save-Button"
                  variant="primary"
                  onClick={updateEditSubmit}
                >
                  Update Data
                </Button>
              </Col>
            </Row>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handlecancel}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default FrenchiseRateMaster;
