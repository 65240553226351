import React, { useState } from 'react'
import Apiservice from '../../Services/Apiservice';
import '../../Style/transactions.css';

const TransactionTracking = () => {
    const [traId, setTraId] = useState();
    const [TrackData, setTrackData] = useState()

    const getTraData = async () => {

        let data = {
            transaction_id: traId
        }
        const track = await Apiservice.getTrackData(data);
        if (!track.error) {
            console.log("track data s\is", track)
            setTrackData(track.data)
        } else {
            console.log("the error is", track)
        }
    }

    return (
        <div>
            <div className="search-container">
                <input onChange={(e) => setTraId(e.target.value)} type="text" placeholder="Transaction Number" />
                <button onClick={getTraData} className="ogm-search-button">Get Data</button>
            </div>
            <div className='status-container'>
                {
                    TrackData ? <div>
                        <p>Status: {TrackData.delivery_status}</p>
                        <p>
                            Locations: {
                                TrackData.Frenchises.map((e, i) => {
                                    return (
                                        <span>
                                            {
                                                i < TrackData.Frenchises.length && i != 0 ? <span>{' => '}</span> : ''
                                            }
                                            {e.State}({e.District})
                                        </span>
                                    )
                                })
                            }
                        </p>
                    </div> : ''
                }
            </div>
        </div>
    )
}
export default TransactionTracking