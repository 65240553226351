import React, { useEffect, useState } from 'react'
import Apiservice from '../../Services/Apiservice';
import { toast, ToastContainer, Zoom } from "react-toastify";
import Select from "react-select";
import { FileText } from "react-bootstrap-icons";
import {
    Col,
    Container,
    Row,
    Table,
    Card,
    Form,
    Button,
    Modal,
    Spinner,
} from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSync, faTrash } from "@fortawesome/free-solid-svg-icons";
import CustomModal from '../../common/CustomModal';

function RoleMaster() {
    const [rollForm, setRollForm] = useState({
        rollName: ''
    })
    const [rollNameError, setRollNameError] = useState({
        rollName: ''
    })
    const [rowData, setRowData] = useState(null)
    const [pageReload, setPageReload] = useState(1)

    /* Toast Modal */
    const [showPopupOpen, setShowPopupOpen] = useState(false)
    const [modalMessage, setModalMessage] = useState(null)
    const handleClosePopup = () => {
        setShowPopupOpen(false)
    }
    const handleOkClick = () => {
        setShowPopupOpen(false)
    }


    const handleChange = (e) => {
        setRollForm({
            ...rollForm, ['rollName']: e.target.value
        })
        setRollNameError({
            ...rollNameError, ['rollName']: null
        })
    }
    const handleValidation = () => {
        const textReg = /[A-Za-z]/
        const { rollName } = rollForm
        const newError = {}
        if (!rollName) {
            newError.rollName = 'Please Enter Roll Name'
        } else if (rollName && !textReg.test(rollName)) {
            newError.rollName = 'nummbers is not allowed'
        } else if (rollName && rollName.length > 30) {
            newError.rollName = 'Roll Name Should be below 30 charecters'
        }
        return newError
    }

    const handleSubmit = async () => {
        const handleValidationObject = handleValidation()
        if (Object.keys(handleValidationObject).length > 0) {
            setRollNameError(handleValidationObject)
        } else {
            const payload = {
                role_name: rollForm.rollName,
            }
            const pincode = await Apiservice.postdata(`/User/create_role`, payload);
            if (pincode.error == false) {
                // toast.success(pincode.message)
                setShowPopupOpen(true)
                setModalMessage(pincode.message)
                setPageReload(pageReload + 1)
                setRollForm({
                    ...rollForm,
                    rollName: ""
                })
            } else {
                // toast.error(pincode.message)
                setShowPopupOpen(true)
                setModalMessage(pincode.message)
                setPageReload(pageReload + 1)
                setRollForm({
                    ...rollForm,
                    rollName: ""
                })
            }
        }

    }

    useEffect(async () => {
        const pincode = await Apiservice.postdata(`/User/role_master_list`);
        if (pincode.error == false) {
            setRowData(pincode.data)
        } else {
            console.log("failue");
        }
    }, [pageReload])


    /**** Roll Master List Table *****/
    const rowHeight = 50;
    const DefaultColumnSetting = {
        sortable: true,
        filter: true,

        floatingFilter: true,
        flex: 1,
        resizable: true,
        minWidth: 120,
    };

    // const [colDefs, setColDefs] = useState([{ field: 'make' }]);

    const [colDefs, setColDefs] = useState([
        {
            headerName: "#",
            valueGetter: "node.rowIndex + 1",
            filter: true,
            lockPosition: true,
        },
        {
            headerName: "Roll Name",
            filter: true,
            field: "role_name",
        },
        // {
        //     headerName: "Pin code",
        //     filter: true,
        //     valueGetter: "data.pincode_masters[0].pincode",
        // },
        // {
        //   headerName: "Edit",
        //   filter: true,
        //   cellRendererFramework: (params) => (
        //     <center
        //     // onClick={() => handleClickdata(params)}
        //     >
        //       <FontAwesomeIcon icon={faEdit} className="iconstyle" />
        //     </center>
        //   ),
        // },
        // {
        //     headerName: "Delete",
        //     filter: true,
        //     cellRendererFramework: (params) => (
        //         <center
        //         // onClick={() => handleClickdata(params.data)}
        //         >
        //             <FontAwesomeIcon icon={faTrash} className="iconstyle" />
        //         </center>
        //     ),
        // },
    ]);



    return (
        <div>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar
                transition={Zoom}
                delay={1000}
                limit={1}
            />
            <CustomModal showPopupOpen={showPopupOpen}
                handleOkClick={handleOkClick}
                handleClosePopup={handleClosePopup}
                modalMessage={modalMessage}
            />
            <div className="my-3" >
                <div className="card w-100" >
                    <div class="card-body p-2" >
                        <div className="col-md-3">
                            <h6 class="card-title    my-3 bg-light w-auto py-2 rounded ">
                                <FileText /> <b> Create Roll Master</b>
                            </h6>
                        </div>
                        <div className="row" >
                            <div className='col-3'>
                                <Form.Group>
                                    <Form.Label>Roll Name</Form.Label>
                                    <Form.Control
                                        onChange={handleChange}
                                        value={rollForm.rollName}
                                    ></Form.Control>
                                </Form.Group>
                                <span className='text-danger' >{rollNameError.rollName}</span>
                            </div>
                            <div className='col-2' style={{ marginTop: '30px' }} >
                                <button onClick={handleSubmit} className='btn btn-danger' >Submit</button>
                            </div>
                            {/* <div className="col-md-4">
                                <label>City Name</label>
                                <div className="mt-2" >
                                    <Select
                                        options={pincodeData}
                                        onChange={handleChangeBranchList}
                                        value={branchIdList}
                                    />
                                </div>
                            </div> */}
                        </div>
                        <div className='row' >
                            <div
                                className="ag-theme-alpine"
                                style={{ height: "400Px", width: "70%", marginTop: "20px" }}
                            >
                                <AgGridReact
                                    rowHeight={rowHeight}
                                    // columnDefs={columns}
                                    columnDefs={colDefs}
                                    defaultColDef={DefaultColumnSetting}
                                    pagination={true}
                                    paginationPageSize={10}
                                    // onGridReady={onGridReady}
                                    rowData={rowData}
                                />{" "}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RoleMaster