import { faUserAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useHistory } from 'react-router'
import {
  Card,
  Col,
  Container,
  FloatingLabel,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import Apiservice from "../Services/Apiservice";
import { useForm } from "react-hook-form";

import Storage from "../Services/Storage";
import Register from "./Register";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiOutlineEyeInvisible } from 'react-icons/ai';
import { AiOutlineEye } from 'react-icons/ai';


const Login = (props) => {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [data, setdata] = useState({});
  const [loadstate, setloadstate] = useState(false);
  const [registerValue, setregisterValue] = useState(true);
  const [PasswordVisible, setPasswordVisible] = useState('no')

  const handlelogin = async () => {
    setloadstate(true);
    const loginApi = await Apiservice.login(data);
    setloadstate(false);
    if (!loginApi.error) {
      let user = loginApi.data[0];
      toast.success(loginApi.message)
      localStorage.setItem('userDetails', JSON.stringify(user));
      // setdata({
      //   ...data,
      //   MobileNumber: "",
      //   Password: ""
      // })
      setTimeout(() => {
        history.push('/')
      }, 1000);
    }
    else {
      toast.error(loginApi?.message)
      toast.error(loginApi?.message?.message)
      // setdata({
      //   ...data,
      //   MobileNumber: "",
      //   Password: ""
      // })
    }
  };

  const handleEmail = (e) => {
    setdata({ ...data, MobileNumber: e.target.value });
  };

  const handlePassword = (e) => {
    setdata({ ...data, Password: e.target.value });
  };

  const handleregister = () => {
    setregisterValue(!registerValue);
  };
  const handlePasswordVisible = () => {
    setPasswordVisible('no')
  }
  const handlePasswordNotVisible = () => {
    setPasswordVisible('yes')
  }

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        transition={Zoom}
        delay={1000}
        limit={1}
      />
      {registerValue == false ? (
        <Register handleregister={handleregister} />
      ) : (
        <>
          <Row className="justify-content-center" style={{ marginTop: '150px' }}>
            <Col md={6}>
              <div className="user">
                <Card className="p-3 shadow w-100">
                  <Container>
                    <h1 className="mt-4 signin-title more-link">
                      {" "}
                      <FontAwesomeIcon
                        icon={faUserAlt}
                        color="orange"
                      ></FontAwesomeIcon>{" "}
                      LogIn
                    </h1>
                    <hr />
                    <Row>
                      <Col md={6}>
                        <form action="" onSubmit={handleSubmit(handlelogin)}>
                          <div className="form-signin">
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Mobile Number"
                              className="mb-3"
                            >
                              <Form.Control
                                type="number"
                                placeholder="Mobile Number"
                                name="Email"
                                value={data.MobileNumber}
                                maxLength={10}
                                onInput={(e) => {
                                  if (e.target.value.length > e.target.maxLength)
                                    e.target.value = e.target.value.slice(0, e.target.maxLength)
                                }}
                                onWheelCapture={(e) => e.target.blur()}
                                autoComplete='off'
                                {...register("Email", {
                                  onChange: (e) => {
                                    handleEmail(e);
                                  },
                                  required: "Please Enter Mobile Number",
                                  pattern: {
                                    pattern: /[0-9]/,
                                    message: 'Please Enter Nuumbers'
                                  },
                                  minLength: {
                                    value: 10,
                                    message: "Please Enter 10 Digit Mobile Number",
                                  },
                                  maxLength: {
                                    value: 10,
                                    message: "Please Enter 10 Digit Mobile Number",
                                  }
                                })}
                              />
                              <small className="text-danger">
                                {errors.Email ? (
                                  <>
                                    {errors.Email &&
                                      errors.Email.message}
                                  </>
                                ) : null}
                              </small>
                            </FloatingLabel>

                            <div className="d-flex" >
                              <div className="col-12" >
                                <FloatingLabel
                                  controlId="floatingPassword"
                                  label="Password"
                                >
                                  <Form.Control
                                    type={PasswordVisible == 'yes' ? 'text' : "password"}
                                    placeholder="Password"
                                    name="Password"
                                    value={data.Password}
                                    {...register("Password", {
                                      onChange: (e) => {
                                        handlePassword(e);
                                      },
                                      required: "Enter Valid Password",
                                    })}
                                    autoComplete='off'
                                  />
                                  <small className="text-danger">
                                    {errors.Password ? (
                                      <>
                                        {errors.Password.type === "required" &&
                                          errors.Password.message}
                                      </>
                                    ) : null}
                                  </small>
                                </FloatingLabel>
                              </div>
                              <div className="col mt-3">
                                {
                                  PasswordVisible == 'no' &&
                                  < div onClick={handlePasswordNotVisible} style={{ position: 'relative', right: '30px', top: '0px' }} >
                                    <AiOutlineEyeInvisible size={20} ></AiOutlineEyeInvisible>
                                  </div>
                                }
                                {
                                  PasswordVisible == 'yes' &&
                                  <div onClick={handlePasswordVisible} style={{ position: 'relative', right: '30px', top: '0px' }}>
                                    <AiOutlineEye size={20} ></AiOutlineEye>
                                  </div>
                                }
                              </div>
                            </div>
                            <div className="d-flex justify-content-between mt-4">
                              <button className="all-button  btn-md rounded">
                                Login{" "}
                                {loadstate ? (
                                  <Spinner
                                    animation="border"
                                    variant="Primary"
                                    size="sm"
                                  />
                                ) : (
                                  ""
                                )}
                              </button>
                              {
                                //  <span className="more-link" onClick={handleregister}>
                                //   Register
                                // </span>
                              }
                            </div>
                          </div>
                        </form>
                      </Col>

                      <Col md={6} className="right-border">
                        <div className="circularlog">
                          <img src="MoxD_PrimaryLogo1.png" width='' alt="" />
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Card>

              </div>
            </Col>
          </Row>
        </>
      )
      }
    </>
  );
};

export default Login;
