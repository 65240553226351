import React, { useEffect, useState } from "react";
import { ToastContainer, toast, Zoom } from "react-toastify";
import Apiservice from "../../Services/Apiservice";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { FileText } from "react-bootstrap-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import CustomDeleteModal from "../../common/CustomDeleteModal";


function NewFranchise() {
  //   window.scrollTo(0, 0);
  const [rowData, setRowData] = useState();

  // delete state
  const [deleteModal, setDeleteModal] = useState({
    loader: false,
    show: false,
    type: "",
    data: {},
  })

  // get request franchise
  const handleGetNewFranchise = async () => {
    const cnoteData = await Apiservice.postdata("/User/frenchise_request");
    if (cnoteData.error == false) {
      setRowData(cnoteData.data);
    } else {
    }
  }

  useEffect(() => {
    handleGetNewFranchise()
  }, []);

  const rowHeight = 50;
  const [colDefs, setColDefs] = useState([
    {
      headerName: "Sl.No",
      valueGetter: "node.rowIndex + 1",
      filter: true,
      lockPosition: true,
    },
    {
      headerName: "Business Name",
      filter: true,
      field: "businessName",
    },
    {
      headerName: "Contact Persion",
      filter: true,
      field: "ContactPersion",
    },
    {
      headerName: "Mobile NUmber",
      filter: true,
      field: "MobileNumber",
    },
    {
      headerName: "Email",
      filter: true,
      field: "Email",
    },
    {
      headerName: "State",
      filter: true,
      field: "State",
    },
    {
      headerName: "District",
      filter: true,
      field: "District",
    },
    {
      headerName: "Address",
      filter: true,
      field: "Address",
    },
    {
      headerName: "Pincode",
      filter: true,
      field: "Pincode",
    },
    {
      headerName: "Status",
      filter: true,
      field: "status",
    },
    // {
    //   headerName: "Branch Name",
    //   filter: true,
    //   cellRendererFramework: (params) => (<center>
    //     <p>{params?.data?.Frenchises[0]?.District}</p>
    //   </center>)
    // },
    {
      headerName: "Delete",
      filter: true,
      cellRendererFramework: (params) => (
        <center onClick={() => setDeleteModal({ ...deleteModal, show: true, data: params.data, type: "DELETE", deletedmodule: 'Delivery Boy', deletedname: params?.data?.ContactPersion })} >
          <FontAwesomeIcon icon={faTrash} className="iconstyle" />
        </center>
      )
    },
  ]);

  const DefaultColumnSetting = {
    sortable: true,
    filter: true,

    floatingFilter: true,
    flex: 1,
    resizable: true,
    minWidth: 120,
  };

  /******* Delete API Integration ******/
  const hendleDeletSubmitApi = async (data) => {
    console.log(data, 'data');
    setDeleteModal({ ...deleteModal, loader: true })
    const payload = {
      id: data?.id,
    };
    let price = await Apiservice.postdata("/User/delete_frenchise_request", payload);
    if (price.error == false) {
      setDeleteModal({})
      toast.success(price.message)
      handleGetNewFranchise()

    } else {
      setDeleteModal({})
      toast.error(price.message)
      handleGetNewFranchise()

    }
  };

  const hendleDeleteModal = (data) => {
    setDeleteModal({})
  }

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        transition={Zoom}
        delay={1000}
        limit={1}
      />
      {/* <div className="more-link">
        <h6>New Franchise Request </h6>
      </div> */}

      <div className="my-3">
        <div className="card w-100 my-3 p-3">
          <div className="col-md-3">
            <h6 class="card-title    my-3 bg-light w-auto py-2 rounded ">
              <FileText /> <b>New Franchise Master</b>
            </h6>
          </div>
          <div
            className="ag-theme-alpine"
            style={{ height: "70vh", width: "100%", marginTop: "30px" }}
          >
            <AgGridReact
              rowHeight={rowHeight}
              // columnDefs={columns}
              columnDefs={colDefs}
              defaultColDef={DefaultColumnSetting}
              pagination={true}
              paginationPageSize={10}
              // onGridReady={onGridReady}
              rowData={rowData}
            />{" "}
          </div>
        </div>
      </div>

      {/* delete modal */}
      <CustomDeleteModal
        hendleDeleteModal={hendleDeleteModal}
        deleteModal={deleteModal}
        hendleDeletSubmitApi={hendleDeletSubmitApi}
      ></CustomDeleteModal>
    </div>
  );
}

export default NewFranchise;
