import { faSearch, faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { FileText } from "react-bootstrap-icons";
import Apiservice from "../../Services/Apiservice";
import { useForm } from "react-hook-form";
import { Circles } from "react-loader-spinner";
import { ToastContainer, toast, Zoom } from "react-toastify";
import { AgGridReact } from "ag-grid-react";

const options = [
  { value: "", text: "--Choose an option--" },
  { value: "raise", text: "Raise Complaint" },
  { value: "received", text: "Received Complaint" },
];


const ComplaintsReport = () => {
  /** date states **/
  const [rowData, setRowData] = useState()
  const [dateOne, setDate] = useState("");
  const [cNoteFilterFromTo, setCNoteFilterFromTo] = useState({
    from: "",
    to: ""
  })
  const [dateFieldErrorFrom, setDateFieldErrorFrom] = useState('')
  const [dateFieldErrorTo, setDateFieldErrorTo] = useState('')

  const [userData, setUserData] = useState(null)
  const [subDateLoader, setSubDateLoader] = useState(false)


  useEffect(() => {
    const loginData = JSON.parse(localStorage.getItem("userDetails"));
    setUserData(loginData)
  }, [])

  useEffect(() => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    const todayDate = yyyy + "-" + mm + "-" + dd;

    setCNoteFilterFromTo({
      ...cNoteFilterFromTo,
      from: todayDate,
      to: todayDate
    })

    // var d = new Date();
    // d.getHours(); // => 9
    // d.getMinutes(); // =>  30
    // d.getSeconds();

    // var hh = d.getHours();
    // var mm = d.getMinutes();

    // const todayTime = hh + ":" + mm;
    // setTime(todayTime);
  }, []);


  /** List of complaints **/
  useEffect(async () => {
    const userData = JSON.parse(localStorage.getItem("userDetails"));
    /** Admin **/
    if (userData.Hub_type == 'NULL') {
      // const one = await localStorage.getItem("userDetails");
      // const payload = {
      //   frenchise_id: userData.id,
      // };
      const alllist = await Apiservice.postdata("/admin/complaintlist");
      if (alllist.error == false && alllist.data.length != 0) {
        setRowData(alllist.data);
      } else {
        console.log("get API is not working");
        setRowData(null);
      }
    }

    /** Super**/
    if (userData.Hub_type == 'SUPER') {
      const payload = {
        frenchise_id: userData.id,
      };
      const alllist = await Apiservice.postdata("/admin/complaintlist", payload);
      if (alllist.error == false && alllist.data.length != 0) {
        setRowData(alllist.data);
      } else {
        console.log("get API is not working");
        setRowData(null);

      }
    }


    /** Local **/
    if (userData.Hub_type == 'LOCAL') {
      const payload = {
        frenchise_id: userData.id,
      };
      const alllist = await Apiservice.postdata("/admin/complaintlist", payload);
      if (alllist.error == false && alllist.data.length != 0) {
        setRowData(alllist.data);
      } else {
        console.log("get API is not working");
        setRowData(null);
      }
    }

  }, []);

  const onchangedate = (event) => {
    setCNoteFilterFromTo({ ...cNoteFilterFromTo, [event.target.name]: event.target.value })
  }


  const handelFromToSubmit = async (e) => {
    e.preventDefault()

    if (cNoteFilterFromTo.from != '' && cNoteFilterFromTo.to != '') {
      const userData = JSON.parse(localStorage.getItem("userDetails"));
      /* Admin Filter*/
      if (userData.Hub_type == 'NULL') {
        const payload = {
          dateFrom: cNoteFilterFromTo.from == '' ? dateOne : cNoteFilterFromTo.from,
          dateTo: cNoteFilterFromTo.to == '' ? dateOne : cNoteFilterFromTo.to
        }
        setSubDateLoader(true)
        const cnoteData = await Apiservice.postdata("/admin/complaintlist", payload)
        if (cnoteData.error == false && cnoteData.data.length != 0) {
          setSubDateLoader(false)
          toast.success(cnoteData.message)
          setRowData(cnoteData?.data)
        }
        else {
          setSubDateLoader(false)
          toast.error('based on date there is no complaints')
          setRowData(null);
        }
      }

      /**  Franchise Filter **/
      if (userData.Hub_type == 'SUPER') {
        const payload = {
          frenchise_id: userData.id,
          dateFrom: cNoteFilterFromTo.from == '' ? dateOne : cNoteFilterFromTo.from,
          dateTo: cNoteFilterFromTo.to == '' ? dateOne : cNoteFilterFromTo.to
        }
        setSubDateLoader(true)
        const cnoteData = await Apiservice.postdata("/admin/complaintlist", payload)
        if (cnoteData.error == false && cnoteData.data.length != 0) {
          setSubDateLoader(false)
          setRowData(cnoteData?.data)
          toast.success(cnoteData.message)
        }
        else {
          setSubDateLoader(false)
          toast.error('based on date there is no complaints')
          setRowData(null);
        }
      }

      /** Local Franchise Fiter **/
      if (userData.Hub_type == 'LOCAL') {
        const payload = {
          frenchise_id: userData.id,
          dateFrom: cNoteFilterFromTo.from == '' ? dateOne : cNoteFilterFromTo.from,
          dateTo: cNoteFilterFromTo.to == '' ? dateOne : cNoteFilterFromTo.to
        }
        setSubDateLoader(true)
        const cnoteData = await Apiservice.postdata("/admin/complaintlist", payload)
        if (cnoteData.error == false && cnoteData.data.length != 0) {
          setSubDateLoader(false)
          setRowData(cnoteData?.data)
          toast.success(cnoteData.message)
        }
        else {
          setSubDateLoader(false)
          toast.error('based on date there is no complaints')
          setRowData(null);
        }
      }

    } else {

      if (cNoteFilterFromTo.from == '') {
        setDateFieldErrorFrom('please select from date')
      }

      if (cNoteFilterFromTo.to == '') {
        setDateFieldErrorTo('please select to date')
      }
    }

  }


  const rowHeight = 50;
  const DefaultColumnSetting = {
    sortable: true,
    filter: true,

    floatingFilter: true,
    flex: 1,
    resizable: true,
    minWidth: 120,
  };
  const [colDefs, setColDefs] = useState([
    {
      headerName: "Sl.NO",
      valueGetter: "node.rowIndex + 1",
      filter: true,
      lockPosition: true,
    },
    {
      headerName: "C-note",
      filter: true,
      field: "c_note",
    },
    {
      headerName: "Order Id",
      filter: true,
      field: "tracking_number",
    },
    {
      headerName: "First Name",
      filter: true,
      field: "firstName",
    },
    {
      headerName: "Last Name",
      filter: true,
      field: "lastName",
    },
    {
      headerName: "Complaint Message",
      filter: true,
      field: "complaint_message",
    },
  ]);

  /**Date And Time **/
  useEffect(() => {
    if (cNoteFilterFromTo.from != '') {
      setDateFieldErrorFrom('')
    }
    if (cNoteFilterFromTo.to != '') {
      setDateFieldErrorTo('')
    }
  }, [cNoteFilterFromTo])


  const handleDateClear = () => {
    const todayDate = '' + "-" + '' + "-" + '';
    setDate(todayDate);
    setCNoteFilterFromTo({
      ...cNoteFilterFromTo,
      from: "",
      to: ""
    })
  }

  return (
    <>
      <div>
        <div className="my-3">
          <div className="card w-100 my-3 p-3">
            <div class="card-body pt-0">
              <div className="row ">
                <div className="col-md-6">
                  <h6 class="card-title   bg-light w-auto py-2 rounded ">
                    {
                      userData && userData?.Hub_type == 'NULL' && <div>
                        <FileText /> <b>All Complaints Reports In Admin </b>
                      </div>
                    }
                    {
                      userData && userData?.Hub_type == 'SUPER' && <div>
                        <FileText /> <b>All Complaints Reports In Super Franchise</b>
                      </div>
                    }
                    {
                      userData && userData?.Hub_type == 'LOCAL' && <div>
                        <FileText /> <b>All Complaints Reports In Local Franchise</b>
                      </div>
                    }
                  </h6>
                </div>
              </div>
              <div className="row">
                <div className='col' >
                  <form onSubmit={handelFromToSubmit} >
                    <div className="row">
                      <div className="col-md-4" >
                      </div>
                      <div className="col-md-8" >
                        <div className="row" >
                          <div className="col-md-4">
                            {" "}
                            <Form.Label>From Date</Form.Label>
                            <Form.Control
                              type="date"
                              value={cNoteFilterFromTo.from == '' ? dateOne : cNoteFilterFromTo.from}
                              onChange={onchangedate}
                              name="from"
                              format="DD/MM/YYYY"
                            />
                            <span className="text-danger" >{dateFieldErrorFrom}</span>

                          </div>

                          <div className="col-md-4">
                            <Form.Label>To Date</Form.Label>
                            <Form.Control
                              type="date"
                              value={cNoteFilterFromTo.to == '' ? dateOne : cNoteFilterFromTo.to}
                              onChange={onchangedate}
                              name="to"
                              format="DD/MM/YYYY"
                            />
                            <span className="text-danger" >{dateFieldErrorTo}</span>
                          </div>
                          <div className="col-md-4 mt-3">
                            <button
                              className="btn btn-danger rounded mx-3  text-white mt-3"
                              type="reset"
                              onClick={handleDateClear}
                            >
                              Clear
                            </button>
                            <button
                              className="btn btn-success rounded mx-3  text-white mt-3"
                              type='submit'
                            >
                              Submit{" "}
                              {subDateLoader ? (
                                <Spinner
                                  animation="border"
                                  variant="Primary"
                                  size="sm"
                                />
                              ) : (
                                ""
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div
              className="ag-theme-alpine"
              style={{ height: "70vh", width: "100%" }}
            >
              <AgGridReact
                rowHeight={rowHeight}
                columnDefs={colDefs}
                defaultColDef={DefaultColumnSetting}
                pagination={true}
                paginationPageSize={10}
                rowData={rowData}
              />{" "}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ComplaintsReport;
