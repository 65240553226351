import React, { useEffect, useState } from "react";
import { Circles } from "react-loader-spinner";
import { ToastContainer, toast, Zoom } from "react-toastify";
import Apiservice from "../../Services/Apiservice";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Switch from "react-switch";
import { Button, Form } from "react-bootstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { FileText } from "react-bootstrap-icons";

function CNote(props) {
  const [cNoteTabledata, setCnoteTableData] = useState(null);

  const [search, setSearch] = useState("");
  const [filterTabledata, setFilterTabledata] = useState(null);
  const [reloadPage, setReloadPage] = useState(1);

  const onSearchClick = () => {
    if (search === "") {
      setFilterTabledata([]);
    } else {
      const mapingData = cNoteTabledata.map((ele, ind) => {
        return ele;
      });

      const filterTabledataOne = mapingData.filter(
        (tablerow) =>
          tablerow?.Frenchise?.BusinessName.toLowerCase() ==
          search.toLowerCase()
      );
      setFilterTabledata(filterTabledataOne);
    }
  };

  const [userId, setUserId] = useState(null);
  useEffect(async () => {
    const LocalData = localStorage.getItem("userDetails");
    const frenchise_id = JSON.parse(LocalData).role_masters[0].role_name;
    const cnoteData = await Apiservice.postdata(
      "/booking/c_note_frenchise_list"
    );
    if (cnoteData.error == false) {
      setCnoteTableData(cnoteData.data);
      setRowData(cnoteData.data);
    } else {
    }
  }, [reloadPage]);

  useEffect(async () => {
    const LocalData = localStorage.getItem("userDetails");
    const frenchise_id = JSON.parse(LocalData).role_masters[0].role_name;
    const cnoteData = await Apiservice.postdata(
      "/booking/c_note_frenchise_list"
    );
    if (cnoteData.error == false) {
      setCnoteTableData(cnoteData.data);
      setRowData(cnoteData.data);
    } else {
    }
  }, [props]);

  const handleSwichChange = async (ele) => {
    const payload = {
      id: ele.id,
    };

    if (ele.status == "SUCCESS") {
      payload.status = "PENDING";
    }
    if (ele.status == "PENDING" || ele.status == "") {
      payload.status = "SUCCESS";
    }

    const cnoteDataUpdate = await Apiservice.postdata(
      "/booking/c_note_update",
      payload
    );
    if (cnoteDataUpdate.error == false) {
      toast.success(cnoteDataUpdate.message);
      setReloadPage(reloadPage + 1);
    } else {
      console.log("failures............");
    }
  };

  const rowHeight = 50;

  const [rowData, setRowData] = useState();

  const [colDefs, setColDefs] = useState([
    {
      headerName: "#",
      valueGetter: "node.rowIndex + 1",
      filter: true,
      lockPosition: true,
    },
    {
      headerName: "Branch Name",
      filter: true,
      valueGetter: "data.Frenchise.city_masters[0].city_name",
    },
    {
      headerName: "Business Name",
      filter: true,
      valueGetter: "data.Frenchise.BusinessName",
    },
    {
      headerName: "Name",
      filter: true,
      valueGetter: "data.Frenchise.ContactPersion",
    },
    {
      headerName: "Start Number",
      filter: true,
      field: "start_no",
    },
    {
      headerName: "End Number",
      filter: true,
      field: "end_no",
    },
    {
      headerName: "Quantity",
      filter: true,
      valueGetter: "data.end_no - data.start_no + 1",
    },
    {
      headerName: "Cash Mode",
      filter: true,
      field: "cash_mode",
    },
    {
      headerName: "Amount",
      filter: true,
      field: "amount",
    },
    {
      headerName: "Receive Amount",
      filter: true,
      field: "receive_amount",
    },
    {
      headerName: "Amount Received Date",
      filter: true,
      field: "receive_date",
    },
  ]);

  const DefaultColumnSetting = {
    sortable: true,
    filter: true,

    floatingFilter: true,
    flex: 1,
    resizable: true,
    minWidth: 120,
  };

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        transition={Zoom}
        delay={1000}
        limit={1}
      />
      <div className="my-3">
        <div className="card w-100 my-3 p-3">
          <div className="col-md-3">
            <h6 class="card-title    my-3 bg-light w-auto py-2 rounded ">
              <FileText /> <b>C-Note Master</b>
            </h6>
          </div>
          {userId == "Admin" ? (
            <div className="row">
              <div className="col-md-3">
                <Form.Label>Select Business Name</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              <div className="col-md-7 mt-2">
                <Button
                  onClick={() => onSearchClick()}
                  className="btn btn-success rounded mx-3 mt-4 "
                >
                  Search
                </Button>
              </div>
            </div>
          ) : (
            <span></span>
          )}

          {userId == "Franchise" ? (
            <div className="row ">
              <div className="col-md-2">
                {" "}
                <Form.Label>From</Form.Label>
                <Form.Control
                  type="date"
                  // onChange={onchangedate}
                  name="from"
                  format="DD/MM/YYYY"
                />
              </div>

              <div className="col-md-2 ">
                <Form.Label>To</Form.Label>
                <Form.Control
                  type="date"
                  // onChange={onchangedate}
                  name="to"
                  format="DD/MM/YYYY"
                />
              </div>
              <div className="col-md-2 mt-3">
                <button
                  className="btn btn-success rounded mx-3  text-white mt-3"
                  // onClick={handelSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          ) : (
            <span></span>
          )}

          {filterTabledata == null || filterTabledata == [] ? (
            <div>
              {cNoteTabledata ? (
                // <table className="table table-bordered table-hover mt-5 text-center">
                //   <thead>
                //     <tr>
                //       <th>#</th>
                //       <th>Business Name</th>
                //       <th>Name</th>
                //       <th>Start Number</th>
                //       <th>End Number</th>
                //       <th>Quantity</th>
                //       <th>Cash Mode</th>
                //       <th>Amount Received Date</th>
                //       {
                //         // <th>Status</th>
                //       }
                //     </tr>
                //   </thead>
                //   <tbody>
                //     {cNoteTabledata?.map((ele, ind) => {
                //       return (
                //         <tr>
                //           <td>{ind + 1}</td>
                //           <td>{ele?.Frenchise?.BusinessName}</td>
                //           <td>{ele?.Frenchise?.ContactPersion}</td>
                //           <td>{ele?.start_no}</td>
                //           <td>{ele?.end_no}</td>
                //           <td>{ele?.end_no-ele?.start_no}</td>
                //           <td>{ele?.cash_mode}</td>
                //           <td>{ele?.receive_date}</td>
                //           {
                //             // <td>
                //             //  <Switch
                //             //   onChange={(ev) => handleSwichChange(ev, ele)}
                //             //   checked={ele.status == 'PENDING' ? false : true}
                //             // />
                //             // <Button
                //             //   className="w-100"
                //             //   variant="primary"
                //             //   size="sm"
                //             //   onClick={() => handleSwichChange(ele)}
                //             // >
                //             //   {
                //             //     ele.status == 'SUCCESS' ? "Disapprove" : "Approve"
                //             //   }
                //             // </Button>
                //             // </td>
                //           }
                //         </tr>
                //       );
                //     })}
                //   </tbody>
                // </table>

                <div
                  className="ag-theme-alpine"
                  style={{ height: "70vh", width: "100%" }}
                >
                  <AgGridReact
                    rowHeight={rowHeight}
                    // columnDefs={columns}
                    columnDefs={colDefs}
                    defaultColDef={DefaultColumnSetting}
                    pagination={true}
                    paginationPageSize={10}
                    // onGridReady={onGridReady}
                    rowData={rowData}
                  />{" "}
                </div>
              ) : (
                <div style={{ marginLeft: "550px", marginTop: "100px" }}>
                  <Circles
                    height="30"
                    width="30"
                    color="grey"
                    ariaLabel="loading"
                  />
                </div>
              )}
            </div>
          ) : (
            <div>
              {filterTabledata ? (
                <table className="table table-bordered table-hover mt-5 text-center">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Business Name</th>
                      <th>Name</th>
                      <th>Start Number</th>
                      <th>End Number</th>
                      <th>Quantity</th>
                      <th>Cash Mode</th>
                      <th>Receiving Date</th>
                      {
                        //  <th>Status</th>
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {filterTabledata?.map((ele, ind) => {
                      return (
                        <tr>
                          <td>{ind + 1}</td>
                          <td>{ele?.Frenchise?.BusinessName}</td>
                          <td>{ele?.Frenchise?.ContactPersion}</td>
                          <td>{ele?.start_no}</td>
                          <td>{ele?.end_no}</td>
                          <td>{ele?.quantity}</td>
                          <td>{ele?.cash_mode}</td>
                          <td>{ele?.receive_date}</td>
                          {
                            //  <td>
                            // <Switch
                            // onChange={(ev) => handleSwichChange(ev, ele)}
                            // checked={ele.status == 'PENDING' ? false : true}
                            // />
                            //   <Button
                            //     className="w-100"
                            //     variant="primary"
                            //     size="sm"
                            //     onClick={() => handleSwichChange(ele)}
                            //   >
                            //     {
                            //       ele.status == 'SUCCESS' ? "Disapprove" : "Approve"
                            //     }
                            //   </Button>
                            // </td>
                          }
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <div style={{ marginLeft: "550px", marginTop: "100px" }}>
                  <Circles
                    height="30"
                    width="30"
                    color="grey"
                    ariaLabel="loading"
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CNote;
