import React, { useEffect, useState } from "react";

import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { FileText } from "react-bootstrap-icons";
import Apiservice from "../../Services/Apiservice";
import { AgGridReact } from "ag-grid-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer, Zoom } from "react-toastify";

function CouponCodeList() {
  const [dateOne, setDate] = useState("");
  const [submitDateLoader, setSubmitDateLoader] = useState(false)
  const [submitCouponCodeLoader, setSubmitCouponCodeLoader] = useState(false)

  const options = [
    { value: "", text: "--Choose an option--" },
    { value: "percentage", text: "Percentage" },
    { value: "amount", text: "Amount" },
  ];
  const [selected, setSelected] = useState(options[0].value ? options[0].value : "visible");
  const [show, setShow] = useState(false);


  useEffect(() => {
    if (selected == 'percentage') {
      setFormList({
        ...formList,
        ['selectDropdown']: selected,
        ['amount']: ''
      })
      setFormListError({
        ...formListError,
        ['selectDropdown']: null,
        ['amount']: null
      })
    }

    if (selected == 'amount') {
      setFormList({
        ...formList,
        ['selectDropdown']: selected,
        ['percentage']: ''

      })
      setFormListError({
        ...formListError,
        ['selectDropdown']: null,
        ['percentage']: null

      })
    }
  }, [selected])

  const [formList, setFormList] = useState({
    couponTitle: "",
    couponCode: "",
    percentage: "",
    amount: "",
    couponDescription: "",
    startingDate: "",
    endingDate: "",
    selectDropdown: selected == 'visible' ? "" : selected
  });

  const [formListError, setFormListError] = useState({
    couponTitle: "",
    couponCode: "",
    percentage: "",
    amount: "",
    couponDescription: "",
    startingDate: "",
    endingDate: "",
    selectDropdown: ""

  });


  const [rowData, setRowData] = useState()
  const [loader, setLoader] = useState(false)
  const [pageReload, setPageReload] = useState(1);
  const [dateReload, setDateReload] = useState(1)

  /** Date States**/
  const [cNoteFilterFromTo, setCNoteFilterFromTo] = useState({
    from: "",
    to: ""
  })
  const [dateFieldErrorFrom, setDateFieldErrorFrom] = useState('')
  const [dateFieldErrorTo, setDateFieldErrorTo] = useState('')


  const handleClose = () => {
    setShow(false);
    setFormList({
      ...formList,
      couponTitle: "",
      couponCode: "",
      percentage: "",
      amount: "",
      couponDescription: "",
      startingDate: "",
      endingDate: "",
      selectDropdown: selected == 'visible' ? "" : selected
    })
    setFormListError({
      ...formListError,
      couponTitle: "",
      couponCode: "",
      percentage: "",
      amount: "",
      couponDescription: "",
      startingDate: "",
      endingDate: "",
      selectDropdown: ''
    })
    setDateReload(dateReload + 1)
    setPageReload(pageReload + 1)
  }
  const handleShow = () => setShow(true);

  const handleChange = (event) => {
    console.log(event.target.value, "Value");
    setSelected(event.target.value);
  };


  useEffect(() => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    const todayDate = yyyy + "-" + mm + "-" + dd;

    // setDate(todayDate);

    setFormList({
      ...formList,
      startingDate: todayDate,
      endingDate: todayDate
    })

    // var d = new Date();
    // d.getHours(); // => 9
    // d.getMinutes(); // =>  30
    // d.getSeconds();

    // var hh = d.getHours();
    // var mm = d.getMinutes();

    // const todayTime = hh + ":" + mm;
    // setTime(todayTime);
  }, [dateReload]);

  useEffect(() => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    const todayDate = yyyy + "-" + mm + "-" + dd;

    // setDate(todayDate);

    setCNoteFilterFromTo({
      ...cNoteFilterFromTo,
      from: todayDate,
      to: todayDate
    })

    // var d = new Date();
    // d.getHours(); // => 9
    // d.getMinutes(); // =>  30
    // d.getSeconds();

    // var hh = d.getHours();
    // var mm = d.getMinutes();

    // const todayTime = hh + ":" + mm;
    // setTime(todayTime);
  }, []);




  const onchangedate = (event) => {
    setCNoteFilterFromTo({ ...cNoteFilterFromTo, [event.target.name]: event.target.value })
  }
  const handelFromToSubmit = async (e) => {
    e.preventDefault()
    if (cNoteFilterFromTo.from != '' && cNoteFilterFromTo.to != '') {
      setSubmitDateLoader(true)
      const payload = {
        dateFrom: cNoteFilterFromTo.from == '' ? dateOne : cNoteFilterFromTo.from,
        dateTo: cNoteFilterFromTo.to == '' ? dateOne : cNoteFilterFromTo.to
      }
      const cnoteData = await Apiservice.postdata("/admin/couponlistss", payload)
      if (cnoteData.error == false && cnoteData.data.length != 0) {
        setSubmitDateLoader(false)
        setRowData(cnoteData?.data)
        toast.success(cnoteData.message)
      }
      else {
        setSubmitDateLoader(false)
        toast.error('No data was found within this date range')
        setRowData(null)

      }
    } else {

      if (cNoteFilterFromTo.from == '') {
        setDateFieldErrorFrom('please select from date')
      }

      if (cNoteFilterFromTo.to == '') {
        setDateFieldErrorTo('please select to date')
      }

    }
  }


  const handelOnChange = (event) => {
    // const p = { ...formList };
    // p[event.target.name] = event.target.value;
    // setFormList(p);
    console.log(event, 'event');



    setFormList({
      ...formList,
      [event.target.name]: event.target.value
    })
    setFormListError({
      ...formListError,
      [event.target.name]: null
    })
  };

  useEffect(async () => {
    const data = await Apiservice.postdata("/admin/couponlistss");
    if (data.error == false) {
      setRowData(data.data);
    } else {
      console.log("get API is not working");
    }
  }, [pageReload]);

  const handleValidation = () => {
    const {
      couponTitle,
      couponCode,
      percentage,
      amount,
      couponDescription,
      startingDate,
      endingDate,
      selectDropdown
    } = formList
    const newErrors = {}

    if (!couponTitle) {
      newErrors.couponTitle = 'please enetr coupon title'
    }

    if (!couponCode) {
      newErrors.couponCode = 'please enetr coupon code'
    }

    if (!percentage && selectDropdown == 'percentage') {
      newErrors.percentage = 'please enetr coupon percentage'
    }

    if (!amount && selectDropdown == 'amount') {
      newErrors.amount = 'please enetr coupon amount'
    }

    if (!couponDescription) {
      newErrors.couponDescription = 'please enetr coupon couponDescription'
    }
    if (!selectDropdown) {
      newErrors.selectDropdown = 'please select option'
    }

    return newErrors
  }

  const handelSubmit = async () => {
    const handleValidationObject = handleValidation()
    if (Object.keys(handleValidationObject).length > 0) {
      setFormListError(handleValidationObject)
    } else {
      setSubmitCouponCodeLoader(true)
      const payload = {
        coupon_title: formList.couponTitle,
        coupon_desc: formList.couponDescription,
        coupon_code: formList.couponCode,
        starting_date: formList.startingDate,
        ending_date: formList.endingDate,
        type: selected,
        percentage: formList.percentage == '' ? null : formList.percentage,
        amount: formList.amount == '' ? null : formList.amount,
      };
      const data = await Apiservice.postdata("/admin/couponcreate", payload);
      if (data.error == false) {
        setSubmitCouponCodeLoader(false)
        setFormList({
          ...formList,
          couponTitle: "",
          couponCode: "",
          percentage: "",
          amount: "",
          couponDescription: "",
          startingDate: "",
          endingDate: "",
          selectDropdown: selected == 'visible' ? "" : selected
        })
        setFormListError({
          ...formListError,
          couponTitle: "",
          couponCode: "",
          percentage: "",
          amount: "",
          couponDescription: "",
          startingDate: "",
          endingDate: "",
          selectDropdown: ''
        })
        handleClose();
        setDateReload(dateReload + 1)
        setPageReload(pageReload + 1)
        toast.success(data.message)

      } else {
        setSubmitCouponCodeLoader(false)
        setFormList({
          ...formList,
          couponTitle: "",
          couponCode: "",
          percentage: "",
          amount: "",
          couponDescription: "",
          startingDate: "",
          endingDate: "",
          selectDropdown: selected == 'visible' ? "" : selected
        })
        setFormListError({
          ...formListError,
          couponTitle: "",
          couponCode: "",
          percentage: "",
          amount: "",
          couponDescription: "",
          startingDate: "",
          endingDate: "",
          selectDropdown: ''
        })
        setDateReload(dateReload + 1)
        setPageReload(pageReload + 1)
        toast.error(data.message)
        handleClose();
      }
    }



  };



  const rowHeight = 50;
  const DefaultColumnSetting = {
    sortable: true,
    filter: true,

    floatingFilter: true,
    flex: 1,
    resizable: true,
    minWidth: 120,
  };

  const columns = [
    {
      headerName: "Sl.No",
      valueGetter: "node.rowIndex + 1",
      filter: false,
      lockPosition: true,
    },
    {
      headerName: "Coupon Title",
      filter: true,
      field: "coupon_title",
    },

    {
      headerName: "Coupon Code",
      filter: true,
      field: "coupon_code",
    },
    {
      headerName: "Amount Type",
      filter: true,
      field: "type",
    },
    // {
    //   headerName: "Amount",
    //   filter: true,
    //   field: "amount",
    // },
    {
      headerName: "Amount",
      filter: true,
      cellRendererFramework: (params) => (<center>
        {" "}

        <p>{params?.data?.amount == null ? 0 : params?.data?.amount}</p>
      </center>)
    },
    // {
    //   headerName: "Amount(%)",
    //   filter: true,
    //   field: "percentage",
    // },

    {
      headerName: "Amount(%)",
      filter: true,
      cellRendererFramework: (params) => (<center>
        {" "}
        <p>{params?.data?.percentage == null ? 0 : params?.data?.percentage}</p>
      </center>)
    },

    {
      headerName: "Coupon Description",
      filter: true,
      field: "coupon_desc",
    },
    {
      headerName: "Starting Date",
      filter: true,
      field: "starting_date",
    },
    {
      headerName: "Ending Date",
      filter: true,
      field: "ending_date",
    },
    {
      headerName: "Delete",
      filter: true,
      cellRendererFramework: (params) => (
        <center onClick={() => handelDelete(params.data)}>
          <FontAwesomeIcon icon={faTrash} className="iconstyle" />
        </center>
      ),
    },

  ];


  /******* Delete API Integration ******/
  const [smShow, setSmShow] = useState();
  const [deleteId, setDeleteId] = useState();


  const handelDelete = async (dataID) => {
    setSmShow(true);
    setDeleteId(dataID.id);
  };
  const handleCancelClear = () => {
    setSmShow(false);
  };
  const onClicksucess = async () => {
    setLoader(true)
    const payload = {
      id: deleteId
    }
    const data = await Apiservice.postdata("/admin/delete_coupon", payload);
    if (data.error == false) {
      setLoader(false)
      setSmShow(false);
      setPageReload(pageReload + 1);
      toast.success(data.message)
    } else {
      setSmShow(false);
      setPageReload(pageReload + 1);
      setLoader(false)
      toast.error(data.message)
    }
  };

  const handleCouponClear = () => {
    setFormList({
      ...formList,
      couponTitle: "",
      couponCode: "",
      percentage: "",
      amount: "",
      couponDescription: "",
      startingDate: "",
      endingDate: "",
      selectDropdown: selected == 'visible' ? "" : selected
    })
    setFormListError({
      ...formListError,
      couponTitle: "",
      couponCode: "",
      percentage: "",
      amount: "",
      couponDescription: "",
      startingDate: "",
      endingDate: "",
      selectDropdown: ''
    })
    // setDateReload(dateReload + 1)
  }


  useEffect(() => {
    if (cNoteFilterFromTo.from != '') {
      setDateFieldErrorFrom('')
    }
    if (cNoteFilterFromTo.to != '') {
      setDateFieldErrorTo('')
    }
  }, [cNoteFilterFromTo])


  const handleDateClear = () => {
    const todayDate = '' + "-" + '' + "-" + '';
    setDate(todayDate);
    setCNoteFilterFromTo({
      ...cNoteFilterFromTo,
      from: "",
      to: ""
    })
  }




  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        transition={Zoom}
        delay={1000}
        limit={1}
      />
      <div>
        <div className="my-3">
          <div className="card w-100 my-3 p-3">
            <div class="card-body pt-0">
              <div className="row ">
                <div className="col-md-2">
                  <h6 class="card-title   bg-light w-auto py-2 rounded ">
                    <FileText /> <b>Coupon Code List</b>
                  </h6>
                </div>
                <div className="col-md-10 text-end">
                  <Button
                    variant="danger"
                    className="mx-2 rounded"
                    onClick={handleShow}
                  >
                    Add Cupon Code
                  </Button>
                </div>
              </div>
            </div>
            
            <div className='col' >
              <form onSubmit={handelFromToSubmit} >
                <div className="row">
                  <div className="col-md-4" >
                  </div>
                  <div className="col-md-8" >
                    <div className="row" >
                      <div className="col-md-4">
                        {" "}
                        <Form.Label>From Date</Form.Label>
                        <Form.Control
                          type="date"
                          value={cNoteFilterFromTo.from == '' ? dateOne : cNoteFilterFromTo.from}
                          onChange={onchangedate}
                          name="from"
                          format="DD/MM/YYYY"
                        />
                        <span className="text-danger" >{dateFieldErrorFrom}</span>

                      </div>

                      <div className="col-md-4">
                        <Form.Label>To Date</Form.Label>
                        <Form.Control
                          type="date"
                          value={cNoteFilterFromTo.to == '' ? dateOne : cNoteFilterFromTo.to}
                          onChange={onchangedate}
                          name="to"
                          format="DD/MM/YYYY"
                        />
                        <span className="text-danger" >{dateFieldErrorTo}</span>
                      </div>
                      <div className="col-md-4 mt-3">
                        <button
                          className="btn btn-danger rounded mx-3  text-white mt-3"
                          type="reset"
                          onClick={handleDateClear}
                        >
                          Clear
                        </button>
                        <button
                          className="btn btn-success rounded mx-3  text-white mt-3"
                          // onClick={handelFromToSubmit}
                          type='submit'
                        >
                          Submit{" "}
                          {submitDateLoader ? (
                            <Spinner
                              animation="border"
                              variant="Primary"
                              size="sm"
                            />
                          ) : (
                            ""
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>


            <div
              className="ag-theme-alpine"
              style={{ height: "70vh", width: "100%", marginTop: '30px' }}
            >
              <AgGridReact
                rowHeight={rowHeight}
                columnDefs={columns}
                defaultColDef={DefaultColumnSetting}
                pagination={true}
                paginationPageSize={10}
                enableCellChangeFlash={true}
                // onGridReady={onGridReady}
                rowData={rowData}
              />
            </div>

          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create Coupon Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Coupon Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Coupon Title"
                name="couponTitle"
                value={formList.couponTitle}
                onChange={handelOnChange}
                autoComplete='off'
              />
              <span className="text-danger" >{formListError.couponTitle}</span>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Coupon Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Coupon Code"
                name="couponCode"
                value={formList.couponCode}
                onChange={handelOnChange}
                autoComplete='off'
              />
              <span className="text-danger" >{formListError.couponCode}</span>

            </Form.Group>
            {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Starting Date</Form.Label>
              <Form.Control
                type="date"
                placeholder="Starting Date"
                name="startingDate"
                value={formList.startingDate}
                onChange={handelOnChange}
                autoFocus
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Ending Date</Form.Label>
              <Form.Control
                type="date"
                placeholder="Ending Date"
                name="endingDate"
                value={formList.endingDate}
                onChange={handelOnChange}
                autoFocus
              />
            </Form.Group> */}


            <div className="col-md-12">
              {" "}
              <Form.Label>Starting Date</Form.Label>
              <Form.Control
                type="date"
                value={formList.startingDate == '' ? dateOne : formList.startingDate}
                name="startingDate"
                format="DD/MM/YYYY"
                onChange={handelOnChange}
                autoComplete='off'
              />
              {/* <span className="text-danger" >{formList.startingDate}</span> */}

            </div>
            <div className="col-md-12">
              <Form.Label>Ending Date</Form.Label>
              <Form.Control
                type="date"
                value={formList.endingDate == '' ? dateOne : formList.endingDate}
                name="endingDate"
                format="DD/MM/YYYY"
                onChange={handelOnChange}
                autoComplete='off'
              />
              {/* <span className="text-danger" >{formList.endingDate}</span> */}
            </div>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Select Type</Form.Label>
              <Form.Select size="sm" value={selected} onChange={handleChange}>
                {options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.text}
                  </option>
                ))}
              </Form.Select>
              <span className="text-danger" >{formListError.selectDropdown}</span>
            </Form.Group>

            {
              selected == 'visible' || selected == 'percentage' ?
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Percentage</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Percentage"
                    autoFocus
                    name="percentage"
                    value={formList.percentage}
                    onChange={handelOnChange}
                    autoComplete='off'
                    maxLength={10}
                    onInput={(e) => {
                      if (e.target.value.length > e.target.maxLength)
                        e.target.value = e.target.value.slice(0, e.target.maxLength);
                    }}
                    onWheel={event => event.currentTarget.blur()}
                  />
                  <span className="text-danger" >{formListError.percentage}</span>
                </Form.Group> : " "
            }

            {
              selected == 'visible' || selected == 'amount' ?
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Amount</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Amount"
                    autoFocus
                    name="amount"
                    value={formList.amount}
                    onChange={handelOnChange}
                    autoComplete='off'
                    maxLength={10}
                    onInput={(e) => {
                      if (e.target.value.length > e.target.maxLength)
                        e.target.value = e.target.value.slice(0, e.target.maxLength);
                    }}
                    onWheel={event => event.currentTarget.blur()}
                  />
                  <span className="text-danger" >{formListError.amount}</span>
                </Form.Group> : " "

            }
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Coupon Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Coupon Description"
                name="couponDescription"
                value={formList.couponDescription}
                onChange={handelOnChange}
                autoComplete='off'
              />
              <span className="text-danger" >{formListError.couponDescription}</span>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="m-auto">
          <Button variant="danger" onClick={handleCouponClear} className="rounded">
            Clear
          </Button>
          <Button variant="primary" onClick={handelSubmit} className="rounded">
            Submit{" "}
            {submitCouponCodeLoader ? (
              <Spinner
                animation="border"
                variant="Primary"
                size="sm"
              />
            ) : (
              ""
            )}
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal show={smShow} onHide={() => setSmShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Are You Confirm to Delete Cupon Code</Modal.Title>
        </Modal.Header>
        <Modal.Footer className="m-auto">
          <Button
            variant="danger"
            className=" rounded"
            onClick={handleCancelClear}
          >
            Cancel
          </Button>
          <Button
            variant="success"
            className=" rounded"
            onClick={onClicksucess}
          >
            Delete
            {
              loader &&
              <Spinner animation="border" variant="Primary" size="sm" />
            }
          </Button>
        </Modal.Footer>
      </Modal>
    </div >
  );
}

export default CouponCodeList;
