import React, { useEffect, useState } from "react";
import { Card, Col, Form, Row, Spinner } from "react-bootstrap";
import Apiservice from "../../Services/Apiservice";
import Bookingreport from "./BookingReport";
import { ToastContainer, toast, Zoom } from "react-toastify";
import ComplaintsReport from "./ComplaintsReport";
import BranchLinstReport from "./BranchLinstReport";
import OGMInwardReport from "./OGMInwardReport";
import OGMOutWardReport from "./OGMOutWardReport";
import LOCALInwardReport from "./LOCALInwardReport";
import LOCALOutwardReport from "./LOCALOutwardReport";
import DeliveryReport from "./DeliveryReport";
import AllOgmInWardReport from "./AllOgmInWardReport";
import AllOgmOutWardReport from "./AllOgmOutWardReport";
import AllLocalInWardReport from "./AllLocalInWardReport";
import AllLocalOutWard from "./AllLocalOutWard";
import Allbooking from "../Transaction/AllBooking";
import { FileText } from "react-bootstrap-icons";

const Reportmaster = () => {
  const [userData, setUserData] = useState("");
  const [emailUser, setEmailUser] = useState([]);
  const [handleReport, sethandleReport] = useState(1);
  const [bookingReportTableData, setBookingReportTableData] = useState([]);
  const [show, setShow] = useState(false);
  const [searchCnoteLoader, setSearchCnoteLoader] = useState(false);
  const [searchOrderIdLoader, setSearchOrderIdLoader] = useState(false);
  const [podImage, setPodImage] = useState(null);

  useEffect(async () => {
    const localstorege = localStorage.getItem("userDetails");
    const localstoregeParseData = JSON.parse(localstorege);
    setEmailUser(localstoregeParseData);
    const mainData = await localstoregeParseData;

    const one = [mainData.role_masters].map((ele, ind) => {
      setUserData(ele[0].role_name);
    });
  }, []);
  const handleReportStatus = (e) => {
    sethandleReport(e.target.value);
  };

  /*******  transaction integratio  *******/
  const [transactionId, setTransactionId] = useState({ transaction: "" });
  const [transactionError, setTransactionError] = useState({ transaction: "" });
  const onChnangeTransaction = (ele) => {
    setTransactionId({ ...transactionId, [ele.target.name]: ele.target.value });
    setTransactionError({ ...transactionError, [ele.target.name]: null, })
  };
  const transactionValidation = () => {
    const { transaction } = transactionId;
    const newError = {};
    if (transaction == "") {
      newError.transaction = "Please enter order Id";
    } else if (transaction && transaction.length > 10) {
      newError.transaction = "Please enter below 10 digits";
    }
    return newError;
  };

  const handelSearchOrderId = async (e) => {
    e.preventDefault();
    const transactionIdObject = transactionValidation();
    if (Object.keys(transactionIdObject).length > 0) {
      setTransactionError(transactionIdObject);
    } else {
      const payload = {
        c_note_no: "",
        c_note_status: false,
        trans_id_status: true,
        transaction_id: transactionId.transaction,
      };
      setSearchOrderIdLoader(true);
      const transactionIdApi = await Apiservice.postdata("/booking/tracking_by_id", payload);
      if (transactionIdApi?.error == false && transactionIdApi?.data?.length != 0) {
        toast.success(transactionIdApi?.message);
        setSearchOrderIdLoader(false);
        setBookingReportTableData(transactionIdApi);
        setShow(true);
      } else {
        setSearchOrderIdLoader(false);
        setBookingReportTableData([]);
        toast.error("based on Order Id there is no data");
      }
    }
  };

  const handelSearchOrderIdClear = () => {
    setTransactionId({ ...transactionId, transaction: "" });
    setTransactionError({ ...transactionError, transactionError: "" });
  };

  /********* cnote Integration  *********/

  const [cNoteNumber, setCnoteNumber] = useState({ cnote: "" });
  const [cnoteError, setCnoteError] = useState({ cnote: "" });
  const onChangeCnote = (ele) => {
    setCnoteNumber({ ...cNoteNumber, [ele.target.name]: ele.target.value });
    setCnoteError({ ...cnoteError, [ele.target.name]: null });
  };
  const cnoteNumberValidation = () => {
    const { cnote } = cNoteNumber;
    const newError = {};
    if (cnote == "") {
      newError.cnote = "Please enter c-note number";
    } else if (cnote && cnote.length > 10) {
      newError.cnote = "Please enter below 10 digits";
    }
    return newError;
  };

  const handleSearchCnoteNumber = async (e) => {
    e.preventDefault();
    const cnoteNumberValidationObject = cnoteNumberValidation();
    if (Object.keys(cnoteNumberValidationObject).length > 0) {
      setCnoteError(cnoteNumberValidationObject);
    } else {
      const payload = {
        transaction_id: "",
        trans_id_status: false,
        c_note_no: cNoteNumber.cnote,
        c_note_status: true,
      };
      setSearchCnoteLoader(true);
      const cNoteNumberApi = await Apiservice.postdata("/booking/tracking_by_id", payload);
      if (cNoteNumberApi.error == false && cNoteNumberApi.data.length != 0) {
        setSearchCnoteLoader(false);
        toast.success(cNoteNumberApi.message);
        setBookingReportTableData(cNoteNumberApi);
        setShow(true);
      } else {
        setSearchCnoteLoader(false);
        setBookingReportTableData([]);
        toast.error("based on c-note there is no data");
      }
    }
  };

  const handelSearchCnoteIdClear = () => {
    setCnoteNumber({ ...cNoteNumber, cnote: "" });
    setCnoteError({ ...cnoteError, cnote: "" });
  };


  // console.log(bookingReportTableData?.data?.outforDelivery ? bookingReportTableData?.data?.outforDelivery?.delivery_status :'');


  return (
    <>
      <div className="more-link">
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar
          transition={Zoom}
          delay={1000}
          limit={1}
        />
        <h6>
          {handleReport == 1 ? "Booking Report" : ""}
          {handleReport == 2 ? "Outward Consignment" : ""}
          {handleReport == 3 ? "Complaints Report" : ""}
          {handleReport == 4 ? "Branch List Report" : ""}
        </h6>
      </div>
      <Card className="shadow rounded p-2 w-100 mb-4">
        <div className="master">
          <Row>
            <Col md={6}>
              <div className="leftedge d-flex justify-content-space">
                <Form.Select
                  aria-label="Default select example"
                  size={"sm"}
                  className="selectsizesmall"
                  onChange={handleReportStatus}
                >
                  <option value="1">Booking Report</option>
                  {/* <option value="2">Delivery Status</option> */}
                  <option value="3">Complaints Report</option>
                  <option value="4">Branch List Report</option>
                  {emailUser.Hub_type != "LOCAL" && (
                    <option value="5">OGM Inward Report</option>
                  )}
                  {emailUser.Hub_type != "LOCAL" && (
                    <option value="6">OGM Outward Report</option>
                  )}
                  <option value="7">Local Inward Report</option>
                  <option value="8">Local Outward Report</option>
                  <option value="9">Out For Delivery Report</option>
                  <option value="10">All Booking</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
        {handleReport == 1 ? <Bookingreport /> : ""}
        {handleReport == 10 ? <Allbooking /> : ""}
        {handleReport == 3 ? <ComplaintsReport /> : ""}
        {handleReport == 4 ? <BranchLinstReport /> : ""}
        {handleReport == 5 ? (
          userData == "Admin" ? (
            <AllOgmInWardReport />
          ) : (
            <OGMInwardReport />
          )
        ) : (
          ""
        )}
        {handleReport == 6 ? (
          userData == "Admin" ? (
            <AllOgmOutWardReport />
          ) : (
            <OGMOutWardReport />
          )
        ) : (
          ""
        )}
        {handleReport == 7 ? (
          userData == "Admin" ? (
            <AllLocalInWardReport />
          ) : (
            <LOCALInwardReport />
          )
        ) : (
          ""
        )}
        {handleReport == 8 ? (
          userData == "Admin" ? (
            <AllLocalOutWard />
          ) : (
            <LOCALOutwardReport />
          )
        ) : (
          ""
        )}
        {
          handleReport == 9 ? <DeliveryReport /> : ""
        }
        {
          handleReport == 1 ? (
            <Card className="shadow rounded mt-3 p-3 w-100">
              <div className="row my-2 mt-4">
                <div className="col-6">
                  <form onSubmit={handelSearchOrderId}>
                    <div className="row">
                      <div className="col-md-6">
                        <label for="formFile" class="form-label">
                          Order Id
                        </label>
                        <input
                          type="number"
                          name="transaction"
                          onChange={onChnangeTransaction}
                          onWheelCapture={(e) => {
                            e.target.blur();
                          }}
                          value={transactionId.transaction}
                          class="form-control form-control-md"
                        />{" "}
                        <span className="text-danger">
                          {transactionError?.transaction}
                        </span>
                      </div>
                      <div className="col-md-3" style={{ marginTop: "30px" }}>
                        <button
                          className="btn btn-danger  btn-md rounded"
                          type="reset"
                          onClick={handelSearchOrderIdClear}
                        >
                          Clear
                        </button>
                      </div>
                      <div className="col-md-2" style={{ marginTop: "30px" }}>
                        <button type="submit" className="btn btn-primary btn-md rounded"> Search{" "}
                          {searchOrderIdLoader ? (
                            <Spinner
                              animation="border"
                              variant="Primary"
                              size="sm"
                            />
                          ) : (
                            ""
                          )}
                        </button>

                      </div>
                    </div>
                  </form>
                </div>

                <div className="col-6">
                  <form onSubmit={handleSearchCnoteNumber}>
                    <div className="row">
                      <div className="col-md-6">
                        <label for="formFile" class="form-label">
                          C-note Number
                        </label>
                        <input
                          type="number"
                          name="cnote"
                          onChange={onChangeCnote}
                          value={cNoteNumber.cnote}
                          class="form-control form-control-md"
                          onWheelCapture={(e) => {
                            e.target.blur();
                          }}
                        />
                        <span className="text-danger">{cnoteError.cnote}</span>
                      </div>
                      <div className="col-md-3" style={{ marginTop: "30px" }}>
                        <button
                          className="btn btn-danger  btn-md rounded"
                          type="reset"
                          onClick={handelSearchCnoteIdClear}
                        >
                          Clear
                        </button>
                      </div>
                      <div className="col-md-3" style={{ marginTop: "30px" }}>
                        <button
                          className="btn btn-primary  btn-md rounded"
                          // onClick={handleSearchCnoteNumber}
                          onClick="submit"
                        >
                          Search{" "}
                          {searchCnoteLoader ? (
                            <Spinner
                              animation="border"
                              variant="Primary"
                              size="sm"
                            />
                          ) : (
                            ""
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="mt-3">
                {/* No Booking */}
                {
                  bookingReportTableData && bookingReportTableData?.length == 0
                    ?
                    (
                      <table
                        size="sm"
                        className="table table-bordered table-hover text-center"
                      >
                        <thead>
                          <tr>
                            <th>Sl.No</th>
                            <th>Date</th>
                            <th>C-Note Number</th>
                            <th>User Id</th>
                            <th>Entry Date</th>
                            <th>OGM/Runsheet</th>
                            <th>Transaction Type</th>
                            <th>Status</th>
                            {/* <th>Remark</th> */}
                            <th>Branch Name</th>
                            {/* <th>POD</th> */}
                          </tr>
                        </thead>
                        <tbody className="text-center">
                          <tr>
                            <td> </td>
                            <td> </td>
                            <td> </td>
                            <td> </td>
                            <div>{" "}<p className="text-center my-2">  {" "}  Booking is not available for this C-note Number</p>{" "}</div>
                            <td> </td>
                            <td> </td>
                            <td> </td>
                            <td> </td>
                          </tr>
                        </tbody>
                      </table>
                    ) :
                    ("")

                }

                {/* With Booking */}

                {
                  bookingReportTableData &&
                    bookingReportTableData.length != 0 &&
                    Object.keys(bookingReportTableData?.data).includes("order_id") == true &&
                    Object.keys(bookingReportTableData?.data).includes("outforDelivery") != true
                    ?
                    (
                      <table
                        size="sm"
                        className="table table-bordered table-hover text-center"
                      >
                        <thead>
                          <tr>
                            <th>Sl.No</th>
                            <th>Date</th>
                            <th>C-Note Number</th>
                            <th>User Id</th>
                            <th>Entry Date</th>
                            <th>OGM/Runsheet</th>
                            <th>Transaction Type</th>
                            <th>Status</th>
                            {/* <th>Remark</th> */}
                            <th>Branch Name</th>
                            {/* <th>POD</th> */}
                          </tr>
                        </thead>
                        {
                          show == true &&
                            bookingReportTableData &&
                            Object.keys(bookingReportTableData?.data).includes(
                              "order_id"
                            ) == true &&
                            Object.keys(bookingReportTableData?.data).length > 0 ? (
                            <tbody>
                              {
                                show == true
                                  ? bookingReportTableData &&
                                  [bookingReportTableData?.data]?.map((ele, ind) => {
                                    return (
                                      <tr key={ind}>
                                        <td>
                                          <b>{"*"}</b>
                                        </td>
                                        <td>{ele?.createdAt}</td>
                                        <td>{ele?.c_node_no}</td>
                                        <td>{ele?.Frenchises[0]?.MobileNumber}</td>
                                        <td>{ele?.createdAt}</td>
                                        <td>{ele?.OGNs && ele.OGMs[0]?.OGM_no}</td>
                                        <td>Booking</td>
                                        <td>
                                          <b>From:</b>{" "}
                                          {
                                            ele?.Frenchises[0]?.city_masters[0]
                                              ?.city_name
                                          }
                                          {" "}<b>To:{" "}</b>
                                          {ele?.delivery_district}
                                        </td>
                                        {/* <td>{ele?.remarks}</td> */}
                                        <td>
                                          {
                                            ele?.Frenchises[0]?.city_masters[0]
                                              ?.city_name
                                          }
                                        </td>
                                      </tr>
                                    );
                                  })
                                  : ""
                              }

                              {
                                show == true
                                  ? bookingReportTableData &&
                                  bookingReportTableData?.data?.OGMs.length > 0 &&
                                  bookingReportTableData &&
                                  bookingReportTableData?.data?.OGMs.map(
                                    (ogmMap, ind1) => {
                                      return ogmMap?.order_trackings?.map(
                                        (ele, ind) => {
                                          return (
                                            <tr key={ind}>
                                              <td style={{ textAlign: "center" }}>
                                                {
                                                  // ogmMap?.order_trackings.length +

                                                  <b>{"*"}</b>
                                                }
                                              </td>
                                              <td>{ele?.createdAt}</td>
                                              <td>{ogmMap?.c_node_no}</td>
                                              <td>
                                                {ele?.type == "inward"
                                                  ? ele?.FranchiseTo?.Frenchises[0]
                                                    ?.MobileNumber
                                                  : ele?.FranchiseFrom?.Frenchises[0]
                                                    ?.MobileNumber}{" "}
                                              </td>
                                              <td>{ele?.createdAt}</td>
                                              <td>{ele?.ogm_no}</td>
                                              <td>
                                                {
                                                  ele?.type == "inward" || ele?.type == "local-inward"
                                                    ? "Package Received"
                                                    : "Package Dispatched"
                                                }
                                              </td>
                                              <td>
                                                <b>From:</b>{" "}
                                                {
                                                  ele?.FranchiseFrom?.Frenchises[0]
                                                    ?.city_masters[0]?.city_name
                                                }
                                                {" "}<b>To:{" "}</b>
                                                {
                                                  ele?.FranchiseTo?.Frenchises[0]
                                                    ?.city_masters[0]?.city_name
                                                }{" "}
                                              </td>
                                              {/* <td></td> */}
                                              <td>
                                                {
                                                  ele?.type == "inward" || ele?.type == "local-inward"
                                                    ? ele?.FranchiseTo?.Frenchises[0]
                                                      ?.city_masters[0]?.city_name
                                                    : ele?.FranchiseFrom?.Frenchises[0]
                                                      ?.city_masters[0]
                                                      ?.city_name}{" "
                                                }
                                              </td>
                                            </tr>
                                          );
                                        }
                                      );
                                    }
                                  )
                                  : ""
                              }


                              {/* assign delivery boy */}
                              {
                                show == true
                                  ? bookingReportTableData &&
                                  bookingReportTableData?.data?.assign_deliveryboys
                                    .length > 0 &&
                                  bookingReportTableData &&
                                  bookingReportTableData?.data?.assign_deliveryboys?.map(
                                    (ele, ind) => {
                                      // ele&& setPodImage(ele)
                                      return (
                                        <tr
                                          key={ind}
                                          style={{
                                            backgroundColor:
                                              ele?.delivery_status == "Delivered"
                                                ? "#b5e7a0"
                                                : "",
                                          }}
                                        >
                                          <td>{<b>{"*"}</b>}</td>
                                          <td>{ele?.createdAt}</td>
                                          <td>{ele?.c_note_no}</td>
                                          <td>{ele?.Frenchises[0]?.MobileNumber} </td>
                                          <td>{ele?.createdAt}</td>
                                          <td></td>
                                          <td>Delivery </td>
                                          <td>{ele?.delivery_status}</td>
                                          {/* <td></td> */}
                                          <td>
                                            {
                                              ele?.Frenchises[0]?.city_masters[0]
                                                ?.city_name
                                            }{" "}
                                          </td>
                                          {/* <td></td> */}
                                        </tr>
                                      );
                                    }
                                  )
                                  : ""
                              }{" "}
                            </tbody>
                          ) : (
                            <tbody className="text-center">
                              <tr>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                                <td> </td>

                                <div>
                                  {" "}
                                  <p className="text-center my-2">
                                    {" "}
                                    Booking is not available for this C-note Number
                                  </p>{" "}
                                </div>

                                <td> </td>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                              </tr>
                            </tbody>
                          )}
                      </table>
                    )
                    :
                    ''
                }


                {/* Without Booking */}
                {/* {
                  bookingReportTableData &&
                    bookingReportTableData.length != 0 &&
                    Object.keys(bookingReportTableData.data).includes("order_id") != true &&
                    Object.keys(bookingReportTableData?.data).includes("outforDelivery") != true
                    ?
                    (
                      <table
                        size="sm"
                        className="table table-bordered table-hover text-center"
                      >
                        <thead>
                          <tr>
                            <th>Sl.No</th>
                            <th>Date</th>
                            <th>C-Note Number</th>
                            <th>User Id</th>
                            <th>Entry Date</th>
                            <th>OGM/Runsheet</th>
                            <th>Transaction Type</th>
                            <th>Status</th>
                            <th>Branch Name</th>
                          </tr>
                        </thead>
                        {
                          show == true &&
                            Object.keys(bookingReportTableData.data).includes(
                              "order_id"
                            ) != true &&
                            bookingReportTableData &&
                            Object.keys(bookingReportTableData?.data).length > 0 ? (
                            <tbody>
                              {show == true
                                ? bookingReportTableData && (
                                  <tr>
                                    <td>
                                      <b>{"*"}</b>
                                    </td>
                                    <td>
                                      {
                                        bookingReportTableData?.data[0]
                                          ?.order_trackings[0]?.createdAt
                                      }
                                    </td>
                                    <td>{bookingReportTableData?.data[0]?.c_node_no}</td>
                                    <td>{
                                      bookingReportTableData?.data[0]
                                        ?.order_trackings[0]?.FranchiseFrom
                                        .Frenchises[0]?.
                                        MobileNumber}
                                    </td>
                                    <td>
                                      {
                                        bookingReportTableData?.data[0]
                                          ?.order_trackings[0]?.createdAt
                                      }
                                    </td>
                                    <td>
                                      {bookingReportTableData?.data[0]?.OGM_no}
                                    </td>
                                    <td>Outward</td>
                                    <td>
                                      <b>From:</b>{" "}
                                      {
                                        bookingReportTableData?.data[0]
                                          ?.order_trackings[0]?.FranchiseFrom
                                          .Frenchises[0]?.city_masters[0]?.city_name
                                      }{" "}
                                      <b>To:</b>{" "}
                                      {
                                        bookingReportTableData?.data[0]
                                          ?.order_trackings[0]?.FranchiseTo
                                          .Frenchises[0]?.city_masters[0]?.city_name
                                      }
                                    </td>
                                    <td>
                                      {
                                        bookingReportTableData?.data[0]
                                          ?.order_trackings[0]?.FranchiseFrom
                                          .Frenchises[0]?.city_masters[0]?.city_name
                                      }
                                    </td>
                                  </tr>
                                )
                                : ""
                              }

                              {
                                show == true
                                  ? bookingReportTableData &&
                                  bookingReportTableData?.data[
                                    bookingReportTableData?.data.length - 1
                                  ].order_trackings[
                                    bookingReportTableData?.data[
                                      bookingReportTableData?.data.length - 1
                                    ].order_trackings.length - 1
                                  ].type == "inward" && (
                                    <tr>
                                      <td>
                                        <b>{"*"}</b>
                                      </td>
                                      <td>
                                        {
                                          bookingReportTableData?.data[
                                            bookingReportTableData?.data.length - 1
                                          ]?.order_trackings[0]?.createdAt
                                        }
                                      </td>
                                      <td>{
                                        bookingReportTableData?.data[0]?.c_node_no}</td>
                                      <td>
                                        {
                                          bookingReportTableData?.data[0]
                                            ?.order_trackings[0]?.FranchiseTo
                                            .Frenchises[0]?.MobileNumber}
                                      </td>
                                      <td>
                                        {
                                          bookingReportTableData?.data[
                                            bookingReportTableData?.data.length - 1
                                          ]?.order_trackings[0]?.createdAt
                                        }
                                      </td>
                                      <td>
                                        {
                                          bookingReportTableData?.data[
                                            bookingReportTableData?.data.length - 1
                                          ]?.OGM_no
                                        }
                                      </td>
                                      <td>Inward</td>
                                      <td>
                                        <b>From:</b>{" "}
                                        {
                                          bookingReportTableData?.data[0]
                                            ?.order_trackings[0]?.FranchiseFrom
                                            .Frenchises[0]?.city_masters[0]?.city_name
                                        }
                                        {" "}<b>To:</b>{" "}
                                        {
                                          bookingReportTableData?.data[0]
                                            ?.order_trackings[0]?.FranchiseTo
                                            .Frenchises[0]?.city_masters[0]?.city_name
                                        }
                                      </td>
                                      <td>
                                        {
                                          bookingReportTableData?.data[0]
                                            ?.order_trackings[0]?.FranchiseTo
                                            .Frenchises[0]?.city_masters[0]?.city_name
                                        }
                                      </td>
                                    </tr>
                                  )
                                  : ""
                              }

                              {
                                show == true
                                  ? bookingReportTableData &&
                                  bookingReportTableData?.data[
                                    bookingReportTableData?.data.length - 1
                                  ].assign_deliveryboys[
                                  bookingReportTableData?.data[
                                    bookingReportTableData?.data.length - 1
                                  ].assign_deliveryboys.length - 1
                                  ] && (
                                    <tr
                                      style={{
                                        backgroundColor:
                                          bookingReportTableData?.data[
                                            bookingReportTableData?.data.length - 1
                                          ].assign_deliveryboys[
                                            bookingReportTableData?.data[
                                              bookingReportTableData?.data.length - 1
                                            ].assign_deliveryboys.length - 1
                                          ]?.delivery_status == "Delivered"
                                            ? "#b5e7a0"
                                            : "",
                                      }}
                                    >
                                      <td>
                                        <b>{"*"}</b>
                                      </td>
                                      <td>
                                        {
                                          bookingReportTableData?.data[
                                            bookingReportTableData?.data.length - 1
                                          ]?.order_trackings[0]?.createdAt
                                        }
                                      </td>
                                      <td>{bookingReportTableData?.data[0]?.c_node_no}</td>
                                      <td></td>
                                      <td>
                                        {
                                          bookingReportTableData?.data[
                                            bookingReportTableData?.data.length - 1
                                          ]?.order_trackings[0]?.createdAt
                                        }
                                      </td>
                                      <td>
                                        {
                                          bookingReportTableData?.data[
                                            bookingReportTableData?.data.length - 1
                                          ]?.OGM_no
                                        }
                                      </td>
                                      <td>Delivery</td>
                                      <td>
                                        {
                                          bookingReportTableData?.data[
                                            bookingReportTableData?.data.length - 1
                                          ].assign_deliveryboys[
                                            bookingReportTableData?.data[
                                              bookingReportTableData?.data.length - 1
                                            ].assign_deliveryboys.length - 1
                                          ]?.delivery_status
                                        }
                                      </td>
                                      <td>
                                        {
                                          bookingReportTableData?.data[
                                            bookingReportTableData?.data.length - 1
                                          ].assign_deliveryboys[
                                            bookingReportTableData?.data[
                                              bookingReportTableData?.data.length - 1
                                            ].assign_deliveryboys.length - 1
                                          ]?.Frenchises[0]?.city_masters[0]?.city_name
                                        }
                                      </td>
                                    </tr>
                                  )
                                  : ""
                              }
                            </tbody>
                          ) : (
                            <tbody className="text-center">
                              <tr>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                                <div>
                                  {" "}
                                  <p className="text-center my-2">
                                    {" "}
                                    Booking is not available for this C-note Number
                                  </p>{" "}
                                </div>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                              </tr>
                            </tbody>
                          )}
                      </table>
                    )
                    :
                    ''
                } */}


                {
                  bookingReportTableData &&
                    bookingReportTableData.length != 0 &&
                    Object.keys(bookingReportTableData.data).includes("order_id") != true &&
                    Object.keys(bookingReportTableData?.data).includes("outforDelivery") != true
                    ?
                    (
                      <table
                        size="sm"
                        className="table table-bordered table-hover text-center"
                      >
                        <thead>
                          <tr>
                            <th>Sl.No</th>
                            <th>Date</th>
                            <th>C-Note Number</th>
                            <th>User Id</th>
                            <th>Entry Date</th>
                            <th>OGM/Runsheet</th>
                            <th>Transaction Type</th>
                            <th>Status</th>
                            <th>Branch Name</th>
                          </tr>
                        </thead>
                        {
                          show == true &&
                            Object.keys(bookingReportTableData.data).includes("order_id") != true &&
                            bookingReportTableData &&
                            Object.keys(bookingReportTableData?.data).length > 0 ? (
                            <tbody>
                              {show == true
                                ? bookingReportTableData &&
                                bookingReportTableData.data.map((trackingMap, ind) => {
                                  return trackingMap?.order_trackings.map((ele, ind) => {
                                    return (
                                      <tr>
                                        <td>
                                          <b>{"*"}</b>
                                        </td>
                                        <td>
                                          {
                                            ele?.createdAt
                                          }
                                        </td>
                                        <td>{trackingMap?.c_node_no}</td>
                                        <td>
                                          {
                                            ele?.type == "inward" || ele?.type == "local-inward"
                                              ? ele?.FranchiseTo?.Frenchises[0]
                                                ?.MobileNumber
                                              : ele?.FranchiseFrom?.Frenchises[0]
                                                ?.MobileNumber
                                          }{" "}
                                        </td>
                                        <td>
                                          {
                                            ele?.createdAt
                                          }
                                        </td>
                                        <td>
                                          {
                                            ele?.ogm_no
                                          }
                                        </td>
                                        <td>
                                          {
                                            ele?.type == "inward" || ele?.type == "local-inward"
                                              ? "Package Received"
                                              : "Package Dispatched"
                                          }
                                        </td>
                                        <td>
                                          <b>From:</b>{" "}
                                          {
                                            ele?.FranchiseFrom
                                              .Frenchises[0]?.city_masters[0]?.city_name
                                          }{" "}
                                          <b>To:</b>{" "}
                                          {
                                            ele?.FranchiseTo
                                              .Frenchises[0]?.city_masters[0]?.city_name
                                          }
                                        </td>
                                        <td>
                                          {
                                            ele?.type == "inward" || ele?.type == "local-inward"
                                              ? ele?.FranchiseTo?.Frenchises[0]
                                                ?.city_masters[0]?.city_name
                                              : ele?.FranchiseFrom?.Frenchises[0]
                                                ?.city_masters[0]
                                                ?.city_name}{" "}
                                        </td>
                                      </tr>

                                    )
                                  })

                                }
                                )
                                :
                                ("")
                              }

                              {/* assign delivery boy */}
                              {
                                show == true
                                  ? bookingReportTableData &&
                                  bookingReportTableData?.data[
                                    bookingReportTableData?.data.length - 1
                                  ].assign_deliveryboys[
                                  bookingReportTableData?.data[
                                    bookingReportTableData?.data.length - 1
                                  ].assign_deliveryboys.length - 1
                                  ] && (
                                    <tr
                                      style={{
                                        backgroundColor:
                                          bookingReportTableData?.data[
                                            bookingReportTableData?.data.length - 1
                                          ].assign_deliveryboys[
                                            bookingReportTableData?.data[
                                              bookingReportTableData?.data.length - 1
                                            ].assign_deliveryboys.length - 1
                                          ]?.delivery_status == "Delivered"
                                            ? "#b5e7a0"
                                            : "",
                                      }}
                                    >
                                      <td>
                                        <b>{"*"}</b>
                                      </td>
                                      <td>
                                        {
                                          bookingReportTableData?.data[
                                            bookingReportTableData?.data.length - 1
                                          ]?.order_trackings[0]?.createdAt
                                        }
                                      </td>
                                      <td>{bookingReportTableData?.data[0]?.c_node_no}</td>
                                      <td></td>
                                      <td>
                                        {
                                          bookingReportTableData?.data[
                                            bookingReportTableData?.data.length - 1
                                          ]?.order_trackings[0]?.createdAt
                                        }
                                      </td>
                                      <td>
                                        {
                                          bookingReportTableData?.data[
                                            bookingReportTableData?.data.length - 1
                                          ]?.OGM_no
                                        }
                                      </td>
                                      <td>Delivery</td>
                                      <td>
                                        {
                                          bookingReportTableData?.data[
                                            bookingReportTableData?.data.length - 1
                                          ].assign_deliveryboys[
                                            bookingReportTableData?.data[
                                              bookingReportTableData?.data.length - 1
                                            ].assign_deliveryboys.length - 1
                                          ]?.delivery_status
                                        }
                                      </td>
                                      <td>
                                        {
                                          bookingReportTableData?.data[
                                            bookingReportTableData?.data.length - 1
                                          ].assign_deliveryboys[
                                            bookingReportTableData?.data[
                                              bookingReportTableData?.data.length - 1
                                            ].assign_deliveryboys.length - 1
                                          ]?.Frenchises[0]?.city_masters[0]?.city_name
                                        }
                                      </td>
                                    </tr>
                                  )
                                  : ""
                              }
                            </tbody>
                          ) : (
                            <tbody className="text-center">
                              <tr>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                                <div>
                                  {" "}
                                  <p className="text-center my-2">
                                    {" "}
                                    Booking is not available for this C-note Number
                                  </p>{" "}
                                </div>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                              </tr>
                            </tbody>
                          )}
                      </table>
                    )
                    :
                    ''
                }

                {/* Out For Delivery */}
                {
                  bookingReportTableData &&
                    // bookingReportTableData.length != 0 &&
                    bookingReportTableData?.data &&
                    Object.keys(bookingReportTableData?.data).includes("outforDelivery") == true &&
                    bookingReportTableData?.data?.outforDelivery
                    ?
                    (
                      <table
                        size="sm"
                        className="table table-bordered table-hover text-center"
                      >
                        <thead>
                          <tr>
                            <th>Sl.No</th>
                            <th>Date</th>
                            <th>C-Note Number</th>
                            <th>User Id</th>
                            <th>Entry Date</th>
                            <th>OGM/Runsheet</th>
                            <th>Transaction Type</th>
                            <th>Status</th>
                            {/* <th>Remark</th> */}
                            <th>Branch Name</th>
                            {/* <th>POD</th> */}
                          </tr>
                        </thead>
                        {
                          show == true &&
                            bookingReportTableData?.data &&
                            Object.keys(bookingReportTableData?.data).includes("outforDelivery") == true &&
                            bookingReportTableData?.data?.outforDelivery

                            ? (
                              <tbody>
                                {
                                  show == true
                                    ?
                                    (
                                      <tr
                                        style={{
                                          backgroundColor:
                                            bookingReportTableData?.data?.outforDelivery && bookingReportTableData?.data?.outforDelivery?.delivery_status == "Delivered"
                                              ?
                                              "#b5e7a0"
                                              :
                                              "",
                                        }}
                                      >
                                        <td>
                                          <b>{"*"}</b>
                                        </td>
                                        <td>{bookingReportTableData?.data?.outforDelivery ? bookingReportTableData?.data?.outforDelivery?.createdAt : ''}</td>
                                        <td>{bookingReportTableData?.data?.outforDelivery ? bookingReportTableData?.data?.outforDelivery?.c_note_no : ''}</td>
                                        <td>{bookingReportTableData?.data?.outforDelivery ? bookingReportTableData?.data?.outforDelivery?.Frenchises[0]?.MobileNumber : ''}</td>
                                        <td>{bookingReportTableData?.data?.outforDelivery ? bookingReportTableData?.data?.outforDelivery?.createdAt : ''}</td>
                                        <td>null</td>
                                        <td> null</td>
                                        <td>{bookingReportTableData?.data?.outforDelivery ? bookingReportTableData?.data?.outforDelivery?.delivery_status : ''}</td>
                                        <td>{bookingReportTableData?.data?.outforDelivery ? bookingReportTableData?.data?.outforDelivery?.Frenchises[0]?.city_masters[0]?.city_name : ''}</td>
                                      </tr>
                                    )
                                    : ""
                                }
                              </tbody>
                            ) : (
                              <tbody className="text-center">
                                <tr>
                                  <td> </td>
                                  <td> </td>
                                  <td> </td>
                                  <td> </td>
                                  <div>
                                    {" "}
                                    <p className="text-center my-2">
                                      {" "}
                                      Booking is not available for this C-note Number
                                    </p>{" "}
                                  </div>
                                  <td> </td>
                                  <td> </td>
                                  <td> </td>
                                  <td> </td>
                                </tr>
                              </tbody>
                            )
                        }
                      </table>
                    )
                    :
                    ''
                }

                {/* With Booking Images */}

                <div>
                  {
                    bookingReportTableData && bookingReportTableData?.data?.image
                      ?
                      (
                        <div className="row">
                          <h6 class="card-title    my-3 bg-light w-auto py-2 rounded ">
                            <FileText /> <b>Booking Image</b>
                          </h6>
                          <div>
                            <Card className="shadow rounded mt-3 p-3 w-100">
                              <img
                                src={bookingReportTableData && bookingReportTableData?.data?.image}
                                width="100%"
                                height="100%"
                              />
                            </Card>
                          </div>
                        </div>
                      )
                      :
                      ''
                  }
                </div>

                <div>
                  {
                    Object.keys(bookingReportTableData).length &&
                    Object.keys(bookingReportTableData?.data).includes(
                      "order_id"
                    ) == true &&
                    bookingReportTableData?.data?.assign_deliveryboys[0]
                      ?.receipt_img_url != null && (
                      <div className="row">
                        <h6 class="card-title    my-3 bg-light w-auto py-2 rounded ">
                          <FileText /> <b>Proof of Dispatch Receipt Image</b>
                        </h6>
                        <div>
                          <Card className="shadow rounded mt-3 p-3 w-100">
                            <img
                              src={
                                bookingReportTableData &&
                                bookingReportTableData?.data
                                  ?.assign_deliveryboys[0]?.receipt_img_url
                              }
                              width="100%"
                              height="100%"
                            />
                          </Card>
                        </div>
                      </div>
                    )
                  }
                </div>
                <div>
                  {
                    Object.keys(bookingReportTableData).length &&
                      Object.keys(bookingReportTableData.data).includes("order_id") == true &&
                      bookingReportTableData?.data?.assign_deliveryboys.length > 0 &&
                      bookingReportTableData?.data?.assign_deliveryboys[0]?.pod_image != null
                      ?
                      (
                        <div className="row">
                          <h6 class="card-title    my-3 bg-light w-auto py-2 rounded ">
                            <FileText /> <b>Proof of Delivery Receipt Image</b>
                          </h6>
                          <div>
                            <Card className="shadow rounded mt-3 p-3 w-100">
                              <img
                                src={
                                  bookingReportTableData &&
                                  bookingReportTableData?.data
                                    ?.assign_deliveryboys[0]?.pod_image
                                }
                                width="100%"
                                height="100%"
                              />
                            </Card>
                          </div>
                        </div>
                      )
                      :
                      ("")
                  }
                </div>

                {/* With out Booking Booking */}
                {/* <div>
                {Object.keys(bookingReportTableData).length &&
                  Object.keys(bookingReportTableData?.data).includes(
                    "order_id"
                  ) != true &&
                  bookingReportTableData?.data?.assign_deliveryboys[0]
                    ?.receipt_img_url != null && (
                    <div className="row">
                      <h6 class="card-title    my-3 bg-light w-auto py-2 rounded ">
                        <FileText /> <b>Proof of Dispatch Receipt Image</b>
                      </h6>
                      <div>
                        <Card className="shadow rounded mt-3 p-3 w-100">
                          <img
                            src={
                              bookingReportTableData &&
                              bookingReportTableData?.data
                                ?.assign_deliveryboys[0]?.receipt_img_url
                            }
                            width="500px"
                            height="500px"
                          />
                        </Card>
                      </div>
                    </div>
                  )}
              </div> */}

                <div>
                  {
                    bookingReportTableData &&
                      bookingReportTableData.length != 0 &&
                      Object.keys(bookingReportTableData.data).includes("order_id") != true &&
                      Object.keys(bookingReportTableData?.data).includes("outforDelivery") != true &&
                      bookingReportTableData && bookingReportTableData?.data[0]?.assign_deliveryboys?.length > 0 &&
                      bookingReportTableData && bookingReportTableData?.data[0]?.assign_deliveryboys[0]?.receipt_img_url
                      ?
                      (
                        <div className="row">
                          <h6 class="card-title    my-3 bg-light w-auto py-2 rounded ">
                            <FileText /> <b>Proof of Delivery Receipt Image</b>
                          </h6>
                          <div>
                            <Card className="shadow rounded mt-3 p-3 w-100">
                              <img
                                src={
                                  bookingReportTableData &&
                                  bookingReportTableData?.data[0]
                                    ?.assign_deliveryboys[0]?.receipt_img_url
                                }
                                width="100%"
                                height="100%"
                              />
                            </Card>
                          </div>
                        </div>
                      )
                      :
                      ("")
                  }
                </div>
              </div>


              {/* Out For Delivery Images */}
              <div>
                {
                  Object.keys(bookingReportTableData).length &&
                    Object.keys(bookingReportTableData.data).includes(
                      "outforDelivery"
                    ) == true &&
                    bookingReportTableData?.data?.outforDelivery
                      ?.receipt_img_url
                    ?
                    (
                      <div className="row">
                        <h6 class="card-title    my-3 bg-light w-auto py-2 rounded ">
                          <FileText /> <b>Proof of Dispatch Receipt Image</b>
                        </h6>
                        <div>
                          <Card className="shadow rounded mt-3 p-3 w-100">
                            <img
                              src={
                                bookingReportTableData &&
                                bookingReportTableData?.data
                                  ?.outforDelivery?.receipt_img_url
                              }
                              width="100%"
                              height="100%"
                            />
                          </Card>
                        </div>
                      </div>
                    )
                    :
                    ("")
                }
              </div>

              <div>
                {
                  Object.keys(bookingReportTableData).length &&
                    Object.keys(bookingReportTableData?.data).includes("outforDelivery") == true &&
                    bookingReportTableData?.data?.drs?.drs_image != null
                    ?
                    (
                      <div className="row">
                        <h6 class="card-title    my-3 bg-light w-auto py-2 rounded ">
                          <FileText /> <b>Proof of Delivery Receipt Image</b>
                        </h6>
                        <div>
                          <Card className="shadow rounded mt-3 p-3 w-100">
                            <img
                              src={
                                bookingReportTableData &&
                                bookingReportTableData?.data
                                  ?.drs?.drs_image
                              }
                              width="100%"
                              height="100%"
                            />
                          </Card>
                        </div>
                      </div>
                    )
                    :
                    ("")
                }
              </div>
            </Card>
          ) : (
            ""
          )
        }
      </Card>
    </>
  );
};

export default Reportmaster;
