import React from "react";
import { Button } from "react-bootstrap";

function CustomButton({ title, colour, onClick }) {
  return (
    <Button variant={colour} className="rounded mx-2" onClick={onClick}>
      {title}
    </Button>
  );
}

export default CustomButton;
