import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.css";
import { useEffect, useState } from "react";
import { Form, Table, ToastContainer } from "react-bootstrap";
import { FileText } from "react-bootstrap-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { toast, Zoom } from "react-toastify";
import Apiservice from "../../Services/Apiservice";

export const productsGenerator = (quantity) => {
  const items = [];
  for (let i = 0; i < quantity; i++) {
    items.push({ id: i, name: `Item name ${i}`, price: 2100 + i });
  }
  return items;
};

const products = productsGenerator(100);

const columns = [
  {
    dataField: "id",
    text: "Product ID",
    sort: true,
  },
  {
    dataField: "name",
    text: "Product Name",
    sort: true,
  },
  {
    dataField: "price",
    text: "Product Price",
  },
];

const Transactionmaster = (params) => {
  const [choice, setchoice] = useState(1);
  const [path, setPath] = useState("");
  const [textareaError, setTextareaError] = useState("");
  const [textareaError1, setTextareaError1] = useState("");

  const [inwardOgmData, setInwardOgmData] = useState([]);
  const [inwardOgmNumber, setInwardOgmNumber] = useState("");
  const [inwardTextArea, setInwardTextArea] = useState("");
  const [disabledOgmField, setDisabledOgmField] = useState(false);

  const [disableOutwardCity, setDisableOutwardCity] = useState(true);
  const [outwardTextArea, setOutwardTextArea] = useState([]);
  const [outwardCityData, setOutwardCityData] = useState([]);

  const [deliveryBoyData, setDeliveryBoyIdData] = useState([]);
  const [outForCityData, setOutForCityData] = useState([]);
  const [cNoteNumber, setCnoteNumber] = useState("");
  const [weight, setWeight] = useState("");
  const [noPcs, setNoPcs] = useState("");
  const [receiverName, setReceiverName] = useState("");
  const [receiverMobile, setReceiverMobile] = useState("");
  const [remarks, setRemarks] = useState("");
  const [width, setWidth] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [franchiseId, setFranchiseId] = useState([]);
  const [disableOutForDeliveryBoy, setDisableOutForDeliveryBoy] =
    useState(true);
  const [disableOutForCityName, setDisableOutForCityName] = useState(true);

  /** fast booking **/
  const [fastBookingDate, setFastBookingDate] = useState("");
  const [fastBookingTime, setFastBookingTime] = useState("");
  const [fastBookingMobileNumber, setFastBookingMobileNumber] = useState("");
  const [fastBookingRefNo, setFastBookingRefNo] = useState("");
  const [fastBookingCNoteNo, setFastBookingCNoteNo] = useState("");
  const [fastBookingConsignee, setFastBookingConsignee] = useState("");
  const [fastBookingRemarks, setFastBookingRemarks] = useState("");
  const [fastBookingNoPcs, setFastBookingNoPcs] = useState("");
  const [fastBookingWtKgs, setFastBookingWtKgs] = useState("");
  const [fastBookingWtGms, setFastBookingWtGms] = useState("");
  const [fastBookingChWt, setFastBookingChWt] = useState("");

  const handlemenuchange = (e) => {
    setchoice(e.target.value);
  };

  useEffect(() => {
    axios
      .get("https://jsonplaceholder.typicode.com/users")
      .then((ele, ind) => {});
    const LocalData = localStorage.getItem("userDetails");
    const LocalData1 = JSON.parse(LocalData);
    setFranchiseId(LocalData1);
  }, []);

  useEffect(() => {
    setPath({ ...params.location.Subtransaction });
  }, [params]);

  useEffect(() => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    const todayDate = yyyy + "-" + mm + "-" + dd;
    setDate(todayDate);

    var d = new Date();
    // d.getHours(); // => 9
    // d.getMinutes(); // =>  30
    // d.getSeconds();

    var hh = d.getHours();
    var mm = d.getMinutes();

    const todayTime = hh + ":" + mm;
    setTime(todayTime);
  }, []);

  // const handleInwardTextarea = (e) => {
  //   if (e.keyCode == 32) {
  //     document.getElementById('textData').value = document.getElementById('textData').value + '\n'
  //   }
  // }

  const handleClickArea = (e) => {
    document.getElementById("areaInput").value = e?.city_name;
    setDisableOutForCityName(false);
  };

  /************ handleInwardOgmApi ***********/
  const handleInwardOgmApi = async () => {
    const ogmPayload = {
      ogm_no: inwardOgmNumber,
    };
    const ogmData = await Apiservice.postdata(
      `/booking/getOGMSourceNo`,
      ogmPayload
    );
    if (ogmData.error == false) {
      setInwardOgmData(ogmData.data);
      toast.success(ogmData.message);
      setDisabledOgmField(true);
    } else {
      toast.error("something went wrong");
    }
  };

  /******** handleInward  API **********/
  var newArr = [];
  const handleInward = async () => {
    const one = inwardTextArea.split("\n");
    const data = one.map((ele, ind) => {
      if (ele !== "") {
        newArr.push(ele);
      }
    });

    const payloadInward = {
      // c_node_no: [
      //   134, 143, 256
      // ],
      c_node_no: newArr,
      frenchise_id: franchiseId.id,
      OGM_NO: inwardOgmNumber,
      source_franchise_id: !inwardOgmData.source_franchise_id
        ? ""
        : inwardOgmData.source_franchise_id,
      type :"inward"  
    };

    let inwardApi = await Apiservice.postdata(
      `/booking/bulkAssginOGMInward`,
      payloadInward
    );
    if (inwardApi.error == false) {
      setInwardOgmNumber("");
      setInwardTextArea("");
      setDisabledOgmField(false);
      toast.success(inwardApi.data);
    } else {
      console.log("something went wrong");
    }
  };

  /************ outward city API *******************/
  const handleOutwardCity = async (cityname) => {
    setDisableOutwardCity(true);
    let AreaNameData = await Apiservice.postdata(`/User/listCity`, {
      city_name: cityname,
    });
    if (AreaNameData.error == false) {
      setOutwardCityData(AreaNameData);
      // setOutwardTextArea('')
    } else {
      console.log("wrong");
    }
  };

  // const handleOutwardTextarea = (e) => {
  //   if (e.keyCode == 32) {
  //     document.getElementById('textData').value = document.getElementById('textData').value + '\n'
  //   }
  // }

  const handleClickCity = (e) => {
    document.getElementById("areaInput").value = e?.city_name;
    setDisableOutwardCity(false);
  };

  /******** handleOutward  API **********/
  var newArr1 = [];
  const handleOutward = async () => {
    const sourcefranchiseid = outwardCityData.data[0].regional_id;
    const one = outwardTextArea.split("\n");
    const two = one.map((ele, ind) => {
      if (ele !== "") {
        newArr1.push(ele);
      }
    });
    const payloadOutward = {
      // c_node_no: [
      //   134, 143, 256
      // ],
      c_node_no: newArr1,
      frenchise_id: franchiseId.id,
      source_franchise_id: sourcefranchiseid,
    };

    let inwardApi = await Apiservice.postdata(
      `/booking/bulkAssginOGMOut`,
      payloadOutward
    );
    if (inwardApi.error == false) {
      console.log(inwardApi, "inwardApi");
      setOutwardTextArea("");
      document.getElementById("areaInput").value = "";
      toast.success(inwardApi.data);
    } else {
      console.log("something went wrong");
    }
  };

  /********** out for Delivery boy API ***************/

  const outForDeliveryBoy = async (deliveryboy) => {
    setDisableOutForDeliveryBoy(true);
    let deliveryboydata = await Apiservice.postdata(
      `/admin/delivery_boy_list`,
      {
        delivery_boy_name: deliveryboy,
      }
    );
    if (deliveryboydata.error == false) {
      setDeliveryBoyIdData(deliveryboydata);
    } else {
      console.log("wrong");
    }
  };

  const handleDeliveryBoy = (e) => {
    document.getElementById("deliveryInput").value = e?.delivery_boy_name;
    setDisableOutForDeliveryBoy(false);
  };

  /***************** Out For city API *******************/
  const handleOutForCity = async (cityname) => {
    setDisableOutForCityName(true);
    let AreaNameData = await Apiservice.postdata(`/User/listCity`, {
      city_name: cityname,
    });
    if (AreaNameData.error == false) {
      setOutForCityData(AreaNameData);
    } else {
      console.log("wrong");
    }
  };

  /*********** handleOutForDelivery API *************/
  const handleOutForDelivery = async () => {
    const deliveryBoyId = deliveryBoyData?.data[0].frenchise_id;
    const areaId = outForCityData.data[0].id;
    const payloadhandleOutForDelivery = {
      date_time: `${date} ${time}`,
      delivery_boy_id: deliveryBoyId,
      area_id: areaId,
      c_note_no: cNoteNumber,
      weight: weight,
      width: width,
      no_pcs: noPcs,
      receiver_name: receiverName,
      receiver_mobile: receiverMobile,
      remarks: remarks,
    };

    let outForDelivery = await Apiservice.postdata(
      `/admin/CreateAssign`,
      payloadhandleOutForDelivery
    );
    if (outForDelivery.error == false) {
      toast.success(outForDelivery.message);
      handleReamove();
    } else {
      console.log("something went wrong");
    }
  };

  const handleReamove = () => {
    setCnoteNumber("");
    setDate("");
    setNoPcs("");
    setReceiverMobile("");
    setReceiverName("");
    setRemarks("");
    setTime("");
    setWeight("");
    setWidth("");
    document.getElementById("deliveryInput").value = "";
    document.getElementById("areaInput").value = "";
  };

  /* fast Booking Save API */
  const handleFastBookingSave = () => {
    console.log("handleFastBookingSave");
  };

  const [showAddOutForDelivery, setShowAddOutForDelivery] = useState(true);
  const [showAddOutForDeliveryForm, setShowAddOutForDeliveryForm] =
    useState(false);
  const [showAddOutForDeliveryUpdate, setShowAddOutForDeliveryFormUpdate] =
    useState(false);
  const AddOutForDeliveryForm = () => {
    setShowAddOutForDeliveryForm(true);
    setShowAddOutForDelivery(false);
    setShowAddOutForDeliveryFormUpdate(false);
  };

  const AddOutForDeliveryUpdate = () => {
    setShowAddOutForDeliveryFormUpdate(true);
    setShowAddOutForDelivery(false);
    setShowAddOutForDeliveryForm(false);
  };

  const Back = () => {
    setShowAddOutForDelivery(true);
    setShowAddOutForDeliveryForm(false);
    setShowAddOutForDeliveryFormUpdate(false);
  };

  const [change, setChange] = useState("");
  const [open, setOpen] = useState(false);
  const [haid, setHaid] = useState(false);
  const objData = [
    {
      cource: "html",
    },
    {
      cource: "css",
    },
    {
      cource: "javascrit",
    },
    {
      cource: "react",
    },
    {
      cource: "angular",
    },
  ];
  const openDown = () => {
    setOpen(true);
  };
  const handleChange = (e) => {
    setChange(e.target.value);
    setHaid(false);
  };
  useEffect(() => {}, [change]);
  const handleSelect = (ele) => {
    console.log(ele, "11111");
    setChange(ele.cource);
    setOpen(false);
    setHaid(true);
  };
  const handleClear = () => {
    setChange("");
  };
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        transition={Zoom}
        delay={1000}
        limit={1}
      />
      <div className="text-danger">
        <h6>
          Transaction
          <FontAwesomeIcon
            icon={faSync}
            style={{ marginLeft: "4px", fontSize: "12px" }}
          />
        </h6>
      </div>
      <div>
        {path?.type == "ogminvert" ? (
          <div className="my-3">
            <div className="card w-100 my-3">
              <div class="card-body pt-0">
                <div className="row">
                  <h6 class="card-title    my-3 bg-light w-auto py-2 rounded ">
                    <FileText /> <b> OGM Inward</b>
                  </h6>
                </div>
                <div className="row my-2 mt-4">
                  <div className="col-md-3">
                    <label for="formFile" class="form-label">
                      C-Note Number
                    </label>
                    <textarea
                      type="text"
                      class="form-control form-control-md"
                    />
                  </div>
                  <div className="col-md-3 mt-4">
                    <div className="mt-2">
                      <button className="btn btn-danger rounded">
                        {" "}
                        Cancel
                      </button>
                      <button className="btn btn-success rounded mx-3">
                        {" "}
                        Get Details
                      </button>
                    </div>
                  </div>
                </div>
                <Table striped bordered hover  className="text-center">
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Username</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Mark</td>
                      <td>Otto</td>
                      <td>@mdo</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Jacob</td>
                      <td>Thornton</td>
                      <td>@fat</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Larry the Bird</td>
                      <td>@twitter</td>
                      <td>@twitter</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>Larry the Bird</td>
                      <td>@twitter</td>
                      <td>@twitter</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>Larry the Bird</td>
                      <td>@twitter</td>
                      <td>@twitter</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

      {path?.type == "ogmoutward" ? (
        <div className="my-3">
          <div className="card w-100 my-3">
            <div class="card-body pt-0">
              <div className="row">
                <h6 class="card-title    my-3 bg-light w-auto py-2 rounded ">
                  <FileText /> <b> OGM Outward</b>
                </h6>
              </div>
              <div className="row my-2 mt-4">
                <div className="col-md-3 ">
                  <label for="formFile" class="form-label">
                    C-Note Number
                  </label>
                  <textarea type="text" class="form-control form-control-md" />
                </div>
                <div className="col-md-3 ">
                  <label for="formFile" class="form-label">
                    City Name
                  </label>
                  <input
                    type="text"
                    onChange={(e) => handleOutwardCity(e.target.value)}
                    class="form-control form-control-md"
                  />
                </div>
              </div>
              <div className="row my-2 mt-4">
                <div className="col-md-12">
                  <button className="btn btn-danger rounded"> Cancel</button>
                  <button className="btn btn-success rounded mx-3">
                    Submit
                  </button>
                </div>
              </div>
              <div>
                <div>
                  <input
                    type="text"
                    value={haid === true ? change : ""}
                    onChange={(e) => handleChange(e)}
                    onClick={openDown}
                    disabled
                  />

                  <p
                    onClick={handleClear}
                    style={{
                      position: "absolute",
                      top: "4px",
                      right: "1360px",
                    }}
                  >
                    X
                  </p>
                </div>
                {open == true ? (
                  <div className="card p-1" style={{ width: "187px" }}>
                    <input
                      onChange={(e) => setChange(e.target.value)}
                      type="text"
                    />
                    {objData
                      .filter((val) => {
                        if (change == "") {
                          return val;
                        } else if (
                          val.cource
                            .toLowerCase()
                            .includes(change.toLocaleLowerCase())
                        ) {
                          return val;
                        }
                      })
                      .map((ele, ind) => {
                        return (
                          <p onClick={() => handleSelect(ele)}>{ele.cource}</p>
                        );
                      })}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <div>
        {path?.type == "outfor" ? (
          <>
            {showAddOutForDelivery && (
              <div className="my-3">
                <div className="card w-100">
                  <div class="card-body pt-0">
                    <div className="row">
                      <h6 class="card-title my-3 bg-light w-auto py-2 rounded ">
                        <FileText /> <b>Out For Delivery</b>
                      </h6>
                    </div>
                    <div className="container">
                      <div class="row justify-content-end">
                        <div class="col-3 text-end">
                          <button
                            className="btn btn-danger rounded "
                            onClick={AddOutForDeliveryForm}
                          >
                            Add Out For Delivery
                          </button>
                        </div>
                        <div class="col-3">
                          <button
                            className="btn btn-success rounded"
                            onClick={AddOutForDeliveryUpdate}
                          >
                            Add Out For Delivery with Update
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="row my-2">
                      <div className="col-md-3">
                        <label for="formFile" class="form-label">
                          From Date
                        </label>
                        <input
                          type="date"
                          class="form-control form-control-md"
                        />
                      </div>
                      <div className="col-md-3">
                        <label for="formFile" class="form-label">
                          To Date
                        </label>
                        <input
                          type="date"
                          class="form-control form-control-md"
                        />
                      </div>
                      <div className="col-md-3 ">
                        <label for="formFile" class="form-label">
                          DRS NO
                        </label>
                        <input
                          type="text"
                          class="form-control form-control-md"
                        />
                      </div>
                      <div className="col-md-3">
                        <label for="formFile" class="form-label">
                          Delivery Boy
                        </label>
                        <input
                          type="text"
                          class="form-control form-control-md"
                        />
                      </div>
                    </div>
                    <div className="row my-2">
                      <div className="col-md-3">
                        <Form.Label>Area</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          className="form-select-sm"
                        >
                          <option>Choose...</option>
                          <option value="1">Normal</option>
                          <option value="2">Prime</option>
                        </Form.Select>
                      </div>
                      <div className="col-md-3">
                        <Form.Label>DRS Status</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          className="form-select-sm"
                        >
                          <option>Choose...</option>
                          <option value="1">Normal</option>
                          <option value="2">Prime</option>
                        </Form.Select>
                      </div>
                      <div className="col-md-3">
                        <Form.Label>SCAN Status</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          className="form-select-sm"
                        >
                          <option>Choose...</option>
                          <option value="1">Normal</option>
                          <option value="2">Prime</option>
                        </Form.Select>
                      </div>
                      <div className="col-md-3">
                        <Form.Label>Branch</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          className="form-select-sm"
                        >
                          <option>Choose...</option>
                          <option value="1">Normal</option>
                          <option value="2">Prime</option>
                        </Form.Select>
                      </div>
                    </div>

                    <div className="row my-2 mt-4">
                      <div className="col-md-3">
                        <input type="checkbox" />
                        <label for="formFile" class="form-label">
                          CNote Wise
                        </label>
                      </div>
                      <div className="col-md-3">
                        <button
                          className="btn btn-success rounded mx-3"
                          onClick={handleFastBookingSave}
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {showAddOutForDeliveryForm && (
              <div className="my-3">
                <div className="card w-100">
                  <div class="card-body pt-0">
                    <div className="row">
                      <h6 class="card-title my-3 bg-light w-auto py-2 rounded ">
                        <FileText /> <b> Add Out For Delivery</b>
                      </h6>
                    </div>
                    <div className="container">
                      <div class="row justify-content-end">
                        <div class="col-3 text-end">
                          <button
                            className="btn btn-danger rounded "
                            onClick={Back}
                          >
                            Back
                          </button>
                        </div>
                        <div class="col-3 text-end">
                          <button
                            className="btn btn-danger rounded "
                            onClick={AddOutForDeliveryForm}
                          >
                            Add Out For Delivery
                          </button>
                        </div>
                        <div class="col-3">
                          <button
                            className="btn btn-success rounded"
                            onClick={AddOutForDeliveryUpdate}
                          >
                            Add Out For Delivery with Update
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="row my-2">
                      <div className="col-md-3">
                        <label for="formFile" class="form-label">
                          Date
                        </label>
                        <input
                          type="date"
                          class="form-control form-control-md"
                        />
                      </div>
                      <div className="col-md-3">
                        <label for="formFile" class="form-label">
                          Time
                        </label>
                        <input
                          type="time"
                          class="form-control form-control-md"
                        />
                      </div>
                      <div className="col-md-3 ">
                        <label for="formFile" class="form-label">
                          Delivery Boy
                        </label>
                        <input
                          type="text"
                          class="form-control form-control-md"
                        />
                      </div>
                      <div className="col-md-3">
                        <label for="formFile" class="form-label">
                          Area
                        </label>
                        <input
                          type="text"
                          class="form-control form-control-md"
                        />
                      </div>
                    </div>

                    <div className="row my-2">
                      <h6 class="card-title my-3 bg-light w-auto py-2 rounded ">
                        <FileText />
                        <b>
                          Choose File and Click on Import Document Inward to
                          Import Bulk Document Inward
                        </b>
                      </h6>
                    </div>

                    <div className="row my-2">
                      <div className="col-md-3">
                        <label for="formFile" class="form-label">
                          Choose file input
                        </label>
                        <input
                          class="form-control form-control-md"
                          type="file"
                          id="formFile"
                        />
                      </div>
                      <div className="col-md-3 text-center pt-4">
                        <a className="btn btn-success rounded">
                          Import Out For Delivery
                        </a>
                      </div>
                      <div className="col-md-3 text-center pt-4">
                        <a class="btn btn-danger rounded">
                          Dowload Upload Sample File
                        </a>
                      </div>
                    </div>

                    <div className="row my-2">
                      <div className="col-md-3">
                        <label for="formFile" class="form-label">
                          C-Note No
                        </label>
                        <input
                          type="text"
                          class="form-control form-control-md"
                        />
                      </div>
                      <div className="col-md-3">
                        <label for="formFile" class="form-label">
                          WT.(Kgs)
                        </label>
                        <input
                          type="text"
                          class="form-control form-control-md"
                        />
                      </div>
                      <div className="col-md-3 ">
                        <label for="formFile" class="form-label">
                          WT.(Gms)
                        </label>
                        <input
                          type="text"
                          class="form-control form-control-md"
                        />
                      </div>
                      <div className="col-md-3">
                        <label for="formFile" class="form-label">
                          No.Pcs
                        </label>
                        <input
                          type="text"
                          class="form-control form-control-md"
                        />
                      </div>
                    </div>
                    <div className="row my-2">
                      <div className="col-md-3">
                        <label for="formFile" class="form-label">
                          Receiver Name
                        </label>
                        <input
                          type="text"
                          class="form-control form-control-md"
                        />
                      </div>
                      <div className="col-md-3">
                        <label for="formFile" class="form-label">
                          Receiver Mobile
                        </label>
                        <input
                          type="text"
                          class="form-control form-control-md"
                        />
                      </div>
                      <div className="col-md-3 ">
                        <label for="formFile" class="form-label">
                          Remarks
                        </label>
                        <input
                          type="text"
                          class="form-control form-control-md"
                        />
                      </div>
                    </div>
                    <div className="row my-2 mt-4 text-center">
                      <div className="col-md-12">
                        <button className="btn btn-success rounded mx-3">
                          {" "}
                          Add Out For Delivery
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {showAddOutForDeliveryUpdate && (
              <div className="my-3">
                <div className="card w-100">
                  <div class="card-body pt-0">
                    <div className="row">
                      <h6 class="card-title my-3 bg-light w-auto py-2 rounded ">
                        <FileText /> <b> Update Out For Delivery</b>
                      </h6>
                    </div>
                    <div className="container">
                      <div class="row justify-content-end">
                        <div class="col-3 text-end">
                          <button
                            className="btn btn-danger rounded "
                            onClick={Back}
                          >
                            Back
                          </button>
                        </div>
                        <div class="col-3 text-end">
                          <button
                            className="btn btn-danger rounded "
                            onClick={AddOutForDeliveryForm}
                          >
                            Add Out For Delivery
                          </button>
                        </div>
                        <div class="col-3">
                          <button
                            className="btn btn-success rounded"
                            onClick={AddOutForDeliveryUpdate}
                          >
                            Add Out For Delivery with Update
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="row my-2">
                      <div className="col-md-3">
                        <label for="formFile" class="form-label">
                          Date
                        </label>
                        <input
                          type="date"
                          class="form-control form-control-md"
                        />
                      </div>
                      <div className="col-md-3">
                        <label for="formFile" class="form-label">
                          Time
                        </label>
                        <input
                          type="time"
                          class="form-control form-control-md"
                        />
                      </div>
                      <div className="col-md-3 ">
                        <label for="formFile" class="form-label">
                          Delivery Boy
                        </label>
                        <input
                          type="text"
                          class="form-control form-control-md"
                        />
                      </div>
                      <div className="col-md-3">
                        <label for="formFile" class="form-label">
                          Area
                        </label>
                        <input
                          type="text"
                          class="form-control form-control-md"
                        />
                      </div>
                    </div>

                    <div className="row my-2">
                      <h6 class="card-title my-3 bg-light w-auto py-2 rounded ">
                        <FileText />
                        <b>
                          Choose File and Click on Import Document Inward to
                          Import Bulk Document Inward
                        </b>
                      </h6>
                    </div>

                    <div className="row my-2">
                      <div className="col-md-3">
                        <label for="formFile" class="form-label">
                          Choose file input
                        </label>
                        <input
                          class="form-control form-control-md"
                          type="file"
                          id="formFile"
                        />
                      </div>
                      <div className="col-md-3 text-center pt-4">
                        <a className="btn btn-success rounded">
                          Import Out For Delivery
                        </a>
                      </div>
                      <div className="col-md-3 text-center pt-4">
                        <a class="btn btn-danger rounded">
                          Dowload Upload Sample File
                        </a>
                      </div>
                    </div>

                    <div className="row my-2">
                      <div className="col-md-3">
                        <label for="formFile" class="form-label">
                          C-Note No
                        </label>
                        <input
                          type="text"
                          class="form-control form-control-md"
                        />
                      </div>
                      <div className="col-md-3">
                        <label for="formFile" class="form-label">
                          WT.(Kgs)
                        </label>
                        <input
                          type="text"
                          class="form-control form-control-md"
                        />
                      </div>
                      <div className="col-md-3 ">
                        <label for="formFile" class="form-label">
                          WT.(Gms)
                        </label>
                        <input
                          type="text"
                          class="form-control form-control-md"
                        />
                      </div>
                      <div className="col-md-3">
                        <label for="formFile" class="form-label">
                          No.Pcs
                        </label>
                        <input
                          type="text"
                          class="form-control form-control-md"
                        />
                      </div>
                    </div>
                    <div className="row my-2">
                      <div className="col-md-3">
                        <label for="formFile" class="form-label">
                          Receiver Name
                        </label>
                        <input
                          type="text"
                          class="form-control form-control-md"
                        />
                      </div>
                      <div className="col-md-3">
                        <label for="formFile" class="form-label">
                          Receiver Mobile
                        </label>
                        <input
                          type="text"
                          class="form-control form-control-md"
                        />
                      </div>
                      <div className="col-md-3 ">
                        <label for="formFile" class="form-label">
                          Remarks
                        </label>
                        <input
                          type="text"
                          class="form-control form-control-md"
                        />
                      </div>
                    </div>
                    <div className="row my-2 mt-4 text-center">
                      <div className="col-md-12">
                        <button className="btn btn-success rounded mx-3">
                          {" "}
                          Add Out For Delivery
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          ""
        )}
        {path?.type == "returntoorigin" ? (
          <div className="my-3">
            <div className="card w-100">
              <div class="card-body pt-0">
                <div className="row ">
                  <h6 class="card-title my-3 bg-light w-auto py-2 rounded ">
                    <FileText /> <b>Return To Origin</b>
                  </h6>
                </div>
                <div className="row"></div>
                <div className="row my-2">
                  <div className="col-md-3">
                    <Form.Label>Type</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      className="form-select-sm"
                    >
                      <option>Choose...</option>
                      <option value="1">Normal</option>
                      <option value="2">Prime</option>
                    </Form.Select>
                  </div>

                  <div className="col-md-3 ">
                    <label for="formFile" class="form-label">
                      Ref.No
                    </label>
                    <input type="text" class="form-control form-control-md" />
                  </div>
                  <div className="col-md-3">
                    <Form.Label>Forward To</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      className="form-select-sm"
                    >
                      <option>Bhopal</option>
                      <option value="1">JETLIN</option>
                      <option value="2">Prime</option>
                    </Form.Select>
                  </div>
                  <div className="col-md-3 ">
                    <label for="formFile" class="form-label">
                      Agent/Delivery Boy
                    </label>
                    <input type="text" class="form-control form-control-md" />
                  </div>
                </div>
                <div className="row my-2 mt-4">
                  <div className="col-md-3">
                    <label for="formFile" class="form-label">
                      Date
                    </label>
                    <input type="date" class="form-control form-control-md" />
                  </div>
                  <div className="col-md-3">
                    <label for="formFile" class="form-label">
                      Time
                    </label>
                    <input type="time" class="form-control form-control-md" />
                  </div>
                  <div className="col-md-3">
                    <label for="formFile" class="form-label">
                      CN No
                    </label>
                    <input type="date" class="form-control form-control-md" />
                  </div>
                  <div className="col-md-3 ">
                    <Form.Label>Reason</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      className="form-select-sm"
                    >
                      <option>Forward To</option>
                      <option value="1">JETLIN</option>
                      <option value="2">Prime</option>
                    </Form.Select>
                  </div>
                </div>
                <div className="row my-2 mt-4">
                  {" "}
                  <div className="col-md-3 ">
                    <label for="formFile" class="form-label">
                      No.Pcs
                    </label>
                    <input type="text" class="form-control form-control-md" />
                  </div>
                  <div className="col-md-3 ">
                    <label for="formFile" class="form-label">
                      Remarks
                    </label>
                    <textarea
                      type="text"
                      class="form-control form-control-md"
                    />
                  </div>
                </div>
                <div className="row text-center">
                  <div className="row my-2 mt-4 ">
                    <div className="col-md-12">
                      <button className="btn btn-danger rounded "> Add</button>
                      <button
                        className="btn btn-success rounded mx-3"
                        onClick={handleFastBookingSave}
                      >
                        Clear List
                      </button>
                    </div>
                  </div>
                </div>
                {
                  //  <div className="row" >
                  //   <div className="col-md-3 ">
                  //     <label for="formFile" class="form-label">
                  //       Remarks
                  //     </label>
                  //     <textarea type="text" class="form-control form-control-md" />
                  //   </div>
                  //   <div className="row my-2 mt-4">
                  //     <div className="col-md-12">
                  //       <button className="btn btn-danger rounded">
                  //         {" "}
                  //         Cancel
                  //       </button>
                  //       <button className="btn btn-success rounded mx-3" onClick={handleFastBookingSave} > Save</button>
                  //     </div>
                  //   </div>
                  // </div>
                }
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {path?.type == "booking" ? (
          // <Card className="shadow rounded p-2 w-100">
          //   <Card
          //     className="shadow rounded p-2 w-100 mb-2"
          //     style={{ backgroundColor: "#f5f5f5" }}
          //   >
          //     <h6>Booking</h6>
          //   </Card>
          //   {
          //     //  <Row md={12}  >
          //     //   <Col md={3} className="shadow rounded p-2 w-100" >
          //     //     <Form.Group controlId="formFile" className="mb-3">
          //     //       <Form.Control size="sm" type="file" />
          //     //     </Form.Group>
          //     //   </Col>
          //     //   <Col md={3} >
          //     //     <Button className="btn btn-success"  >Import Booking</Button>
          //     //   </Col>
          //     //   <Col md={3} >
          //     //     <p style={{ color: 'red' }} >Download Upload Sample File</p>
          //     //   </Col>
          //     // </Row>
          //   }

          //   <Row md={12}>
          //     <Col md={3}>
          //       <Form.Group className="mb-3">
          //         <Form.Label>Booking Type</Form.Label>
          //         <Form.Select size="sm">
          //           <Form.Control value="moxd" size="sm" />
          //           <option>html</option>
          //           <option>css</option>
          //           <option>javascrrit</option>
          //         </Form.Select>
          //       </Form.Group>
          //     </Col>
          //     <Col md={3}>
          //       <Form.Group className="mb-3">
          //         <Form.Label>Select Ass.Counter</Form.Label>
          //         <Form.Select size="sm">
          //           <Form.Control value="moxd" size="sm" />
          //           <option>html</option>
          //           <option>css</option>
          //           <option>javascrrit</option>
          //         </Form.Select>
          //       </Form.Group>
          //     </Col>
          //     <Col md={3}>
          //       <Form.Group className="mb-3">
          //         <Form.Label>Date</Form.Label>
          //         <Form.Control type="date" size="sm" />
          //       </Form.Group>
          //     </Col>
          //     <Col md={3}>
          //       <Form.Group className="mb-3">
          //         <Form.Label>Time</Form.Label>
          //         <Form.Control type="time" size="sm" />
          //       </Form.Group>
          //     </Col>
          //   </Row>
          //   <Row>
          //     <Col>
          //       <Form.Group className="mb-3">
          //         <Form.Label>Type</Form.Label>
          //         <Form.Select size="sm">
          //           <option>html</option>
          //           <option>css</option>
          //           <option>javascrrit</option>
          //         </Form.Select>
          //       </Form.Group>
          //     </Col>
          //     <Col>
          //       <Form.Group className="mb-3">
          //         <Form.Label>CN No</Form.Label>
          //         <Form.Control type="text" size="sm" />
          //       </Form.Group>
          //     </Col>
          //     <Col>
          //       <Form.Group className="mb-3">
          //         <Form.Label>Ref. No.</Form.Label>
          //         <Form.Control type="text" size="sm" />
          //       </Form.Group>
          //     </Col>
          //     <Col>
          //       <Form.Group className="mb-3">
          //         <Form.Label>Pay Mode</Form.Label>
          //         <Form.Select size="sm">
          //           <option>html</option>
          //           <option>css</option>
          //           <option>javascrrit</option>
          //         </Form.Select>
          //       </Form.Group>
          //     </Col>
          //     <Col>
          //       <Form.Group className="mb-3">
          //         <Form.Label>Booking Mode</Form.Label>
          //         <Form.Select size="sm">
          //           <option>html</option>
          //           <option>css</option>
          //           <option>javascrrit</option>
          //         </Form.Select>
          //       </Form.Group>
          //     </Col>
          //     <Col>
          //       <Form.Group className="mb-3">
          //         <Form.Label>Sub-Branch/Franchise</Form.Label>
          //         <Form.Select size="sm">
          //           <option>html</option>
          //           <option>css</option>
          //           <option>javascrrit</option>
          //         </Form.Select>
          //       </Form.Group>
          //     </Col>
          //   </Row>
          //   <Row md={12}>
          //     <Col md={6}>
          //       <Form.Group className="mb-3">
          //         <Form.Label>Consignor Name</Form.Label>
          //         <Form.Control type="text" size="sm" />
          //       </Form.Group>
          //     </Col>
          //     <Col md={6}>
          //       <Form.Group className="mb-3">
          //         <Form.Label>Consignee Name</Form.Label>
          //         <Form.Control type="text" size="sm" />
          //       </Form.Group>
          //     </Col>
          //   </Row>
          //   <Row>
          //     <Col md={3}>
          //       <Form.Group className="mb-3">
          //         <Form.Label>Address</Form.Label>
          //         <Form.Control type="text" size="sm" />
          //       </Form.Group>
          //     </Col>
          //     <Col md={3}>
          //       <Form.Group className="mb-3">
          //         <Form.Label>Mobile No</Form.Label>
          //         <Form.Control type="text" size="sm" />
          //       </Form.Group>
          //     </Col>
          //     <Col md={3}>
          //       <Form.Group className="mb-3">
          //         <Form.Label>Address</Form.Label>
          //         <Form.Control type="text" size="sm" />
          //       </Form.Group>
          //     </Col>
          //     <Col md={3}>
          //       <Form.Group className="mb-3">
          //         <Form.Label>Mobile No</Form.Label>
          //         <Form.Control type="text" size="sm" />
          //       </Form.Group>
          //     </Col>
          //   </Row>
          //   <Row>
          //     <Col md={3}>
          //       <Form.Group className="mb-3">
          //         <Form.Label>GST No</Form.Label>
          //         <Form.Control type="text" size="sm" />
          //       </Form.Group>
          //     </Col>
          //     <Col md={3}>
          //       <Form.Group className="mb-3">
          //         <Form.Label>Select Destination</Form.Label>
          //         <Form.Select size="sm">
          //           <option>html</option>
          //           <option>css</option>
          //           <option>javascrrit</option>
          //         </Form.Select>
          //       </Form.Group>
          //     </Col>
          //     <Col md={3}>
          //       <Form.Group className="mb-3">
          //         <Form.Label>Pincode</Form.Label>
          //         <Form.Control type="text" size="sm" />
          //       </Form.Group>
          //     </Col>
          //     <Col md={3}>
          //       <Form.Group className="mb-3">
          //         <Form.Label>Select Area</Form.Label>
          //         <Form.Select size="sm">
          //           <option>html</option>
          //           <option>css</option>
          //           <option>javascrrit</option>
          //         </Form.Select>
          //       </Form.Group>
          //     </Col>
          //   </Row>
          //   <Row>
          //     <Col md={2}>
          //       <Form.Group className="mb-3">
          //         <Form.Label>No Pcs.</Form.Label>
          //         <Form.Control type="text" size="sm" />
          //       </Form.Group>
          //     </Col>
          //     <Col md={2}>
          //       <Form.Group className="mb-3">
          //         <Form.Label>Weight(kg)</Form.Label>
          //         <Form.Control type="text" size="sm" />
          //       </Form.Group>
          //     </Col>
          //     <Col md={2}>
          //       <Form.Group className="mb-3">
          //         <Form.Label>Weight(Gms)</Form.Label>
          //         <Form.Control type="text" size="sm" />
          //       </Form.Group>
          //     </Col>
          //     <Col md={2}>
          //       <Form.Group className="mb-3">
          //         <Form.Label>Ch Weight</Form.Label>
          //         <Form.Control type="text" size="sm" />
          //       </Form.Group>
          //     </Col>
          //     <Col md={2}>
          //       <Form.Group className="mb-3">
          //         <Form.Control type="checkbox" size="sm" />
          //         <Form.Label>Apply GST</Form.Label>
          //       </Form.Group>
          //     </Col>
          //     <Col md={2}>
          //       <Form.Group className="mb-3">
          //         <Form.Label>Declared Value</Form.Label>
          //         <Form.Control type="text" size="sm" />
          //       </Form.Group>
          //     </Col>
          //   </Row>
          //   <Row>
          //     <Button>Save</Button>
          //     <Button>Cancel</Button>
          //   </Row>
          // </Card>

          <div className="my-3">
            <div className="card w-100">
              <div class="card-body pt-0">
                <div className="row ">
                  <h6 class="card-title    my-3 bg-light w-auto py-2 rounded ">
                    <FileText /> <b> Booking</b>
                  </h6>
                </div>
                <div className="row"></div>
                <div className="row my-2">
                  <div className="col-md-3">
                    <Form.Label>Booking Type</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      className="form-select-sm"
                    >
                      <option>Choose...</option>
                      <option value="1">Normal</option>
                      <option value="2">Prime</option>
                    </Form.Select>
                  </div>
                  <div className="col-md-3">
                    <Form.Label>Select Ass.Courier</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      className="form-select-sm"
                    >
                      <option>Choose...</option>
                      <option value="1">JETLIN</option>
                      <option value="2">Prime</option>
                    </Form.Select>
                  </div>
                  <div className="col-md-3 ">
                    <label for="formFile" class="form-label">
                      Date
                    </label>
                    <input type="date" class="form-control form-control-md" />
                  </div>
                  <div className="col-md-3 ">
                    <label for="formFile" class="form-label">
                      Time
                    </label>
                    <input type="time" class="form-control form-control-md" />
                  </div>
                </div>
                <div className="row my-2 mt-4">
                  <div className="col-md-3 ">
                    <Form.Label>Type</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      className="form-select-sm"
                    >
                      <option>Choose...</option>
                      <option value="1">JETLIN</option>
                      <option value="2">Prime</option>
                    </Form.Select>
                  </div>
                  <div className="col-md-3">
                    <label for="formFile" class="form-label">
                      CN No
                    </label>
                    <input type="text" class="form-control form-control-md" />
                  </div>
                  <div className="col-md-3">
                    <label for="formFile" class="form-label">
                      Ref. No.
                    </label>
                    <input type="text" class="form-control form-control-md" />
                  </div>
                  <div className="col-md-3 ">
                    <Form.Label>Pay Mode</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      className="form-select-sm"
                    >
                      <option>Select Pay Mode</option>
                      <option value="1">Parcel</option>
                      <option value="2">Document</option>
                      <option value="3">Three</option>
                    </Form.Select>
                  </div>{" "}
                </div>
                <div className="row my-2 mt-4">
                  <div className="col-md-3 ">
                    <Form.Label>Booking Mode</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      className="form-select-sm"
                    >
                      <option>Choose...</option>
                      <option value="1">Air</option>
                      <option value="2">Document</option>
                      <option value="3">Three</option>
                    </Form.Select>
                  </div>
                  <div className="col-md-3 ">
                    <Form.Label>Sub-Branch/Franchise</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      className="form-select-sm"
                    >
                      <option>Choose...</option>
                      <option value="1">Outer Banglore</option>
                      <option value="2">Document</option>
                      <option value="3">Three</option>
                    </Form.Select>
                  </div>{" "}
                  <div className="col-md-3 ">
                    <label for="formFile" class="form-label">
                      Consignor Name
                    </label>
                    <input type="text" class="form-control form-control-md" />
                  </div>
                  <div className="col-md-3 ">
                    <label for="formFile" class="form-label">
                      Address
                    </label>
                    <input type="text" class="form-control form-control-md" />
                  </div>
                </div>
                <div className="row">
                  {" "}
                  <div className="col-md-3 ">
                    <label for="formFile" class="form-label">
                      Mobile No
                    </label>
                    <input type="text" class="form-control form-control-md" />
                  </div>
                  <div className="col-md-3 ">
                    <label for="formFile" class="form-label">
                      Address
                    </label>
                    <input type="text" class="form-control form-control-md" />
                  </div>{" "}
                  <div className="col-md-3 ">
                    <label for="formFile" class="form-label">
                      Ch. Weight
                    </label>
                    <input type="text" class="form-control form-control-md" />
                  </div>
                </div>
                <div className="row my-2 mt-4">
                  <div className="col-md-3 ">
                    <label for="formFile" class="form-label">
                      Mobile No
                    </label>
                    <input type="text" class="form-control form-control-md" />
                  </div>
                  <div className="col-md-3 ">
                    <label for="formFile" class="form-label">
                      GST No
                    </label>
                    <input type="text" class="form-control form-control-md" />
                  </div>
                  <div className="col-md-3 ">
                    <Form.Label>Select Destination</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      className="form-select-sm"
                    >
                      <option>Select Destination</option>
                      <option value="1">Parcel</option>
                      <option value="2">Document</option>
                      <option value="3">Three</option>
                    </Form.Select>
                  </div>
                  <div className="col-md-3 ">
                    <label for="formFile" class="form-label">
                      Pincode
                    </label>
                    <input type="text" class="form-control form-control-md" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3 ">
                    <Form.Label>Select Area</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      className="form-select-sm"
                    >
                      <option>Select Area</option>
                      <option value="1">Parcel</option>
                      <option value="2">Document</option>
                      <option value="3">Three</option>
                    </Form.Select>
                  </div>
                  <div className="col-md-3 ">
                    <label for="formFile" class="form-label">
                      No. Pcs.
                    </label>
                    <input type="text" class="form-control form-control-md" />
                  </div>
                  <div className="col-md-3 ">
                    <label for="formFile" class="form-label">
                      Weight (Kgs)
                    </label>
                    <input type="text" class="form-control form-control-md" />
                  </div>
                  <div className="col-md-3 ">
                    <label for="formFile" class="form-label">
                      Weight (Gms)
                    </label>
                    <input type="text" class="form-control form-control-md" />
                  </div>
                </div>
                <div className="row">
                  {" "}
                  <div className="col-md-3 ">
                    <label for="formFile" class="form-label">
                      Declared Value
                    </label>
                    <input type="text" class="form-control form-control-md" />
                  </div>
                  <div className="col-md-3 ">
                    <label for="formFile" class="form-label">
                      Content
                    </label>
                    <input type="text" class="form-control form-control-md" />
                  </div>
                  <div className="col-md-3 ">
                    <label for="formFile" class="form-label">
                      To Pay Amount
                    </label>
                    <input type="text" class="form-control form-control-md" />
                  </div>
                  <div className="col-md-3 ">
                    <label for="formFile" class="form-label">
                      Remarks
                    </label>
                    <input type="text" class="form-control form-control-md" />
                  </div>
                </div>
                <div className="row my-2 mt-4 text-center">
                  <div className="col-md-6">
                    <button className="btn btn-danger rounded mx-3">
                      {" "}
                      Cancel
                    </button>
                    <button className="btn btn-success rounded"> Save</button>
                  </div>
                  <div className="col-md-6">
                    <button className="btn btn-danger rounded mx-3">
                      {" "}
                      Print
                    </button>
                    <button className="btn btn-success rounded"> Edit</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {path?.type == "fastbooking" ? (
          <>
            <div className="my-3">
              <div className="card w-100 my-3">
                <div class="card-body pt-0">
                  <div className="row">
                    <h6 class="card-title    my-3 bg-light w-auto py-2 rounded ">
                      <FileText /> <b> Import Fast Booking</b>
                    </h6>
                  </div>
                  <div className="row my-1">
                    <div className="col-md-3">
                      <Form.Label>Type</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        className="form-select-sm"
                      >
                        <option>select menu</option>
                        <option value="1">Parcel</option>
                        <option value="2">Document</option>
                        <option value="3">Three</option>
                      </Form.Select>
                    </div>
                    <div className="col-md-3">
                      <label for="formFile" class="form-label">
                        Choose file input
                      </label>
                      <input
                        class="form-control form-control-md"
                        type="file"
                        id="formFile"
                      />
                    </div>
                    <div className="col-md-3 text-center pt-4">
                      <a className="btn btn-success rounded">Import Booking</a>
                    </div>
                    <div className="col-md-3 text-center pt-4">
                      {
                        //  <a class="btn btn-danger rounded">
                        //   Dowload Upload Sample File
                        // </a>
                        <ReactHTMLTableToExcel
                          id="test-table-xls-button"
                          className="download-table-xls-button btn btn-danger rounded mb-3"
                          table="table-to-xls"
                          filename="tablexls"
                          sheet="tablexls"
                          buttonText="Dowload Upload Sample File"
                        />
                      }
                      <div className="d-none">
                        <table className="table text-center" id="table-to-xls">
                          <thead className="thead-dark">
                            <tr>
                              <th>Date</th>
                              <th>Type</th>
                              <th>Awb No.</th>
                              <th>Ref No</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              // result.apiData?.data?.data?.getBlogPostsV2?.map((ele, ind) => {
                              //   return (
                              //     <tr>
                              //       <td>{JSON.parse(ele.content).title}</td>
                              //       <td>{ele.ID}</td>
                              //       <td></td>
                              //       <td></td>
                              //     </tr>
                              //   )
                              // }
                              // )
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card w-100">
                <div class="card-body pt-0">
                  <div className="row ">
                    <h6 class="card-title    my-3 bg-light w-auto py-2 rounded ">
                      <FileText /> <b> Fast Booking</b>
                    </h6>
                  </div>
                  <div className="row"></div>
                  <div className="row my-2">
                    <div className="col-md-3">
                      <Form.Label>Select Order Term</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        className="form-select-sm"
                      >
                        <option>Choose...</option>
                        <option value="1">Deliver Now</option>
                        <option value="2">Deliver Letter</option>
                      </Form.Select>
                    </div>
                    <div className="col-md-3">
                      <label for="formFile" class="form-label">
                        Date
                      </label>
                      <input
                        type="date"
                        value={date}
                        onChange={(e) => setFastBookingDate(e.target.value)}
                        class="form-control form-control-md"
                      />
                    </div>
                    <div className="col-md-3 ">
                      <label for="formFile" class="form-label">
                        Time
                      </label>
                      <input
                        type="time"
                        value={time}
                        onChange={(e) => setFastBookingTime(e.target.value)}
                        class="form-control form-control-md"
                      />
                    </div>
                    <div className="col-md-3 ">
                      {" "}
                      <Form.Label>Weight Package</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        className="form-select-sm"
                      >
                        <option>Select Form Type</option>
                        <option value="1">Parcel</option>
                        <option value="2">Document</option>
                        <option value="3">Three</option>
                      </Form.Select>
                    </div>
                  </div>
                  <div className="row my-2 mt-4">
                    <div className="col-md-3 ">
                      <label for="formFile" class="form-label">
                        Ref. No.
                      </label>
                      <input type="text" class="form-control form-control-md" />
                    </div>
                    <div className="col-md-3">
                      <label for="formFile" class="form-label">
                        C-Note No
                      </label>
                      <input type="text" class="form-control form-control-md" />
                    </div>
                    <div className="col-md-3">
                      <label for="formFile" class="form-label">
                        Mobile Number
                      </label>
                      <input type="text" class="form-control form-control-md" />
                    </div>
                    <div className="col-md-3 ">
                      <Form.Label>Select State</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        className="form-select-sm"
                      >
                        <option>Select State</option>
                        <option value="1">Parcel</option>
                        <option value="2">Document</option>
                        <option value="3">Three</option>
                      </Form.Select>
                    </div>{" "}
                  </div>
                  <div className="row my-2 mt-4">
                    <div className="col-md-3 ">
                      <label for="formFile" class="form-label">
                        Consignee
                      </label>
                      <input type="text" class="form-control form-control-md" />
                    </div>
                    <div className="col-md-3 ">
                      <label for="formFile" class="form-label">
                        Remarks
                      </label>
                      <input type="text" class="form-control form-control-md" />
                    </div>{" "}
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-3 ">
                          <label for="formFile" class="form-label">
                            No. Pcs.
                          </label>
                          <input
                            type="text"
                            class="form-control form-control-md"
                          />
                        </div>
                        <div className="col-md-3 ">
                          <label for="formFile" class="form-label">
                            Wt. (Kgs)
                          </label>
                          <input
                            type="text"
                            class="form-control form-control-md"
                          />
                        </div>
                        <div className="col-md-3 ">
                          <label for="formFile" class="form-label">
                            Wt. (Gms)
                          </label>
                          <input
                            type="text"
                            class="form-control form-control-md"
                          />
                        </div>
                        <div className="col-md-3 ">
                          <label for="formFile" class="form-label">
                            Ch. Wt
                          </label>
                          <input
                            type="text"
                            class="form-control form-control-md"
                          />
                        </div>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="row my-2 mt-4">
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-6 ">
                          <label for="formFile" class="form-label">
                            Select From Party
                          </label>
                          <input
                            type="text"
                            class="form-control form-control-md"
                          />
                        </div>
                        <div className="col-md-6 ">
                          <Form.Label>Select Destination</Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            className="form-select-sm"
                          >
                            <option>Select Destination</option>
                            <option value="1">Parcel</option>
                            <option value="2">Document</option>
                            <option value="3">Three</option>
                          </Form.Select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row my-2 mt-4 text-center">
                    <div className="col-md-12">
                      <button className="btn btn-danger rounded mx-3">
                        {" "}
                        Cancel
                      </button>
                      <button
                        className="btn btn-success rounded"
                        onClick={handleFastBookingSave}
                      >
                        {" "}
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <Card className="shadow rounded p-2 w-100">
              <Card
                className="shadow rounded p-2 w-100 mb-2"
                style={{ backgroundColor: "#f5f5f5" }}
              >
                <h6>Import Fast Booking</h6>
              </Card>
              <Row md={12}>
                <Col md={2}>
                  <Form.Group>
                    <Form.Label>Type</Form.Label>
                    <Form.Select>
                      <option></option>
                      <option></option>
                      <option></option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={2}>
                  <Form.Group>
                    <Form.Label>Ref.No</Form.Label>
                    <Form.Control type="text" />
                  </Form.Group>
                </Col>

                <Col md={2}>
                  <Form.Group>
                    <Form.Label>Forward To</Form.Label>
                    <Form.Select>
                      <option></option>
                      <option></option>
                      <option></option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={2}>
                  <Form.Group>
                    <Form.Label>Agent/Delivery Boy</Form.Label>
                    <Form.Control />
                  </Form.Group>
                </Col>
                <Col md={2}>
                  <Form.Group>
                    <Form.Label>Date</Form.Label>
                    <Form.Control type="date" />
                  </Form.Group>
                </Col>
                <Col md={2}>
                  <Form.Group>
                    <Form.Label>Date</Form.Label>
                    <Form.Control type="time" />
                  </Form.Group>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col md={2}>
                  <Form.Group>
                    <Form.Label>CNote No</Form.Label>
                    <Form.Control type="text" />
                  </Form.Group>
                </Col>
                <Col md={2}>
                  <Form.Group>
                    <Form.Label>Reason</Form.Label>
                    <Form.Select>
                      <option></option>
                      <option></option>
                      <option></option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={2}>
                  <Form.Group>
                    <Form.Label>No.Pcs</Form.Label>
                    <Form.Control type="text" />
                  </Form.Group>
                </Col>
                <Col>
                  <Button>Add</Button>
                  <Button>Clear List</Button>
                </Col>
              </Row>
              <Row md={12}>
                <Col md={2}>
                  <Form.Group>
                    <Form.Label>Remarks</Form.Label>
                    <Form.Control type="textarea"></Form.Control>
                  </Form.Group>
                </Col>
              </Row>
            </Card> */}
          </>
        ) : (
          ""
        )}
      </div>
      {/* <Card className=" rounded p-2 w-100">
        <div className="master">
          <Row>
            <Col md={6}>
              <div className="leftedge d-flex justify-content-space">
                <Form.Select
                  aria-label="Default select example"
                  size={"sm"}
                  className="selectsizesmall"
                  onChange={(e) => handlemenuchange(e)}
                >
                  <option value="1">All Booking</option>
                  <option value="2">Transaction</option>
                </Form.Select>
                <Form.Select
                  aria-label="Default select example"
                  size={"sm"}
                  className="selectsizesmall"
                >
                  <option value="1">All</option>
                  <option value="2">A</option>
                </Form.Select>
              </div>
            </Col>
            <Col md={6}>
              <div className="rightedge d-flex">
                <div className="wrapper d-flex">
                  <input type="text" />
                  <FontAwesomeIcon
                    icon={faSearch}
                    style={{ marginLeft: "122px" }}
                  />
                </div>
                <Form.Select
                  aria-label="Default select example"
                  size={"sm"}
                  className="selectsizesmall"
                >
                  <option>Open this select menu</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
        <hr />
        {choice == 1 ? <Allbooking /> : ""}
        {choice == 2 ? <Transaction /> : ""}

        <div className="more-link">
          <h6>Load more</h6>
        </div>
      </Card> 
      */}
    </>
  );
};

export default Transactionmaster;
