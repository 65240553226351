import React, { useEffect, useRef, useState } from "react";
import { Form, Spinner, Table } from "react-bootstrap";
import { FileText } from "react-bootstrap-icons";
import Apiservice from "../../Services/Apiservice";
import { AgGridReact } from "ag-grid-react";
import { toast, ToastContainer, Zoom } from "react-toastify";
import { useReactToPrint } from "react-to-print";
import Loader from "react-spinner-loader";



function DeliveryReport() {
  const [rowData, setRowData] = useState()
  const [dateOne, setDate] = useState("");
  const [cNoteFilterFromTo, setCNoteFilterFromTo] = useState({ from: "", to: "" })
  const [dateFieldErrorFrom, setDateFieldErrorFrom] = useState('')
  const [dateFieldErrorTo, setDateFieldErrorTo] = useState('')
  const [userData, setUserData] = useState();
  const [subDateLoader, setSubDateLoader] = useState(false)
  const [drsNumberReport, setDrsNumberReport] = useState([])
  const [screenLoader, setScreenLoader] = useState(false)


  const main = {
    width: "100%",
    marginRight: "auto",
    marginLeft: "8px",
  };
  const bigEle = {
    border: "2px solid black",
  };
  const componentRef = useRef();
  const handelprint = useReactToPrint({ content: () => componentRef.current });

  useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem("userDetails")));
  }, []);


  useEffect(async () => {
    if (userData?.Hub_type == 'NULL') {
      const outwardApi = await Apiservice.postdata("/admin/courier_delivery_list");
      if (outwardApi.error == false) {
        setRowData(outwardApi.data);
      } else {
        console.log("API is not working");
      }
    }

    if (userData?.Hub_type != 'NULL') {
      const data = JSON.parse(localStorage.getItem("userDetails"))
      const payload = {
        frenchise_id: data.id,
      }
      const outwardApi = await Apiservice.postdata("/admin/courier_delivery_list", payload);
      if (outwardApi.error == false) {
        setRowData(outwardApi.data);
      } else {
        console.log("API is not working");
      }
    }



  }, []);

  useEffect(() => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    const todayDate = yyyy + "-" + mm + "-" + dd;

    setCNoteFilterFromTo({
      ...cNoteFilterFromTo,
      from: todayDate,
      to: todayDate
    })



    // var d = new Date();
    // d.getHours(); // => 9
    // d.getMinutes(); // =>  30
    // d.getSeconds();

    // var hh = d.getHours();
    // var mm = d.getMinutes();

    // const todayTime = hh + ":" + mm;
    // setTime(todayTime);
  }, []);


  const onchangedate = (event) => {
    setCNoteFilterFromTo({ ...cNoteFilterFromTo, [event.target.name]: event.target.value })
  }
  const handelFromToSubmit = async (e) => {
    e.preventDefault()

    if (userData?.Hub_type == 'NULL') {
      if (cNoteFilterFromTo.from != '' && cNoteFilterFromTo.to != '') {
        const payload = {
          dateFrom: cNoteFilterFromTo.from == '' ? dateOne : cNoteFilterFromTo.from,
          dateTo: cNoteFilterFromTo.to == '' ? dateOne : cNoteFilterFromTo.to
        }
        setSubDateLoader(true)
        const cnoteData = await Apiservice.postdata("/admin/courier_delivery_list", payload)
        if (cnoteData.error == false && cnoteData.data.length != 0) {
          setSubDateLoader(false)
          setRowData(cnoteData?.data)
          toast.success(cnoteData.message)
        }
        else {
          setSubDateLoader(false)
          toast.error('No data was found within this date range')
          setRowData(null)
        }
      } else {

        if (cNoteFilterFromTo.from == '') {
          setDateFieldErrorFrom('please select from date')
        }

        if (cNoteFilterFromTo.to == '') {
          setDateFieldErrorTo('please select to date')
        }
      }
    }

    if (userData?.Hub_type != 'NULL') {
      if (cNoteFilterFromTo.from != '' && cNoteFilterFromTo.to != '') {
        const payload = {
          dateFrom: cNoteFilterFromTo.from == '' ? dateOne : cNoteFilterFromTo.from,
          dateTo: cNoteFilterFromTo.to == '' ? dateOne : cNoteFilterFromTo.to,
          frenchise_id: userData?.id,
        }
        setSubDateLoader(true)
        const cnoteData = await Apiservice.postdata("/admin/courier_delivery_list", payload)
        if (cnoteData.error == false && cnoteData.data.length != 0) {
          setSubDateLoader(false)
          setRowData(cnoteData?.data)
          toast.success(cnoteData.message)
        }
        else {
          setSubDateLoader(false)
          toast.error('No data was found within this date range')
          setRowData(null)
        }
      } else {

        if (cNoteFilterFromTo.from == '') {
          setDateFieldErrorFrom('please select from date')
        }

        if (cNoteFilterFromTo.to == '') {
          setDateFieldErrorTo('please select to date')
        }
      }
    }


  }


  const rowHeight = 50;
  const DefaultColumnSetting = {
    sortable: true,
    filter: true,

    floatingFilter: true,
    flex: 1,
    resizable: true,
    minWidth: 120,
  };

  const [colDefs, setColDefs] = useState([
    {
      headerName: "Sl.No",
      valueGetter: "node.rowIndex + 1",
      filter: true,
      lockPosition: true,
    },
    {
      headerName: "Drs No",
      filter: true,
      field: "drs_no",
      cellRenderer: 'ButtonCellRenderer'

    },
    {
      headerName: "C Note No",
      filter: true,
      field: "c_note_no",
    },
    {
      headerName: "Receiver Name",
      filter: true,
      field: "receiver_name",
    },
    {
      headerName: "Receiver Mobile",
      filter: true,
      field: "receiver_mobile",
    },
    {
      headerName: "Delivery Status",
      filter: true,
      field: "delivery_status",
    },

  ]);

  const getRowStyle = (params) => {
    if (params?.data?.delivery_status == 'Delivered') {
      return {
        color: 'white',
        background: '#20c997'
      };
    }
    else {
      return {
        color: 'white',
        background: '#d63384'
      };
    }
  };

  function ButtonCellRenderer(props) {
    const onClick = async () => {
      setScreenLoader(true)
      const { data } = props;
      const payload = {
        drs_no: data.drs_no,
      };
      const getOGMOutwardList = await Apiservice.postdata("/admin/courier_delivery_list", payload);
      if (getOGMOutwardList.error == false) {
        setScreenLoader(false)
        setDrsNumberReport(getOGMOutwardList.data)
        handelprint();
      } else {
        setScreenLoader(false)
      }

    };
    return <b style={{ color: "blue" }} onClick={onClick}>{props.data.drs_no}</b>;
  }

  useEffect(() => {
    if (cNoteFilterFromTo.from != '') {
      setDateFieldErrorFrom('')
    }
    if (cNoteFilterFromTo.to != '') {
      setDateFieldErrorTo('')
    }
  }, [cNoteFilterFromTo])


  const handleDateClear = () => {
    const todayDate = '' + "-" + '' + "-" + '';
    setDate(todayDate);
    setCNoteFilterFromTo({
      ...cNoteFilterFromTo,
      from: "",
      to: ""
    })
  }

  return (
    <div>
      {/* Loader */}
      <div style={{ position: 'absolute', zIndex: 9999 }} >
        <Loader
          show={screenLoader}
          type="body"
          stack="vertical"
          message="Loading Data"
        />
      </div>
      <div>
        <div className="card w-100 mt-3">
          <div class="card-body pt-0">
            <div className="row ">
              <h6 class="card-title  my-3 bg-light w-auto py-2 rounded ">
                {/* <FileText /> <b>Out For Delivered Report</b> */}
                {
                  userData && userData?.Hub_type == 'NULL' && <div>
                    <FileText /> <b>Out For Delivered Reports In Super Admin</b>
                  </div>
                }
                {
                  userData && userData?.Hub_type == 'SUPER' && <div>
                    <FileText /> <b>Out For Delivered Reports In Super Franchise</b>
                  </div>
                }
                {
                  userData && userData?.Hub_type == 'LOCAL' && <div>
                    <FileText /> <b>Out For Delivered Report In Local Franchise</b>
                  </div>
                }
              </h6>
            </div>
            <div className='col' >
              <form onSubmit={handelFromToSubmit} >
                <div className="row">
                  <div className="col-md-4" >
                  </div>
                  <div className="col-md-8" >
                    <div className="row" >
                      <div className="col-md-4">
                        {" "}
                        <Form.Label>From Date</Form.Label>
                        <Form.Control
                          type="date"
                          value={cNoteFilterFromTo.from == '' ? dateOne : cNoteFilterFromTo.from}
                          onChange={onchangedate}
                          name="from"
                          format="DD/MM/YYYY"
                        />
                        <span className="text-danger" >{dateFieldErrorFrom}</span>

                      </div>

                      <div className="col-md-4">
                        <Form.Label>To Date</Form.Label>
                        <Form.Control
                          type="date"
                          value={cNoteFilterFromTo.to == '' ? dateOne : cNoteFilterFromTo.to}
                          onChange={onchangedate}
                          name="to"
                          format="DD/MM/YYYY"
                        />
                        <span className="text-danger" >{dateFieldErrorTo}</span>
                      </div>
                      <div className="col-md-4 mt-3">
                        <button
                          className="btn btn-danger rounded mx-3  text-white mt-3"
                          type="reset"
                          onClick={handleDateClear}
                        >
                          Clear
                        </button>
                        <button
                          className="btn btn-success rounded mx-3  text-white mt-3"
                          // onClick={handelFromToSubmit}
                          type='submit'
                        >
                          Submit{" "}
                          {subDateLoader ? (
                            <Spinner
                              animation="border"
                              variant="Primary"
                              size="sm"
                            />
                          ) : (
                            ""
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div
              className="ag-theme-alpine"
              style={{ height: "70vh", width: "100%", marginTop: "30px" }}
            >
              <AgGridReact
                rowHeight={rowHeight}
                // columnDefs={columns}
                columnDefs={colDefs}
                defaultColDef={DefaultColumnSetting}
                pagination={true}
                paginationPageSize={10}
                // onGridReady={onGridReady}
                rowData={rowData}
                getRowStyle={getRowStyle}
                frameworkComponents={{
                  ButtonCellRenderer
                }}
              />
            </div>


            <div>
              {
                <div
                  ref={componentRef}
                  className="container-fluid print-div"
                  style={main}
                >
                  {
                    <div className="p-2 m-0" style={bigEle}>
                      <div>
                        <div className="row m-1" style={{ border: '2px solid black', borderRadius: "5px" }} >
                          <div className="col" >
                            <b style={{ fontSize: '25px' }} >MOHAN COURIER SERVICES</b>
                            <div>
                              <b>Address:</b><span>{" "}{userData && userData?.Address}</span><br></br>
                              <b>Branch Name:</b><span>{" "}{userData?.city_masters && userData?.city_masters[0]?.city_name}</span><br></br>
                              <b>Pincode:</b><span>{" "}{userData && userData.Pincode}</span><br></br>
                              <b>State Name:</b><span>{" "}{userData && userData?.State}</span><br></br>
                              <b>Mobile No:</b><span>{" "}{userData && userData?.MobileNumber}</span><br></br>
                              {/* <b>GST No:</b><span>shgfcsd</span> */}

                            </div>
                          </div>
                          <div className="col"  >
                            {/* <Image src={logo} className="img-logo" width={300} height={150} /> */}
                          </div>
                        </div>
                      </div>
                      <div style={{ marginTop: "20px" }}>
                        <div className="row" style={{ marginLeft: "5px" }}>
                          <h6 class="card-title    my-3 bg-light w-auto py-2 rounded ">
                            <FileText /> <b>Courier Details</b>
                          </h6>
                        </div>
                        <Table
                          className="m-0 text-center"
                          style={{ border: "2px solid black" }}
                          bordered
                          hover
                        >
                          <thead
                            style={{
                              backgroundColor: "#5f7780",
                              color: "#FFFFFF",
                              fontSize: "14px",
                            }}
                          >
                            <tr>
                              <th>Sl.No</th>
                              <th>DRS Number</th>
                              <th>C-Note Number</th>
                              <th>Receiver Name</th>
                              <th>Receiver Mobile</th>
                              <th>Receipt Image</th>
                              <th>Delivery Status</th>
                            </tr>
                          </thead>
                          <tbody style={{ fontSize: "14px" }}>
                            <tr>
                              <td>1</td>
                              <td>{drsNumberReport && drsNumberReport[0]?.drs_no || 'null'}</td>
                              <td>{drsNumberReport && drsNumberReport[0]?.c_note_no || 'null'}</td>
                              <td>{drsNumberReport && drsNumberReport[0]?.receiver_name || 'null'}</td>
                              <td>{drsNumberReport && drsNumberReport[0]?.receiver_mobile || 'null'}</td>
                              <td><img width='50px' height='50px' src={drsNumberReport && drsNumberReport[0]?.receipt_img_url || 'null'} /></td>
                              <td>{drsNumberReport && drsNumberReport[0]?.delivery_status || 'null'}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                      <div style={{ textAlign: 'end' }} >
                        <b style={{ fontSize: '15px', marginRight: '10px' }} >POWERD BY MOXD</b>
                      </div>
                    </div>
                  }
                </div>
              }
            </div>


          </div>
        </div>
      </div>
    </div>
  );
}

export default DeliveryReport;
