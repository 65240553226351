import React, { useEffect, useState } from "react";
import { Form, Table } from "react-bootstrap";
import { FileText } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import Apiservice from "../../Services/Apiservice";
import { AgGridReact } from "ag-grid-react";
import { toast, ToastContainer, Zoom } from "react-toastify";


function BookingList() {
  const [tableData, setGetTableData] = useState();

  /*******  Booking List API Integratio ********/
  useEffect(async () => {
    window.scrollTo(0, 0);
    const one = localStorage.getItem("userDetails");
    // setLoginData(JSON.parse(one))
    const payload = {
      trans_frenchise_id: JSON.parse(one).id,
      with_cnote: true
    };
    const apiData = await Apiservice.postdata("/booking/all_booking", payload);
    if (apiData.error == false) {
      setGetTableData(apiData.data);
    } else {
      console.log("fale");
    }
  }, []);

  const [rowData, setRowdata] = useState()
  const [dateOne, setDate] = useState("");
  const [cNoteFilterFromTo, setCNoteFilterFromTo] = useState({
    from: "",
    to: ""
  })
  const [dateFieldErrorFrom, setDateFieldErrorFrom] = useState('')
  const [dateFieldErrorTo, setDateFieldErrorTo] = useState('')



  useEffect(() => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    const todayDate = yyyy + "-" + mm + "-" + dd;

    // setDate(todayDate);

    setCNoteFilterFromTo({
      ...cNoteFilterFromTo,
      from: todayDate,
      to: todayDate
    })

    // var d = new Date();
    // d.getHours(); // => 9
    // d.getMinutes(); // =>  30
    // d.getSeconds();

    // var hh = d.getHours();
    // var mm = d.getMinutes();

    // const todayTime = hh + ":" + mm;
    // setTime(todayTime);
  }, []);


  const onchangedate = (event) => {
    setCNoteFilterFromTo({ ...cNoteFilterFromTo, [event.target.name]: event.target.value })
  }
  const handelFromToSubmit = async () => {
    if (cNoteFilterFromTo.from != '' && cNoteFilterFromTo.to != '') {
      const payload = {
        dateFrom: cNoteFilterFromTo.from == '' ? dateOne : cNoteFilterFromTo.from,
        dateTo: cNoteFilterFromTo.to == '' ? dateOne : cNoteFilterFromTo.to
      }
      const cnoteData = await Apiservice.postdata("/booking/all_booking", payload)
      if (cnoteData.error == false && cnoteData.data) {
        setGetTableData(cnoteData?.data)
        toast.success(cnoteData.message)
      }
      else {
        toast.error(cnoteData.message)
      }
    } else {

      if (cNoteFilterFromTo.from == '') {
        setDateFieldErrorFrom('please select from date')
      }

      if (cNoteFilterFromTo.to == '') {
        setDateFieldErrorTo('please select to date')
      }

    }
  }


  useEffect(() => {
    if (cNoteFilterFromTo.from != '') {
      setDateFieldErrorFrom('')
    }
    if (cNoteFilterFromTo.to != '') {
      setDateFieldErrorTo('')
    }
  }, [cNoteFilterFromTo])


  const handleDateClear = () => {
    const todayDate = '' + "-" + '' + "-" + '';
    setDate(todayDate);
    setCNoteFilterFromTo({
      ...cNoteFilterFromTo,
      from: "",
      to: ""
    })
  }




  const rowHeight = 50;
  const DefaultColumnSetting = {
    sortable: true,
    filter: true,

    floatingFilter: true,
    flex: 1,
    resizable: true,
    minWidth: 120,
  };

  const [colDefs, setColDefs] = useState([
    {
      headerName: "Sl.No",
      valueGetter: "node.rowIndex + 1",
      filter: true,
      lockPosition: true,
    },
    {
      headerName: "Order Id",
      filter: true,
      field: "order_id",
    },
    {
      headerName: "C-Note Number",
      filter: true,
      field: "c_node_no",
    },
    {
      headerName: "Date of Booking",
      filter: true,
      field: "createdAt",
    },
    {
      headerName: "Sender Mobile No.",
      filter: true,
      field: "pickup_mobile",
    },
    {
      headerName: "sender Address",
      filter: true,
      field: "pickup_address",
    },
    {
      headerName: "Receiver Mobile No.",
      filter: true,
      field: "delivery_mobile",
    },
    {
      headerName: "Receiver Pincode",
      filter: true,
      field: "delivery_pincode",
    },
    {
      headerName: "Receiver Address",
      filter: true,
      field: "delivery_address",
    },
    {
      headerName: "weight",
      filter: true,
      field: "weight",
    },
    {
      headerName: "Payment Status",
      filter: true,
      field: "payment_status",
    },
  ]);

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        transition={Zoom}
        delay={1000}
        limit={1}
      />
      <div className="card w-100 mt-3">
        <div class="card-body pt-0">
          <div className="row ">
            <div className="col-md-2">
              <h6 class="card-title  my-3 bg-light w-auto py-2 rounded ">
                <FileText /> <b>Booking List</b>
              </h6>
            </div>

            <div className="col-md-6 pt-3">

              <button className="btn btn-danger rounded mx-3  text-white" style={{ padding: '3px 10px 3px 10px' }}>
                <Link to={"/booking"} className="text-white">
                  {" "}
                  Add Booking
                </Link>
              </button>
            </div>
          </div>


          <div className='col' >
            {
              <div className="row">
                <div className="col-md-4" >

                </div>
                <div className="col-md-8" >

                  <div className="row" >
                    <div className="col-md-4">
                      {" "}
                      <Form.Label>From Date</Form.Label>
                      <Form.Control
                        type="date"
                        value={cNoteFilterFromTo.from == '' ? dateOne : cNoteFilterFromTo.from}
                        onChange={onchangedate}
                        name="from"
                        format="DD/MM/YYYY"
                      />
                      <span className="text-danger" >{dateFieldErrorFrom}</span>

                    </div>

                    <div className="col-md-4">
                      <Form.Label>To Date</Form.Label>
                      <Form.Control
                        type="date"
                        value={cNoteFilterFromTo.to == '' ? dateOne : cNoteFilterFromTo.to}
                        onChange={onchangedate}
                        name="to"
                        format="DD/MM/YYYY"
                      />
                      <span className="text-danger" >{dateFieldErrorTo}</span>
                    </div>
                    <div className="col-md-4 mt-3">
                      <button
                        className="btn btn-danger rounded mx-3  text-white mt-3"
                        onClick={handleDateClear}
                      >
                        Clear
                      </button>
                      <button
                        className="btn btn-success rounded mx-3  text-white mt-3"
                        onClick={handelFromToSubmit}
                      >
                        Submit
                      </button>
                    </div>

                  </div>

                </div>


              </div>
            }
          </div>

          <div className="row my-2 mt-3">
            <div
              className="ag-theme-alpine"
              style={{ height: "70vh", width: "100%" }}
            >
              <AgGridReact
                rowHeight={rowHeight}
                // columnDefs={columns}
                columnDefs={colDefs}
                defaultColDef={DefaultColumnSetting}
                pagination={true}
                paginationPageSize={10}
                // onGridReady={onGridReady}
                rowData={tableData}
              />{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookingList;
