import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-multiple-select-dropdown-lite/dist/index.css";
import React, { useEffect, useState } from "react";
import Apiservice from "../../Services/Apiservice";
import { Form, Spinner } from "react-bootstrap";
import { ToastContainer, toast, Zoom } from "react-toastify";
import { FileText } from "react-bootstrap-icons";
import { AgGridReact } from "ag-grid-react";
import Select from "react-select";
import CustomModal from "../../common/CustomModal";
import CustomDeleteModal from "../../common/CustomDeleteModal";

const Pincodemaster = () => {
  const [pageReload, setPageReload] = useState(1);
  const [pinodeFormError1, setPincodeFormError1] = useState({ branchName: '' })
  const [pinodeFormError2, setPincodeFormError2] = useState({ pincode: "" })
  const [allBranchList, setAllBranchList] = useState([]);
  const [selectedBranchName, setSelectedBranchName] = useState([]);
  const [pinCode, setPincode] = useState("");

  /* Toast Modal */
  const [showPopupOpen, setShowPopupOpen] = useState(false)
  const [modalMessage, setModalMessage] = useState(null)
  const [deleteModal, setDeleteModal] = useState({
    loader: false,
    show: false,
    type: "",
    data: {},
  })
  const [loader, setLoader] = useState(false)
  const [showCreateCity, setShowCrateCity] = useState(true);


  const handleClosePopup = () => {
    setShowPopupOpen(false)
  }
  const handleOkClick = () => {
    setShowPopupOpen(false)
  }

  /****  List Pincode Master  ****/
  const handlepincodechange = (e) => {
    setPincode(e.target.value.slice(0, 6));
    setPincodeFormError2('')
  };



  const handleAddPincode = async () => {
    if (selectedBranchName && pinCode.length == 6) {
      setLoader(true)

      const addPincodePayload = {
        pincode: pinCode,
        city_id: selectedBranchName[selectedBranchName?.length - 1]?.id,
      };
      const picodeMaster = await Apiservice.postdata("/booking/pincode_master", addPincodePayload);
      if (picodeMaster.error == false) {
        setLoader(false)
        setShowPopupOpen(true)
        setModalMessage(picodeMaster.message)
        setPincode("");
        setPageReload(pageReload + 1);
        setSelectedBranchName([])
      } else {
        setLoader(false)
        setPageReload(pageReload + 1);
        setShowPopupOpen(true)
        setModalMessage(picodeMaster.message)
        setSelectedBranchName([])
        setPincode("");
      }
    } else {
      setLoader(false)
      if (selectedBranchName.length == 0) {
        setPincodeFormError1({
          ...pinodeFormError1, ['branchName']: "Please select branch name"
        })
      }
      if (pinCode == '') {
        setPincodeFormError2({
          ...pinodeFormError2, ['pincode']: "Please enter pincode"
        })
      } else if (pinCode && pinCode.length != 6) {
        setPincodeFormError2({
          ...pinodeFormError2, ['pincode']: "Pincode Should be 6 digits"
        })
      }
    }
  }



  useEffect(() => {
    if (selectedBranchName.length != 0) {
      setPincodeFormError1({
        ...pinodeFormError1, ['branchName']: ""
      })
    }
  }, [selectedBranchName])


  const handleShowCreateCity = () => {
    setShowCrateCity(!showCreateCity);
  };


  /*********** pincode Master List ***********/
  const [rowData, setRowdata] = useState();
  useEffect(async () => {
    const userData = localStorage.getItem("userDetails")
    const parseUserData = JSON.parse(userData)
    const payload = {
      city_id: parseUserData?.city_masters[0]?.id,
      status:'true',
    };

    if (parseUserData.Hub_type == 'NULL') {
      const ListPincodeGet = await Apiservice.postdata("/booking/listpincodemaster");
      if (ListPincodeGet.error == false) {
        setRowdata(ListPincodeGet.data);
      } else {
        console.log("get API is not working");
      }
    }


    if (parseUserData.Hub_type == 'SUPER') {
      const ListPincodeGet = await Apiservice.postdata( "/booking/listpincodemaster", payload);
      if (ListPincodeGet.error == false) {
        setRowdata(ListPincodeGet.data);
      } else {
        console.log("get API is not working");
      }
    }

    if (parseUserData.Hub_type == 'LOCAL') {
      const ListPincodeGet = await Apiservice.postdata(
        "/booking/listpincodemaster", payload);
      if (ListPincodeGet.error == false) {
        setRowdata(ListPincodeGet.data);
      } else {
        console.log("get API is not working");
      }
    }
  }, [pageReload]);

  const rowHeight = 50;
  const DefaultColumnSetting = {
    sortable: true,
    filter: true,

    floatingFilter: true,
    flex: 1,
    resizable: true,
    minWidth: 120,
  };

  const [colDefs, setColDefs] = useState([
    {
      headerName: "Id",
      valueGetter: "node.rowIndex + 1",
      filter: true,
      lockPosition: true,
    },
    {
      headerName: "City Name",
      filter: true,
      valueGetter: "data.city_masters[0]?.city_name",
    },
    {
      headerName: "Pin Code",
      filter: true,
      field: "pincode",
    },
    // {
    //   headerName: "Action",
    //   filter: true,
    //   cellRendererFramework: (params) => (
    //     <center onClick={() => handleClick(params)}>
    //       <FontAwesomeIcon icon={faEdit} className="iconstyle" />
    //     </center>
    //   ),
    // },
    {
      headerName: "Delete",
      filter: true,
      cellRendererFramework: (params) => (
        <center onClick={() => setDeleteModal({ ...deleteModal, show: true, data: params.data, type: "DELETE", deletedmodule: 'Pincode', deletedname: params?.data?.pincode })} >
          <FontAwesomeIcon icon={faTrash} className="iconstyle" />
        </center>
      ),
    },
  ]);


  /******* Delete API Integration ******/
  const hendleDeleteModal = (data) => {
    setDeleteModal({})
  }
  const hendleDeletSubmitApi = async (data) => {
    setDeleteModal({ ...deleteModal, loader: true })
    const payload = {
      id: data?.id,
    };
    let price = await Apiservice.postdata("/booking/deletepincodemaster", payload);
    if (price.error == false) {
      setDeleteModal({})
      setPageReload(pageReload + 1);
      toast.success(price.message)
    } else {
      setDeleteModal({})
      setPageReload(pageReload + 1);
      toast.error(price.message)

    }
  };


  /*** get branch list ***/
  const getBranchList = async () => {
    const detailes = JSON.parse(localStorage.getItem("userDetails"))
    // /User/list_all_city
    let AreaNameData = await Apiservice.postdata(`/User/listCity`, {
      city_name: '',
      isNull: false,
      Hub_id: detailes.id,
      ApprovedStatus: true,
    });
    if (AreaNameData.error == false) {
      const pincodeDropdown = [];
      const pincodeMaping = AreaNameData.data.map((ele, ind) => {
        pincodeDropdown.push({
          label: ele.city_name,
          value: ele.city_name,
          id: ele.id,
        });
      });
      setAllBranchList(pincodeDropdown);
    } else {
      console.log("failue");
    }
  }

  useEffect(() => {
    getBranchList()
  }, [])

  const handlePincodeClear = () => {
    setPincode("");
    setSelectedBranchName([])
    setPincodeFormError1({
      ...pinodeFormError1, ['branchName']: ""
    })
    setPincodeFormError2({
      ...pinodeFormError2, ['pincode']: " "
    })
  }


  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        transition={Zoom}
        delay={1000}
        limit={1}
      />

      {/* Toast message */}
      <CustomModal showPopupOpen={showPopupOpen}
        handleOkClick={handleOkClick}
        handleClosePopup={handleClosePopup}
        modalMessage={modalMessage}
      />

      {/* delete modal */}
      <CustomDeleteModal
        hendleDeleteModal={hendleDeleteModal}
        deleteModal={deleteModal}
        hendleDeletSubmitApi={hendleDeletSubmitApi}
      ></CustomDeleteModal>
      <div className="my-3">
        <div className="card w-100">
          <div class="card-body pt-0">
            <div className="row">
              <div className="col-md-3">
                <h6 class="card-title    my-3 bg-light w-auto py-2 rounded ">
                  <FileText /> <b> Pin code Master</b>
                </h6>
              </div>
              {/* <div className="col-md-3">
                <button
                  className="btn btn-success  btn-md rounded mx-3 mt-3"
                  onClick={handleShowCreateCity}
                >
                  Create Pin Code
                </button>
              </div> */}
            </div>

            {
              showCreateCity && (
                <div className="row my-2">
                  <div className="col-md-3 ">
                    <diV className="mb-3">
                      <label>Branch Name</label>
                      <div style={{ marginTop: "8px" }} >
                        <Select
                          options={allBranchList}
                          onChange={setSelectedBranchName}
                          value={selectedBranchName[selectedBranchName.length - 1] || null}
                          isMulti
                        />
                      </div>
                      <span className="text-danger">{pinodeFormError1.branchName}</span>
                    </diV>
                  </div>

                  <div className="col-md-3">
                    <Form.Label>Enter Pincode</Form.Label>
                    <Form.Control
                      type="number"
                      value={pinCode}
                      className=''
                      as="input"
                      autoComplete="off"
                      onChange={handlepincodechange}
                    />
                    <span className="text-danger">{pinodeFormError2.pincode}</span>
                  </div>
                  <div className="col-md-3 mt-2">
                    <button
                      className="btn btn-danger btn-md rounded mx-3 mt-4 "
                      onClick={handlePincodeClear}
                    >
                      Clear
                    </button>
                    <button
                      className="btn btn-success btn-md rounded mx-3 mt-4 "
                      onClick={handleAddPincode}
                    >
                      Add Pincode {
                        loader &&
                        <Spinner animation="border" variant="Primary" size="sm" />
                      }
                    </button>
                  </div>
                </div>
              )}
            <div
              className="ag-theme-alpine"
              style={{ height: "70vh", width: "100%" }}
            >
              <AgGridReact
                rowHeight={rowHeight}
                // columnDefs={columns}
                columnDefs={colDefs}
                defaultColDef={DefaultColumnSetting}
                pagination={true}
                paginationPageSize={10}
                // onGridReady={onGridReady}
                rowData={rowData}
              />{" "}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pincodemaster;
