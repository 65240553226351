import { FETCH_DATA } from "../Constants/Constant";

const initialstate = {
    data: [],
};
export default function Alldata(state = initialstate, action) {
    switch (action.type) {
        case FETCH_DATA:
            return {...state, data: action.data };
            break;
        default:
            return state;
            break;
    }
};