import React, { useEffect, useRef, useState } from "react";
import { Card, Form, Spinner, Table } from "react-bootstrap";
import { FileText } from "react-bootstrap-icons";
import DateTimePicker from "react-datetime-picker";
import Select from "react-select";
import { ToastContainer, toast, Zoom } from "react-toastify";
import CustomButton from "../../common/CustomButton";
import Apiservice from "../../Services/Apiservice";
import "./print.css";
import { useReactToPrint } from "react-to-print";
import "react-image-picker/dist/index.css";
import { AiFillEye } from "react-icons/ai";
import { BsFillCameraFill } from "react-icons/bs";
import Webcam from "react-webcam";
import { Modal, Row, Container, Button } from "react-bootstrap";
import CustomModal from "../../common/CustomModal";
import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import '../../Style/OutForDelivery.css'
import ReactPaginate from "react-paginate";
import { HashLoader } from "react-spinners";

const DRSStatus = [
  { label: "DRS Updated Pending", value: "DRS Updated Pending" },
  { label: "DRS Updated", value: "DRS Updated" },
];

const SCANStatus = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];

const Branch = [
  { label: "Banglore", value: "Banglore" },
  { label: "Maharashtra", value: "Maharashtra" },
];

const status = [
  // { label: "Dispatched", value: "Dispatched" },
  { label: "Delivered", value: "Delivered" },
  { label: "Door closed", value: "Door closed" },
  { label: "Persion not Available", value: "Persion not Available" },
];

function OutForDelivery() {
  const [tableData, setTableData] = useState([]);
  const [showrightContent, setShowrightContent] = useState("none");
  const [showleftContent, setShowleftContent] = useState("none");
  const [drsStatus, setDrsStatus] = useState([]);
  const [dispatchUploadLoader, setDispatchUploadLoader] = useState(false);
  const [dispatchStatusLoader, setDispatchStatusLoader] = useState(false);
  const [searchCnoteLoader, setSearchCnoteLoader] = useState(false);
  const [deliveryUploadLoader, setDeliveryUploadLoader] = useState(false);
  const [areaOptionDataName, setAreaOptionDataName] = useState([]);
  const [selectedArea, setSelectedArea] = useState([]);
  const [areaName, setAreaName] = useState([]);
  const [drsNumber, setDrsNumber] = useState("");
  const [updateStatus, setUpdateStus] = useState([]);
  const [cnoteNumber, setCnoteNumber] = useState("");
  const [cnotevalid, setCnotevalid] = useState("");
  const [date, setDate] = useState(new Date());
  const [itemOffset, setItemOffset] = useState(0);
  const [categories, setCategories] = useState([]);
  const [outForDeleveryLoader, setOutForDeleveryLoader] = useState(false)
  const [selectBoyName, setSelectBoyName] = useState([]);
  const [areaerror, setAreaerror] = useState("");
  const [boyerror, setBoyerror] = useState("");


  const [deliveryData, setDeliveryData] = useState({
    date: "",
    time: "",
    deliveryBoy: "",
    selectedArea: "",
    cNoteNo: "",
    noPcs: "",
    receiverName: "",
    receiverMobile: "",
    remarks: "",
    wtKgs: "",
    wtGms: "",
  });


  const [cnoteTableData, setCnoteTableData] = useState([]);
  const [pdfData, setPdfData] = useState([]);
  const [userDetails, setUserDetailes] = useState([]);

  const [calculatedWeight, setCalculatedWeight] = useState(0);
  const [showAddOutForDelivery, setShowAddOutForDelivery] = useState(true);
  const [showAddOutForDeliveryForm, setShowAddOutForDeliveryForm] =
    useState(false);
  const [areaOptionData, setAreaOptionData] = useState([]);

  const [commonImage, setCommonImage] = useState("https://storage.googleapis.com/replit/images/1645421469071_a57b93c53ffb9dfba27160c2dabdcea9.jpeg");
  const [selectionType, setSelectionType] = useState("");
  const [imageType, setImageType] = useState("");
  const AddOutForDeliveryForm = () => {
    setShowAddOutForDeliveryForm(true);
    setShowAddOutForDelivery(false);
  };

  const [showTable, setShowTable] = useState("");
  const [showWebCamePod, setShowWebCamPod] = useState(false);
  const [showWebCameDrs, setShowWebCamDrs] = useState(false);
  const [searchLoader, setSearchLoader] = useState(false);

  const [show, setShow] = useState(true);
  const [showDrsNumber, setShowDrsNumber] = useState(false);

  const [showPopupOpenWebcam, setShowPopupOpenWebcam] = useState(false);
  const webRef = useRef(null);
  const [selectWebPic, setSelectWebPic] = useState("");
  const [podModal, setPodModal] = useState(false);
  const [drsModal, setDrsdModal] = useState(false);
  const [manualTableData, setManualTableData] = useState([]);
  const [rowData, setRowData] = useState()
  const [drsModalStatus, setDrsModalStatus] = useState(false)
  const [drsStoredNumber, setDrsStoredNumber] = useState()
  const [updateStatusIndex, setUpdateStatusIndex] = useState()
  const [loading, setLoading] = useState(false);



  /* Toast Modal */
  const [showPopupOpen, setShowPopupOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState(null);
  const handleClosePopup = () => {
    setShowPopupOpen(false);
  };
  const handleOkClick = () => {
    setShowPopupOpen(false);
  };

  const Back = () => {
    setShowAddOutForDelivery(true);
    setShowAddOutForDeliveryForm(false);
  };

  const handleright = () => {
    setShowrightContent("block");
    setShowleftContent("none");
    setCnoteNumber("");
    setShowTable("manual");
    setCnotevalid(" ");
    setAreaerror(" ");
    setBoyerror(" ");
  };

  useEffect(() => {
    const todayDate = String(date.getDate()).padStart(2, "0");
    const todayMonth = String(date.getMonth() + 1).padStart(2, "0");
    const todayYear = date.getFullYear();
    const one = todayYear + "-" + todayMonth + "-" + todayDate;

    // date.getHours(); // => 9
    // date.getMinutes(); // =>  30
    // d.getSeconds();

    var hh = date.getHours();
    var mm = date.getMinutes();

    const todayTime = hh + ":" + mm;
    const date1 = "date";
    const time1 = "time";
    setDeliveryData({ ...deliveryData, [date1]: one, [time1]: todayTime });
  }, [date]);

  useEffect(async () => {
    window.scrollTo(0, 0);
    const LocalData = localStorage.getItem("userDetails");
    const frenchise_id = JSON.parse(LocalData).id;
    const payload = {
      franchise_id: frenchise_id,
    };

    const outwardApi = await Apiservice.postdata(
      "/admin/courier_delivery_list",
      payload
    );
    if (
      outwardApi.error == false
      //  && outwardApi.data.length > 0
    ) {
      const statusData = [
        { label: "Deverd", value: "Deverd" },
        { label: "door closed", value: "door closed" },
        { label: "persion not Available", value: "persion not Available" },
      ];
      setTableData(outwardApi.data);
      // setRowdata(outwardApi.data);
      const updateStatusData = [];
      const statusMaping = outwardApi.data.map((ele, ind) => {
        updateStatusData.push({
          label: ele.delivery_status,
          value: ele.delivery_status,
        });
      });
    } else {
    }
  }, []);

  /*********** Delivery Boy List 1 *************/
  const [deliveryBoyOptionData1, setDeliveryBoyOptionData1] = useState([]);
  useEffect(async () => {
    const LocalData = localStorage.getItem("userDetails");
    const frenchise_id = JSON.parse(LocalData);
    let AreaNameData = await Apiservice.postdata(`/admin/delivery_boy_list`, {
      frenchise_id: frenchise_id.id,
      delivery_boy_name: "",
    });
    if (AreaNameData.error == false) {
      const deliveryBoy = [];
      const areaMapData = AreaNameData.data.map((ele, ind) => {
        deliveryBoy.push({
          label: ele.name,
          value: ele.name,
          id: ele.id,
        });
      });
      setDeliveryBoyOptionData1(deliveryBoy);
    } else {
      console.log("wrong");
    }
  }, []);

  const [selectBoyName1, setSelectBoyName1] = useState([]);
  const handleBoy1 = (e) => {
    setSelectBoyName1(e);
  };

  /*********** Delivery Boy List 2 *************/
  const [deliveryBoyOptionData, setDeliveryBoyOptionData] = useState([]);
  useEffect(async () => {
    const LocalData = localStorage.getItem("userDetails");
    const frenchise_id = JSON.parse(LocalData);
    let AreaNameData = await Apiservice.postdata(`/admin/delivery_boy_list`, {
      frenchise_id: frenchise_id.id,
      delivery_boy_name: "",
    });
    if (AreaNameData.error == false) {
      const deliveryBoy = [];
      const areaMapData = AreaNameData.data.map((ele, ind) => {
        deliveryBoy.push({
          label: ele.name,
          value: ele.name,
          id: ele.id,
        });
      });
      setDeliveryBoyOptionData(deliveryBoy);
    } else {
      console.log("wrong");
    }
  }, []);

  const handleBoy = (e) => {
    console.log("this is working" , e)
    setSelectBoyName(e);
  };

  /******  Area List ******/
  useEffect(async () => {
    const LocalData = localStorage.getItem("userDetails");
    const frenchise_id = JSON.parse(LocalData);
    setUserDetailes(frenchise_id);
    let AreaNameData = await Apiservice.postdata(`/User/list_area`, {
      city_id: frenchise_id.city_masters[0].id,
    });
    if (AreaNameData.error == false) {
      const areaData = [];
      const areaMapData = AreaNameData.data.map((ele, ind) => {
        areaData.push({
          label: ele.area_name,
          value: ele.area_name,
          id: ele.id,
        });
      });
      setAreaOptionData(areaData);
    } else {
      console.log("wrong");
    }
  }, []);


  /********* Branch Name  *********/
  const [BranchNameList, setBranchNameList] = useState([]);
  useEffect(async () => {
    let BranchNameData = await Apiservice.postdata(`/User/listCity`, {
      city_name: "",
    });
    if (BranchNameData.error == false) {
      const areaData = [];
      const areaMapData = BranchNameData.data.map((ele, ind) => {
        areaData.push({
          label: ele.city_name,
          value: ele.city_name,
          id: ele.id,
        });
      });
      setBranchNameList(areaData);
    } else {
      console.log("wrong");
    }
  }, []);

  useEffect(async () => {
    const LocalData = localStorage.getItem("userDetails");
    const frenchise_id = JSON.parse(LocalData);
    let AreaNameData = await Apiservice.postdata(`/User/list_area`, {
      city_id: frenchise_id.city_masters[0].id,
    });
    if (AreaNameData.error == false) {
      const areaData = [];
      const areaMapData = AreaNameData.data.map((ele, ind) => {
        areaData.push({
          label: ele.area_name,
          value: ele.area_name,
          id: ele.id,
        });
      });
      setAreaOptionDataName(areaData);
    } else {
      console.log("wrong");
    }
  }, []);

  const handleClear = () => {
    setDrsNumber("");
    setAreaName([]);
    setDrsStatus([]);
    setSelectBoyName1([]);
  };

  const handleFastBookingSave = async (ele) => {
    ele.preventDefault();
    const LocalData = localStorage.getItem("userDetails");
    const frenchise_id = JSON.parse(LocalData);
    const payload = {
      franchise_id: frenchise_id.id,
    };
    if (drsNumber != 0) payload.drs_no = drsNumber;

    if (selectBoyName1?.length != 0)
      payload.delivery_boy_id = selectBoyName1[selectBoyName1.length - 1].id;

    if (drsStatus?.length != 0)
      payload.delivery_status = drsStatus[drsStatus?.length - 1].value;

    if (areaName?.length != 0)
      payload.area_id = areaName[areaName.length - 1].id;

    if (
      drsNumber != 0 &&
      selectBoyName1?.length == 0 &&
      drsStatus?.length == 0 &&
      areaName?.length == 0
    ) {
      setShowDrsNumber(true);
    }
    if (drsNumber == 0) {
      setShowDrsNumber(false);
    }

    setSearchLoader(true);
    const filterApi = await Apiservice.postdata(
      "/admin/courier_delivery_list",
      payload
    );
    if (filterApi.error == false && filterApi.data.length > 0) {
      setDrsStatus([]);
      setAreaName([]);
      setSearchLoader(false);
      setShowPopupOpen(true);
      setModalMessage(filterApi.message);
      setTableData(filterApi.data);
      setShow(true);
    } else {
      setDrsStatus([]);
      setAreaName([]);

      setSearchLoader(false);
      setShowPopupOpen(true);
      setModalMessage("based on request there is no data");
      setTableData([]);
      setShow(false);
    }
  };

  const handleleft = () => {
    setShowleftContent("block");
    setShowrightContent("none");
    setShowTable("cnote");
    setFormFieldErrors({
      ...FormFieldErrors,
      receiverName: "",
      receiverMobile: "",
      noPcs: "",
      weightkgs: "",
      weighGrs: "",
      remarks: "",
    });
    setAreaerror(" ");
    setBoyerror(" ");
  };

  /******** Manula Detailes Validation *******/

  const [FormFieldErrors, setFormFieldErrors] = useState("");
  const [dataName, setDataName] = useState({
    receiverName: "",
    receiverMobile: "",
    weightkgs: "",
    weighGrs: "",
    remarks: "",
    noPcs: "",
    cnote: "",
  });
  const handleChange = (event) => {
    if (event.target.name == "cnote") {
      var one = [];
      if (event.target.value.includes("\n")) {
        one = event.target.value.split("\n");
      } else {
        one = event.target.value.split(" ");
      }
      const conte = [];
      const data = one.map((ele) => {
        if (ele.includes("\n")) {
          var one1 = ele.split("\n");
        } else {
          var one2 = ele.split(" ");
        }
        if (ele !== "") {
          conte.push(ele);
        }
      });

      // setDataName({
      //   ...dataName,
      //   [event.target.name]: conte,
      // });
    } else {
      // setDataName({
      //   ...dataName,
      //   [event.target.name]: event.target.value,
      // });
    }

    setDataName({
      ...dataName,
      [event.target.name]: event.target.value,
    });

    setFormFieldErrors({
      ...FormFieldErrors,
      [event.target.name]: null,
    });
  };


  const ValidatingFilter = () => {
    const { receiverName, receiverMobile, weightkgs, weighGrs, remarks, noPcs, cnote } = dataName;
    const newErrors = {};
    const textReg = /[a-zA-Z]/;
    if (cnote == "") {
      newErrors.cnote = "Please enter c-note number";
    }
    if (receiverName == "") {
      newErrors.receiverName = "Please enter receiver name";
    } else if (receiverName && !textReg.test(receiverName)) {
      newErrors.receiverName = "Please enter charecters";
    } else if (receiverName && receiverName.length > 50) {
      newErrors.receiverName = "Please Enter The below 50 charecters";
    }
    if (receiverMobile == "") {
      newErrors.receiverMobile = "Please enter receiver mobile";
    } else if (receiverMobile && receiverMobile.length != 10) {
      newErrors.receiverMobile = "Please enter 10 digits";
    }
    // if (noPcs == "") {
    //   newErrors.noPcs = "Please enter number of piecies";
    // } else if (noPcs && noPcs.length > 3) {
    //   newErrors.noPcs = "Please enter below 3 digits";
    // }
    // if (weightkgs == "") {
    //   newErrors.weightkgs = "Please enter weight in Kgs";
    // } else if (weightkgs && weightkgs.length > 5) {
    //   newErrors.weightkgs = "Please enter below 5 digits";
    // }
    // if (weighGrs == "") {
    //   newErrors.weighGrs = "Please enter weight in grams";
    // } else if (weighGrs && weighGrs.length > 4) {
    //   newErrors.weighGrs = "Please enter below 4 digits";
    // }
    // if (remarks == "") {
    //   newErrors.remarks = "Please enter remarks";
    // }
    return newErrors;
  };


  const handlemanualData = () => {
    const validationObject = ValidatingFilter();
    if (Object.keys(validationObject).length > 0) {
      setFormFieldErrors(validationObject);
    } else {
      var one = [];
      if (dataName.cnote.includes("\n")) {
        one = dataName.cnote.split("\n");
      } else {
        one = dataName.cnote.split(" ");
      }
      const conte = [];
      const data = one.map((ele) => {
        if (ele.includes("\n")) {
          var one1 = ele.split("\n");
        } else {
          var one2 = ele.split(" ");
        }
        if (ele !== "") {
          conte.push(ele);
        }
      });

      if (
        dataName.cnote &&
        dataName.receiverName &&
        dataName.receiverMobile &&
        dataName.weightkgs &&
        dataName.weighGrs &&
        dataName.remarks
      ) {
        const dataName1 = []
        conte.map((ele, ind) => {
          dataName1.push({
            receiverName: dataName.receiverName,
            receiverMobile: dataName.receiverMobile,
            weightkgs: dataName.weightkgs,
            weighGrs: dataName.weighGrs,
            remarks: dataName.remarks,
            noPcs: dataName.noPcs,
            cnote: ele,
          })
        })
        setManualTableData([...manualTableData, ...dataName1]);
        // setManualTableData([...manualTableData, dataName1]);
        setDataName({
          ...dataName,
          cnote: "",
          receiverName: "",
          receiverMobile: "",
          noPcs: "",
          weightkgs: "",
          weighGrs: "",
          remarks: ""
        });
      } else {
        console.log("field is not fileped");
      }
    }
  };

  const haandleRemoveManualData = () => {
    setManualTableData([])
    setDataName({
      ...dataName,
      receiverName: "",
      receiverMobile: "",
      noPcs: "",
      weightkgs: "",
      weighGrs: "",
      remarks: "",
    });
  }


  const handlearea = (e) => {
    setSelectedArea(e);
    setAreaerror("");
    setDeliveryData({ ...deliveryData, selectedArea: e[e?.length - 1]?.value || "" })
  };

  useEffect(() => {
    setAreaerror("");
  }, [selectedArea]);

  useEffect(() => {
    setBoyerror("");
  }, [selectBoyName]);

  // submit add out for delivery
  const handleSubmitOutForDeliver = async () => {
    const LocalData = localStorage.getItem("userDetails");
    const frenchise_id = JSON.parse(LocalData).id;
    var payload = [];
    let totalWeight = [];
    if (selectedArea?.length == 0) {
      setAreaerror("Please select area");
    }
    if (selectBoyName.length == 0) {
      setBoyerror("Please select delivery boy");
    } else {
      if (showTable == "cnote") {
        const one =
          Object.keys(cnoteTableData[0])?.includes("OGM_no") == true &&
          cnoteTableData.map((ele, ind) => {
            payload.push({
              date_time: `${deliveryData.date} ${deliveryData.time}`,
              delivery_boy_id: selectBoyName[selectBoyName.length - 1].id,
              area_id: selectedArea[selectedArea?.length - 1]?.id,
              c_note_no: ele.c_node_no,
              weight: ele.weight || null,
              no_pcs: ele.no_pcs || null,
              receiver_name: ele.delivery_name || "",
              receiver_mobile: ele.delivery_mobile || "",
              remarks: ele.remarks || "",
              franchise_id: frenchise_id,
            });
            totalWeight.push(ele.weight);
          });

        const two =
          Object.keys(cnoteTableData[0])?.includes("OGM_no") != true &&
          cnoteTableData.map((ele, ind) => {
            payload.push({
              date_time: `${deliveryData.date} ${deliveryData.time}`,
              delivery_boy_id: selectBoyName[selectBoyName.length - 1].id,
              area_id: selectedArea[selectedArea?.length - 1]?.id,
              c_note_no: ele.c_node_no || "",
              weight: ele.weight || null,
              no_pcs: ele.no_pcs || null,
              receiver_name: ele.delivery_name || "",
              receiver_mobile: ele.delivery_mobile || "",
              remarks: ele.remarks || "",
              franchise_id: frenchise_id,
            });
            totalWeight.push(ele.weight);
          });
      } else {
        const two = manualTableData.map((ele, ind) => {
          payload.push({
            date_time: `${deliveryData.date} ${deliveryData.time}`,
            delivery_boy_id: selectBoyName[selectBoyName.length - 1].id,
            area_id: selectedArea[selectedArea?.length - 1]?.id,
            c_note_no: ele.cnote,
            weight: `${ele.weightkgs}.${ele.weighGrs} `,
            no_pcs: ele.noPcs,
            receiver_name: ele.receiverName,
            receiver_mobile: ele.receiverMobile,
            remarks: ele.remarks,
            franchise_id: frenchise_id,
          });
          totalWeight.push(ele.weight);
        });
      }
    }
    const totalCalculatedWeight = totalWeight.reduce((a, b) => {
      return a + b;
    });

    setCalculatedWeight(totalCalculatedWeight);
    setPdfData(payload);
    setOutForDeleveryLoader(true)
    const OutForDeliveryApi = await Apiservice.postdata("/admin/CreateAssign", payload);
    if (OutForDeliveryApi?.error == false) {
      handelprint();
      setShowPopupOpen(true);
      setModalMessage('Success');
      setPageReload(pageReload + 1);
      setDrsNumberData(OutForDeliveryApi);
      setOutForDeleveryLoader(false)
      setSelectBoyName([])
      setSelectedArea([])
      setDataName({ ...dataName, cnote: '' })
      setManualTableData([])
      setShowTable("")
    } else {
      setPageReload(pageReload + 1);
      setShowPopupOpen(true);
      setModalMessage(OutForDeliveryApi?.message);
      setOutForDeleveryLoader(false)
      setSelectBoyName([])
      setSelectedArea([])
      setDataName({ ...dataName, cnote: '' })
      setManualTableData([])
      setShowTable("")
    }
  };

  /******* DRS Number Data *******/
  const [drsNumberData, setDrsNumberData] = useState(0);

  const handleCnoteChange = (e) => {
    setCnoteNumber(e.target.value);
    setCnotevalid(" ");
  };

  const handleGetCNoteData = async () => {
    var one = [];
    if (cnoteNumber.includes("\n")) {
      one = cnoteNumber.split("\n");
    } else {
      one = cnoteNumber.split(" ");
    }
    const conte = [];
    const data = one.map((ele) => {
      if (ele.includes("\n")) {
        var one1 = ele.split("\n");
      } else {
        var one2 = ele.split(" ");
      }
      if (ele !== "") {
        conte.push(ele);
      }
    });

    if (conte == "") {
      setCnotevalid("Please enter c-note number");
    } else {
      setSearchCnoteLoader(true);
      setDeliveryData({ ...deliveryData, cNoteNo: conte })
      let cnoteApi = await Apiservice.postdata(`/admin/delivery_cnote_list`, {
        c_note_no: conte,
      });
      if (cnoteApi.error == false && cnoteApi.data.length != 0) {
        console.log(cnoteApi.data, "cnoteApi.data");
        setSearchCnoteLoader(false);
        setCnoteTableData(cnoteApi.data);
        setShowPopupOpen(true);
        setModalMessage(cnoteApi.message);
        setCnoteNumber("");
      } else {
        setSearchCnoteLoader(false);
        setShowPopupOpen(true);
        setCnoteTableData([]);
        setModalMessage(cnoteApi?.message);
      }
    }
  };

  const main = {
    width: "100%",
    marginRight: "auto",
    marginLeft: "8px",
  };
  const childEle = {
    border: "2px solid black",
    width: "50%",
    padding: "5px 10px 5px",
    fontSize: "12px",
  };
  const smallEle = {
    borderLeft: "2px solid black",
    borderRight: "2px solid black",
    height: "15px",
  };
  const bigEle = {
    border: "2px solid black",
  };
  const mediumEle = {
    border: "2px solid black",
  };

  const componentRef = useRef();
  const handelprint = useReactToPrint({ content: () => componentRef.current });
  const [data1, setData1] = useState(null);
  const [data2, setData2] = useState(null);
  const [indNumber, setIndNumber] = useState();
  const [pageReload, setPageReload] = useState(1);

  const handlePODImageUploadApiSubmit = async (row) => {
    if (selectionType == "webcame") {
      setDispatchUploadLoader(true);
      const podAPI = await fetch(
        "https://moxd-api.moshimoshi.cloud/api/imageupload",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: row.id,
            base64image: data1,
          }),
        }
      )
        .then((response) => response.json())
        .then((json) => {
          setDispatchUploadLoader(false);
          setShowPopupOpen(true);
          setModalMessage("Success fully Uploaded");
          setPageReload(pageReload + 1);
          setSelectionType("");
        })
        .catch((error) => {
          setDispatchUploadLoader(false);
          setShowPopupOpen(true);
          setModalMessage("Success fully Uploaded");
          setPageReload(pageReload + 1);
          setSelectionType("");
        });
    }
    if (selectionType == "localfile") {
      setDispatchUploadLoader(true);
      const formData = new FormData();
      formData.append("base64image", data1);
      formData.append("id", row.id);
      const fileUploadAoi = await axios.post("https://moxd-api.moshimoshi.cloud/api/imageupload", formData);
      if (fileUploadAoi.status == 200) {
        setDispatchUploadLoader(false);
        setShowPopupOpen(true);
        setModalMessage("Success fully Uploaded");
        setPageReload(pageReload + 1);
        setSelectionType("");
      } else {
        setDispatchUploadLoader(false);
        setShowPopupOpen(true);
        setModalMessage("not uploaded");
        setPageReload(pageReload + 1);
        setSelectionType("");
      }
    }
  };

  useEffect(async () => {
    // window.scrollTo(0, 0);
    // const LocalData = localStorage.getItem("userDetails");
    // const frenchise_id = JSON.parse(LocalData).id;
    // setShowDrsNumber(false);
    // const payload = {
    //   franchise_id: frenchise_id,
    // };

    // const outwardApi = await Apiservice.postdata("/admin/courier_delivery_list", payload);
    // if (outwardApi.error == false) {
    //   const statusData = [
    //     { label: "Deverd", value: "Deverd" },
    //     { label: "door closed", value: "door closed" },
    //     { label: "persion not Available", value: "persion not Available" },
    //   ];
    //   setTableData(outwardApi.data);
    //   const updateStatusData = [];
    //   const statusMaping = outwardApi.data.map((ele, ind) => {
    //     updateStatusData.push({
    //       label: ele.delivery_status,
    //       value: ele.delivery_status,
    //     });
    //   });
    // } else {
    // }

    const LocalData = localStorage.getItem("userDetails");
    const frenchise_id = JSON.parse(LocalData).id;
    setShowDrsNumber(false);
    const DRSData = localStorage.getItem("drsPayload");
    const parsingDRSData = JSON.parse(DRSData).drs_no
    const payload = {
      franchise_id: frenchise_id,
      drs_no: parsingDRSData
    };
    const outwardApi = await Apiservice.postdata("/admin/courier_delivery_list", payload);
    if (outwardApi.error == false) {
      setTableData(outwardApi.data);
    } else {
    }
  }, [pageReload]);


  const handleUpdatestatus = async (ele, ind) => {
    setUpdateStatusIndex(ind)
    const LocalData = localStorage.getItem("userDetails");
    const frenchise_id = JSON.parse(LocalData);

    const payload = {
      drsNumber:drsStoredNumber ?drsStoredNumber : 0 ,  
      c_note_no:ele?.c_note_no,
      id: ele.id,
      delivery_status: updateStatus.value,
      frenchise_id: frenchise_id.id,
    };
    setDispatchStatusLoader(true);
    const updateStatusApi = await Apiservice.postdata("/admin/deliverystatus", payload);
    if (updateStatusApi.error == false) {
      setDispatchStatusLoader(false);
      setShowPopupOpen(true);
      setModalMessage(updateStatusApi.message);
      setPageReload(pageReload + 1);
    } else {
      setDispatchStatusLoader(false);
      setShowPopupOpen(true);
      setModalMessage(updateStatusApi.message);
    }
  };

  const handleShowImage = (e) => {
    window.open(
      `${e.receipt_img_url}`,
      "sharer",
      "toolbar=0,status=0,width=800,height=325"
    );
  };

  /** DRS Image Update **/
  const [drsData, setDrsData] = useState(null);
  const [drsData1, setDrsData1] = useState(null);

  const handlePodUploadImage = (e) => {
    if (e.target.files && e.target.files[0]) {
      setSelectionType("localfile");
      setData1(e.target.files[0]);
      setData2(URL.createObjectURL(e.target.files[0]));
      setDrsData1(URL.createObjectURL(e.target.files[0]));
      setPodModal(false);
    }
  };

  const handleDrsFileUploadApi = async () => {
    // const formData = new FormData();
    // formData.append("image", drsFileData[0]);
    // formData.append("drs_no", drsIndNumber);
    // const fileUploadAoi = await Apiservice.postdata("/updatedrsimage", formData);
    // if (fileUploadAoi.error == false) {
    //   toast.success(fileUploadAoi.message);
    //   setDrsData(null)
    //   // setPageReload(pageReload + 1)
    //   handleFastBookingSave()
    // } else {
    //   console.log("not updated");
    // }

    if (selectionType == "localfile") {
      setDispatchUploadLoader(true);
      setDeliveryUploadLoader(true);
      const formData = new FormData();
      formData.append("base64image", data1);
      formData.append("drs_no", drsNumber);
      const fileUploadAoi = await axios.post(
        "https://moxd-api.moshimoshi.cloud/api/drs_image",
        formData
      );
      if (fileUploadAoi.status == 200) {
        setDeliveryUploadLoader(false);
        setShowPopupOpen(true);
        setModalMessage("Success fully Uploaded");
        setDrsData(null);
        setDrsData1(null);

        handleFastBookingSave();
        setShow(false);
      } else {
        setDeliveryUploadLoader(false);
        setShowPopupOpen(true);
        setModalMessage("not uploaded");
        setShow(false);
      }
    }

    if (selectionType == "webcame") {
      setDeliveryUploadLoader(true);
      const podAPI = await fetch(
        "https://moxd-api.moshimoshi.cloud/api/drs_image",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            drs_no: drsNumber,
            base64image: drsData,
          }),
        }
      )
        .then((response) => response.json())
        .then((json) => {
          setDeliveryUploadLoader(false);
          setShowPopupOpen(true);
          setModalMessage(json.status);
          setDrsData(null);
          setDrsData1(null);

          handleFastBookingSave();
          setShow(false);
        })
        .catch((error) => {
          setDeliveryUploadLoader(false);
          setShowPopupOpen(true);
          setModalMessage("not uploaded");
          setShow(false);
        });
    }
  };

  /** Pod Image **/
  const handleOpenWebcamPod = (e, ind) => {
    setImageType(e);
    setIndNumber(ind);
    setPodModal(true);
  };

  const openWebCam = () => {
    setPodModal(false);
    setShowWebCamPod(true);
    setShowPopupOpenWebcam(true);
  };
  const PodModalClose = () => {
    setPodModal(false);
  };

  const hanadleTakePhotoWebcamPod = () => {
    if (imageType == "podImage") {
      setData1(webRef.current.getScreenshot());
      setData2(webRef.current.getScreenshot());
      setSelectionType("webcame");
      setShowPopupOpenWebcam(false);
    }
    if (imageType == "drsImage") {
      setDrsData(webRef.current.getScreenshot());
      setDrsData1(webRef.current.getScreenshot());

      setSelectionType("webcame");
      setShowPopupOpenWebcam(false);
    }
  };

  /** DRS Image **/
  const handleOpenWebcamDrs = (ele) => {
    setImageType(ele);
    // setShowWebCamDrs(true);
    // setShowPopupOpenWebcam(true);
    // setDrsdModal(true);
    setPodModal(true);
  };

  const handleClosePopupWebcam = () => {
    setShowPopupOpenWebcam(false);
  };

  const hanadleTakePhotoWebcamDrs = () => {
    setDrsData(webRef.current.getScreenshot());
    setDrsData1(webRef.current.getScreenshot());
    setSelectionType("webcame");
    setShowPopupOpenWebcam(false);
  };


  const itemsPerPage = 10;
  const endOffset = itemOffset + itemsPerPage;
  const pageCount = Math.ceil(categories.length / itemsPerPage);
  const currentItems = categories.slice(itemOffset, endOffset);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % categories.length;
    setItemOffset(newOffset);
  };

  const drsNewList = async () => {
    const LocalData = localStorage.getItem("userDetails");
    const frenchise_id = JSON.parse(LocalData).id;
    const payload = {
      franchise_id: frenchise_id,
    };
    const newDrsResponce = await axios.post("https://moxd-api.moshimoshi.cloud/api/admin/all_drs_list", payload);
    if (newDrsResponce.status == 200) {
      setRowData(newDrsResponce?.data?.data);
    } else {
      console.log("something went");
    }
  }

  const rowHeight = 50;
  const [colDefs, setColDefs] = useState(
    [
      {
        headerName: "Sl.NO",
        valueGetter: "node.rowIndex + 1",
        filter: true,
        lockPosition: true,
      },
      {
        headerName: "Drs Number	",
        filter: true,
        field: "drs_no",
        cellRenderer: 'ButtonCellRenderer'
      }
    ])

  const DefaultColumnSetting = {
    sortable: true,
    filter: true,

    floatingFilter: true,
    flex: 1,
    resizable: true,
    minWidth: 120,
  };



  useEffect(() => {
    drsNewList()
  }, [])

  function ButtonCellRenderer(props) {
    const onClick = async () => {
      const { data } = props;
      const LocalData = localStorage.getItem("userDetails");
      const frenchise_id = JSON.parse(LocalData).id;
      setShowDrsNumber(false);
      const payload = {
        franchise_id: frenchise_id,
        drs_no: data?.drs_no
      };
      const drsPayload = JSON.stringify(payload)
      localStorage.setItem('drsPayload', drsPayload)
      const drsData = JSON.parse(localStorage.getItem('drsPayload'))
      setDrsStoredNumber(data?.drs_no)
      setLoading(true)
      const outwardApi = await Apiservice.postdata("/admin/courier_delivery_list", payload);
      if (outwardApi.error == false) {
        setLoading(false)
        setDrsModalStatus(true)
        const statusData = [
          { label: "Deverd", value: "Deverd" },
          { label: "door closed", value: "door closed" },
          { label: "persion not Available", value: "persion not Available" },
        ];
        setTableData(outwardApi.data);
        const updateStatusData = [];
        const statusMaping = outwardApi.data.map((ele, ind) => {
          updateStatusData.push({
            label: ele.delivery_status,
            value: ele.delivery_status,
          });
        });
      } else {
        setLoading(false)
      }
    };
    return <div>
      <b style={{ color: "blue" }} onClick={onClick}>{props.data.drs_no}
        {/* {
          <Spinner
            animation="border"
            variant="Primary"
            size="sm"
          />
        } */}
      </b>
    </div>;
  }

  //  userDetails = localStorage.getItem("userDetails")


  return (
    <div>
      {
        loading ?
          (
            <div className="overlay-loader">
              <div className="loader">
                <HashLoader color="#36D7B7" loading={loading} />
              </div>
            </div>
          )
          :
          ("")
      }
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        transition={Zoom}
        delay={1000}
        limit={1}
      />
      <CustomModal
        showPopupOpen={showPopupOpen}
        handleOkClick={handleOkClick}
        handleClosePopup={handleClosePopup}
        modalMessage={modalMessage}
      />

      <div className="more-link">
        <h6>Out For Delivery </h6>
      </div>
      <div>
        {showAddOutForDelivery && (
          <div className="my-3">
            <div className="card w-100">
              <div class="card-body pt-0">
                <div className="row">
                  <h6 class="card-title my-3 bg-light w-auto py-2 rounded ">
                    <FileText /> <b>Out For Delivery</b>
                  </h6>
                  {/* <h6 class="card-title my-3 bg-light w-auto py-2 rounded" style={{ marginLeft: '50px' }} >
                    <b>DRS Number:{" "}{drsNumberData?.message?.drs_no ? drsNumberData?.message?.drs_no : 0}</b>
                  </h6> */}
                </div>
                <div className="container">
                  <div class="row justify-content-end">
                    <div class="col-3 text-end">
                      <CustomButton title="Add Out For Delivery" colour="danger" onClick={AddOutForDeliveryForm} />
                    </div>
                  </div>
                </div>

                <form onSubmit={handleFastBookingSave}>
                  <div className="row ">
                    <div className="col-md-3 ">
                      <label for="formFile" class="form-label">
                        DRS NO
                      </label>
                      <input
                        type="number"
                        onChange={(e) => setDrsNumber(e.target.value)}
                        class="form-control "
                        value={drsNumber}
                      />
                    </div>
                    <div className="col-md-4 ">
                      <label for="formFile" class="form-label">
                        Delivery Boy
                      </label>
                      <Select
                        isMulti
                        options={deliveryBoyOptionData1}
                        value={
                          selectBoyName1[selectBoyName1.length - 1] || null
                        }
                        onChange={handleBoy1}
                      />
                      <span className="text-danger">{areaerror}</span>
                    </div>
                    <div className="col-md-3">
                      <label for="formFile" class="form-label">
                        Area
                      </label>
                      <Select
                        isMulti
                        options={areaOptionDataName}
                        value={areaName[areaName?.length - 1] || null}
                        onChange={(e) => setAreaName(e)}
                      />
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-md-3">
                      <Form.Label>DRS Status</Form.Label>
                      <Select
                        isMulti
                        options={status}
                        value={drsStatus[drsStatus?.length - 1] || null}
                        onChange={(e) => setDrsStatus(e)}
                      />
                    </div>
                    <div className="col-md-3" style={{ display: "none" }}>
                      <Form.Label>SCAN Status</Form.Label>
                      <Select options={SCANStatus} />
                    </div>
                    <div
                      className="col-md-3 d-flex"
                      style={{ marginTop: "32px" }}
                    >
                      <div>
                        <button
                          type="reset"
                          onClick={handleClear}
                          className="btn btn-danger btn-md rounded"
                        >
                          Reset
                        </button>
                      </div>
                      <div>
                        <button
                          type="submit"
                          className="btn btn-success btn-md rounded"
                          style={{ marginLeft: "10px" }}
                        >
                          Search{" "}
                          {searchLoader && (
                            <Spinner
                              animation="border"
                              variant="Primary"
                              size="sm"
                            />
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>

                <div className="row my-2 mt-4"></div>
              </div>
            </div>
          </div>
        )}
        {showAddOutForDeliveryForm && (
          <div className="my-3">
            <div className="card w-100">
              <div class="card-body pt-0">
                <div className="row">
                  <h6 class="card-title my-3 bg-light w-auto py-2 rounded ">
                    <FileText /> <b> Add Out For Delivery</b>
                  </h6>
                </div>
                <div className="container">
                  <div class="row justify-content-end">
                    <div class="col-3 text-end">
                      <CustomButton
                        title="Back"
                        colour="danger"
                        onClick={Back}
                      />
                    </div>
                    {!showAddOutForDeliveryForm && (
                      <div class="col-3 text-end">
                        <CustomButton title="Add Out For Delivery" onClick={AddOutForDeliveryForm} colour="danger" />
                      </div>
                    )}
                  </div>
                </div>
                <div className="row my-2">

                  <div className="col-md-3">
                    <label for="formFile" class="form-label">
                      Date and Time{" "}
                    </label>
                    <div className="custom-date-picker" style={{ marginLeft: "10px" }}>
                      <DateTimePicker
                        onChange={setDate}
                        value={date}
                        className=""
                        clearIcon={false}
                      />
                    </div>
                  </div>

                  <div className="col-md-3">
                    <label for="formFile" class="form-label">
                      Delivery Boy
                    </label>
                    <Select
                      isMulti
                      options={deliveryBoyOptionData}
                      value={selectBoyName[selectBoyName.length - 1] || null}
                      onChange={handleBoy}
                    />
                    <span className="text-danger">{boyerror}</span>
                  </div>

                  <div className="col-md-3">
                    <label for="formFile" class="form-label">
                      Area
                    </label>
                    <Select
                      isMulti
                      options={areaOptionData}
                      value={selectedArea[selectedArea.length - 1] || null}
                      onChange={handlearea}
                    />
                    <span className="text-danger">{areaerror}</span>
                  </div>
                </div>

                <div className="row mt-5">
                  <div className="col-md-3 mb-2 mt-note">
                    <Form.Group controlId="exampleForm.ControlTextarea1">
                      <Form.Check
                        inline
                        label="With Booking"
                        name="group1"
                        type="radio"
                        onClick={handleleft}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-3 mb-2 mt-note">
                    <Form.Group controlId="exampleForm.ControlTextarea1">
                      <Form.Check
                        inline
                        label="Without Booking"
                        name="group1"
                        type="radio"
                        onClick={handleright}
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="row mt-5">
                  <div
                    className="col-md-4 mb-2 mt-note"
                    style={{ display: showleftContent }}
                  >
                    <Form.Group controlId="exampleForm.ControlTextarea1">
                      <Form.Label>C-Note Number</Form.Label>
                      <Form.Control
                        as="textarea"
                        onChange={handleCnoteChange}
                        name="address"
                        rows={4}
                        value={cnoteNumber}
                      />
                    </Form.Group>
                    <span class="text-danger">{cnotevalid}</span>
                  </div>
                  <div
                    className="col-md-2 mt-btn"
                    style={{ display: showleftContent }}
                  >
                    <button
                      type="button"
                      className=" btn btn-primary btn-md rounded"
                      onClick={handleGetCNoteData}
                    >
                      Get Data{" "}
                      {searchCnoteLoader == true && (
                        <Spinner
                          animation="border"
                          variant="Primary"
                          size="sm"
                        />
                      )}
                    </button>
                  </div>

                  <div
                    className="col-md-12  mt-note"
                    style={{ display: showrightContent }}
                  >
                    <div className="row">
                      <div
                        className="col-md-3 mb-2 mt-6"
                        style={{ display: showrightContent }}
                      >
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                          <Form.Label>C-Note Number</Form.Label>
                          <Form.Control
                            as="textarea"
                            onChange={handleChange}
                            name="cnote"
                            rows={4}
                            value={dataName.cnote}
                          />
                          <span className="text-danger">
                            {FormFieldErrors.cnote}
                          </span>
                        </Form.Group>
                      </div>

                      <div
                        className="col-md-3 mb-2 mt-6"
                        style={{ display: showrightContent }}
                      >
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                          <Form.Label>Receiver Name</Form.Label>
                          <Form.Control
                            type="text"
                            value={dataName.receiverName}
                            as="input"
                            placeholder="Receiver Name"
                            name="receiverName"
                            onChange={handleChange}
                            autoComplete="off"
                          />
                          {/* <span className='text-danger'>{receivernameerror}</span> */}
                          <span className="text-danger">
                            {FormFieldErrors.receiverName}
                          </span>
                        </Form.Group>
                      </div>

                      <div
                        className="col-md-3 mb-2 mt-6"
                        style={{ display: showrightContent }}
                      >
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                          <Form.Label>Receiver Mobile</Form.Label>
                          <Form.Control
                            type="number"
                            value={dataName.receiverMobile}
                            placeholder="Receiver Mobile"
                            as="input"
                            name="receiverMobile"
                            maxLength={10}
                            onInput={(e) => {
                              if (e.target.value.length > e.target.maxLength)
                                e.target.value = e.target.value.slice(
                                  0,
                                  e.target.maxLength
                                );
                            }}
                            onChange={handleChange}
                            autoComplete="off"
                            onWheelCapture={(e) => e.target.blur()}
                          />
                          {/* <span className='text-danger'>{receivermoberror}</span> */}
                          <span className="text-danger">
                            {FormFieldErrors.receiverMobile}
                          </span>
                        </Form.Group>
                      </div>

                      <div
                        className="col-md-3 mb-2 mt-6"
                        style={{ display: showrightContent }}
                      >
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                          <Form.Label>No.Pcs</Form.Label>
                          <Form.Control
                            type="number"
                            value={dataName.noPcs}
                            as="input"
                            name="noPcs"
                            placeholder="No fo Pcs"
                            maxLength={4}
                            onInput={(e) => {
                              if (e.target.value.length > e.target.maxLength) {
                                e.target.value = e.target.value.slice(
                                  0,
                                  e.target.maxLength
                                );
                              }
                            }}
                            onChange={handleChange}
                            autoComplete="off"
                          />
                          {/* <span className='text-danger'>{noPcsError}</span> */}
                          <span className="text-danger">
                            {FormFieldErrors.noPcs}
                          </span>
                        </Form.Group>
                      </div>
                    </div>
                    {/* <div className="row"> */}

                    <div className="row">
                      <div
                        className="col-md-3 mb-2 mt-6"
                        style={{ display: showrightContent }}
                      >
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                          <Form.Label>Weight(Kgs)</Form.Label>
                          <Form.Control
                            type="number"
                            value={dataName.weightkgs}
                            as="input"
                            placeholder="weightkgs"
                            name="weightkgs"
                            maxLength={3}
                            onInput={(e) => {
                              if (e.target.value.length > e.target.maxLength) {
                                e.target.value = e.target.value.slice(
                                  0,
                                  e.target.maxLength
                                );
                              }
                            }}
                            onChange={handleChange}
                            autoComplete="off"
                          />
                          {/* <span className='text-danger'>{weighterror}</span> */}
                          <span className="text-danger">
                            {FormFieldErrors.weightkgs}
                          </span>
                        </Form.Group>
                      </div>

                      <div
                        className="col-md-3 mb-2 mt-6"
                        style={{ display: showrightContent }}
                      >
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                          <Form.Label>Weight(Grs)</Form.Label>
                          <Form.Control
                            type="number"
                            value={dataName.weighGrs}
                            as="input"
                            placeholder="weighGrs"
                            name="weighGrs"
                            maxLength={3}
                            onInput={(e) => {
                              if (e.target.value.length > e.target.maxLength) {
                                e.target.value = e.target.value.slice(
                                  0,
                                  e.target.maxLength
                                );
                              }
                            }}
                            onChange={handleChange}
                            autoComplete="off"
                          />
                          {/* <span className='text-danger'>{weighterror}</span> */}
                          <span className="text-danger">
                            {FormFieldErrors.weighGrs}
                          </span>
                        </Form.Group>
                      </div>

                      <div
                        className="col-md-3 mb-2 mt-6"
                        style={{ display: showrightContent }}
                      >
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                          <Form.Label>Remarks</Form.Label>
                          <Form.Control
                            type="text"
                            value={dataName.remarks}
                            as="input"
                            placeholder="Remarks"
                            name="remarks"
                            onChange={handleChange}
                            autoComplete="off"
                          />
                          {/* <span className='text-danger'>{remarkerror}</span> */}
                          <span className="text-danger">
                            {FormFieldErrors.remarks}
                          </span>
                        </Form.Group>
                      </div>

                      <div
                        className="col-md-2 my-3"
                        style={{ display: showrightContent }}
                      >
                        <button
                          type="button"
                          className=" btn btn-outline-primary btn-md rounded  mt-3"
                          onClick={haandleRemoveManualData}
                        >
                          Remove
                        </button>
                        <button
                          type="button"
                          style={{ marginLeft: '10px' }}
                          className=" btn btn-outline-primary btn-md rounded  mt-3"
                          onClick={handlemanualData}
                        >
                          Submit
                        </button>
                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                </div>

                {/* CNOTE */}
                {
                  showTable == "cnote" ? (
                    <div className="row my-2">
                      <table size="sm" className="table table-bordered table-hover text-center">
                        <thead>
                          <tr>
                            <td className="table-headings">Sr No.</td>
                            <td className="table-headings">C Note Number</td>
                            {/* <td className="table-headings">OGM Number</td> */}
                            <td className="table-headings">Receiver Name</td>
                            <td className="table-headings">Receiver Mobile</td>
                            <td className="table-headings">Wt.(Kgs)</td>
                            <td className="table-headings">Wt.(Gms)</td>
                            <td className="table-headings">Remarks</td>
                            {/* <td className="table-headings">{[10.578].toString().split('.')[1]}</td> */}
                          </tr>
                        </thead>
                        {
                          cnoteTableData && cnoteTableData.length &&
                            Object.keys(cnoteTableData[0])?.includes("OGM_no") !=
                            true
                            ?
                            (
                              <tbody>
                                {cnoteTableData?.map((e, i) => {
                                  return (
                                    <tr>
                                      <td className="py-2 ">{i + 1}</td>
                                      <td>{e.c_node_no || "NULL"}</td>
                                      {/* <td>{e.OGM_no || "NULL"}</td> */}
                                      <td>{e.delivery_name || "NULL"}</td>
                                      <td>{e.delivery_mobile || "NULL"}</td>
                                      <td>
                                        {[e.weight].toString().split(".")[0] ||
                                          "NULL"}
                                      </td>
                                      <td>
                                        {[e.weight].toString().split(".")[1] ||
                                          "NULL"}
                                      </td>
                                      <td>{e.remarks || "NULL"}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            )
                            :
                            ('')
                        }

                        {
                          cnoteTableData && cnoteTableData.length &&
                            Object.keys(cnoteTableData[0])?.includes("OGM_no") ==
                            true
                            ?
                            (
                              <tbody>
                                {cnoteTableData?.map((e, i) => {
                                  return (
                                    <tr>
                                      <td className="py-2 ">{i + 1}</td>
                                      <td>{e?.c_node_no || "NULL"}</td>
                                      {/* <td>{e?.OGM_no || "NULL"}</td> */}
                                      <td>{e.delivery_name || "NULL"}</td>
                                      <td>{e.delivery_mobile || "NULL"}</td>
                                      <td>
                                        {[e.weight].toString().split(".")[0] ||
                                          "NULL"}
                                      </td>
                                      <td>
                                        {[e.weight].toString().split(".")[1] ||
                                          "NULL"}
                                      </td>
                                      <td>{e.remarks || "NULL"}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            )
                            :
                            ('')
                        }
                        {
                          cnoteTableData.length == 0
                            ?
                            <tbody>
                              <tr>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                                <div>{" "}<p className="text-center my-2">  {" "}  Booking is not available for this C-note Number</p>{" "}</div>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                              </tr>
                            </tbody>
                            :
                            ''
                        }
                      </table>
                    </div>
                  ) : (
                    ""
                  )}

                {/* MANUAL */}
                {
                  showTable == "manual" ? (
                    <div className="row my-2">
                      <table
                        size="sm"
                        className="table table-bordered table-hover text-center"
                      >
                        <thead>
                          <tr>
                            <td className="table-headings">Sr No.</td>
                            <td className="table-headings">C Note Number</td>
                            <td className="table-headings">Receiver Name</td>
                            <td className="table-headings">Receiver Mobile</td>
                            <td className="table-headings">Wt.(Kgs)</td>
                            <td className="table-headings">Wt.(Gms)</td>
                            <td className="table-headings">Remarks</td>
                            {/* <td className="table-headings">{[10.578].toString().split('.')[1]}</td> */}
                          </tr>
                        </thead>
                        <tbody>
                          {
                            manualTableData && manualTableData != 0
                              ?
                              manualTableData?.map((ele, ind) => {
                                return (
                                  <tr>
                                    <td>{ind + 1}</td>
                                    <td>{ele.cnote}</td>
                                    <td>{ele.receiverName}</td>
                                    <td>{ele.receiverMobile}</td>
                                    <td>{ele.weightkgs}</td>
                                    <td>{ele.weighGrs}</td>
                                    <td>{ele.remarks}</td>
                                  </tr>
                                );
                              })
                              :
                              <tr>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                                <div>{" "}<p className="text-center my-2">  {" "}  Booking is not available for this C-note Number</p>{" "}</div>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                              </tr>
                          }
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    ""
                  )
                }

                <div className="row my-2 mt-4 text-center">
                  <div className="col-md-12">
                    <button
                      className="btn btn-success rounded mx-3"
                      onClick={handleSubmitOutForDeliver}
                    >
                      Add Out For Delivery{" "}
                      {
                        outForDeleveryLoader == true ? (
                          <Spinner animation="border" variant="Primary" size="sm" />
                        ) : (
                          ""
                        )
                      }
                    </button>

                    {/* add out for delivery print */}
                    {
                      <div
                        ref={componentRef}
                        className="container-fluid print-div"
                        style={main}
                      >
                        <div
                          className="text-center"
                          style={{ border: "2px solid black" }}
                        > 
                          <p className="m-0" style={{ fontWeight: "bold" }}>
                           {userDetails?.BusinessName + " , " + userDetails?.State }
                          </p>
                        </div>
                        <div className="d-flex">
                      
                          <div className="border-top-0" style={childEle}> 
                            {/* <span className="m-0">RunSheet No:{" "}</span>
                            <span></span>
                            <br />
                            <span className="m-0">Ref No:{" "}</span>
                            <span></span> */}
                            {/* <br /> */}
                            <span className="m-0">Area:{" "}{deliveryData && deliveryData?.selectedArea}</span>
                            <span>
                              {
                                //  cnoteTableData.length && cnoteTableData[0]?.Frenchises[0]
                                //     ?.city_masters[0]?.city_name
                              }
                            </span>
                          </div>
                          <div
                            className="border-top-0 border-start-0"
                            style={childEle}
                          >
                            <span className="m-0">Delivery Incharge:</span>
                            <span>{ selectBoyName[selectBoyName.length - 1]?.value}</span>
                            <br />
                            <span className="m-0">Date:</span>
                            <span>
                              {deliveryData.date} :{deliveryData.time}
                            </span>
                          </div>
                        </div>
                        <div style={smallEle}></div>
                        <div className="p-2 m-0" style={bigEle}>
                          <Table
                            className="m-0 text-center"
                            style={{ border: "2px solid black" }}
                            bordered
                            hover
                          >
                            <thead
                              style={{
                                backgroundColor: "#5f7780",
                                color: "#FFFFFF",
                                fontSize: "14px",
                              }}
                            >
                              <tr>
                                <th>SrN</th>
                                <th>C-Node No.</th>
                                <th>Origin Branch</th>
                                <th>Receiver name</th>
                                <th>WT/Qty</th>
                                <th>Stamp &#38; Signature</th>
                                <th>Remarks</th>
                              </tr>
                            </thead>
                            <tbody style={{ fontSize: "14px" }}>
                              {pdfData.map((ele, ind) => {
                                return (
                                  <tr>
                                    <td>{ind + 1}</td>
                                    <td>{ele.c_note_no}</td>
                                    <td>{userDetails?.city_masters[0]?.city_name}</td>
                                    <td>{ele.receiver_name}</td>
                                    <td className="px-0 pt-1 text-center">
                                      <span className="m-0">
                                        {ele.weight}kgs,
                                        <br />
                                        {ele.weight}gm
                                      </span>
                                      <br />
                                      <span
                                        className="m-0 d-block"
                                        style={{
                                          borderTop: "2px solid black",
                                        }}
                                      >
                                        {ele.no_pcs}
                                      </span>
                                    </td>
                                    <td></td>
                                    <td>{ele.remarks}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </div>
                        <div style={smallEle}></div>
                        <div className="text-center" style={mediumEle}>
                          <span className="m-0" style={{ fontWeight: "bold" }}>
                            {/* Total Count: */}
                          </span>
                          <span className="m-0" style={{ fontWeight: "bold" }}>
                            {cnoteTableData.length}
                          </span>{" "}
                          <span className="m-0" style={{ fontWeight: "bold" }}>
                            {/* Total Weight: */}
                          </span>
                          <span
                            className="m-0"
                            style={{ fontWeight: "bold", marginRight: "15px" }}
                          >
                            {
                              // calculatedWeight
                            }
                          </span>{" "}
                          <span className="m-0" style={{ fontWeight: "bold" }}>
                            {/* Total Pcs: */}
                          </span>
                          <span
                            className="m-0"
                            style={{ fontWeight: "bold" }}
                          ></span>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* POD Image */}

        {/* <div>
          <div className="card w-100 p-2">
            <table size="sm" className="table table-bordered table-hover text-center">
              <thead>
                <tr>
                  <th>Sr No.</th>
                  <th>C Note</th>
                  <th>Delivery Status</th>
                  <th>Receiver Name</th>
                  <th>Receiver Mobile</th>
                  <th>Weight</th>
                  <th>Drs Number</th>
                  <th>Update Status</th>
                  <th>POD Image</th>
                </tr>
              </thead>
              <tbody>
                {
                  tableData.length != 0
                    ?
                    tableData?.map((e, ind) => {
                      return (
                        <tr>
                          <td>{ind + 1}</td>
                          <td>{e.c_note_no}</td>
                          <td>{e.delivery_status}</td>
                          <td>{e.receiver_name}</td>
                          <td>{e.receiver_mobile}</td>
                          <td>{e.weight}</td>
                          <td>{e.drs_no}</td>
                          <td>
                            <div className="d-flex">
                              <div className="col-md-3" style={{ width: "200px" }}>
                                <Select
                                  defaultValue={
                                    e.delivery_status == "Dispatched"
                                      ? { label: "Dispatched", value: "Dispatched" }
                                      : "" || e.delivery_status == "Delivered"
                                        ? { label: "Delivered", value: "Delivered" }
                                        : "" || e.delivery_status == "Door closed"
                                          ? {
                                            label: "Door closed",
                                            value: "Door closed",
                                          }
                                          : "" ||
                                            e.delivery_status == "Persion not Available"
                                            ? {
                                              label: "Persion not Available",
                                              value: "Persion not Available",
                                            }
                                            : ""
                                  }
                                  options={status}
                                  onChange={(e) => setUpdateStus(e)}
                                />
                              </div>
                              <div>
                                <button
                                  className="btn btn-primary btn-md rounded"
                                  onClick={() => handleUpdatestatus(e)}
                                  style={{ marginLeft: "8px" }}
                                >
                                  Update{" "}
                                  {dispatchStatusLoader ? (
                                    <Spinner
                                      animation="border"
                                      variant="Primary"
                                      size="sm"
                                    />
                                  ) : (
                                    ""
                                  )}
                                </button>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div
                              className="d-flex"
                              style={{ width: "400px", alignItems: "center" }}
                            >
                              <div class="col-2">
                                <img
                                  height="50px"
                                  width="70px"
                                  src={
                                    indNumber == ind &&
                                      data2 ? data2 : "" ||
                                        e?.receipt_img_url
                                      ? e?.receipt_img_url
                                      : commonImage
                                  }
                                />
                              </div>
                              <div className="col-4">
                                <button
                                  className="btn btn-success btn-md rounded"
                                  onClick={() =>
                                    handleOpenWebcamPod("podImage", ind)
                                  }
                                  style={{ marginLeft: "10px" }}
                                >
                                  Select Image
                                </button>
                              </div>
                              <div className="col-5">
                                <button
                                  className="btn btn-primary btn-md rounded"
                                  onClick={() => handlePODImageUploadApiSubmit(e)}
                                >
                                  Upload Image{" "}
                                  {dispatchUploadLoader && indNumber == ind ? (
                                    <Spinner
                                      animation="border"
                                      variant="Primary"
                                      size="sm"
                                    />
                                  ) : (
                                    ""
                                  )}
                                </button>
                              </div>
                              <div
                                onClick={() => handleShowImage(e)}
                                className="col-1"
                              >
                                <AiFillEye size="30px" />
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                    :
                    <tr>
                      <td> </td>
                      <td> </td>
                      <td> </td>
                      <td> </td>
                      <div>{" "}<p className="text-center my-2">  {" "}  Out For Delivery list is not available</p>{" "}</div>
                      <td> </td>
                      <td> </td>
                      <td> </td>
                      <td> </td>
                    </tr>
                }
              </tbody>
            </table>
          </div>
        </div> */}

        <div
          className="ag-theme-alpine"
          style={{ height: "70vh", width: "100%", marginTop: "30px" }}
        >
          <AgGridReact
            rowHeight={rowHeight}
            columnDefs={colDefs}
            defaultColDef={DefaultColumnSetting}
            pagination={true}
            paginationPageSize={10}
            rowData={rowData}
            // getRowStyle={getRowStyle}
            // onCellClicked={cellClickedHandler}
            frameworkComponents={{
              ButtonCellRenderer
            }}
          />{" "}
        </div>



        {/* DRS Image */}
        {showDrsNumber == true && show == true ? (
          <div className="card w-100 p-2 mt-3">
            <div className="row ">
              <div>
                <div className="row" style={{ textAlign: "center" }}>
                  <div class="upload-btn-wrapper col-8">
                    <img
                      height={
                        tableData[0]?.drs_data[0]?.drs_image == null
                          ? "50px"
                          : "800px"
                      }
                      width={
                        tableData[0]?.drs_data[0]?.drs_image == null
                          ? "50px"
                          : "50%"
                      }
                      src={
                        tableData[0]?.drs_data[0]?.drs_image == null &&
                          drsData1 == null
                          ? commonImage
                          : "" || drsData1 != null
                            ? drsData1
                            : tableData[0]?.drs_data[0]?.drs_image
                      }
                    />
                  </div>
                  <div className="col-2">
                    <button className="btn btn-success btn-md rounded" onClick={() => handleOpenWebcamDrs("drsImage")}  >Select Image </button>
                  </div>
                  <div className="col-2">
                    <button className="btn btn-primary btn-md rounded" onClick={handleDrsFileUploadApi}>
                      Upload Image{" "}
                      {deliveryUploadLoader ? (
                        <Spinner
                          animation="border"
                          variant="Primary"
                          size="sm"
                        />
                      ) : (
                        ""
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>


      {/* POD Modal */}
      <div>
        <Modal show={podModal} onHide={PodModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Choose Document Upload Type</Modal.Title>
          </Modal.Header>
          <Modal.Body className="show-grid">
            <Container>
              <div className="row justify-content-center g-0">
                <div className="col-md-6">
                  <div className="card col-10 p-2 text-center">
                    <label> Web Camera Image Upload</label>
                    <div className="pb-3 mt-2">
                      <BsFillCameraFill
                        onClick={openWebCam}
                        style={{ fontSize: "35px" }}
                      ></BsFillCameraFill>
                    </div>
                  </div>
                </div>
                <div className="col-md-5" style={{ marginLeft: "10px" }}>
                  <div className="card col-12 p-2 text-center">
                    <label>Local File Upload</label>
                    <Form.Group controlId="formFile" className="pb-3 mt-4 ">
                      <Form.Control
                        type="file"
                        onChange={(e) => handlePodUploadImage(e)}
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>
            </Container>
          </Modal.Body>
        </Modal>
      </div>
      <div>
        {showWebCamePod && (
          <div>
            <Modal show={showPopupOpenWebcam} onHide={handleClosePopupWebcam}>
              <Modal.Header closeButton>
                <Modal.Title>POD Image</Modal.Title>
              </Modal.Header>
              <Modal.Body className="show-grid">
                <Container>
                  <Row>
                    <Webcam ref={webRef} />
                  </Row>
                </Container>
              </Modal.Body>
              <Modal.Footer className="m-auto">
                <Button
                  variant="success"
                  className=" rounded"
                  onClick={hanadleTakePhotoWebcamPod}
                >
                  Take Photo{" "}
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        )}
        {showWebCameDrs && (
          <div>
            <Modal show={showPopupOpenWebcam} onHide={handleClosePopupWebcam}>
              <Modal.Header closeButton>
                <Modal.Title>DRS Image</Modal.Title>
              </Modal.Header>
              <Modal.Body className="show-grid">
                <Container>
                  <Row>
                    <Webcam ref={webRef} />
                  </Row>
                </Container>
              </Modal.Body>
              <Modal.Footer className="m-auto">
                <Button
                  variant="success"
                  className=" rounded"
                  onClick={hanadleTakePhotoWebcamDrs}
                >
                  Take Photo{" "}
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        )}
      </div>
      {/* DRS Status Modal */}
      <div>
        <Modal show={drsModalStatus}
          onHide={() => setDrsModalStatus(false)}
          size="xl"
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Out For Delivery Modal</Modal.Title>
          </Modal.Header>
          <Modal.Body className="show-grid">
            <div className="d-flex" >
              <span> <h5> DRS Number : </h5></span>
              <span> <h4 className="text-danger" >{drsStoredNumber ? drsStoredNumber : '0'} </h4></span>
            </div>
            <div>
              <div className="card col-md-12 p-2">
                <table size="sm" className="table table-bordered table-hover text-center">
                  <thead>
                    <tr>
                      <th>Sr No.</th>
                      <th>C Note</th>
                      <th>Delivery Status</th>
                      <th>Receiver Name</th>
                      <th>Receiver Mobile</th>
                      <th>Weight</th>
                      {/* <th>Drs Number</th> */}
                      <th>Update Status</th>
                      <th>POD Image</th>
                    </tr>
                  </thead>
                 
                  <tbody>
                    {
                      tableData.length != 0
                        ?
                        tableData?.map((e, ind) => {
                          return (
                            <tr>
                              <td>{ind + 1}</td>
                              <td>{e.c_note_no}</td>
                              <td>{e.delivery_status}</td>
                              <td>{e.receiver_name}</td>
                              <td>{e.receiver_mobile}</td>
                              <td>{e.weight}</td>
                              {/* <td>{e.drs_no}</td> */}
                              <td>
                            
                                <div className="d-flex">
                                  <div className="col-md-3"
                                    style={{ width: "120px" }}
                                  >
                                 
                                <Select

  options={status}
  onChange={(selectedOption) => setUpdateStus(selectedOption)}
/>


                                  </div>
                                  <div>
                                    <button
                                      className="btn btn-primary btn-md rounded"
                                      onClick={() => handleUpdatestatus(e, ind)}
                                      style={{ marginLeft: "8px" }}
                                    >
                                      Update{" "}
                                      {dispatchStatusLoader && ind == updateStatusIndex ? (
                                        <Spinner
                                          animation="border"
                                          variant="Primary"
                                          size="sm"
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </button>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div
                                  className="d-flex"
                                  style={{ width: "400px", alignItems: "center" }}
                                >
                                  <div class="col-2">
                                    <img
                                      height="50px"
                                      width="70px"
                                      src={
                                        indNumber == ind &&
                                          data2 ? data2 : "" ||
                                            e?.receipt_img_url
                                          ? e?.receipt_img_url
                                          : commonImage
                                      }
                                    />
                                  </div>
                                  <div className="col-4">
                                    <button
                                      className="btn btn-success btn-md rounded"
                                      onClick={() =>
                                        handleOpenWebcamPod("podImage", ind)
                                      }
                                      style={{ marginLeft: "10px" }}
                                    >
                                      Select Image
                                    </button>
                                  </div>
                                  <div className="col-5">
                                    <button
                                      className="btn btn-primary btn-md rounded"
                                      onClick={() => handlePODImageUploadApiSubmit(e)}
                                    >
                                      Upload Image{" "}
                                      {dispatchUploadLoader && indNumber == ind ? (
                                        <Spinner
                                          animation="border"
                                          variant="Primary"
                                          size="sm"
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </button>
                                  </div>
                                  <div
                                    onClick={() => handleShowImage(e)}
                                    className="col-1"
                                  >
                                    <AiFillEye size="30px" />
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                        :
                        <tr>
                          <td> </td>
                          <td> </td>
                          <td> </td>
                          <td> </td>
                          <div>{" "}<p className="text-center my-2">  {" "}  Out For Delivery list is not available</p>{" "}</div>
                          <td> </td>
                          <td> </td>
                          <td> </td>
                          <td> </td>
                        </tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="m-auto">
          </Modal.Footer>
        </Modal>
      </div>

    </div>
  );
}

export default OutForDelivery;
