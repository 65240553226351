import { connect } from "react-redux";
import Dashboard from "../../Dashboard";

import { alldata } from "../Action/Action";

const mapDispatchToProps = dispatch => ({
    fetchdatahandler: data => dispatch(alldata())
})

const mapStateToProps = state => ({})

export default connect(mapDispatchToProps, mapStateToProps)(Dashboard);