import React, { useEffect, useState, useRef } from "react";
import { Form, Spinner, Table } from "react-bootstrap";
import { FileText } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import Apiservice from "../Services/Apiservice";
import { toast, ToastContainer, Zoom } from "react-toastify";
import { AgGridReact } from "ag-grid-react";
import Select from "react-select";
import { useReactToPrint } from "react-to-print";



function Wallet() {
  const [walletamount, setWalletamount] = useState();
  const [rowData, setRowData] = useState(null)
  /** Date States**/
  const [dateOne, setDate] = useState("");
  const [cNoteFilterFromTo, setCNoteFilterFromTo] = useState({ from: "", to: "" })
  const [dateFieldErrorFrom, setDateFieldErrorFrom] = useState('')
  const [dateFieldErrorTo, setDateFieldErrorTo] = useState('')
  const [subDateLoader, setSubDateLoader] = useState(false)
  const [selectedBranchName, setSelectedBranchName] = useState([]);
  const [selectedBranchNameError, setSelectedBranchNameError] = useState('')
  const [allBranchList, setAllBranchList] = useState([]);
  const [selectPosButton, setSelectPosButton] = useState(true)
  // const [charges, setCarges] = useState([{ newKey: "", newValue: "", villaId: Number(new Date()) }]);
  const [charges, setCarges] = useState([]);
  const [chargesError, setCargesError] = useState("");
  const [finalCharges, setfinalCarges] = useState(0);
  const [outwardListPrintingData, setOutwardListPrintingData] = useState([])
  const [finalData, setFinalData] = useState([])
  const [userData, setUserData] = useState([])
  const [currentDate, setCurrentDate] = useState("")
  const [totalAmount, setTotalAmount] = useState()
  const [totalWeight, setTotalWeight] = useState()
  const [totalPieces, setTotalPieces] = useState()
  const [buttonTrue, setButtonTrue] = useState(false)



  const main = {
    width: "100%",
    marginRight: "auto",
    marginLeft: "8px",
  };
  const bigEle = {
    border: "2px solid black",
  };
  const componentRef = useRef();
  const handelprint = useReactToPrint({ content: () => componentRef.current });

  useEffect(() => {
    // User Details
    const data = JSON.parse(localStorage.getItem("userDetails"));
    setUserData(data)

    // Date and Time
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    const todayDate = yyyy + "-" + mm + "-" + dd;
    setCurrentDate(todayDate)
    setCNoteFilterFromTo({
      ...cNoteFilterFromTo,
      from: todayDate,
      to: todayDate
    })

    // var d = new Date();
    // d.getHours(); // => 9
    // d.getMinutes(); // =>  30
    // d.getSeconds();

    // var hh = d.getHours();
    // var mm = d.getMinutes();

    // const todayTime = hh + ":" + mm;
    // setTime(todayTime);

    // if (data?.city_masters?.length > 0) {
    handleGetListCity();
    // }

  }, [])

  const handleGetListCity = async () => {
    try {
      let AreaNameData = await Apiservice.postdata(`/User/listCity`, {
        city_name: "",
        isNull: true,
      });

      if (AreaNameData.error == false) {
        if (JSON.parse(localStorage.getItem("userDetails"))?.Hub_type == 'NULL') {
          const filterNotAssignedFranchise = AreaNameData?.data.filter(item => item.Frenchises != 0)
          const areaData = [];
          const areaMapData = filterNotAssignedFranchise.map((ele, ind) => {
            areaData.push({
              label: ele.city_name,
              value: ele.city_name,
              id: ele,
            });
          });
          setAllBranchList(areaData);
        } else {
          const filterNotAssignedFranchise = AreaNameData?.data && AreaNameData?.data?.filter(item => item.Frenchises != 0)
          const allCityList = filterNotAssignedFranchise.filter((ele, ind) => {
            return ele.city_name != JSON.parse(localStorage.getItem("userDetails"))?.city_masters[0].city_name;
          });
          const areaData = [];
          const areaMapData = allCityList.map((ele, ind) => {
            areaData.push({
              label: ele.city_name,
              value: ele.city_name,
              id: ele,
            });
          });
          setAllBranchList(areaData);
        }

      } else {
        console.log("Error fetching city list");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  }


  // GET wallet history
  const handleGetWalletHistory = async () => {
    if (userData?.Hub_type == 'NULL') {
      const wallethistory = await Apiservice.postdata("/booking/wallethistory");
      if (wallethistory.error == false) {
        setRowData(wallethistory.data)
        setFinalData(wallethistory)
      }
    }
    if (userData?.Hub_type == 'SUPER') {
      const payload = {
        frenchise_id: userData?.id,
      };
      const wallethistory = await Apiservice.postdata("/booking/wallethistory", payload);
      if (wallethistory.error == false) {
        setRowData(wallethistory.data)
        setFinalData(wallethistory)
      }
    }
    if (userData?.Hub_type == 'LOCAL') {
      const payload = {
        frenchise_id: userData.id,
      };
      const wallethistory = await Apiservice.postdata("/booking/wallethistory", payload);
      if (wallethistory.error == false) {
        setRowData(wallethistory.data)
        setFinalData(wallethistory)
      }
    }
  }

  useEffect(() => {
    handleGetWalletHistory()
  }, [userData]);

  /**** filter date wise ****/
  const onchangedate = (event) => {
    setCNoteFilterFromTo({ ...cNoteFilterFromTo, [event.target.name]: event.target.value })
  }
  const handelFromToSubmit = async (e) => {
    e.preventDefault()
    // if (userData?.Hub_type == 'NULL') {
      if (cNoteFilterFromTo.from != '' && cNoteFilterFromTo.to != '' && selectedBranchName.length != 0) {
        const payload = {
          dateFrom: cNoteFilterFromTo.from == '' ? dateOne : cNoteFilterFromTo.from,
          dateTo: cNoteFilterFromTo.to == '' ? dateOne : cNoteFilterFromTo.to,
          frenchise_id: selectedBranchName[selectedBranchName.length - 1]?.id.Frenchises[0]?.id
        }
        // if (JSON.parse(localStorage.getItem("userDetails"))?.Hub_type = 'NULL') {
        //   payload.source_franchise_id = selectedBranchName[selectedBranchName.length - 1]?.id.Frenchises[0]?.id
        // }
        setSubDateLoader(true)
        const cnoteData = await Apiservice.postdata("/booking/wallethistory", payload)
        if (cnoteData.error == false && cnoteData.data.length != 0) {
          setSubDateLoader(false)
          setRowData(cnoteData?.data)

          // Total Amount
          let totalFreight = 0;
          let totalWeight = 0;
          let totalPieces = 0;

          cnoteData?.data?.forEach(ele => {
            if (ele?.order_transactions && ele?.order_transactions[0]?.freight) {
              totalFreight += +ele.order_transactions[0].freight;
              totalWeight += +ele.order_transactions[0].weight;
              totalPieces += +ele.order_transactions[0].no_pcs;
            }
          });
          setTotalAmount(totalFreight)
          setTotalWeight(totalWeight)
          setTotalPieces(totalPieces)
          setFinalData(cnoteData)
          toast.success(cnoteData.message)
          setButtonTrue(true)
        }
        else {
          setSubDateLoader(false)
          toast.error('No data was found within this date range')
          setRowData(null)
        }
      } else {
        if (cNoteFilterFromTo.from == '') {
          setDateFieldErrorFrom('please select from date')
        }
        if (cNoteFilterFromTo.to == '') {
          setDateFieldErrorTo('please select to date')
        }
        if (selectedBranchName.length == 0) {
          setSelectedBranchNameError('Please select branch')
        }
      // }
    }

    // if (userData?.Hub_type != 'NULL') {
    //   if (cNoteFilterFromTo.from != '' && cNoteFilterFromTo.to != '' && selectedBranchName.length != 0) {
    //     const payload = {
    //       dateFrom: cNoteFilterFromTo.from == '' ? dateOne : cNoteFilterFromTo.from,
    //       dateTo: cNoteFilterFromTo.to == '' ? dateOne : cNoteFilterFromTo.to,
    //       frenchise_id: userData.id,
    //       source_franchise_id: selectedBranchName[selectedBranchName.length - 1]?.id.Frenchises[0]?.id
    //     }
    //     setSubDateLoader(true)
    //     const cnoteData = await Apiservice.postdata("/booking/wallethistory", payload)
    //     if (cnoteData.error == false && cnoteData.data.length != 0) {
    //       setSubDateLoader(false)
    //       setRowData(cnoteData?.data)
    //       setFinalData(cnoteData)
    //       toast.success(cnoteData.message)
    //     }
    //     else {
    //       setSubDateLoader(false)
    //       toast.error('No data found.')
    //       setRowData(null)

    //     }
    //   } else {

    //     if (cNoteFilterFromTo.from == '') {
    //       setDateFieldErrorFrom('please select from date')
    //     }
    //     if (cNoteFilterFromTo.to == '') {
    //       setDateFieldErrorTo('please select to date')
    //     }
    //     if (selectedBranchName.length == 0) {
    //       setSelectedBranchNameError('Please select branch')
    //     }
    //   }
    // }
  }


  // get wallet amount
  useEffect(async () => {
    const one = localStorage.getItem("userDetails");
    const payload = { frenchise_id: JSON.parse(one).id };
    const walletamount = await Apiservice.postdata("/booking/walletamount", payload);
    if (walletamount.error == false) {
      // if (walletamount.data.length == "1") {
      //   setWalletamount(walletamount.data[0].total_amount);
      // } else if (walletamount.data.length > 1) {
      //   if (
      //     walletamount.data[1].type == "Cr" &&
      //     walletamount.data[0].type == "Dr"
      //   ) {
      //     setWalletamount(
      //       walletamount.data[1].total_amount -
      //       walletamount.data[0].total_amount
      //     );
      //   } else if (
      //     walletamount.data[0].type == "Cr" &&
      //     walletamount.data[1].type == "Dr"
      //   ) {
      //     setWalletamount(
      //       walletamount.data[0].total_amount -
      //       walletamount.data[1].total_amount
      //     );
      //   }
      // }

      setWalletamount( walletamount?.data?.walletamount ? walletamount?.data?.walletamount : 0)
    } else {
      console.log("get API is not working");
    }
  }, []);

console.log(rowData ,"aaaa")



  const rowHeight = 50;
  const DefaultColumnSetting = {
    sortable: true,
    filter: true,

    floatingFilter: true,
    flex: 1,
    resizable: true,
    minWidth: 120,
  };

  const [colDefs, setColDefs] = useState([
    {
      headerName: "Sl.No",
      valueGetter: "node.rowIndex + 1",
      filter: true,
      lockPosition: true,
    },
    // {
    //   headerName: "Credit/Debit",
    //   filter: true,
    //   field: "type",
    // },
    {
      headerName: "Credit/Debit",
      filter: true,
      cellRendererFramework: (params) => (<div>
        <p>{params?.data?.type == 'Cr' ? 'Credited' : 'Debited'}</p>
      </div>)
    },
    {
      headerName: "Amount",
      filter: true,
      field: "debit_credit",
    },
    {
      headerName: "Name",
      filter: true,
      valueGetter: "data.Frenchises[0]?.ContactPersion",
    },
    // {
    //   headerName: "Origin",
    //   filter: true,
    //   valueGetter: "data.Frenchises[0]?.city_masters[0]?.city_name",
    // },
    // {
    //   headerName: "Destination",
    //   filter: true,
    //   valueGetter: "data.transFrenchise.city_masters[0]?.city_name",
    // },
    {
      headerName: "Origin",
      filter: true,
      cellRendererFramework: (params) => (
        <div>
          {" "}
          {
            params?.data.type == 'Dr'
              ?
              (<p>{params?.data.Frenchises && params?.data.Frenchises[0]?.city_masters[0]?.city_name}</p>)
              :
              (<p>{params?.data.transFrenchise && params?.data.transFrenchise.city_masters[0]?.city_name}</p>) 
          }
        </div>
      ),
    },
    {
      headerName: "Destination",
      filter: true,
      cellRendererFramework: (params) => (
        <div>
          {" "}
          {
            params?.data.type == 'Cr'
              ?
              (<p>{params?.data.Frenchises && params?.data.Frenchises[0]?.city_masters[0]?.city_name}</p>)
              :
              (<p>{params?.data.transFrenchise && params?.data.transFrenchise.city_masters[0]?.city_name}</p>)
          }
        </div>
      ),
    },

    {
      headerName: "Date/Time",
      filter: true,
      field: "createdAt",
    },

  ]);


  const getRowStyle = (params) => {
    if (params?.data?.type == 'Cr') {
      return {
        color: 'white',
        background: '#0033FF'
      };
    }
    else {
      return {
        color: 'white',
        background: '#FF3366'
      };
    }
  };
  const showingDates = () => {
    var dtToday = new Date();
    var month = dtToday.getMonth() + 1;
    var day = dtToday.getDate();
    var year = dtToday.getFullYear();
    if (month < 10) month = "0" + month.toString();
    if (day < 10) day = "0" + day.toString();
    var hideDates = year + "-" + month + "-" + day;
    return hideDates;
  }


  useEffect(() => {
    if (cNoteFilterFromTo.from != '') {
      setDateFieldErrorFrom('')
    }
    if (cNoteFilterFromTo.to != '') {
      setDateFieldErrorTo('')
    }
  }, [cNoteFilterFromTo])


  const handleDateClear = () => {
    const todayDate = '' + "-" + '' + "-" + '';
    setDate(todayDate);
    setCNoteFilterFromTo({
      ...cNoteFilterFromTo,
      from: "",
      to: ""
    })
    setSelectedBranchName([])
  }


  useEffect(() => {
    if (selectedBranchName.length != 0) {
      setSelectedBranchNameError('')
    } else {
      // setSelectedBranchNameError('Please selct branch')
    }
  }, [selectedBranchName])


  const handleRemoveInput = () => {
    setSelectPosButton(false)
    if (charges.length > 0) {
      const newVillas = [...charges];
      newVillas.pop();
      setCarges(newVillas);
    }
  };

  const handleAddInput = () => {
    setSelectPosButton(true);
    const newVilla = { newKey: "", newValue: "", villaId: Number(new Date()) };
    setCarges([...charges, newVilla]);
  };


  const handleInputChange = (e, index, key) => {
    const newVillas = [...charges];
    newVillas[index][key] = e.target.value;
    setCarges(newVillas);
  };


  const handleExport = () => {
    if (charges.length != 0) {
      if (charges[0].newKey != '' && charges[0].newValue != '') {
        const hasEmptyFields = charges.some(charge => !charge.newKey || !charge.newValue);
        if (hasEmptyFields) {
          // toast.error("Please fill all fields")
        } else {
          // handelprint();
          if (cNoteFilterFromTo.from != '' && cNoteFilterFromTo.to != '' && selectedBranchName.length != 0) {
            handelprint();
          } else {
            if (cNoteFilterFromTo.from == '') {
              setDateFieldErrorFrom('please select from date')
            }
            if (cNoteFilterFromTo.to == '') {
              setDateFieldErrorTo('please select to date')
            }
            if (selectedBranchName.length == 0) {
              setSelectedBranchNameError('Please select branch')
            }
          }
        }
      } else {
        // toast.error("Please fill all fields")
      }
    } else {
      // handelprint();
      if (cNoteFilterFromTo.from != '' && cNoteFilterFromTo.to != '' && selectedBranchName.length != 0) {
        handelprint();
      } else {
        if (cNoteFilterFromTo.from == '') {
          setDateFieldErrorFrom('please select from date')
        }
        if (cNoteFilterFromTo.to == '') {
          setDateFieldErrorTo('please select to date')
        }
        if (selectedBranchName.length == 0) {
          setSelectedBranchNameError('Please select branch')
        }
      }
    }
  }


  useEffect(() => {
    setOutwardListPrintingData(rowData)
  }, [rowData])

  useEffect(() => {
    const totalSum = charges.reduce((accumulator, item) => {
      return accumulator + parseInt(item.newValue);
    }, 0);
    setfinalCarges(totalSum)
    const hasEmptyFields = charges.some(charge => !charge.newKey || !charge.newValue);
    if (hasEmptyFields) {
      setCargesError("All fields are required")
    } else {
      setCargesError("")
    }
  }, [charges])

  const grandTotal = finalCharges + totalAmount + finalData?.totalAmount
  const tableStyle = {
        borderCollapse: 'collapse',
        width: '100%',
        border: '1px solid #ddd',
        tableLayout: 'auto',
      };
      const cellStyle = {
        border: '1px solid #ddd',
        padding: '8px',
        textAlign: 'center',
      };
      const cellStyle2 = {
        border: '1px solid #ddd',
        padding: '8px',
      };

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        transition={Zoom}
        delay={1000}
        limit={1}
      />

      <div>
        <div className="card w-100 mt-3">
          <div class="card-body pt-0">
            <div className="row ">
              <div className="col-md-2">
                <h6 class="card-title  my-3 bg-light w-auto py-2 rounded ">
                  <FileText /> <b>Wallet History</b>
                </h6>
              </div>

              <div className="col-md-6 pt-3">
                {
                  userData.Hub_type != 'NULL' && <h5> Total Wallet Amount : {
                    parseFloat(walletamount).toFixed(2) == 'NaN' ?
                      <span>{" "}
                        <Spinner
                          animation="border"
                          variant="Primary"
                          size="sm"
                        />
                      </span>
                      : parseFloat(walletamount).toFixed(2)
                  }</h5>
                }
              </div>
            </div>
            <div className='col' >
              <form onSubmit={handelFromToSubmit} >
                <div className="row">
                  <div className="col-md-12" >
                    <div className="row" >
                      <div className="col-md-3">
                        <label>Branch Name</label>
                        <div className="mt-2">
                          <Select
                            isMulti
                            options={allBranchList}
                            value={selectedBranchName[selectedBranchName.length - 1] || null}
                            onChange={setSelectedBranchName}
                          />
                        </div>
                        <span className="text-danger" >{selectedBranchNameError}</span>
                      </div>
                      <div className="col-md-3">
                        {" "}
                        <Form.Label>From Date</Form.Label>
                        <Form.Control
                          type="date"
                          value={cNoteFilterFromTo.from == '' ? dateOne : cNoteFilterFromTo.from}
                          onChange={onchangedate}
                          name="from"
                          format="DD/MM/YYYY"
                        />
                        <span className="text-danger" >{dateFieldErrorFrom}</span>
                      </div>

                      <div className="col-md-3">
                        <Form.Label>To Date</Form.Label>
                        <Form.Control
                          type="date"
                          value={cNoteFilterFromTo.to == '' ? dateOne : cNoteFilterFromTo.to}
                          onChange={onchangedate}
                          name="to"
                          format="DD/MM/YYYY"
                        // min={showingDates()}
                        />
                        <span className="text-danger" >{dateFieldErrorTo}</span>
                      </div>
                      <div className="col-md-3 mt-3">
                        <button
                          className="btn btn-danger rounded mx-3  text-white mt-3"
                          type="reset"
                          onClick={handleDateClear}
                        >
                          Clear
                        </button>
                        <button
                          className="btn btn-success rounded mx-3  text-white mt-3"
                          type='submit'
                        >
                          Submit{" "}
                          {subDateLoader ? (
                            <Spinner
                              animation="border"
                              variant="Primary"
                              size="sm"
                            />
                          ) : (
                            ""
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div
              className="ag-theme-alpine"
              style={{ height: "70vh", width: "100%", marginTop: "30px" }}
            >
              <AgGridReact
                rowHeight={rowHeight}
                // columnDefs={columns}
                columnDefs={colDefs}
                defaultColDef={DefaultColumnSetting}
                pagination={true}
                paginationPageSize={10}
                // onGridReady={onGridReady}
                rowData={rowData}
                getRowStyle={getRowStyle}
              // frameworkComponents={{
              //   ButtonCellRenderer
              // }}
              />
            </div>

            {/* new fields */}
            {
              JSON.parse(localStorage.getItem("userDetails")) && JSON.parse(localStorage.getItem("userDetails"))?.Hub_type == 'NULL' 
                ?
                <div className="row m-1" >
                  <div className="col-6 p-2 card m-0 p-0 pe-3 overflow-auto caterogyPageScroll-internal">
                    <div className="">
                      <b className="societyinternalp">Add Extra Charges:</b>
                      <div className="row" >
                        <div className="col-6 mt-3" >
                          <div className="d-flex col-2" >
                            <button className={`${selectPosButton == false ? 'societyinternalfloorbutton' : 'societyinternalfloorbuttonactive'}`} onClick={handleAddInput}>Add</button>
                            <button className={`${selectPosButton == false ? 'societyinternalfloorbuttonactive' : 'societyinternalfloorbutton'}`} onClick={handleRemoveInput} style={{ marginLeft: '5px' }} >Remove</button>
                          </div>
                          <div className="mt-3">
                            <span className="text-danger">{chargesError}</span>
                          </div>
                        </div>
                        <div className="col-6 mt-3" >
                          {
                            rowData && rowData?.length > 0
                              ?
                              <div>
                                {
                                  buttonTrue
                                    ?
                                    <button className="btn btn-danger  btn-md rounded" onClick={handleExport} >Export Report</button>
                                    :
                                    ''
                                }
                              </div>
                              :
                              ''
                          }
                        </div>
                      </div>
                      <div className="d-flex" >
                        <div className="col-6" >
                          {
                            charges?.map((ele, index) => (
                              <div key={ele.villaId}>
                                <div className="d-flex align-items-center gap-2">
                                  <div>
                                    <input
                                      name="newKey"
                                      type="text"
                                      value={ele.newKey}
                                      onChange={(e) => handleInputChange(e, index, 'newKey')}
                                      autoComplete="off"
                                      className={`placeholder-light form-control my-2 py-2 px-3 w-100`}
                                      placeholder="Enter Key"
                                    />
                                  </div>
                                  <div>
                                    <input
                                      name="newValue"
                                      type="number"
                                      value={ele.newValue}
                                      onChange={(e) => handleInputChange(e, index, 'newValue')}
                                      autoComplete="off"
                                      className={`placeholder-light form-control my-2 py-2 px-3 w-100`}
                                      placeholder="Enter Value"
                                    />
                                  </div>
                                </div>
                              </div>
                            ))
                          }
                        </div>
                        {/* <div className=" d-flex col-2" style={{ marginLeft: '10px', marginTop: '8px' }} >
                    <button className={`${selectPosButton == false ? 'societyinternalfloorbutton' : 'societyinternalfloorbuttonactive'}`} onClick={handleAddInput}>Add</button>
                    <button className={`${selectPosButton == false ? 'societyinternalfloorbuttonactive' : 'societyinternalfloorbutton'}`} onClick={handleRemoveInput} style={{ marginLeft: '5px' }} >Remove</button>
                  </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-6" >
                    {/* <button className="btn btn-danger  btn-md rounded mt-4" onClick={handleExport} >Export Report</button> */}
                  </div>
                </div> : ""}
              
             

            <div>
              {
                <div
                  ref={componentRef}
                  className="container-fluid print-div"
                  // style={main}
                >
                  {
                    <div className="p-2 m-0 "
                    
                    //  style={bigEle}
                     >
                      <div>
                     

                        <div className="row m-1 text-center" >
                          <b style={{ fontSize: '25px' }} >Tax Invoice</b>
                          <b style={{ fontSize: '25px', marginTop: "10px" }} >MOHAN COURIER SERVICES</b>
                          <b>NO.38, 8TH CROSS, NEAR 6TH MAIN, SAMPANGIRAM NAGAR, BANGALORE-560027</b>
                          <b>madhurbanglore@yahoo.in</b>
                        </div>
                        <div className="d-flex" style={{ marginTop: "20px" }} >
                          <div className="col-6" >
                            <table class="table table-bordered" >
                              <tbody>
                                <tr>
                                  <th scope="row">M/s.</th>
                                  {/* <td><b>MOHAN COURIER SERVICE</b></td> */}
                                  <td><b>{selectedBranchName[selectedBranchName.length - 1]?.id?.Frenchises[0]?.BusinessName }</b></td>

                                  {/* <td><b>{selectedBranchName[selectedBranchName.length - 1]?.id?.city_name}</b></td> */}
                                </tr>
                                <tr>
                                  <th scope="row">Address</th>
                                  <td>{selectedBranchName[selectedBranchName.length - 1]?.id?.Frenchises[0].Address}</td>
                                  {/* No.38, 8th Cross, Near 6th Main, Sampanigiram Nagar, BANGALORE-560027 */}
                                </tr>
                                <tr>
                                  <th scope="row">Franchise Owner Name</th>
                                  {/* <td colspan="2">29AOJPP7126F3ZL</td> */}
                                  <td>{selectedBranchName[selectedBranchName.length - 1]?.id?.Frenchises[0].ContactPersion}</td>
                                </tr>
                                <tr>
                                  <th scope="row">GST Number</th>
                                  {/* <td colspan="2">29AOJPP7126F3ZL</td> */}
                                  <td>{selectedBranchName[selectedBranchName.length - 1]?.id?.Frenchises[0].GST}</td>
                                </tr>
                                <tr>
                                  <th scope="row">PAN Number</th>
                                  {/* <td colspan="2">AOJPP7126F</td> */}
                                  <td>{selectedBranchName[selectedBranchName.length - 1]?.id?.Frenchises[0].Pan_Number}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Phone Number</th>
                                  {/* <td colspan="2">8977675789</td> */}
                                  <td>{selectedBranchName[selectedBranchName.length - 1]?.id?.Frenchises[0].MobileNumber}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="col-6" style={{ marginLeft: '5px' }} >
                            <table class="table table-bordered">
                              <tbody>
                                <tr>
                                  <th scope="row">Invoice No</th>
                                  <td colspan="2"><b>{Math.floor(Math.random() * 900000000000000) + 100000000000000}</b></td>
                                </tr>
                                <tr>
                                  <th scope="row">Invoice Date</th>
                                  <td colspan="2"><b>{currentDate}</b></td>
                                </tr>
                                <tr>
                                  <th scope="row">Invoice Period</th>
                                  <td colspan="2">{cNoteFilterFromTo.from == '' ? dateOne : cNoteFilterFromTo.from}{" "}<b>To</b>{" "}{cNoteFilterFromTo.to == '' ? dateOne : cNoteFilterFromTo.to}</td>
                                </tr>
                                <tr>
                                  <th scope="row">GST Number</th>
                                  {/* <td colspan="2">{selectedBranchName[selectedBranchName.length - 1]?.id?.Frenchises[0].GST}</td> */}
                                  <td>29AOJPP7126F3ZL</td>
                                </tr>
                                <tr>
                                  <th scope="row">PAN Number</th>
                                  {/* <td colspan="2">{selectedBranchName[selectedBranchName.length - 1]?.id?.Frenchises[0].Pan_Number}</td> */}
                                  <td>AOJPP7126F</td>
                                </tr>
                                <tr>
                                  <th scope="row">Phone Number</th>
                                  {/* <td colspan="2">{selectedBranchName[selectedBranchName.length - 1]?.id?.Frenchises[0].MobileNumber}</td> */}
                                  <td colspan="2">9343086888</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>


                      </div>
                      <div className="">
                        {/* <div className="row" style={{ marginLeft: "5px" }}>
                          <h6 class="card-title    my-3 bg-light w-auto py-2 rounded ">
                            <FileText /> <b>Courier Details</b>
                          </h6>
                        </div> */}
                        <Table
                          className=" text-center"
                          style={{ border: "2px solid black" }}
                          bordered
                          hover
                        >
                          <thead
                            style={{
                              backgroundColor: "#5f7780",
                              color: "#FFFFFF",
                              fontSize: "14px",
                            }}
                          >
                            <tr>
                              <th>Sl.No</th>
                              <th>Date/Time</th>
                              <th>C-Note Number</th>
                              <th>Origin</th>
                              <th>Destination</th>
                              <th>Credit/Debit</th>
                              <th>Amount</th>
                              <th>Mode</th>
                              <th>PCs</th>
                              <th>WGT-in,Kgs</th>
                              <th>Rate</th>
                              <th>Freight</th>
                              <th>Total</th>
                            </tr>
                          </thead>
                          <tbody style={{ fontSize: "14px" }}>
                            {
                              outwardListPrintingData?.map((ele, ind) => {
                                return <tr>
                                  <td>
                                  
                                  <h4>{ind + 1}</h4></td>
                                  <td><h4>{ele.createdAt && ele.createdAt.slice(0, 10)}  </h4></td>
                                  {/* <td>{ele.Frenchises[0]?.ContactPersion}</td> */}
                                  <td>   <h4>

                                  {ele?.c_note_no ? ele?.c_note_no : ele?.order_transactions ? ele?.order_transactions[0]?.c_node_no : 'null'}
                                  </h4></td>
                                  <td>
                                  {/* {console.log(ele.Frenchises[0]?.city_masters[0]?.city_name ,  ele.transFrenchise?.city_masters[0]?.city_name)} */}
                                  <h4>
                                  {ele?.type == 'Dr'  ? ele.Frenchises[0]?.city_masters[0]?.city_name : ele.transFrenchise?.city_masters[0]?.city_name }
                                  </h4>
                               </td>
                                  <td>
                                  <h4>
                                  { ele?.type == 'Dr' ? ele.transFrenchise?.city_masters[0]?.city_name : ele.Frenchises[0]?.city_masters[0]?.city_name  }
                                  </h4>
                              </td>
                                  <td>
                                  <h4>
                                  {ele?.type == 'Cr' ? 'Credited' : 'Debited'}
                                  </h4>
                                 </td>
                                  <td> <h4>
                                  {ele.debit_credit}
                                  </h4></td>
                                  <td> 
                                  <h4>
                                  {ele?.order_transactions ? ele?.order_transactions[0]?.booking_mode : 'null'}
                                  </h4>
                                </td>
                                  <td>
                                  <h4>
                                  {ele?.order_transactions ? ele?.order_transactions[0]?.no_pcs : 'null'}
                                  </h4>
                                </td>
                                  <td>
                                  <h4>
                                  {ele?.order_transactions ? ele?.order_transactions[0]?.weight : 'null'}
                                  </h4>
                                </td>
                                  <td>
                                  <h4>
                                  {ele?.order_transactions ? ele?.order_transactions[0]?.amount : 'null'}
                                  </h4>
                                </td>
                                  <td>
                                  <h4>
                                  {ele?.order_transactions ? ele?.order_transactions[0]?.freight : 'null'}
                                  </h4>
                                 </td>
                                  <td>
                                  <h4>
                                  {ele?.order_transactions ? ele?.order_transactions[0]?.freight : 'null'}
                                  </h4>
                                 </td>
                                </tr>
                              })
                            }
                            <tr>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td><b className="report-final-title">{finalData?.totalAmount ? finalData?.totalAmount.toFixed(2) : '0.00'}</b></td>
                              <td></td>
                              <td><b className="report-final-title">{totalPieces ? totalPieces.toFixed(2) : '0.00'}</b></td>
                              <td><b className="report-final-title">{totalWeight ? totalWeight.toFixed(2) : '0.00'}</b></td>
                              <td></td>
                              <td></td>
                              <td><b className="report-final-title">{totalAmount ? totalAmount.toFixed(2) : '0.00'}</b></td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                      <table class="table table-bordered mt-3">
                        <tbody>
                          <tr>
                            <td><b className="report-final-title" >credit / debit</b></td>
                            <td><b className="report-final-title" >{finalData?.totalAmount ? finalData?.totalAmount.toFixed(2) : '0.00'}</b></td>
                          </tr>
                          <tr>
                            <td><b className="report-final-title" >Total</b></td>
                            <td><b className="report-final-title" >{totalAmount ? totalAmount.toFixed(2) : '0.00'}</b></td>
                          </tr>
                          {
                            charges.map((ele, ind) => {
                              return <tr>
                                <td><b className="report-final-title" >{ele.newKey}</b></td>
                                <td className="report-final-title"><b className="report-final-title" >{ele.newValue}</b></td>
                              </tr>
                            })
                          }
                          <tr>
                            <td><b className="report-final-title" >Grand Total</b></td>
                            {/* <td> <b> {finalCharges + finalData?.totalAmount}</b></td> */}
                            {/* <td> <b> {finalCharges + totalAmount}</b></td> */}
                            <td> <b>{grandTotal.toFixed(2)}</b></td>
                          </tr>
                        </tbody>
                      </table>
                      <div style={{ textAlign: 'end' }} >
                        <b style={{ fontSize: '15px', marginRight: '10px' }} >POWERD BY MOXD</b>
                      </div>
                    </div>
                  }
                </div>
              }
            </div>
          </div>
        </div>
      </div >
    </div >
  );
}

export default Wallet;
