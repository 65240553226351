import { faSearch, faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import Invoice from "./Invoice";
import Quotation from "./Quotation";
import Unbilledinvoice from "./UnbilledInvoice";

const Accountmaster = () => {
  /* Model1 Quotation */
  const [showQuotation, setShowQuotation] = useState(false);
  const handleCloseQuotation = () => setShowQuotation(false);
  const handleShowQuotation = () => setShowQuotation(true);

  /* Model2 Invoice */
  const [showInvoice, setShowInvoice] = useState(false);
  const handleCloseInvoice = () => setShowInvoice(false);
  const handleShowInvoice = () => setShowInvoice(true);

  /* Model3 Invoice */
  const [showCrossInvoice, setShowCrossInvoice] = useState(false);
  const handleCloseCrossInvoice = () => setShowCrossInvoice(false);
  const handleShowCrossInvoice = () => setShowCrossInvoice(true);

  const [choice, setchoice] = useState(1);
  const handlemenuchange = (e) => {
    let choice = e.target.value;
    console.log(choice);
    switch (choice) {
      case "1":
        setchoice(1);
        break;
      case "2":
        setchoice(2);
        break;
      case "3":
        setchoice(3);
        break;
      case "4":
        setchoice(4);
        break;
      case "5":
        setchoice(5);
        break;
      case "6":
        setchoice(6);
        break;
      case "7":
        setchoice(7);
        break;
    }
  };
  return (
    <>
      <Card className="shadow rounded p-2 w-100">
        <div className="master">
          <Row>
            <Col md={6}>
              <div className="leftedge d-flex justify-content-space">
                <div className="more-link">
                  <h6>Billing Quotation List</h6>
                </div>
                {choice == 1 ? (
                  <Button onClick={handleShowQuotation}>Add Quotation</Button>
                ) : (
                  ""
                )}
                {choice == 2 ? (
                  <>
                    <Button onClick={handleShowInvoice}>Add Invoice</Button>{" "}
                    <Button onClick={handleShowCrossInvoice}>
                      Add Crossing Invoice
                    </Button>
                  </>
                ) : (
                  ""
                )}
              </div>
            </Col>
            <Col md={6}>
              <div className="rightedge d-flex justify-content-end">
                <Form.Select
                  aria-label="Default select example"
                  size={"sm"}
                  className="selectsizesmall"
                  onChange={handlemenuchange}
                >
                  <option value="1">Quotation</option>
                  <option value="2">Invoice</option>
                  <option value="3">Wallet</option>
                  <option value="4">Invoice/Unbilled Report</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
        <hr />
        {choice == 1 ? <Quotation /> : ""}
        {choice == 2 ? <Invoice /> : ""}
        {choice == 4 ? <Unbilledinvoice /> : ""}
        {/* Component */}
        {/*  <div className="more-link">
          <h6>Load more</h6>
        </div> */}
      </Card>
      <Modal
        show={showQuotation}
        onHide={handleCloseQuotation}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <h6 className="text-center more-link mt-4">Add New Quotation</h6>
        <Modal.Body>
          <Form>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Branch Type</Form.Label>
                  <Form.Select size="sm">
                    <option>Select City</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Billing Name</Form.Label>
                  <Form.Select size="sm">
                    <option>Select City</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Booking Mode</Form.Label>
                  <Form.Select size="sm">
                    <option>Select Mode</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Type</Form.Label>
                  <Form.Select size="sm">
                    <option>Select</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Select Product Name</Form.Label>
                  <Form.Select size="sm">
                    <option>Select </option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Employee Name</Form.Label>
                  <Form.Select size="sm">
                    <option>Select Employee </option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Destination Type</Form.Label>
                  <Form.Select size="sm">
                    <option>Select </option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Destination</Form.Label>
                  <Form.Select size="sm">
                    <option>Select Employee </option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Rate Type</Form.Label>
                  <Form.Select size="sm">
                    <option>Select </option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Start</Form.Label>
                  <Form.Control type="text" placeholder="" size="sm" />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>End</Form.Label>
                  <Form.Control type="text" placeholder="" size="sm" />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Slab</Form.Label>
                  <Form.Control type="text" placeholder="" size="sm" />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Rate</Form.Label>
                  <Form.Control type="text" placeholder="" size="sm" />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}></Col>
              <Col md={6}>
                <Button variant="primary" className="all-button  btn-md rounded">
                  Submit
                </Button>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Development Charges(%)</Form.Label>
                  <Form.Control type="text" placeholder="" size="sm" />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Fuelsur Charges(%)</Form.Label>
                  <Form.Control type="text" placeholder="" size="sm" />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseQuotation}>
            Cancel
          </Button>
          <Button
            variant="primary"
            className="all-button"
            onClick={handleCloseQuotation}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showInvoice}
        onHide={handleCloseInvoice}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <h6 className="text-center more-link mt-4">Invoice</h6>
        <Modal.Body>
          <Form>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Branch Type</Form.Label>
                  <Form.Select size="sm">
                    <option>Select City</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Billing Name</Form.Label>
                  <Form.Select size="sm">
                    <option>Select City</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Invoice Number</Form.Label>
                  <Form.Control type="text" size="sm" disabled />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Billing Date</Form.Label>
                  <Form.Control type="date" size="sm" />
                </Form.Group>
              </Col>
            </Row>
            <Row style={{ fontSize: "12px" }}>
              <Col md={2}>
                <Form.Group className="mb-3 d-flex justify-content-between align-items-center">
                  <input type="checkbox" />
                  <label>Applicable IGST</label>
                </Form.Group>
              </Col>
              <Col md={2}>
                <Form.Group className="mb-3 d-flex justify-content-between align-items-center">
                  <input type="checkbox" />
                  <label>Applicable GST</label>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Label>From Date</Form.Label>
                  <Form.Control type="date" size="sm" />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Label>To Date</Form.Label>
                  <Form.Control type="date" size="sm" />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Button className="all-button mt-4">Get Booking List</Button>
              </Col>
            </Row>
            <Row>
              <Col md={4}>Total Amount</Col>
              <Col md={3}></Col>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Control type="text" size="sm" disabled />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={4}>Development Charges(%)</Col>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Control type="text" size="sm" disabled />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Control type="text" size="sm" disabled />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={4}>Fuelsur Charges(%)</Col>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Control type="text" size="sm" disabled />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Control type="text" size="sm" disabled />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={4}>ODA Charges</Col>
              <Col md={3}></Col>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Control type="text" size="sm" />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={4}>FOV Charges</Col>
              <Col md={3}></Col>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Control type="text" size="sm" />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={4}>Sub Total Amount</Col>
              <Col md={3}></Col>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Control type="text" size="sm" />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={4}>SGST(%)</Col>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Control type="text" size="sm" disabled />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Control type="text" size="sm" />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={4}>CGST(%)</Col>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Control type="text" size="sm" disabled />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Control type="text" size="sm" />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={4}>IGST(%)</Col>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Control type="text" size="sm" disabled />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Control type="text" size="sm" />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={4}>Net Amount</Col>
              <Col md={3}></Col>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Control type="text" size="sm" disabled />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <Button className="all-button">Pending</Button>
              </Col>
              <Col md={3}>
                {" "}
                <Button className="all-button">Ready For Billing</Button>
              </Col>
              <Col md={1}>
                {" "}
                <Button className="all-button">Save</Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseInvoice}>
            Cancel
          </Button>
          <Button
            variant="primary"
            className="all-button"
            onClick={handleCloseInvoice}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showCrossInvoice}
        onHide={handleCloseCrossInvoice}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <h6 className="text-center more-link mt-4">Crossing Invoice</h6>
        <Modal.Body>
          <Form>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Branch Type</Form.Label>
                  <Form.Select size="sm">
                    <option>Select City</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Billing Name</Form.Label>
                  <Form.Select size="sm">
                    <option>Select City</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Crossing Type</Form.Label>
                  <Form.Select size="sm">
                    <option></option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Invoice Number</Form.Label>
                  <Form.Control type="text" size="sm" disabled />
                </Form.Group>
              </Col>
            </Row>
            <Row style={{ fontSize: "12px" }}>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Type</Form.Label>
                  <Form.Control type="text" size="sm" />
                </Form.Group>
              </Col>
              <Col md={2}>
                <Form.Group className="mb-3 d-flex justify-content-between align-items-center mt-4">
                  <input type="checkbox" />
                  <label>Applicable IGST</label>
                </Form.Group>
              </Col>
              <Col md={2}>
                <Form.Group className="mb-3 d-flex justify-content-between align-items-center mt-4">
                  <input type="checkbox" />
                  <label>Applicable GST</label>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Billing Date</Form.Label>
                  <Form.Control type="date" size="sm" />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Label>From Date</Form.Label>
                  <Form.Control type="date" size="sm" />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Label>To Date</Form.Label>
                  <Form.Control type="date" size="sm" />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Button className="all-button mt-4">Get Booking List</Button>
              </Col>
            </Row>
            <Row>
              <Col md={4}>Total Amount</Col>
              <Col md={3}></Col>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Control type="text" size="sm" disabled />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={4}>Development Charges(%)</Col>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Control type="text" size="sm" disabled />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Control type="text" size="sm" disabled />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={4}>Fuelsur Charges(%)</Col>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Control type="text" size="sm" disabled />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Control type="text" size="sm" disabled />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={4}>ODA Charges</Col>
              <Col md={3}></Col>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Control type="text" size="sm" />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={4}>FOV Charges</Col>
              <Col md={3}></Col>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Control type="text" size="sm" />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={4}>Sub Total Amount</Col>
              <Col md={3}></Col>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Control type="text" size="sm" />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={4}>SGST(%)</Col>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Control type="text" size="sm" disabled />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Control type="text" size="sm" />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={4}>CGST(%)</Col>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Control type="text" size="sm" disabled />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Control type="text" size="sm" />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={4}>IGST(%)</Col>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Control type="text" size="sm" disabled />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Control type="text" size="sm" />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={4}>Net Amount</Col>
              <Col md={3}></Col>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Control type="text" size="sm" disabled />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <Button className="all-button">Pending</Button>
              </Col>
              <Col md={3}>
                {" "}
                <Button className="all-button">Ready For Billing</Button>
              </Col>
              <Col md={1}>
                {" "}
                <Button className="all-button">Save</Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseCrossInvoice}>
            Cancel
          </Button>
          <Button
            variant="primary"
            className="all-button"
            onClick={handleCloseCrossInvoice}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Accountmaster;
