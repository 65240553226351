import React from "react";
import "../Styles/header.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faEnvelope,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  Modal,
  Nav,
  NavDropdown,
  Row,
} from "react-bootstrap";
import Storage from "../Services/Storage";
import { useState, useEffect } from "react";

import logo1 from "./../Assets/MoxD_PrimaryLogo1.png";
import Apiservice from "./../Services/Apiservice";
import { Link } from "react-router-dom";
import { ToastContainer, toast, Zoom } from "react-toastify";
import { useHistory } from "react-router-dom";


const Navbar = () => {
  const [loginData, setLoginData] = useState([]);
  const [data, setData] = useState();
  const history = useHistory();


  const handlebyebyeuser = (e) => {
    toast.success('Logout Successful')
    setTimeout(() => {
      history.push('/login')
      localStorage.clear()
    }, [1000])
    // Storage.Destroyee();
  };
  useEffect(() => {
    const LocalData = localStorage.getItem("userDetails");
    const LocalData1 = JSON.parse(LocalData);
    // console.log(LocalData1.role_masters[0].role_name,'111');
    setLoginData(LocalData1);
    setData(LocalData1.role_masters[0].role_name);
  }, []);
  const [walletamount, setWalletamount] = useState();
  useEffect(async () => {
    const one = await localStorage.getItem("userDetails");
    // setLoginData(JSON.parse(one))
    const payload = {
      frenchise_id: JSON.parse(one).id,
    };

    const walletamount = await Apiservice.postdata(
      "/booking/walletamount",
      payload
    );

    if (walletamount.error == false) {
      if (walletamount.data.length == "1") {
        setWalletamount(walletamount.data[0].total_amount);
      } else if (walletamount.data.length > 1) {
        if (
          walletamount.data[1].type == "Cr" &&
          walletamount.data[0].type == "Dr"
        ) {
          setWalletamount(
            walletamount.data[1].total_amount -
            walletamount.data[0].total_amount
          );
        } else if (
          walletamount.data[0].type == "Cr" &&
          walletamount.data[1].type == "Dr"
        ) {
          setWalletamount(
            walletamount.data[0].total_amount -
            walletamount.data[1].total_amount
          );
        }
      }
    } else {
      console.log("get API is not working");
    }
  }, []);
  const ClickRefrash = async () => {
    const one = await localStorage.getItem("userDetails");

    const payload = {
      frenchise_id: JSON.parse(one).id,
    };
    const walletamount = await Apiservice.postdata(
      "/booking/walletamount",
      payload
    );

    if (walletamount.error == false) {
      if (walletamount.data.length == "1") {
        setWalletamount(walletamount.data[0].total_amount);
      } else if (walletamount.data.length > 1) {
        if (
          walletamount.data[1].type == "Cr" &&
          walletamount.data[0].type == "Dr"
        ) {
          setWalletamount(
            walletamount.data[1].total_amount -
            walletamount.data[0].total_amount
          );
        } else if (
          walletamount.data[0].type == "Cr" &&
          walletamount.data[1].type == "Dr"
        ) {
          setWalletamount(
            walletamount.data[0].total_amount -
            walletamount.data[1].total_amount
          );
        }
      }
    } else {
      console.log("get API is not working");
    }
    console.log(
      walletamount.data[1].total_amount - walletamount.data[0].total_amount
    );
  };

  const [userData, setUserData] = useState();

  useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem("userDetails")));
    // setOpenSubTransaction("false")
  }, []);

  // console.log(loginData?.role_masters[0].role_name, 'loginData');

  return (
    <>
      <div className="container-fluid  nav-bar">
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar
          transition={Zoom}
          delay={1000}
          limit={1}
        />
        <Row className="">
          <div className="col-md-4">
            <div className="dashboard-nav">
              <img src={logo1} alt="" className="branding" />
            </div>
          </div>
          <div className="col-md-6">
            <div className="row mt-3">
              {data == "Franchise" ? (
                <div className="col-md-5">
                  <div className="border">
                    <i class="bi bi-wallet mx-2 text-dark mx-3 fs-4"></i>
                    <span className="fs-5">
                      <b>&#x20B9;{walletamount} </b>
                    </span>

                    <i
                      class="bi bi-bootstrap-reboot text-danger mx-3 fs-4 "
                      onClick={ClickRefrash}
                    ></i>
                    <Link to="/Wallet" className="text-white">
                      <i class="bi bi-clock-history text-success mx-2 fs-4"></i>
                    </Link>
                  </div>
                </div>
              ) : (
                <div></div>
              )}{" "}
              <div className="col-md-4">
                <Link to="/report" className="text-white">
                  <button className="btn btn-dark rounded">
                    TrackingTracking
                  </button>
                </Link>
              </div>
            </div>
          </div>

          <div className="col-md-2 my-3 ">
            <div className="m-auto">
              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-basic"
                  className="menubutton "
                  size="sm"
                >
                  {loginData.Email}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {/* <Dropdown.Item href="#/action-1"> USER </Dropdown.Item> */}
                  <Dropdown.Item
                    // href="/login"
                    onClick={(e) => handlebyebyeuser(e)}
                  >
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </Row>
      </div>
    </>
  );
};

export default Navbar;
