import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const Bank = () => {
  return (
    <div>
      <div className="personal">
        <Container>
          <Row>
            <Col className="d-flex justify-content-between">
              <h6>Bank Account Name</h6>
              <p>xxxxxxxxxxxxx</p>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-between">
              <h6>Account Number</h6>
              <p>xxxxxxxxxxxxx</p>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-between">
              <h6>IFSC Code</h6>
              <p>xxxxxxxxxxxxx</p>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-between">
              <h6>Bank Name</h6>
              <p>xxxxxxxxxxxxx</p>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-between">
              <h6>Branch Name</h6>
              <p>xxxxxxxxxxxxx</p>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-between">
              <h6>Branch Code</h6>
              <p>xxxxxxxxxxxxx</p>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Bank;
