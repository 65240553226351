import React, { useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import { ToastContainer, toast, Zoom } from "react-toastify";
import {
  Button,
  Col,
  Container,
  Modal,
  Row,
  Tab,
  Tabs,
  Table,
  Card,
  Form,
  Spinner,
} from "react-bootstrap";
import Apiservice from "../../Services/Apiservice";
import Switch from "react-switch";

const BranchListReport = () => {
  /** Date States**/
  const [rowData, setRowData] = useState();
  const [dateOne, setDate] = useState("");
  const [cNoteFilterFromTo, setCNoteFilterFromTo] = useState({
    from: "",
    to: "",
  });
  const [dateFieldErrorFrom, setDateFieldErrorFrom] = useState("");
  const [dateFieldErrorTo, setDateFieldErrorTo] = useState("");
  const [submitDateLoader, setSubmitDateLoader] = useState(false);

  useEffect(() => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    const todayDate = yyyy + "-" + mm + "-" + dd;
    setCNoteFilterFromTo({
      ...cNoteFilterFromTo,
      from: todayDate,
      to: todayDate,
    });

    // var d = new Date();
    // d.getHours(); // => 9
    // d.getMinutes(); // =>  30
    // d.getSeconds();

    // var hh = d.getHours();
    // var mm = d.getMinutes();

    // const todayTime = hh + ":" + mm;
    // setTime(todayTime);
  }, []);

  useEffect(async () => {
    const apiData = await Apiservice.getFranchiseList();
    if (apiData.error == false) {
      setRowData(apiData.data);
    } else {
      console.log("fale");
    }
  }, []);

  const onchangedate = (event) => {
    setCNoteFilterFromTo({
      ...cNoteFilterFromTo,
      [event.target.name]: event.target.value,
    });
  };
  const handelFromToSubmit = async (e) => {
    e.preventDefault();
    if (cNoteFilterFromTo.from != "" && cNoteFilterFromTo.to != "") {
      setSubmitDateLoader(true);
      const payload = {
        dateFrom:
          cNoteFilterFromTo.from == "" ? dateOne : cNoteFilterFromTo.from,
        dateTo: cNoteFilterFromTo.to == "" ? dateOne : cNoteFilterFromTo.to,
      };
      const cnoteData = await Apiservice.getFranchiseList(payload);
      if (cnoteData.error == false && cnoteData.data.length != 0) {
        setSubmitDateLoader(false);
        setRowData(cnoteData?.data);
        toast.success(cnoteData.message);
      } else {
        setSubmitDateLoader(false);
        toast.error("No data was found within this date range");
        setRowData(null);
      }
    } else {
      if (cNoteFilterFromTo.from == "") {
        setDateFieldErrorFrom("please select from date");
      }

      if (cNoteFilterFromTo.to == "") {
        setDateFieldErrorTo("please select to date");
      }
    }
  };

  const rowHeight = 50;
  const DefaultColumnSetting = {
    sortable: true,
    filter: true,

    floatingFilter: true,
    flex: 1,
    resizable: true,
    minWidth: 120,
  };

  const [colDefs, setColDefs] = useState([
    {
      headerName: "Sl.No",
      valueGetter: "node.rowIndex + 1",
      filter: true,
      lockPosition: true,
    },
    {
      headerName: "Business Name",
      filter: true,
      valueGetter: "data.city_masters[0].city_name",
    },
    {
      headerName: "Name",
      filter: true,
      field: "ContactPersion",
    },
    {
      headerName: "State",
      filter: true,
      field: "State",
    },
    {
      headerName: "District",
      filter: true,
      field: "District",
    },
  ]);

  useEffect(() => {
    if (cNoteFilterFromTo.from != "") {
      setDateFieldErrorFrom("");
    }
    if (cNoteFilterFromTo.to != "") {
      setDateFieldErrorTo("");
    }
  }, [cNoteFilterFromTo]);

  const handleDateClear = () => {
    const todayDate = "" + "-" + "" + "-" + "";
    setDate(todayDate);
    setCNoteFilterFromTo({
      ...cNoteFilterFromTo,
      from: "",
      to: "",
    });
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        transition={Zoom}
        delay={1000}
        limit={1}
      />
      <div className="more-link">
        <h6>New Franchise Request </h6>
      </div>
      <Card className="shadow rounded p-2 w-100 my-2">
        <div>
          <Row>
            <div className="col">
              <form onSubmit={handelFromToSubmit}>
                <div className="row">
                  <div className="col-md-4"></div>
                  <div className="col-md-8">
                    <div className="row">
                      <div className="col-md-4">
                        {" "}
                        <Form.Label>From Date</Form.Label>
                        <Form.Control
                          type="date"
                          value={
                            cNoteFilterFromTo.from == ""
                              ? dateOne
                              : cNoteFilterFromTo.from
                          }
                          onChange={onchangedate}
                          name="from"
                          format="DD/MM/YYYY"
                        />
                        <span className="text-danger">
                          {dateFieldErrorFrom}
                        </span>
                      </div>

                      <div className="col-md-4">
                        <Form.Label>To Date</Form.Label>
                        <Form.Control
                          type="date"
                          value={
                            cNoteFilterFromTo.to == ""
                              ? dateOne
                              : cNoteFilterFromTo.to
                          }
                          onChange={onchangedate}
                          name="to"
                          format="DD/MM/YYYY"
                        />
                        <span className="text-danger">{dateFieldErrorTo}</span>
                      </div>
                      <div className="col-md-4 mt-3">
                        <button
                          className="btn btn-danger rounded mx-3  text-white mt-3"
                          type="reset"
                          onClick={handleDateClear}
                        >
                          Clear
                        </button>
                        <button
                          className="btn btn-success rounded mx-3  text-white mt-3"
                          // onClick={handelFromToSubmit}
                          type="submit"
                        >
                          Submit{" "}
                          {submitDateLoader ? (
                            <Spinner
                              animation="border"
                              variant="Primary"
                              size="sm"
                            />
                          ) : (
                            ""
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </Row>
          <Row>
            <div
              className="ag-theme-alpine"
              style={{ height: "70vh", width: "100%", marginTop: "30px" }}
            >
              <AgGridReact
                rowHeight={rowHeight}
                // columnDefs={columns}
                columnDefs={colDefs}
                defaultColDef={DefaultColumnSetting}
                pagination={true}
                paginationPageSize={10}
                // onGridReady={onGridReady}
                rowData={rowData}
              />
            </div>
          </Row>
        </div>
      </Card>

      <Modal
        // show={showComplain}
        backdrop="static"
        keyboard={false}
        centered
        size="xl"
      >
        <Modal.Body>
          <h1>This is popup</h1>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BranchListReport;
