
import React from 'react'
import { Route, BrowserRouter, Switch } from 'react-router-dom';

import DashboardContainer from "../Services/Container/DashboardContainer";
import Login from '../pages/Login';
import ProtectedRoute from './ProtectedRoute';

const ScreenRouter = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route path='/login' component={Login} />
                <ProtectedRoute path='/' component={DashboardContainer} />
            </Switch>
        </BrowserRouter>
    )
}

export default ScreenRouter
