import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Dropdown,
  Nav,
  Overlay,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import "../src/Styles/sidenav.css";
import Navbar from "./pages/Navbar";
import { Link } from "react-router-dom";
import ViewRouter from "./Router/ViewRouter";
import DashboardNavbar from "./pages/DashboardNavbar";
const Dashboard = (props) => {
  const [userData, setUserData] = useState();
  const [openSubTransation, setOpenSubTransaction] = useState("false");
  const [number, setNumber] = useState(1);
  const [setupMaster, setSetupMaster] = useState(false);
  const [openSubSetUpMaster, setOpenSubSetUpMaster] = useState("false");
  const [showTransaction, setShowTransaction] = useState(false);
  const [hide, setHide] = useState(false);
  const targetTransaction = useRef(null);

  const [show, setShow] = useState(false);
  const target = useRef(null);

  const mystyle = {
    display: "none",
  };

  useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem("userDetails")));
    setNumber(1);
  }, []);

  const clickToOpen = () => {
    setShowTransaction(!showTransaction);
    setShow(false);
  };

  const setupMasterOpen = () => {
    setShow(!show);
    setShowTransaction(false);
  };
  const hidesidebar = () => {
    setShowTransaction(hide);
    setShow(hide);
  };

  return (
    <>
      {/* <div class="ant-menu-submenu ant-menu-submenu-popup ant-menu ant-menu-light ant-menu-submenu-placement-rightTop " style="left: 84px; top: 284px;"></div> */}
      {/* <Navbar /> */}
      <DashboardNavbar />
      <div className="container-fluid">
        <Row>
          <Col md={2} style={{ display: "none" }}>
            <div className="side-nav">
              <Nav
                variant="pills"
                defaultActiveKey="link0"
                className="flex-column"
              >
                {userData
                  ? userData.role_mappings.map((e, i) => {
                    return (
                      <div>
                        <Nav.Item
                          ref={targetTransaction}
                          key={i}
                          onClick={
                            e.module_master.module_name == "transaction"
                              ? clickToOpen
                              : hidesidebar
                          }
                        >
                          <Nav.Link eventKey={"link" + i}>
                            {e.module_master.module_name != "transaction" ? (
                              <Link to={"/" + e.module_master.module_name}>
                                <div className="activeLink">
                                  {e.module_master.module_name}
                                </div>
                              </Link>
                            ) : (
                              <Link
                                to={
                                  "/" +
                                  e.module_master.module_name +
                                  "/booking"
                                }
                              >
                                <div className="activeLink">
                                  {e.module_master.module_name}
                                  <Overlay
                                    target={targetTransaction.current}
                                    show={showTransaction}
                                    placement="right"
                                  >
                                    {(props) => (
                                      <Tooltip
                                        id="overlay-example"
                                        {...props}
                                      >
                                        {
                                          // userData?.role_masters[0]
                                          //   .role_name == "Admin" ? (
                                          //   <div>
                                          //     <Nav.Item>
                                          //       <Nav.Link className="subLink">
                                          //         <Link
                                          //           to="/Allbooking"
                                          //           style={{ color: "white" }}
                                          //         >
                                          //           <div className="activeLink">
                                          //             All Booking
                                          //           </div>
                                          //         </Link>
                                          //       </Nav.Link>
                                          //     </Nav.Item>
                                          //   </div>
                                          // ) : (
                                          //   " "
                                          // )
                                        }

                                        {userData?.role_masters[0]
                                          .role_name == "Franchise" &&
                                          userData?.Hub_type == "SUPER" ? (
                                          <Nav.Item>
                                            <Nav.Link className="subLink">
                                              <Link
                                                to="/transaction/Cnote"
                                                style={{ color: "white" }}
                                              >
                                                <div className="activeLink">
                                                  C-Note Details
                                                </div>
                                              </Link>
                                            </Nav.Link>
                                            <Nav.Link className="subLink">
                                              <Link
                                                to="/transaction/OgmInward"
                                                style={{ color: "white" }}
                                              >
                                                <div className="activeLink">
                                                  OGM Inward
                                                </div>
                                              </Link>
                                            </Nav.Link>
                                            <Nav.Link className="subLink">
                                              <Link
                                                to="/transaction/OgmOutward"
                                                style={{ color: "white" }}
                                              >
                                                <div className="activeLink">
                                                  OGM Outward
                                                </div>
                                              </Link>
                                            </Nav.Link>
                                            <Nav.Link className="subLink">
                                              <Link
                                                to="/transaction/OutForDelivery"
                                                style={{ color: "white" }}
                                              >
                                                <div className="activeLink">
                                                  Out For Delivery
                                                </div>
                                              </Link>
                                            </Nav.Link>
                                            <Nav.Link className="subLink">
                                              <Link
                                                to="/transaction/LocalInward"
                                                style={{ color: "white" }}
                                              >
                                                <div className="activeLink">
                                                  Local Inward
                                                </div>
                                              </Link>
                                            </Nav.Link>
                                            <Nav.Link className="subLink">
                                              <Link
                                                to="/transaction/LocalOutward"
                                                style={{ color: "white" }}
                                              >
                                                <div className="activeLink">
                                                  Local Outward
                                                </div>
                                              </Link>
                                              <Nav.Link className="subLink">
                                                <Link
                                                  to="/transaction/bookinglist"
                                                  style={{ color: "white" }}
                                                >
                                                  <div className="activeLink">
                                                    Booking
                                                  </div>
                                                </Link>
                                              </Nav.Link>
                                              <Nav.Link className="subLink">
                                                <Link
                                                  to="/Allbooking"
                                                  style={{ color: "white" }}
                                                >
                                                  <div className="activeLink">
                                                    All Booking
                                                  </div>
                                                </Link>
                                              </Nav.Link>
                                            </Nav.Link>
                                          </Nav.Item>
                                        ) : (
                                          " "
                                        )}
                                        {userData?.Hub_type == "LOCAL" &&
                                          userData?.role_masters[0].role_name ==
                                          "Franchise" ? (
                                          <Nav.Item
                                          // className={openSubSetUpMaster}
                                          // style={{ color: "white" }}
                                          >
                                            <Nav.Link className="subLink">
                                              <Link
                                                to="/transaction/Cnote"
                                                style={{ color: "white" }}
                                              >
                                                <div className="activeLink">
                                                  C-Note Details
                                                </div>
                                              </Link>
                                            </Nav.Link>
                                            <Nav.Link className="subLink">
                                              <Link
                                                to="/transaction/OutForDelivery"
                                                style={{ color: "white" }}
                                              >
                                                <div className="activeLink">
                                                  Out For Delivery
                                                </div>
                                              </Link>
                                            </Nav.Link>
                                            <Nav.Link className="subLink">
                                              <Link
                                                to="/transaction/LocalInward"
                                                style={{ color: "white" }}
                                              >
                                                <div className="activeLink">
                                                  Local Inward
                                                </div>
                                              </Link>
                                            </Nav.Link>
                                            <Nav.Link className="subLink">
                                              <Link
                                                to="/transaction/LocalOutward"
                                                style={{ color: "white" }}
                                              >
                                                <div className="activeLink">
                                                  Local Outward
                                                </div>
                                              </Link>
                                              <Nav.Link className="subLink">
                                                <Link
                                                  to="/transaction/bookinglist"
                                                  style={{ color: "white" }}
                                                >
                                                  <div className="activeLink">
                                                    Booking
                                                  </div>
                                                </Link>
                                              </Nav.Link>
                                              <Nav.Link className="subLink">
                                                <Link
                                                  to="/Allbooking"
                                                  style={{ color: "white" }}
                                                >
                                                  <div className="activeLink">
                                                    All Booking
                                                  </div>
                                                </Link>
                                              </Nav.Link>
                                            </Nav.Link>
                                          </Nav.Item>
                                        ) : (
                                          " "
                                        )}
                                      </Tooltip>
                                    )}
                                  </Overlay>
                                </div>
                              </Link>
                            )}
                          </Nav.Link>
                        </Nav.Item>
                      </div>
                    );
                  })
                  : ""}

                <div>
                  <Nav.Item
                    eventKey={"link"}
                    // onClick={() => setSetupMaster(!setupMaster)}
                    ref={target}
                    onClick={setupMasterOpen}
                  >
                    <Nav.Link>
                      <Link>
                        <div className="activeLink">SETUPMASTER</div>
                      </Link>
                    </Nav.Link>
                  </Nav.Item>
                </div>

                <Overlay target={target.current} show={show} placement="right">
                  {(props) => (
                    <Tooltip id="overlay-example" {...props}>
                      {userData?.role_masters[0].role_name == "Admin" ? (
                        <div>
                          <Nav.Item>
                            <Nav.Link className="subLink">
                              <Link
                                to="/Setupmaster/PinCodemaster"
                                style={{ color: "white" }}
                              >
                                <div className="activeLink">Pin Code master</div>
                              </Link>
                            </Nav.Link>
                            <Nav.Link className="subLink">
                              <Link
                                to="/Setupmaster/Areamaster"
                                style={{ color: "white" }}
                              >
                                <div className="activeLink">Areamaster</div>
                              </Link>
                            </Nav.Link>
                            {
                              //   <Nav.Link className="subLink">
                              //   <Link
                              //     to="/Setupmaster/Deliverypartner"
                              //     style={{ color: "white" }}
                              //   >
                              //     <div className="activeLink">Delivery Partner</div>
                              //   </Link>
                              // </Nav.Link>
                            }
                          </Nav.Item>
                        </div>
                      ) : (
                        " "
                      )}

                      {userData?.role_masters[0].role_name == "Franchise" &&
                        userData?.Hub_type == "SUPER" ? (
                        <div>
                          <Nav.Item
                            className={openSubSetUpMaster}
                            style={{ color: "white" }}
                          >
                            <Nav.Link className="subLink">
                              <Link
                                to="/Setupmaster/Deliverypartner"
                                style={{ color: "white" }}
                              >
                                <div className="activeLink">
                                  Delivery Partner
                                </div>
                              </Link>
                            </Nav.Link>
                            {
                              //   <Nav.Link className="subLink">
                              //   <Link
                              //     to="/Setupmaster/PinCodemaster"
                              //     style={{ color: "white" }}
                              //   >
                              //     <div className="activeLink">Pin Code master</div>
                              //   </Link>
                              // </Nav.Link>
                              // <Nav.Link className="subLink">
                              //   <Link
                              //     to="/Setupmaster/Areamaster"
                              //     style={{ color: "white" }}
                              //   >
                              //     <div className="activeLink">Areamaster</div>
                              //   </Link>
                              // </Nav.Link>
                            }
                          </Nav.Item>
                        </div>
                      ) : (
                        " "
                      )}
                      {userData?.Hub_type == "LOCAL" &&
                        userData?.role_masters[0].role_name == "Franchise" ? (
                        <Nav.Item
                          className={openSubSetUpMaster}
                          style={{ color: "white" }}
                        >
                          <Nav.Link className="subLink">
                            <Link
                              to="/Setupmaster/Deliverypartner"
                              style={{ color: "white" }}
                            >
                              <div className="activeLink">Delivery Partner</div>
                            </Link>
                          </Nav.Link>
                          <Nav.Link className="subLink">
                            <Link
                              to="/Setupmaster/PinCodemaster"
                              style={{ color: "white" }}
                            >
                              <div className="activeLink">Pin Code master</div>
                            </Link>
                          </Nav.Link>
                          <Nav.Link className="subLink">
                            <Link
                              to="/Setupmaster/Areamaster"
                              style={{ color: "white" }}
                            >
                              <div className="activeLink">Areamaster</div>
                            </Link>
                          </Nav.Link>
                        </Nav.Item>
                      ) : (
                        " "
                      )}
                    </Tooltip>
                  )}
                </Overlay>
              </Nav>
            </div>
          </Col>
          <Col md={12} className="content" onClick={hidesidebar}>
            <Container fluid>
              <ViewRouter />
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Dashboard;
