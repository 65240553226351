import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import Cnote from "./Cnote";
import Invoiceunbill from "./InvoiceUnbill";
import Mutiplecnote from "./MutipleCnote";
import Omtracking from "./Omtracking";
import TransactionTracking from "./TransactionTracking";

const Mastertracking = () => {
  const [choice, setchoice] = useState(1);
  const handlemenuchange = (e) => {
    setchoice(e.target.value);
  };
  return (
    <>
      <div className="more-link">
        <h6>
          Transaction
          <FontAwesomeIcon
            icon={faSync}
            style={{ marginLeft: "4px", fontSize: "12px" }}
          />
        </h6>
      </div>
      <Card className="shadow rounded p-2 w-100">
        <div className="master">
          <Row>
            <Col md={6}>
              <div className="leftedge d-flex justify-content-space">
                <Form.Select
                  aria-label="Default select example"
                  size={"sm"}
                  className="selectsizesmall"
                  onChange={(e) => handlemenuchange(e)}
                >
                  <option value="1">Transaction Tracking</option>
                  {/* <option value="1">C-Note</option>
                  <option value="2">Multi C-Note</option>
                  <option value="3">OGM Tracking</option>
                  <option value="4">Invoice Bill/Unbilled</option> */}
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
        <hr />
        {choice == 1 ? <TransactionTracking /> : ""}
        {/* {choice == 1 ? <Cnote /> : ""}
        {choice == 2 ? <Mutiplecnote /> : ""}
        {choice == 3 ? <Omtracking /> : ""}
        {choice == 4 ? <Invoiceunbill /> : ""} */}
      </Card>
    </>
  );
};

export default Mastertracking;
