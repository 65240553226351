import React, { useEffect, useState } from "react";
import '../../Style/Ogm.css';

import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Button,
    Card,
    Col,
    Form,
    Modal,
    Row,
    Spinner,
    Table,
} from "react-bootstrap";
import '../../Style/Ogm.css';

import Apiservice from "../../Services/Apiservice";

const Create = () => {

    const [stateList, setStateList] = useState([]);
    const [distList, setDistList] = useState([]);
    const [allOrders, setAllOrders] = useState([]);
    const [selectedPin, setSelectedPin] = useState([]);
    const [selectedPin1, setSelectedPin1] = useState([]);
    const [fraData, setFraData] = useState({
        state: '',
        district: '',
        state_code: ''
    })

    const LocalData = localStorage.getItem("userDetails")
    const LocalData1 = JSON.parse(LocalData)
    useEffect(() => {
        // GetStateList();
        getFraList();
    }, [])

    // const GetStateList = async () => {
    //     let sattes = await Apiservice.getStateList();
    //     console.log("the states are", sattes)
    //     if (!sattes.error) {
    //         setStateList(sattes.list)
    //     } else {
    //         // toast.error("Something Went Wrong")
    //     }
    // }

    // const GetDisList = async (e) => {
    //     setFraData({ ...fraData, state: e.target.value })
    //     const index = e.target.selectedIndex;
    //     const el = e.target.childNodes[index];
    //     const option = el.getAttribute('id');

    //     let dist = await Apiservice.GetDistList({
    //         "state_id": parseInt(option)
    //     });
    //     if (!dist.error) {
    //         setDistList(dist.list)
    //     } else {
    //         // toast.error("Something Went Wrong")
    //     }
    // }

    const getFraList = async () => {
        // let user = JSON.parse(localStorage.getItem('userDetails'));
        // console.log("the user ois", user)
        // let franchise = await Apiservice.getAllorders({ franchise_id: user.id });
        // console.log("the frnchise is", franchise)
        // if (!franchise.error) {
        //     setAllOrders(franchise.data)
        // } else {
        //     // toast.error("Something Went Wrong")
        // }

        let franchise = await Apiservice.postdata("/booking/all_booking", { frenchise_id: LocalData1.id,c_note:true });
        if (!franchise.error) {
            setAllOrders(franchise.data)
        } else {
            // toast.error("Something Went Wrong")
        }
    }

    // const selectDistrict = (e) => {
    //     let selectData = JSON.parse(e.target.value)
    //     let data = {
    //         state: fraData.state,
    //         district: selectData.name
    //     }
    //     setFraData({ ...fraData, district: selectData.name, state_code: selectData.code });
    //     getFraList(data)
    // }

    const HandleCheckBox = (value, e) => {
        let data = [...selectedPin];
        if (e.target.checked) {
            data.push(value);
        } else {
            data = data.filter(item => item.id != value.id)
        }
        setSelectedPin(data);
    }

    useEffect(()=>{
        let main = []
        selectedPin.forEach((ele, ind) => {
            let data = {
                cnote: ele.c_node_no,
                weight: ele.weight,
                source_franchise_id: ele.frenchise_id
            }
            main.push(data)
        })
        setSelectedPin1(main)
    },[selectedPin])
    const createOgm = async () => {
        const CreateOgmPayload = {
            c_node_no: selectedPin1,
            frenchise_id: LocalData1.id,
            state_city_code: ''
        }
        console.log(CreateOgmPayload,'createOgmpayload2');
        let OGM = await Apiservice.createOgm(CreateOgmPayload);
        console.log("the created ogm is", OGM);
        if (!OGM.error) {
            console.log("OGM Creted Done!")
        } else {
            console.log("Something went wrong!")
        }
    }

    return (
        <>
            <Card className="shadow rounded p-2 w-100">
                <div className="master">
                    {/* <Row>
                        <Col md={6}>
                            <div className="rightedge d-flex">
                                <Form.Select
                                    aria-label="Default select example"
                                    size={"sm"}
                                    className="selectsizesmall"
                                    onChange={GetDisList}
                                >
                                    <option>Select State</option>
                                    {
                                        stateList.map(e => {
                                            return <option key={e.id} id={e.id} value={e.name}>{e.name}</option>
                                        })
                                    }
                                </Form.Select>
                                <Form.Select
                                    aria-label="Default select example"
                                    size={"sm"}
                                    className="selectsizesmall"
                                    onChange={selectDistrict}
                                >
                                    <option>Select District</option>
                                    {
                                        distList.map(e => {
                                            return <option key={e.id} value={JSON.stringify({ name: e.name, code: e.state_code })}>{e.name}</option>
                                        })
                                    }
                                </Form.Select>
                            </div>
                        </Col>
                    </Row> */}
                </div>
                {
                    allOrders.length > 0 ?
                        <div>
                            <Row>
                                <Col>
                                    <Table hover size="sm"  className="text-center">
                                        <thead>
                                            <tr>
                                                <td className="table-headings">#</td>
                                                <td className="table-headings">Order ID</td>
                                                <td className="table-headings">C Note</td>
                                                {/* <td className="table-headings">User Name</td> */}
                                                <td className="table-headings">Pickup State</td>
                                                <td className="table-headings">Pickup District</td>
                                                <td className="table-headings">Pickup Pincode</td>
                                                <td className="table-headings">Dilivery State</td>
                                                <td className="table-headings">Dilivery District</td>
                                                <td className="table-headings">Dilivery Pincode</td>
                                                <td className="table-headings">Action</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                allOrders.map((e, i) => {
                                                    return (
                                                        <tr>
                                                            <td>{i + 1}</td>
                                                            <td>{e.id}</td>
                                                            <td>{e.c_node_no}</td>
                                                            {/* <td>{e.User.Name}</td> */}
                                                            <td>{e.pickup_state}</td>
                                                            <td>{e.pickup_district}</td>
                                                            <td>{e.pickup_pincode}</td>
                                                            <td>{e.delivery_state}</td>
                                                            <td>{e.delivery_district}</td>
                                                            <td>{e.delivery_pincode}</td>
                                                            <td>
                                                                <input type="checkbox"
                                                                    onChange={(event) => HandleCheckBox(e, event)}
                                                                />
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            <button onClick={createOgm} className="CreateOgm-button">Create OGM</button>

                        </div>
                        : 'No Result Found'
                }
            </Card>
        </>
    )
}

export default Create