import React, { useEffect, useState } from "react";
import { ToastContainer, toast, Zoom } from "react-toastify";
import Apiservice from "../../Services/Apiservice";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { FileText } from "react-bootstrap-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import CustomDeleteModal from "../../common/CustomDeleteModal";

function NewDeliveryBoy() {
  //   window.scrollTo(0, 0);
  const [rowData, setRowData] = useState();
  // delete state
  const [deleteModal, setDeleteModal] = useState({
    loader: false,
    show: false,
    type: "",
    data: {},
  })


  const handeleGetDeliveryBoyList = async () => {
    const cnoteData = await Apiservice.postdata("/User/delivery_boy_request_list");
    if (cnoteData.error == false) {
      setRowData(cnoteData.data);
    } else {
    }
  }

  useEffect(() => {
    handeleGetDeliveryBoyList()
  }, []);


  const DefaultColumnSetting = {
    sortable: true,
    filter: true,

    floatingFilter: true,
    flex: 1,
    resizable: true,
    minWidth: 120,
  };

  const rowHeight = 50;
  const [colDefs, setColDefs] = useState([
    {
      headerName: "Sl.No",
      valueGetter: "node.rowIndex + 1",
      filter: true,
      lockPosition: true,
    },
    {
      headerName: "Delivery Boy Name",
      filter: true,
      field: "name",
    },
    {
      headerName: "Mobile NUmber",
      filter: true,
      field: "mobile",
    },
    {
      headerName: "Branch Name",
      filter: true,
      cellRendererFramework: (params) => (
        <center>
          <p>{params?.data?.Frenchises[0]?.District}</p>
        </center>
      )
    },
    {
      headerName: "Status",
      filter: true,
      field: "status",
    },
    {
      headerName: "Delete",
      filter: true,
      cellRendererFramework: (params) => (
        <center onClick={() => setDeleteModal({ ...deleteModal, show: true, data: params.data, type: "DELETE", deletedmodule: 'Delivery Boy', deletedname: params?.data?.name })} >
          <FontAwesomeIcon icon={faTrash} className="iconstyle" />
        </center>
      )
    },
  ]);



  /******* Delete API Integration ******/
  const hendleDeletSubmitApi = async (data) => {
    console.log(data, 'data');
    setDeleteModal({ ...deleteModal, loader: true })
    const payload = {
      deliveryboy_id: data?.id,
    };
    let price = await Apiservice.postdata("/User/delete_delivery_boy_request", payload);
    if (price.error == false) {
      setDeleteModal({})
      toast.success(price.message)
      handeleGetDeliveryBoyList()
    } else {
      setDeleteModal({})
      toast.error(price.message)
      handeleGetDeliveryBoyList()
    }
  };

  const hendleDeleteModal = (data) => {
    setDeleteModal({})
  }

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        transition={Zoom}
        delay={1000}
        limit={1}
      />
      {/* <div className="more-link">
        <h6>New Delivery Boy Request </h6>
      </div> */}

      {/* delete modal */}
      <CustomDeleteModal
        hendleDeleteModal={hendleDeleteModal}
        deleteModal={deleteModal}
        hendleDeletSubmitApi={hendleDeletSubmitApi}
      ></CustomDeleteModal>
      <div className="my-3">
        <div className="card w-100 my-3 p-3">
          <div className="col-md-3">
            <h6 class="card-title    my-3 bg-light w-auto py-2 rounded ">
              <FileText /> <b>New Delivery Boy Request Master</b>
            </h6>
          </div>
          <div
            className="ag-theme-alpine"
            style={{ height: "70vh", width: "100%", marginTop: "30px" }}
          >
            <AgGridReact
              rowHeight={rowHeight}
              // columnDefs={columns}
              columnDefs={colDefs}
              defaultColDef={DefaultColumnSetting}
              pagination={true}
              paginationPageSize={10}
              // onGridReady={onGridReady}
              rowData={rowData}
            />{" "}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewDeliveryBoy;
