import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faSync } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { Col, Container, Row, Table, Button, Card, Form, Modal, Spinner } from "react-bootstrap";
import Apiservice from "../../Services/Apiservice";
import { ToastContainer, toast, Zoom } from "react-toastify";
import { FileText } from "react-bootstrap-icons";
import { AgGridReact } from "ag-grid-react";
import CustomModal from "../../common/CustomModal";
import CustomDeleteModal from "../../common/CustomDeleteModal";
import Select from "react-select";


const MasterDeliveryPartner = () => {
  const [loginData, setLoginData] = useState([]);
  // delete state
  const [deleteModal, setDeleteModal] = useState({
    loader: false,
    show: false,
    type: "",
    data: {},
  })

  /*********** Branch Name Dropdown ***********/
  const [listBranchNames, setListBranchNames] = useState([]);
  const [selectedBranchName, setSelectedBranchName] = useState([]);
  const [selectedBranchNameError, setSelectedBranchNameError] = useState();

  /*********** Delivery Boy Data ***********/
  const [checkDeliveryBoy, setCheckDeliveryBoy] = useState(false)
  const [deliveryBoyFormData, setDeliveryBoyFormData] = useState({ branchName: "", name: "", mobile: "", type: "", loader: false })
  const [deliveryBoyFormDataError, setDeliveryBoyFormDataError] = useState({ branchName: "", name: "", mobile: "", type: "" })
  /* Toast Modal */
  const [showPopupOpen, setShowPopupOpen] = useState(false)
  const [modalMessage, setModalMessage] = useState(null)
  const handleClosePopup = () => {
    setShowPopupOpen(false)
  }
  const handleOkClick = () => {
    setShowPopupOpen(false)
  }
  const handleClick = () => {
    setCheckDeliveryBoy(!checkDeliveryBoy)
    handleChange()
  }

  const handleChange = () => {
    setDeliveryBoyFormData({
      ...deliveryBoyFormData, ['type']: checkDeliveryBoy == true ? '' : 'Delivery Boy'
    })
    // setDeliveryBoyFormDataError({ ...deliveryBoyFormDataError, ['type']: checkDeliveryBoy == true ? 'Please select type' : '' })
  }

  const handleChangeData = (key, ele) => {
    setDeliveryBoyFormData({ ...deliveryBoyFormData, [key]: ele })
    setDeliveryBoyFormDataError({ ...deliveryBoyFormDataError, [key]: null })
  }

  const handleDeliveryBoyValidation = () => {
    const textReg = /[a-zA-Z]/
    const { branchName, name, type, mobile } = deliveryBoyFormData
    const newErrordata = {}
    if (name == '') {
      newErrordata.name = 'Please enter delivery boy name'
    }
    else if (name && name.length > 50) {
      newErrordata.name = 'Name should be below 50 charecters'
    }
    else if (name && !textReg.test(name)) {
      newErrordata.name = 'Please name in text form'
    }
    if (mobile == '') {
      newErrordata.mobile = 'Please enter mobile number'
    } else if (mobile && mobile.length != 10) {
      newErrordata.mobile = 'Mobile number should be 10 digits'
    }
    if (branchName == '') {
      newErrordata.branchName = 'Please select branch'
    }

    // if (type == '') {
    //   newErrordata.type = 'Please select type'
    // }
    return newErrordata
  }

  const handleDeliveryBoySubmit = async () => {
    const handleValidationObjectData = handleDeliveryBoyValidation()
    if (Object.keys(handleValidationObjectData).length > 0) {
      setDeliveryBoyFormDataError(handleValidationObjectData)
    } else {
      setDeliveryBoyFormData({ ...deliveryBoyFormData, loader: true })
      const addPincodePayload = {
        // type: deliveryBoyFormData.type,
        type: 'Delivery Boy',
        name: deliveryBoyFormData.name,
        mobileNumber: deliveryBoyFormData.mobile,
        frenchise_id: selectedBranchName[0].id,
      };

      const editApi = await Apiservice.postdata('/booking/deliverboy_master', addPincodePayload)
      if (editApi.error == false) {
        setShowPopupOpen(true)
        setModalMessage(editApi.message)
        setPageReload(pageReload + 1)
        setDeliveryBoyFormData({ ...deliveryBoyFormData, name: '', mobile: '', type: '', loader: false })
        deliveryBoyFormDataError({ ...deliveryBoyFormDataError, name: '', mobile: '', type: '' })
        setCheckDeliveryBoy(false)
      } else {
        setShowPopupOpen(true)
        setModalMessage(editApi.message)
        setPageReload(pageReload + 1)
        setDeliveryBoyFormData({ ...deliveryBoyFormData, name: '', mobile: '', type: '', loader: false })
        setCheckDeliveryBoy(false)
        deliveryBoyFormDataError({ ...deliveryBoyFormDataError, name: '', mobile: '', type: '' })
      }

    }

  }

  useEffect(() => {
    if (selectedBranchName.length != 0) {
      setDeliveryBoyFormData({
        ...deliveryBoyFormData,
        branchName: 'branch'
      })
      setDeliveryBoyFormDataError({
        ...deliveryBoyFormDataError,
        branchName: null
      })
    } else {
      setDeliveryBoyFormData({
        ...deliveryBoyFormData,
        branchName: ''
      })
    }

  }, [selectedBranchName])

  /****** New Table Data  ******/
  const [rowData, setRowData] = useState();
  const [pageReload, setPageReload] = useState(1)
  const handleGetDeliveryBoysList = async () => {
    const one = localStorage.getItem("userDetails");
    const two = JSON.parse(one)
    setLoginData(JSON.parse(one));
    if (two?.Hub_type == 'NULL') {
      const ListPincodeGet = await Apiservice.postdata("/booking/listdeliverymaster");
      if (ListPincodeGet.error == false) {
        setRowData(ListPincodeGet.data);
      } else {
        console.log("get API is not working");
      }
    } else {
      const payload = {
        frenchise_id: JSON.parse(one).id,
      };
      const ListPincodeGet = await Apiservice.postdata("/booking/listdeliverymaster", payload);
      if (ListPincodeGet.error == false) {
        setRowData(ListPincodeGet.data);
      } else {
        console.log("get API is not working");
      }
    }
  }

  /****** Branch name dropdown *******/
  // const handleGetBranch = async () => {
  //   const userData = JSON.parse(localStorage.getItem("userDetails"))
  //   let AreaNameData = await Apiservice.postdata(`/User/listCity`, {
  //     city_name: '',
  //     isNull: false,
  //     Hub_id: userData.id,
  //     ApprovedStatus: true,
  //   });
  //   if (AreaNameData.error == false) {
  //     const pincodeDropdown = [];
  //     const pincodeMaping = AreaNameData.data.map((ele, ind) => {
  //       pincodeDropdown.push({
  //         label: ele.city_name,
  //         value: ele.city_name,
  //         id: ele.id,
  //       });
  //     });
  //     setListBranchNames(pincodeDropdown)
  //   } else {
  //     console.log("failue");
  //   }
  // }

  const gandleGetFranchise = async () => {
    const userData = JSON.parse(localStorage.getItem("userDetails"))
    if (userData.Hub_type == 'NULL') {
      const payload = {
        approvedStatus: true
      }
      let AreaNameData = await Apiservice.postdata(`/booking/get_frenchise`, payload)
      if (AreaNameData.error == false) {
        const pincodeDropdown = [];
        const pincodeMaping = AreaNameData.data.map((ele, ind) => {
          pincodeDropdown.push({
            label: ele.city_masters[0].city_name,
            value: ele.city_masters[0].city_name,
            id: ele.id,
          });
        });
        setListBranchNames(pincodeDropdown)
      } else {
        console.log("failue");
      }
    } else {
      // const userData = JSON.parse(localStorage.getItem("userDetails"))
      // let AreaNameData = await Apiservice.postdata(`/User/listCity`, {
      //   city_name: '',
      //   isNull: false,
      //   Hub_id: userData.id,
      //   ApprovedStatus: true,
      // });
      // if (AreaNameData.error == false) {
      //   const pincodeDropdown = [];
      //   const pincodeMaping = AreaNameData.data.map((ele, ind) => {
      //     pincodeDropdown.push({
      //       label: ele.city_name,
      //       value: ele.city_name,
      //       id: ele.id,
      //     });
      //   });
      //   setListBranchNames(pincodeDropdown)
      // } else {
      //   console.log("failue");
      // }

      const userData = JSON.parse(localStorage.getItem("userDetails"))
      const payload = {
        id: userData.id
      }
      let AreaNameData = await Apiservice.postdata(`/booking/get_frenchise`, payload)
      console.log(AreaNameData, 'AreaNameData');
      if (AreaNameData.error == false) {
        const pincodeDropdown = [];
        const pincodeMaping = AreaNameData.data.map((ele, ind) => {
          pincodeDropdown.push({
            label: ele.city_masters[0].city_name,
            value: ele.city_masters[0].city_name,
            id: ele.id,
          });
        });
        setListBranchNames(pincodeDropdown)
      } else {
        console.log("failue");
      }
    }
  }


  useEffect(() => {
    // handleGetBranch()
    gandleGetFranchise()
  }, [])

  useEffect(() => {
    handleGetDeliveryBoysList()
  }, [pageReload]);

  const rowHeight = 50;
  const DefaultColumnSetting = {
    sortable: true,
    filter: true,

    floatingFilter: true,
    flex: 1,
    resizable: true,
    minWidth: 120,
  };

  const [colDefs, setColDefs] = useState([
    {
      headerName: "#",
      valueGetter: "node.rowIndex + 1",
      filter: false,
      lockPosition: true,
    },
    {
      headerName: "Delivery Boy Name",
      filter: true,
      field: "name",
    },
    {
      headerName: "Mobile Number",
      filter: true,
      field: "mobileNumber",
    },
    {
      headerName: "Type",
      filter: true,
      field: "type",
    },
    {
      headerName: "Branch Name",
      filter: true,
      valueGetter: "data.Frenchises[0].city_masters[0].city_name",
    },
    // {
    //   headerName: "Edit",
    //   filter: false,
    //   cellRendererFramework: (params) => (
    //     <center>
    //       <FontAwesomeIcon
    //         onClick={() => handleDeliveryBoyUpdate(params.data)}
    //         icon={faEdit} className="iconstyle"
    //       />
    //     </center>
    //   ),
    // },
    {
      headerName: "Delete",
      filter: false,
      cellRendererFramework: (params) => (
        <center>
          <FontAwesomeIcon
            // onClick={() => handledeleteClick(params.data.id)}
            // onClick={() => setDeleteData(params.data.id)}
            onClick={() => setDeleteModal({ ...deleteModal, show: true, data: params.data, type: "DELETE", deletedmodule: 'Delivery Boy', deletedname: params?.data?.name })}
            icon={faTrash} className="iconstyle"
          />
        </center>
      ),
    },
  ]);
  /******* delite Data ******/

  const [smShow, setSmShow] = useState(false)
  const [delteteData, setDeleteData] = useState()

  const handleCancelClear = () => {
    setSmShow(false)
  }
  const onClicksucess = async () => {
    const payload = { id: delteteData }
    const handledeleteClickapi = await Apiservice.postdata('/booking/deletedeliverymaster', payload)
    if (handledeleteClickapi.error == false) {
      // toast.success(handledeleteClickapi.message);
      setShowPopupOpen(true)
      setModalMessage(handledeleteClickapi.message)
      setPageReload(pageReload + 1)
      handleGetDeliveryBoysList()
      setSmShow(false)
    }
    else {
      // toast.error(handledeleteClickapi.message);
      setShowPopupOpen(true)
      setModalMessage(handledeleteClickapi.message)
      // setPageReload(pageReload + 1)
      handleGetDeliveryBoysList()
      setSmShow(false)

    }
  }

  const [showEditable, setShowEditable] = useState(false)
  const [editableData, setEditableData] = useState({
    name: '',
    type: '',
    mobile: '',
    id: '',
    loader: false
  })

  const [editableDataError, setEditableDataError] = useState({
    name: '',
    type: '',
    mobile: '',
    id: ''
  })

  /**** Update Data ****/
  const handleDeliveryBoyUpdate = (ele) => {
    console.log(ele, 'data');
    setShowEditable(true);
    setEditableData({
      ...editableData,
      name: ele.name,
      type: ele.type,
      mobile: ele.mobileNumber,
      id: ele.id
    })

  }
  // const handlecancel = () => {
  //   setShowEditable(false);
  // }
  const handleClose = () => {
    setShowEditable(false);
    setEditableData({
      ...editableData,
      name: '',
      type: '',
      mobile: ''
    })
    setEditableDataError({
      ...editableDataError,
      name: '',
      type: '',
      mobile: ''
    })
  }
  const updateEditSubmitClear = () => {
    setEditableData({
      ...editableData,
      name: '',
      type: '',
      // mobile: ''
    })
  }

  const handlChangeUpdate = (key, ele) => {
    setEditableData({
      ...editableData, [key]: ele
    })
    setEditableDataError({
      ...editableDataError, [key]: null
    })
  }

  const handleValidation = () => {
    const textReg = /[a-zA-Z]/
    const { name, type, mobile } = editableData
    const newErrordata = {}
    if (name == '') {
      newErrordata.name = 'Please enter delivery boy name'
    }
    else if (name && name.length > 50) {
      newErrordata.name = 'Name should be below 50 charecters'
    }
    else if (name && !textReg.test(name)) {
      newErrordata.name = 'Please name in Text form'
    }
    if (mobile == '') {
      newErrordata.mobile = 'Please enter mobile number'
    } else if (mobile && mobile.length != 10) {
      newErrordata.mobile = 'Mobile number should be 10 digits'
    }
    if (type == '') {
      newErrordata.type = 'Please enter type'
    } else if (type && !textReg.test(type)) {
      newErrordata.type = 'Please enter text type'
    }
    else if (type && type.length > 30) {
      newErrordata.type = 'Name should be below 50 charecters'
    }
    return newErrordata
  }


  const updateEditSubmit = async () => {
    const handleValidationObject = handleValidation()
    if (Object.keys(handleValidationObject).length > 0) {
      setEditableDataError(handleValidationObject)
    } else {
      setEditableData({ ...editableData, loader: true })
      const payload = {
        name: editableData.name,
        mobileNumber: editableData.mobile,
        type: editableData.type,
        id: editableData.id
      }
      const editApi = await Apiservice.postdata('/booking/editdeliverymaster', payload)
      if (editApi.error == false) {
        setShowPopupOpen(true)
        setModalMessage(editApi.message)
        setEditableData({})
        setShowEditable(false);
        handleUpdateEditAPI()
      } else {
        setShowPopupOpen(true)
        setModalMessage(editApi.message)
        setEditableData({})
      }
    }
  }

  const handleUpdateEditAPI = async () => {
    const one = localStorage.getItem("userDetails");
    setLoginData(JSON.parse(one));
    const payload = {
      frenchise_id: JSON.parse(one).id,
    };
    const ListPincodeGet = await Apiservice.postdata("/booking/listdeliverymaster", payload);
    if (ListPincodeGet.error == false) {
      setRowData(ListPincodeGet.data);
    } else {
      console.log("get API is not working");
    }

  }

  /******* Delete API Integration ******/
  const hendleDeletSubmitApi = async (data) => {
    setDeleteModal({ ...deleteModal, loader: true })
    const payload = {
      id: data?.id,
    };
    let price = await Apiservice.postdata("/booking/deletedeliverymaster", payload);
    if (price.error == false) {
      setDeleteModal({})
      toast.success(price.message)
      handleGetDeliveryBoysList()
    } else {
      setDeleteModal({})
      toast.error(price.message)
      handleGetDeliveryBoysList()
    }
  };
  const hendleDeleteModal = (data) => {
    setDeleteModal({})
  }

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        transition={Zoom}
        delay={1000}
        limit={1}
      />
      <CustomModal showPopupOpen={showPopupOpen}
        handleOkClick={handleOkClick}
        handleClosePopup={handleClosePopup}
        modalMessage={modalMessage}
      />
      {/* delete modal */}
      <CustomDeleteModal
        hendleDeleteModal={hendleDeleteModal}
        deleteModal={deleteModal}
        hendleDeletSubmitApi={hendleDeletSubmitApi}
      ></CustomDeleteModal>
      <Modal
        show={smShow}
        onHide={() => setSmShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Are You Confirm to Delete</Modal.Title>
        </Modal.Header>
        <Modal.Footer className="m-auto">
          <Button
            variant="success"
            className=" rounded"
            onClick={handleCancelClear}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            className=" rounded"
            onClick={onClicksucess}
          >
            Delete{" "}
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="my-3">
        <div className="card w-100">
          <div class="card-body pt-0">
            <div className="row">
              <div className="col-md-3">
                <h6 class="card-title    my-3 bg-light w-auto py-2 rounded ">
                  <FileText /> <b>Delivery Boy Master</b>
                </h6>
              </div>
            </div>
            <div className="row my-2">
              <div className="col-md-3">
                <label style={{ marginBottom: '5px' }} >Branch Name</label>
                <Select
                  isMulti
                  options={listBranchNames}
                  onChange={setSelectedBranchName}
                  className='mt-1'
                  value={selectedBranchName[selectedBranchName.length - 1] || null}
                />
                <span className="text-danger">{deliveryBoyFormDataError.branchName || deliveryBoyFormData.branchName && ''}</span>
              </div>
              <div className="col-md-3">
                <Form.Group className="mb-3">
                  <Form.Label>Delivery Boy Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={deliveryBoyFormData.name}
                    onChange={(e) => handleChangeData('name', e.target.value)}
                    maxLength={50}
                    onInput={(e) => {
                      if (e.target.value > e.target.maxLength) {
                        e.target.value = e.target.value.slice(0, e.target.maxLength)
                      }
                    }}
                  />
                  <span className="text-danger">{deliveryBoyFormDataError.name || deliveryBoyFormData.name && ''}</span>
                </Form.Group>
              </div>
              <div className="col-md-3">
                <Form.Group className="mb-3">
                  <Form.Label>Mobile Number</Form.Label>
                  <Form.Control
                    type="number"
                    value={deliveryBoyFormData.mobile}
                    onChange={(e) => handleChangeData('mobile', e.target.value)}
                    maxLength={10}
                    onInput={(e) => {
                      if (e.target.value.length > e.target.maxLength) {
                        e.target.value = e.target.value.slice(0, e.target.maxLength)
                      }
                    }}
                    onWheelCapture={(e) => e.target.blur()}
                  />
                  <span className="text-danger">{deliveryBoyFormDataError.mobile || deliveryBoyFormData.mobile && ''}</span>
                </Form.Group>
              </div>
              <div className="col-md-3" >
                {/* <div> */}
                {/* <label>Type</label> */}
                {/* </div> */}
                {/* <div className="d-flex" > */}
                {/* <div className="col-md-4" >
                    <Form.Group>
                      <Form.Check
                        type='radio'
                        name='object1'
                        label='Employee'
                        value='Employee'
                        onChange={(e) => handleChangeData('type', e.target.value)}

                      >
                      </Form.Check>
                    </Form.Group>
                  </div> */}
                {/* <div className="col-md-4" style={{ marginLeft: "20px", marginTop: "28px" }} >
                    <Form.Group>
                      <Form.Check
                        label='Delivery Boy'
                        checked={checkDeliveryBoy}
                        onClick={handleClick}

                      >
                      </Form.Check>
                    </Form.Group>
                  </div> */}
                {/* <span className="text-danger">{deliveryBoyFormDataError.type}</span> */}
                {/* </div> */}

                {
                  //  <div className="col-md-3">
                  //   <Form.Group className="mb-3">
                  //     <Form.Label>Commission per</Form.Label>
                  //     <Form.Control
                  //       type="number"
                  //       value={commission}
                  //       onChange={(e) => setCommission(e.target.value)}
                  //     />
                  //     <span className="text-danger">{commissionError}</span>
                  //   </Form.Group>
                  // </div>
                }
                {/* <div className="col-md-3">
                <Form.Label>Select Type</Form.Label>
                {["checkbox"].map((type) => (
                  <div key={`inline-${type}`} className="mb-3">
                    <Form.Check
                      inline
                      label="Employee"
                      name="group1"
                      // type={type}
                      id={`inline-${type}-1`}
                      // onClick={setCheck1}
                      type="radio"
                      onClick={() => handleCheckBox1("Employee")}
                      checked={check1}
                    />
                    <Form.Check
                      inline
                      label="Delivery Boy"
                      name="group1"
                      // type={type}
                      type="radio"
                      id={`inline-${type}-2`}
                      // onClick={setCheck2}
                      onClick={() => handleCheckBox2("Delivery Boy")}
                      checked={check2}
                    />
                    <p className="text-danger">{checkError}</p>
                  </div>
                ))}
              </div> */}

                <div className="mt-2">
                  <Button
                    onClick={handleDeliveryBoySubmit}
                    className="btn btn-success rounded  mt-4 "
                  >
                    Add{" "}
                    {deliveryBoyFormData.loader ? (
                      <Spinner
                        animation="border"
                        variant="Primary"
                        size="sm"
                      />
                    ) : (
                      ""
                    )}
                  </Button>
                </div>
              </div>
            </div>
            {/* {<div className="row">
              <div className="col-md-3">
                <Form.Label>Search Delivery Boy</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) => setSearch(e.target.value)}
                ></Form.Control>
              </div>
              <div className="col-md-3 mt-2">
                <Button
                  onClick={() => onSearchClick()}
                  className="btn btn-danger rounded my-4"
                >
                  Search
                </Button>
              </div>
            </div>} */}


            {/* <div className="row">
              <div className="row my-3">
                {tableData ? (
                  <table size="sm" className="table table-bordered table-hover text-center">
                    <thead>
                      <tr>
                        <th>Sr No.</th>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Mob No</th>
                        {
                          // <th>Comission</th>
                        }
                        <th>Action</th>
                      </tr>
                    </thead>
                    {filterTabledata?.length <= 0 ? (
                      <tbody>
                        {tableData?.map((ele, ind) => {
                          return (
                            <tr>
                              <td>{ind + 1}</td>
                              <td>{ele.name}</td>
                              <td>{ele.type}</td>
                              <td>{ele.mobileNumber}</td>
                              {
                                //   <td>
                                //   {ele.commissionper
                                //     ? ele.commissionper
                                //     : ele.commissionPer}
                                // </td>
                              }
                              <td>
                                <FontAwesomeIcon
                                  icon={faEdit}
                                  className="iconstyle"
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    ) : (
                      <tbody>
                        {filterTabledata?.map((ele, ind) => {
                          return (
                            <tr>
                              <td>{ind + 1}</td>
                              <td>{ele.name}</td>
                              <td>{ele.type}</td>
                              <td>{ele.mobileNumber}</td>
                              {
                                //   <td>
                                //   {ele.commissionper
                                //     ? ele.commissionper
                                //     : ele.commissionPer}
                                // </td>
                              }
                              <td>
                                <FontAwesomeIcon
                                  icon={faEdit}
                                  className="iconstyle"
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    )}
                  </table>
                ) : (
                  <div style={{ marginLeft: "550px", marginTop: "100px" }}>
                    <Circles
                      height="30"
                      width="30"
                      color="grey"
                      ariaLabel="loading"
                    />
                  </div>
                )}
              </div>
            </div> */}

            <div
              className="ag-theme-alpine"
              style={{ height: "70vh", width: "100%", marginTop: "10px" }}
            >
              <AgGridReact
                rowHeight={rowHeight}
                // columnDefs={columns}
                columnDefs={colDefs}
                defaultColDef={DefaultColumnSetting}
                pagination={true}
                paginationPageSize={10}
                // onGridReady={onGridReady}
                rowData={rowData}
              />{" "}
            </div>


          </div>
        </div>
      </div>
      <div>
        <Modal
          show={showEditable}
          backdrop="static"
          keyboard={false}
          centered
          size="lg"
          onHide={handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Upadate Delivery Boy Data</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="new-price-add-container">
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Delivery Boy Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Delivery Boy Name"
                      // size="sm"
                      value={editableData.name}
                      onChange={(e) => handlChangeUpdate('name', e.target.value)}
                    />
                    <span className="text-danger">
                      {editableDataError.name}
                    </span>
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Mobile Number</Form.Label>
                    <Form.Control type="number" placeholder="Mobile Number"
                      onChange={(e) => handlChangeUpdate('mobile', e.target.value)}
                      size="sm"
                      value={editableData.mobile}
                      maxLength={10}
                      onInput={(e) => {
                        if (e.target.value.length > e.target.maxLength) {
                          e.target.value = e.target.value.slice(0, e.target.maxLength)
                        }
                      }}
                    />
                    <span className="text-danger">
                      {editableDataError.mobile}
                    </span>
                  </Form.Group>
                </Col>
                {/* <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Type</Form.Label>
                    <Form.Control type="text" placeholder="Type"
                      onChange={(e) => handlChangeUpdate('type', e.target.value)}
                      size="sm"
                      value={editableData.type}
                      disabled
                    />
                    <span className="text-danger">
                      {editableDataError.type}
                    </span>
                  </Form.Group>
                </Col> */}
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div class="d-flex flex-row-reverse">
              <Col className='d-flex' style={{ marginTop: '30px' }}>
                <div>
                  <button className="btn btn-secondary" onClick={() => { setEditableData({ ...editableData, name: '', mobile: '', loader: false }) }} > Cleare Data </button>
                </div>
                <div className="" style={{ marginLeft: "30px" }}>
                  <button className="btn btn-secondary"
                    onClick={updateEditSubmit}
                  >
                    Update Data
                    {editableData.loader ? (
                      <Spinner
                        animation="border"
                        variant="Primary"
                        size="sm"
                      />
                    ) : (
                      ""
                    )}
                  </button>
                </div>
              </Col>
              {/* <div>
              <button className="btn btn-danger"
                onClick={handlecancel}
              >
                Cancel
              </button>
            </div> */}
            </div>
          </Modal.Footer>
        </Modal>

      </div>
    </>
  );
};

export default MasterDeliveryPartner;
