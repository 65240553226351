import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const Personal = () => {
  return (
    <div className="personal">
      <Container>
        <Row>
          <Col className="d-flex justify-content-between">
            <h6>Fathers Name</h6>
            <p>xxxxxxxxxxxxx</p>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-between">
            <h6>Date of Birth</h6>
            <p>xxxxxxxxxxxxx</p>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-between">
            <h6>E-mail</h6>
            <p>xxxxxxxxxxxxx</p>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-between">
            <h6>Work As</h6>
            <p>xxxxxxxxxxxxx</p>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-between">
            <h6>Date</h6>
            <p>xxxxxxxxxxxxx</p>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-between">
            <h6>Compleate Address</h6>
            <p>xxxxxxxxxxxxx</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Personal;
