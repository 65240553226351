import React, { useState, useRef, useEffect } from "react";
import {
  faEdit,
  faRupeeSign,
  faSearch,
  faSync,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { toast, ToastContainer, Zoom } from "react-toastify";

import Apiservice from "../../Services/Apiservice";
import "../../Styles/Setupmaster.css";
import { Circles } from "react-loader-spinner";
import { AgGridReact } from "ag-grid-react";
import CustomModal from "../../common/CustomModal";
import { FileText } from "react-bootstrap-icons";


const Ratemaster = (props) => {
  const [rateDetailsPop, setRateDetailsPop] = useState(false);
  const [loader, setLoader] = useState(false);
  // const [selectedRate, setSelectedRate] = useState({
  //   distances: [],
  // });

  const [selectedRate, setSelectedRate] = useState([]);

  useEffect(() => {
    setNewPriceData({
      ...newPriceData,
      ["distance_id"]: selectedRate?.id,
    });
  }, [selectedRate]);

  const [isNewPrice, setIsNewPrice] = useState(false);
  const [newPriceData, setNewPriceData] = useState({
    weight_from: "",
    weight_to: "",
    air_amount: "",
    surface_amount: "",
    distance_id: selectedRate?.id,
  });

  const [newPriceDataError, setNewPriceDataError] = useState({
    weight_from: "",
    weight_to: "",
    air_amount: "",
    surface_amount: "",
    // distance_id: selectedRate?.id,
  });

  const [newPriceDataUpdate, setNewPriceDataUpdate] = useState({
    weight_from: "",
    weight_to: "",
    air_amount: "",
    surface_amount: "",
    id: "",
  });

  // console.log(newPriceDataUpdate, 'newPriceDataUpdate');

  const [pageReload, setPageReload] = useState(1);
  const [reloadList, setReloadList] = useState(1);

  /* Toast Modal */
  const [showPopupOpen, setShowPopupOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState(null);
  const handleClosePopup = () => {
    setShowPopupOpen(false);
  };
  const handleOkClick = () => {
    setShowPopupOpen(false);
  };

  // React.useEffect(() => {
  //   childFunc.current = getRateData;
  //   getRateData();
  // }, [])

  // const getRateData = async () => {
  //   let master = await Apiservice.getRateMasterData();
  //   if (!master.error) {
  //     setRateData(master.data);
  //   } else {
  //     toast.warning("Some thing went wrong");
  //   }
  // }

  const handleNewDistancePrice = (type, event) => {
    setNewPriceData({ ...newPriceData, [type]: event.target.value });
    setNewPriceDataError({ ...newPriceDataError, [type]: null });
  };

  const handleNewDistancePriceUpdate = (type, event) => {
    setNewPriceDataUpdate({
      ...newPriceDataUpdate,
      [type]: event.target.value,
    });
  };

  const handleValidation = () => {
    const { weight_from, weight_to, air_amount, surface_amount } = newPriceData;
    const newError = {};
    if (!weight_from) {
      newError.weight_from = "please enter  weight from";
    }
    if (!weight_to) {
      newError.weight_to = "please enter to weight to";
    }
    if (!air_amount) {
      newError.air_amount = "please enter air amount";
    }
    if (!surface_amount) {
      newError.surface_amount = "please enter surface amount";
    }
    return newError;
  };

  const [modalRowData, setModalRowdata] = useState();

  /******** List Rate Master ********/
  const [rowData, setRowdata] = useState();

  useEffect(async () => {
    let master = await Apiservice.getRateMasterData();
    if (!master.error) {
      setRowdata(master.data);
    } else {
      // toast.warning("Some thing went wrong");
    }
  }, [reloadList]);

  useEffect(async () => {
    let master = await Apiservice.getRateMasterData();
    if (!master.error) {
      setRowdata(master.data);
    } else {
      // toast.warning("Some thing went wrong");
    }
  }, [pageReload]);

  useEffect(async () => {
    let master = await Apiservice.getRateMasterData();
    if (!master.error) {
      setRowdata(master.data);
    } else {
      // toast.warning("Some thing went wrong");
    }
  }, [props]);

  const addPriceToDistace = async () => {
    const handleValidationObject = handleValidation();
    if (Object.keys(handleValidationObject).length > 0) {
      setNewPriceDataError(handleValidationObject);
    } else {
      setLoader(true);

      // console.log(newPriceData, 'newPriceData');
      const payload = {
        air_amount: +newPriceData.air_amount,
        distance_id: newPriceData.distance_id,
        surface_amount: +newPriceData.surface_amount,
        weight_from: +newPriceData.weight_from,
        weight_to: +newPriceData.weight_to,
      };

      // console.log(payload, 'payload');

      let price = await Apiservice.addDistancePrice(payload);
      if (!price.error) {
        setLoader(false);
        // let tmpItemdata = selectedRate;
        // tmpItemdata.distances.push(newPriceData);
        // setModalRowdata(tmpItemdata["distances"]);
        // handleNewPriceAdd();
        setNewPriceData({
          ...newPriceData,
          weight_from: "",
          weight_to: "",
          air_amount: "",
          surface_amount: "",
        });
        setShowPopupOpen(true);
        setModalMessage(price.message);
        setIsNewPrice(false);
        setRateDetailsPop(false);
        setReloadList(reloadList + 1);
      } else {
        setLoader(false);
        setShowPopupOpen(true);
        setModalMessage(price.message);
        setIsNewPrice(false);
        setRateDetailsPop(false);
        setReloadList(reloadList + 1);
        setNewPriceData({
          ...newPriceData,
          weight_from: "",
          weight_to: "",
          air_amount: "",
          surface_amount: "",
        });
      }
    }
  };

  /******  update edite data ********/

  const updateEditSubmit = async () => {
    setLoader(true);
    const payload = {
      weight_from: newPriceDataUpdate.weight_from,
      weight_to: newPriceDataUpdate.weight_to,
      air_amount: newPriceDataUpdate.air_amount,
      surface_amount: +newPriceDataUpdate.surface_amount,
      id: newPriceDataUpdate.id,
    };

    let price = await Apiservice.postdata("/admin/add_weight", payload);
    if (!price.error) {
      setLoader(false);
      // let tmpItemdata = selectedRate;
      // tmpItemdata.distances.push(newPriceData);
      // setModalRowdata(tmpItemdata['distances']);

      setShowRate(false);
      handleNewPriceAdd();
      setShowPopupOpen(true);
      setModalMessage(price.message);
      setNewPriceDataUpdate({
        ...newPriceDataUpdate,
        dist_from: "",
        dist_to: "",
        air_amount: "",
        surface_amount: "",
        weight_id: "",
        id: "",
      });
      setUpdateModal(false);
      setReloadList(reloadList + 1);
      setUpdateModal(false);
      setRateDetailsPop(false);
    } else {
      setLoader(false);
      setShowPopupOpen(true);
      setModalMessage(price?.message?.name);
      setUpdateModal(false);
      setReloadList(reloadList + 1);
      setUpdateModal(false);
      setRateDetailsPop(false);
      setShowRate(false);
      setNewPriceDataUpdate({
        ...newPriceDataUpdate,
        dist_from: "",
        dist_to: "",
        air_amount: "",
        surface_amount: "",
        weight_id: "",
        id: "",
      });
    }
  };

  const handleRateDetailsPop = () => {
    setIsNewPrice(false);
    setRateDetailsPop(!rateDetailsPop);
    setIsNewPrice(false);
  };

  const handleRateDetailsClick = (data) => {
    setSelectedRate(data);
    // setNewPriceData({ ...newPriceData, data });
    handleRateDetailsPop();
  };
  const [updateModal, setUpdateModal] = useState(false);

  const handleNewPriceAdd = () => {
    setIsNewPrice(true);
    setUpdateModal("no");
  };
  const handleNewCloseButton = () => {
    setIsNewPrice(false);
    setUpdateModal("no");
    setNewPriceDataError({
      ...newPriceDataError,
      weight_from: "",
      weight_to: "",
      air_amount: "",
      surface_amount: "",
      // distance_id: selectedRate?.id,
    });
  };

  const handleUpdateCloseButton = () => {
    setUpdateModal(false);
    setIsNewPrice(false);
  };

  const handleEditPrice = (e) => {
    setIsNewPrice("no");
    // setUpdateModal(true);
    setNewPriceDataUpdate({
      ...newPriceDataUpdate,
      weight_from: e.weight_from,
      weight_to: e.weight_to,
      air_amount: e.air_amount,
      surface_amount: e.surface_amount,
      // weight_id: e.weight_id,
      id: e.id,
    });
    // setIsNewPrice(false);
    setUpdateModal(true);
  };

  const rowHeight = 50;
  const DefaultColumnSetting = {
    sortable: true,
    filter: true,

    floatingFilter: true,
    flex: 1,
    resizable: true,
    minWidth: 120,
  };

  const [colDefs, setColDefs] = useState([
    {
      headerName: "#",
      valueGetter: "node.rowIndex + 1",
      filter: true,
      lockPosition: true,
    },
    {
      headerName: "Km From",
      filter: true,
      field: "dist_from",
    },
    {
      headerName: "Km To",
      filter: true,
      field: "dist_to",
    },
    {
      headerName: "View",
      filter: true,
      cellRendererFramework: (params) => (
        <center onClick={() => handleRateDetailsClick(params.data)}>
          {/* <FontAwesomeIcon
            onClick={() => handleRateDetailsClick(params.data)}
            icon={faEdit}
            className="iconstyle"
          /> */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-eye"
            viewBox="0 0 16 16"
          >
            <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
            <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
          </svg>
        </center>
      ),
    },
    {
      headerName: "Delete",
      filter: true,
      cellRendererFramework: (params) => (
        <center>
          <FontAwesomeIcon
            onClick={() => deleteRateData(params.data.id)}
            icon={faTrash}
            className="iconstyle"
          />
        </center>
      ),
    },
    {
      headerName: "Edit",
      filter: true,
      cellRendererFramework: (params) => (
        <center onClick={() => handleKilometerUpdate(params.data)}>
          <FontAwesomeIcon
            icon={faEdit}
            // onClick={() => handleEditPrice(e)}
            className="iconstyle"
          />
        </center>
      ),
    },
  ]);

  /**********  Delete API Integration ************/
  const [smShow, setSmShow] = useState();
  const [deleteId, setDeleteId] = useState();
  const deleteRateData = (dataID) => {
    setSmShow(true);
    setDeleteId(dataID);
  };

  const onClicksucess = async () => {
    setLoader(true);
    const one = deleteId;
    const two = one.toString();
    const payload = {
      id: two,
    };
    let price = await Apiservice.postdata("/admin/deleteDistance", payload);
    if (price.error == false) {
      setLoader(false);
      setSmShow(false);
      setPageReload(pageReload + 1);
      setShowPopupOpen(true);
      setModalMessage(price.message);
      setIsNewPrice(false);
    } else {
      setLoader(false);
      setSmShow(false);
      setPageReload(pageReload + 1);
      setShowPopupOpen(true);
      setModalMessage(price.message);
      setIsNewPrice(false);
    }
  };

  const handleCancelClearDistance = () => {
    setSmShow(false);
  };

  /****** Modele Delte Api ******/
  const [smShowModal, setSmShowModal] = useState();
  const [deleteModalId, setDeleteModalId] = useState();
  const handleModelDelteApi = (dataID) => {
    setSmShowModal(true);
    setDeleteModalId(dataID);
  };
  const onClicksucessModal = async () => {
    setLoader(true);
    const one = deleteModalId.id;
    const two = one.toString();
    const payload = {
      id: two,
    };
    let price = await Apiservice.postdata("/admin/delete_weight", payload);
    if (price.error == false) {
      // setRateDetailsPop(!rateDetailsPop);
      setLoader(false);
      // let tmpItemdata = selectedRate;
      // tmpItemdata.distances.push(newPriceData);
      // setModalRowdata(tmpItemdata['distances']);

      setRateDetailsPop(true);
      setSmShowModal(false);
      setPageReload(pageReload + 1);

      setShowPopupOpen(true);
      setModalMessage(price.message);
      setIsNewPrice(false);
      setRateDetailsPop(false);
    } else {
      // setRateDetailsPop(!rateDetailsPop);
      setLoader(false);
      setRateDetailsPop(true);
      setPageReload(pageReload + 1);
      setShowPopupOpen(true);
      setModalMessage(price.message);
      setIsNewPrice(false);
      setRateDetailsPop(false);
      setSmShowModal(false);
    }
  };

  const handleCancelClear = () => {
    setSmShowModal(false);
  };
  /******  Update Distance ******/
  const [showRate, setShowRate] = useState(false);
  const [kmDistance, setKmDistance] = useState({
    dist_from: "",
    dist_to: "",
    id: "",
  });
  const [kmDistanceError, setKmDistanceError] = useState({
    dist_from: "",
    dist_to: "",
    id: "",
  });
  const handleKmDistance = (key, e) => {
    setKmDistance({
      ...kmDistance,
      [key]: e.target.value,
    });
  };

  const handleKilometerUpdate = (data) => {
    setShowRate(true);
    setKmDistance({
      ...kmDistance,
      dist_from: data?.dist_from,
      dist_to: data?.dist_to,
      id: data,
    });
  };

  const handleCloseRate = () => {
    setShowRate(false);
  };

  const handleKmSubmit = async () => {
    const payload = {
      dist_from: +kmDistance?.dist_from,
      dist_to: +kmDistance?.dist_to,
      id: kmDistance?.id?.id,
    };
    const updateDistance = await Apiservice.postdata(
      "/admin/add_distance",
      payload
    );
    if (updateDistance.error == false) {
      setReloadList(reloadList + 1);
      setShowPopupOpen(true);
      setModalMessage(updateDistance?.message);
      setShowRate(false);
    } else {
      setReloadList(reloadList + 1);
      setShowPopupOpen(true);
      setModalMessage(updateDistance?.message?.name);
      setShowRate(false);
    }
  };

  /*********** model List ************/

  const [rateDataModal, setRowDataModal] = useState();

  // useEffect(async () => {
  //   let master = await Apiservice.getRateMasterData();
  //   if (!master.error) {
  //     setRowDataModal(master.data);
  //   } else {
  //     // toast.warning("Some thing went wrong");
  //   }
  //   // }
  // }, [pageReload]);

  // const rowHeight = 50;
  // const DefaultColumnSetting = {
  //   sortable: true,
  //   filter: true,

  //   floatingFilter: true,
  //   flex: 1,
  //   resizable: true,
  //   minWidth: 120,
  // };

  const [colDefsModal, setColDefsModal] = useState([
    {
      headerName: "#",
      valueGetter: "node.rowIndex + 1",
      filter: true,
      lockPosition: true,
    },
    // {
    //   headerName: 'Distance From',
    //   filter: true,
    //   // field: "weight_from",
    //   field: "dist_from",
    //   // valueGetter:'data.distances[0].dist_from'
    // },
    // {
    //   headerName: "Price",
    //   filter: true,
    //   field: "Price",
    // },
    // {
    //   headerName: "Edit",
    //   filter: true,
    //   cellRendererFramework: (params) => (
    //     <center>
    //       <FontAwesomeIcon
    //         onClick={() => handleRateDetailsClick(params.data)}
    //         icon={faEdit} className="iconstyle"
    //       />
    //     </center>
    //   ),

    // }
  ]);

  const clearButton = () => {
    const arr = { ...newPriceData };
    arr.weight_from = "";
    arr.weight_to = "";
    arr.air_amount = "";
    arr.surface_amount = "";
    setNewPriceData(arr);
  };

  const handleWeightUpdateClear = () => {
    setNewPriceDataUpdate({
      ...newPriceDataUpdate,
      weight_from: "",
      weight_to: "",
      air_amount: "",
      surface_amount: "",
      id: "",
    });
  };

  return (
    <>
      <CustomModal
        showPopupOpen={showPopupOpen}
        handleOkClick={handleOkClick}
        handleClosePopup={handleClosePopup}
        modalMessage={modalMessage}
      />
      {/* <Container> */}
        <Modal show={smShow} onHide={() => setSmShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Are You Confirm to Delete Rate</Modal.Title>
          </Modal.Header>
          <Modal.Footer className="m-auto">
            <Button
              variant="danger"
              className=" rounded"
              onClick={handleCancelClearDistance}
            >
              Cancel
            </Button>
            <Button
              variant="success"
              className=" rounded"
              onClick={onClicksucess}
            >
              Delete{" "}
              {loader == true && (
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={smShowModal} onHide={() => setSmShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Are You Confirm to Delete</Modal.Title>
          </Modal.Header>
          <Modal.Footer className="m-auto">
            <Button
              variant="danger"
              className=" rounded"
              onClick={handleCancelClear}
            >
              Cancel
            </Button>
            <Button
              variant="success"
              className=" rounded"
              onClick={onClicksucessModal}
            >
              Delete{" "}
              {loader == true && (
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
            </Button>
          </Modal.Footer>
        </Modal>
        <div className="card w-100  p-3">
          <div class="card-body pt-0">
            <div className="col-md-3">
              <h6 class="card-title    my-3 bg-light w-auto py-2 rounded ">
                <FileText /> <b>Rate Master</b>
              </h6>
            </div>
            <div
              className="ag-theme-alpine"
              style={{ height: "70vh", width: "100%" }}
            >
              <AgGridReact
                rowHeight={rowHeight}
                // columnDefs={columns}
                columnDefs={colDefs}
                defaultColDef={DefaultColumnSetting}
                pagination={true}
                paginationPageSize={10}
                // onGridReady={onGridReady}
                rowData={rowData}
              />{" "}
            </div>
          </div>
        </div>

        <Modal
          show={rateDetailsPop}
          backdrop="static"
          keyboard={false}
          centered
          size="lg"
        >
          <h6 className="text-center more-link mt-4">Price Details</h6>
          <Modal.Body>
            <Table bordered hover size="sm"  className="text-center">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Km From</th>
                  <th>Km To</th>
                  <th>Weight From</th>
                  <th>Weight To</th>
                  <th>Air Amount</th>
                  <th>Surface Amount</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {selectedRate &&
                  selectedRate["weights"]?.map((e, i) => {
                    return (
                      <tr id={i}>
                        <td>{i + 1}</td>
                        <td>{selectedRate && selectedRate?.dist_from}</td>
                        <td>{selectedRate && selectedRate?.dist_to}</td>
                        <td>{e?.weight_from}</td>
                        <td>{e?.weight_to}</td>
                        <td>{e?.air_amount}</td>
                        <td>{e?.surface_amount}</td>
                        <td>
                          <FontAwesomeIcon
                            icon={faEdit}
                            onClick={() => handleEditPrice(e)}
                            className="iconstyle"
                          />
                        </td>
                        <td>
                          <FontAwesomeIcon
                            icon={faTrash}
                            onClick={() => handleModelDelteApi(e)}
                            className="iconstyle"
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>

            {/* <div
              className="ag-theme-alpine"
              style={{ height: "70vh", width: "100%" }}
            >
              <AgGridReact
                rowHeight={rowHeight}
                // columnDefs={columns}
                columnDefs={colDefsModal}
                defaultColDef={DefaultColumnSetting}
                pagination={true}
                paginationPageSize={10}
                // onGridReady={onGridReady}
                rowData={rateDataModal}
              />{" "}
            </div> */}

            {/* <Button variant="primary" onClick={handleNewPriceAdd}>
              {!isNewPrice ? "Add Price" : "Close"}
            </Button> */}

            {isNewPrice == false && (
              <Button variant="primary" onClick={handleNewPriceAdd}>
                Add Price
              </Button>
            )}

            {isNewPrice == true && (
              <Button variant="primary" onClick={handleNewCloseButton}>
                Close
              </Button>
            )}

            {updateModal == true && (
              <Button variant="primary" onClick={handleUpdateCloseButton}>
                Close
              </Button>
            )}

            {updateModal == true && (
              <div className="new-price-add-container">
                <div className="card w-100 my-3 p-3">
                  <div class="card-body pt-0">
                    <Row style={{ textAlign: "center" }}>
                      <b style={{ color: "#7700f6" }}>Update Price Detailes</b>
                    </Row>
                    <Row style={{ marginTop: "20px" }}>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Weight From</Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Weight From"
                            onChange={(e) =>
                              handleNewDistancePriceUpdate("weight_from", e)
                            }
                            size="sm"
                            value={newPriceDataUpdate.weight_from}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please choose a username.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Weight To</Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Weight To"
                            onChange={(e) =>
                              handleNewDistancePriceUpdate("weight_to", e)
                            }
                            size="sm"
                            value={newPriceDataUpdate.weight_to}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Air Amount</Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Air Amount"
                            onChange={(e) =>
                              handleNewDistancePriceUpdate("air_amount", e)
                            }
                            size="sm"
                            value={newPriceDataUpdate.air_amount}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Surface Amount</Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Surface Amount"
                            onChange={(e) =>
                              handleNewDistancePriceUpdate("surface_amount", e)
                            }
                            size="sm"
                            value={newPriceDataUpdate.surface_amount}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={4}>
                        <Button
                          className="save-Button mx-2"
                          variant="primary"
                          onClick={updateEditSubmit}
                        >
                          Update Data
                          {loader == true && (
                            <span
                              style={{ marginLeft: "5px" }}
                              class="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          )}
                        </Button>
                        <Button
                          className="save-Button"
                          variant="danger"
                          onClick={handleWeightUpdateClear}
                        >
                          Clear
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            )}

            {isNewPrice == true && (
              <div className="new-price-add-container">
                <div className="card w-100 my-3 p-3">
                  <div class="card-body pt-0">
                    <Row style={{ textAlign: "center" }}>
                      <b style={{ color: "#7700f6" }}>Add Price Detailes</b>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Weight From </Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Weight From"
                            onChange={(e) =>
                              handleNewDistancePrice("weight_from", e)
                            }
                            size="sm"
                            value={newPriceData.weight_from}
                          />
                          <span className="text-danger">
                            {newPriceDataError?.weight_from}
                          </span>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Weight To</Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Weight To"
                            onChange={(e) =>
                              handleNewDistancePrice("weight_to", e)
                            }
                            size="sm"
                            value={newPriceData.weight_to}
                          />
                          <span className="text-danger">
                            {newPriceDataError?.weight_to}
                          </span>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Air Amount</Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Air Amount"
                            onChange={(e) =>
                              handleNewDistancePrice("air_amount", e)
                            }
                            size="sm"
                            value={newPriceData.air_amount}
                          />
                          <span className="text-danger">
                            {newPriceDataError?.air_amount}
                          </span>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Surface Amount</Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Surface Amount"
                            onChange={(e) =>
                              handleNewDistancePrice("surface_amount", e)
                            }
                            size="sm"
                            value={newPriceData.surface_amount}
                          />
                          <span className="text-danger">
                            {newPriceDataError?.surface_amount}
                          </span>
                        </Form.Group>
                      </Col>
                      <Col md={4}>
                        <Button
                          className="save-Button"
                          variant="primary"
                          onClick={addPriceToDistace}
                        >
                          Save
                          {loader == true && (
                            <span
                              style={{ marginLeft: "5px" }}
                              class="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          )}
                        </Button>{" "}
                        <Button
                          className="save-Button"
                          variant="danger"
                          onClick={clearButton}
                        >
                          Clear
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleRateDetailsPop}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showRate}
          onHide={handleCloseRate}
          backdrop="static"
          keyboard={false}
          centered
          size="lg"
        >
          <h6 className="text-center more-link mt-4">Update Distance</h6>
          <Modal.Body>
            <Form>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Km From</Form.Label>
                    <Form.Control
                      required
                      type="Number"
                      placeholder="Km From"
                      value={kmDistance.dist_from}
                      onChange={(e) => handleKmDistance("dist_from", e)}
                      maxLength={6}
                      onInput={(e) => {
                        if (e.target.value.length > e.target.maxLength)
                          e.target.value = e.target.value.slice(
                            0,
                            e.target.maxLength
                          );
                      }}
                    />
                    <span className="text-danger">
                      {kmDistanceError.dist_from}
                    </span>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group
                    required
                    className="mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Km To</Form.Label>
                    <Form.Control
                      type="Number"
                      placeholder="Km To"
                      value={kmDistance.dist_to}
                      onChange={(e) => handleKmDistance("dist_to", e)}
                      maxLength={6}
                      onInput={(e) => {
                        if (e.target.value.length > e.target.maxLength)
                          e.target.value = e.target.value.slice(
                            0,
                            e.target.maxLength
                          );
                      }}
                    />
                    <span className="text-danger">
                      {kmDistanceError.dist_to}
                    </span>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseRate}>
              Cancel
            </Button>
            <Button
              variant="primary"
              className="all-button"
              onClick={handleKmSubmit}
              type="submit"
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      {/* </Container> */}
    </>
  );
};

export default Ratemaster;
