import React, { useState } from "react";
import '../../Style/Ogm.css';
import Invert from "./Invert";
import Outvert from "./Outvert";
import Create from "./Create";

const Ogm = () => {

    const [isInvert, setIsInvert] = useState('create');

    const changeState = (val) => {
        setIsInvert(val)
    }

    return (
        <div className="ogm-container">
            <div className="ogm-display-button-continer">
                <button onClick={() => changeState('create')} className="ogm-display-button">Create OGM</button>
                {/* <button onClick={() => changeState('invert')} className="ogm-display-button">Invert OGM</button> */}
                <button onClick={() => changeState('outvert')} className="ogm-display-button">Outward OGM</button>
                <button onClick={() => changeState('invert')} className="ogm-display-button">Invert OGM</button>
            </div>
            <div className="screen-container">
                {
                    isInvert == 'invert' ? <Invert />
                        : isInvert == 'outvert' ? <Outvert /> : isInvert == 'create' ? <Create /> : ''
                }
            </div>
        </div>
    )
}

export default Ogm