import React from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap'

function CustomDeleteModal(props) {
    return (
        <div>
            {
                props?.deleteModal?.show && <div>
                    <Modal
                        show={props?.deleteModal?.show}
                        onHide={props.hendleDeleteModal}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title className="text-danger" >Are you confirm to delete “ {props?.deleteModal?.deletedmodule} ”</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row my-4" >
                                <h5 className="d-flex justify-content-center">{props?.deleteModal?.deletedname}</h5>
                            </div>
                        </Modal.Body>
                        <Modal.Footer className="m-auto">
                            <Button
                                variant="danger"
                                className=" rounded"
                                onClick={() => { props?.hendleDeletSubmitApi(props?.deleteModal?.data) }}
                            >
                                Delete{" "}
                                {props?.deleteModal?.loader ? (
                                    <Spinner
                                        animation="border"
                                        variant="Primary"
                                        size="sm"
                                    />
                                ) : (
                                    ""
                                )}
                            </Button>
                            <Button
                                variant="success"
                                className=" rounded"
                                onClick={props?.hendleDeleteModal}
                            >
                                Cancel
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            }
        </div>
    )
}

export default CustomDeleteModal