import axios from "axios";
import Url from "./Url";

const postdata = async (Endpoint, data) => {
  return axios
    .post(Url + Endpoint, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

const getdata = (Endpoint) => {
  return axios
    .get(Url + Endpoint)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

const postCall = (url, body, config) => {
  return new Promise((resolve, reject) => {
    axios
      .post(Url + url, body, config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error.response) resolve(error.response.data);
      });
  });
};

const getCall = (url) => {
  return new Promise((resolve, reject) => {
    axios
      .get(Url + url)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error.response) resolve(error.response.data);
      });
  });
};

const getRateMasterData = (params) => {
  return postCall("/admin/rate_list", params);
};

// const addDistancePrice = (params) => {
//   return postCall("/admin/add_distance", params);
// };

const addDistancePrice = (params) => {
  return postCall("/admin/add_weight", params);
};

const getStateList = () => {
  return getdata("/User/get_state");
};

const GetDistList = (params) => {
  return postCall("/User/get_city", params);
};

const GetFranchiseData = (params) => {
  return postCall("/booking/get_frenchise", params);
};

const getAllorders = (params) => {
  return postCall("/booking/all_booking", params);
};

const createOgm = (params) => {
  return postCall("/booking/assign_ogm", params);
};

const getFranchiseList = (params) => {
  return postCall("/booking/get_frenchise", params);
};

const getOgmList = (params) => {
  return postCall("/booking/get_ogm", params);
};

const outWorldApi = (params) => {
  return postCall("/booking/outword", params);
};

const getInWordList = (params) => {
  return postCall("/booking/inwordList", params);
};

const verifyNode = (params) => {
  return postCall("/booking/OGMCNodeVerified", params);
};

const changeTheFranchiseActiveStatus = (params) => {
  return postCall("/User/approve_franchise", params);
};

const login = (params) => {
  return postCall("/User/frenchise_login", params);
};

const inOutList = (params) => {
  return postCall("/booking/inout_list", params);
};

const submitInvert = (params) => {
  return postCall("/booking/inwordSubmit", params);
};

const getTrackData = (params) => {
  return postCall("/booking/tracking_by_id", params);
};

const serchPin = (params) => {
  return postCall("/User/search_pin", params);
};

const Walletamount = (params) => {
  return postCall("/booking/walletamount", params);
};

export default {
  postdata,
  getdata,
  getRateMasterData,
  addDistancePrice,
  getStateList,
  GetDistList,
  GetFranchiseData,
  getAllorders,
  createOgm,
  getFranchiseList,
  getOgmList,
  outWorldApi,
  getInWordList,
  verifyNode,
  changeTheFranchiseActiveStatus,
  login,
  inOutList,
  submitInvert,
  getTrackData,
  serchPin,
};
