import React from "react";

const All = () => {
  return (
    <>
      <div className="d-flex justify-content-between heading">
        <p>Tracking.No</p>
        <p>Status</p>
      </div>
      <div className="d-flex justify-content-between heading">
        <h6>#123456789</h6>
        <div className="pills">Active</div>
      </div>
      <div className="heading">
        <ul className="tracker">
          <li>
            <div className="all-item">
              <span>1</span>
              <div className="content d-flex justify-content-between">
                <h6>Bangalore,Karnataka</h6>
                <p>March 02,12:20</p>
              </div>
            </div>
          </li>
          <li>
            <div className="all-item">
              <span>1</span>
              <div className="content d-flex justify-content-between">
                <h6>Bangalore,Karnataka</h6>
                <p>March 02,12:20</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};

export default All;
