import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { FileText } from "react-bootstrap-icons";
import Apiservice from "../../Services/Apiservice";
import { AgGridReact } from "ag-grid-react";
import Select from "react-select";
import { toast } from "react-toastify";
import CustomModal from "../../common/CustomModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import CustomDeleteModal from "../../common/CustomDeleteModal";

const Complainmaster = () => {
  const [pageReload, setPageReload] = useState(1);
  const [formFields, setFormFields] = useState({
    firstName: "",
    lastName: "",
    frenchiseFrom: "",
    FrenchiseTo: "",
    cnoteno: [""],
    trackingNumber: "",
    complaintMessage: "",
  });
  const [FormFieldErrors, setFormFieldErrors] = useState("");
  const [show, setShow] = useState(false);
  const [rowData, setRowData] = useState();
  const [submitDateLoader, setSubmitDateLoader] = useState(false);
  const [submitComplaintLoader, setSubmitComplaintLoader] = useState(false);

  /** Date States**/
  const [dateOne, setDateFilter] = useState("");
  const [cNoteFilterFromTo, setCNoteFilterFromTo] = useState({
    from: "",
    to: "",
  });
  const [dateFieldErrorFrom, setDateFieldErrorFrom] = useState("");
  const [dateFieldErrorTo, setDateFieldErrorTo] = useState("");

  /* Toast Modal */
  const [showPopupOpen, setShowPopupOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState(null);
  const handleClosePopup = () => {
    setShowPopupOpen(false);
  };
  const handleOkClick = () => {
    setShowPopupOpen(false);
  };

  const [branchData, setBranchData] = useState([]);
  const [selectedBranchName, setSelectedBranchName] = useState([]);
  const [branchNameError, setBranchNameError] = useState("");

  // delete state
  const [deleteModal, setDeleteModal] = useState({
    loader: false,
    show: false,
    type: "",
    data: {},
  })


  useEffect(() => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    const todayDate = yyyy + "-" + mm + "-" + dd;

    setCNoteFilterFromTo({
      ...cNoteFilterFromTo,
      from: todayDate,
      to: todayDate,
    });

    // var d = new Date();
    // d.getHours(); // => 9
    // d.getMinutes(); // =>  30
    // d.getSeconds();

    // var hh = d.getHours();
    // var mm = d.getMinutes();

    // const todayTime = hh + ":" + mm;
    // setTime(todayTime);
  }, []);

  useEffect(async () => {
    let AreaNameData = await Apiservice.postdata(`/User/listCity`, {
      city_name: "",
      isNull: false,
    });
    if (AreaNameData.error == false) {
      const areaData = [];
      const areaMapData = AreaNameData.data.map((ele, ind) => {
        areaData.push({
          label: ele.city_name,
          value: ele.city_name,
          id: ele,
        });
      });
      setBranchData(areaData);
    } else {
      console.log("wrong");
    }
  }, []);

  useEffect(() => {
    if (selectedBranchName.length != 0) {
      setBranchNameError("");
    }
  }, [selectedBranchName]);

  const handleGetComplaintList = async () => {
    const one = localStorage.getItem("userDetails");
    const payload = {
      frenchise_id: JSON.parse(one).id,
    };
    const alllist = await Apiservice.postdata("/admin/complaintlist", payload);
    if (alllist.error == false) {
      setRowData(alllist.data);
    } else {
      console.log("get API is not working");
    }
  }

  useEffect(() => {
    handleGetComplaintList()
  }, [pageReload]);

  /** Date Filter **/
  const onchangedateFiter = (event) => {
    setCNoteFilterFromTo({
      ...cNoteFilterFromTo,
      [event.target.name]: event.target.value,
    });
  };

  const handelFromToSubmit = async (e) => {
    e.preventDefault();
    if (cNoteFilterFromTo.from != "" && cNoteFilterFromTo.to != "") {
      const one = await localStorage.getItem("userDetails");
      const payload = {
        frenchise_id: JSON.parse(one).id,
        dateFrom:
          cNoteFilterFromTo.from == "" ? dateOne : cNoteFilterFromTo.from,
        dateTo: cNoteFilterFromTo.to == "" ? dateOne : cNoteFilterFromTo.to,
      };
      setSubmitDateLoader(true);
      const cnoteData = await Apiservice.postdata(
        "/booking/all_booking",
        payload
      );
      if (cnoteData.error == false && cnoteData.data.length != 0) {
        setSubmitDateLoader(false);
        setRowData(cnoteData?.data);
        toast.success(cnoteData.message);
      } else {
        setSubmitDateLoader(false);
        toast.error("Based on date there is no data");
        setRowData(null);
      }
    } else {
      if (cNoteFilterFromTo.from == "") {
        setDateFieldErrorFrom("Please select from date");
      }

      if (cNoteFilterFromTo.to == "") {
        setDateFieldErrorTo("Please select to date");
      }
    }
  };

  const handelonchange = (event) => {
    const p = { ...formFields };
    p[event.target.name] = event.target.value;
    setFormFields(p);

    setFormFieldErrors({
      ...FormFieldErrors,
      [event.target.name]: null,
    });
  };

  const ValidatingFormFields = () => {
    const { firstName, lastName, trackingNumber, complaintMessage, cnoteno } =
      formFields;
    const firstNameReg = /[a-zA-Z]/;
    const lastNameReg = /[a-zA-Z]/;
    const cnoteReg = /[0-9]/;
    const newErrors = {};
    if (firstName == "") {
      newErrors.firstName = "Please enter first name";
    } else if (!firstNameReg.test(firstName) && firstName) {
      newErrors.firstName = "Please enter charecter";
    } else if (firstName?.length >= 50 && firstName) {
      newErrors.firstName = "Please enter below 50 charecters";
    }

    if (lastName == "") {
      newErrors.lastName = "Please enter last name";
    } else if (!lastNameReg.test(lastName) && lastName) {
      newErrors.lastName = "Please enter charecter";
    } else if (lastName?.length >= 50 && lastName) {
      newErrors.lastName = "Please enter below 50 charecters";
    }
    // if (trackingNumber == "") {
    //   newErrors.trackingNumber = "Please Enter Tracking Number";
    // } else if (trackingNumber.length > 10) {
    //   newErrors.trackingNumber = "Please enter maximum 10 digits";
    // }
    if (complaintMessage == "") {
      newErrors.complaintMessage = "Please enter complaint message";
    }
    if (!cnoteno || cnoteno == "") {
      newErrors.cnoteno = "Please enter conote number";
    } else if (cnoteno && !cnoteReg.test(cnoteno)) {
      newErrors.cnoteno = "Please enter nuber type";
    } else if (cnoteno.length > 8) {
      newErrors.cnoteno = "Please enter maximum 8 digits";
    }
    return newErrors;
  };

  const handelSubmit = async () => {
    if (selectedBranchName.length == 0) {
      setBranchNameError("Please select To franchise");
    }
    const validationObject = ValidatingFormFields();
    if (Object.keys(validationObject).length > 0) {
      setFormFieldErrors(validationObject);
    } else {
      var one = [];
      if (formFields.cnoteno.includes("\n")) {
        one = formFields.cnoteno.split("\n");
      } else {
        one = formFields.cnoteno.split(" ");
      }
      const conte = [];
      const data = one.map((ele) => {
        if (ele.includes("\n")) {
          var one1 = ele.split("\n");
          console.log(one1);
        } else {
          var one2 = ele.split(" ");
        }
        if (ele !== "") {
          conte.push(+ele);
        }
      });

      if (selectedBranchName.length != 0) {
        const userDataOne = localStorage.getItem("userDetails");
        // tracking_number: formFields.trackingNumber,
        setSubmitComplaintLoader(true);
        const payload = {
          firstName: formFields.firstName,
          lastName: formFields.lastName,
          FrenchiseFrom: JSON.parse(userDataOne).id,
          FrenchiseTo:
            selectedBranchName[selectedBranchName?.length - 1]?.id
              ?.Frenchises[0]?.id,
          complaint_message: formFields.complaintMessage,
          c_note: conte,
        };

        const alllist = await Apiservice.postdata(
          "/admin/createcomplaint",
          payload
        );
        if (alllist.error == false) {
          setSubmitComplaintLoader(false);
          handleClose();
          setPageReload(pageReload + 1);
          setFormFields({
            ...formFields,
            firstName: "",
            lastName: "",
            complaintMessage: "",
            cnoteno: "",
          });
          setSelectedBranchName([]);
          setShowPopupOpen(true);
          setModalMessage(alllist.message);
          setShow(false);
        } else {
          setSubmitComplaintLoader(false);
          setFormFields({
            ...formFields,
            firstName: "",
            lastName: "",
            complaintMessage: "",
            cnoteno: "",
          });
          setSelectedBranchName([]);
          setShowPopupOpen(true);
          setModalMessage(alllist.message);
          setShow(false);
        }
      } else {
      }
    }
  };

  const handleClose = () => {
    setShow(false);
    setFormFieldErrors({
      ...FormFieldErrors,
      firstName: "",
      lastName: "",
      cnoteno: "",
      complaintMessage: "",
    });
    setBranchNameError("");
  };
  const handleShow = () => {
    setShow(true);
  };

  const rowHeight = 50;
  const DefaultColumnSetting = {
    sortable: true,
    filter: true,

    floatingFilter: true,
    flex: 1,
    resizable: true,
    minWidth: 120,
  };

  const [colDefs, setColDefs] = useState([
    {
      headerName: "#",
      valueGetter: "node.rowIndex + 1",
      filter: true,
      lockPosition: true,
    },
    {
      headerName: "First Name",
      filter: true,
      field: "firstName",
    },
    {
      headerName: "Last Name",
      filter: true,
      field: "lastName",
    },
    {
      headerName: "Message",
      filter: true,
      field: "complaint_message",
    },
    {
      headerName: "C Note",
      filter: true,
      field: "c_note",
    },
    {
      headerName: "Delete",
      filter: true,
      cellRendererFramework: (params) => (
        <center onClick={() => setDeleteModal({ ...deleteModal, show: true, data: params.data, type: "DELETE", deletedmodule: 'Complaint', deletedname: params?.data?.complaint_message })} >
          <FontAwesomeIcon icon={faTrash} className="iconstyle" />
        </center>
      )
    },
  ]);

  useEffect(() => {
    if (cNoteFilterFromTo.from != "") {
      setDateFieldErrorFrom("");
    }
    if (cNoteFilterFromTo.to != "") {
      setDateFieldErrorTo("");
    }
  }, [cNoteFilterFromTo]);

  const handleDateClear = () => {
    const todayDate = "" + "-" + "" + "-" + "";
    setDateFilter(todayDate);
    setCNoteFilterFromTo({
      ...cNoteFilterFromTo,
      from: "",
      to: "",
    });
  };


  /******* Delete API Integration ******/
  const hendleDeletSubmitApi = async (data) => {
    console.log(data, 'data');
    setDeleteModal({ ...deleteModal, loader: true })
    const payload = {
      id: data?.id,
    };
    let price = await Apiservice.postdata("/admin/delete_complaintlist", payload);
    if (price.error == false) {
      setDeleteModal({})
      toast.success(price.message)
      handleGetComplaintList()
    } else {
      setDeleteModal({})
      toast.error(price.message)
      handleGetComplaintList()
    }
  };
  const hendleDeleteModal = (data) => {
    setDeleteModal({})
  }


  return (
    <>
      {/* <div className="more-link">
        <h6>Complaint Master</h6>
      </div> */}
      <div>
        <CustomModal
          showPopupOpen={showPopupOpen}
          handleOkClick={handleOkClick}
          handleClosePopup={handleClosePopup}
          modalMessage={modalMessage}
        />

        {/* delete modal */}
        <CustomDeleteModal
          hendleDeleteModal={hendleDeleteModal}
          deleteModal={deleteModal}
          hendleDeletSubmitApi={hendleDeletSubmitApi}
        ></CustomDeleteModal>

        <div className="my-3">
          <div className="card w-100 my-3 p-3">
            <div class="card-body pt-0">
              <div className="row ">
                <div className="col-md-2">
                  <h6 class="card-title   bg-light w-auto py-2 rounded ">
                    <FileText /> <b>Complain Master</b>
                  </h6>
                </div>
                <div className="col-md-6">
                  <button
                    type="button  btn-md rounded"
                    class="btn btn-warning rounded"
                    onClick={handleShow}
                  >
                    Raise Complaint
                  </button>
                </div>
              </div>
            </div>

            <div className="col">
              <form onSubmit={handelFromToSubmit}>
                <div className="row">
                  <div className="col-md-4"></div>
                  <div className="col-md-8">
                    <div className="row">
                      <div className="col-md-4">
                        {" "}
                        <Form.Label>From Date</Form.Label>
                        <Form.Control
                          type="date"
                          value={
                            cNoteFilterFromTo.from == ""
                              ? dateOne
                              : cNoteFilterFromTo.from
                          }
                          onChange={onchangedateFiter}
                          name="from"
                          format="DD/MM/YYYY"
                        />
                        <span className="text-danger">
                          {dateFieldErrorFrom}
                        </span>
                      </div>

                      <div className="col-md-4">
                        <Form.Label>To Date</Form.Label>
                        <Form.Control
                          type="date"
                          value={
                            cNoteFilterFromTo.to == ""
                              ? dateOne
                              : cNoteFilterFromTo.to
                          }
                          onChange={onchangedateFiter}
                          name="to"
                          format="DD/MM/YYYY"
                        />
                        <span className="text-danger">{dateFieldErrorTo}</span>
                      </div>
                      <div className="col-md-4 mt-3">
                        <button
                          className="btn btn-danger rounded mx-3  text-white mt-3"
                          type="reset"
                          onClick={handleDateClear}
                        >
                          Clear
                        </button>
                        <button
                          className="btn btn-success rounded mx-3  text-white mt-3"
                          // onClick={handelFromToSubmit}
                          type="submit"
                        >
                          Submit{" "}
                          {submitDateLoader ? (
                            <Spinner
                              animation="border"
                              variant="Primary"
                              size="sm"
                            />
                          ) : (
                            ""
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div
              className="ag-theme-alpine"
              style={{ height: "70vh", width: "100%", marginTop: "30px" }}
            >
              <AgGridReact
                rowHeight={rowHeight}
                // columnDefs={columns}
                columnDefs={colDefs}
                defaultColDef={DefaultColumnSetting}
                pagination={true}
                paginationPageSize={10}
                // onGridReady={onGridReady}
                rowData={rowData}
              />{" "}
            </div>
          </div>

          <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Raise A Complaint
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label>First name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="First name"
                      name="firstName"
                      onChange={handelonchange}
                      autoComplete="off"
                    />
                    <span className="text-danger">
                      {FormFieldErrors.firstName}
                    </span>
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Form.Label>Last name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Last name"
                      name="lastName"
                      onChange={handelonchange}
                      autoComplete="off"
                    />
                    <span className="text-danger">
                      {FormFieldErrors.lastName}
                    </span>
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <div className="col-md-6">
                    <label for="formFile" class="form-label">
                      Franchise To
                    </label>
                    <Select
                      isMulti
                      options={branchData}
                      onChange={setSelectedBranchName}
                      value={selectedBranchName[selectedBranchName.length - 1]}
                    />
                    <span className="text-danger">{branchNameError}</span>
                  </div>

                  <Form.Group as={Col}>
                    <Form.Label>C-Note Number</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="C-Note Number"
                      name="cnoteno"
                      // onChange={(e) => handeCnoteNumber(e.target.value)}
                      onChange={handelonchange}
                    />
                    <span className="text-danger">
                      {FormFieldErrors.cnoteno}
                    </span>
                  </Form.Group>
                </Row>

                <Row className="mb-3">
                  {/* {  <Col className="w-50">
                    <Form.Group className="w-50">
                      <Form.Label>Tracking Number</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Tracking Number"
                        name="trackingNumber"
                        onChange={handelonchange}
                      />
                      <span className="text-danger">
                        {FormFieldErrors.trackingNumber}
                      </span>
                    </Form.Group>{" "}
                  </Col>} */}
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label>Complaint Message</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Complaint Message"
                      name="complaintMessage"
                      onChange={handelonchange}
                    />
                    <span className="text-danger">
                      {FormFieldErrors.complaintMessage}
                    </span>
                  </Form.Group>
                </Row>
              </Form>{" "}
            </Modal.Body>
            <Modal.Footer className="m-auto">
              <div>
                <button
                  type="button"
                  class="btn btn-warning rounded text-center"
                  onClick={handelSubmit}
                >
                  Submit{" "}
                  {submitComplaintLoader ? (
                    <Spinner animation="border" variant="Primary" size="sm" />
                  ) : (
                    ""
                  )}
                </button>
              </div>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default Complainmaster;
