import { faSearch, faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState, useRef } from "react";
import { FileText } from "react-bootstrap-icons";
import {
  Button,
  Card,
  Col,
  Form,
  Modal,
  Row,
  Spinner,
  Table,
  Dropdown,
} from "react-bootstrap";
// import { Button, Form, Modal, Table } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { toast, ToastContainer, Zoom } from "react-toastify";
import Apiservice from "../../Services/Apiservice";
import Complainmaster from "../Complain/ComplainMaster";
import CNoteNumber from "../Transaction/CNoteNumber";
import Areamaster from "./Areamaster";
import Citymaster from "./Citymaster";
import Clientagent from "./ClientAgent";
import Cnote from "./Cnote";
import FrenchiseRateMaster from "./FrenchiseRateMaster";
import MasterDeliveryPartner from "./MasterDeliveryPartner";
import ModuleMaster from "./ModuleMaster";
import Pincodemaster from "./PinCodemaster";
import Ratemaster from "./Ratemaster";
import RoleMaster from "./RoleMaster";
import Usermaster from "./Usermaster";
import CustomModal from "../../common/CustomModal";
import NewDeliveryBoy from "./NewDeliveryBoy";
import NewFranchise from "./NewFranchise";
import { emailValidity } from "../../utils/helperFunctions";

const Master = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const childFunc = useRef(null);
  const [state, setstate] = useState([]);
  const [city, setCity] = useState([]);
  const [tempstate, settempstate] = useState();
  const [franchise, setfranchise] = useState([]);
  const [userData, setUserData] = useState("");
  const [emailUser, setEmailUser] = useState([]);
  const [disableCreateBanchList, setDisableCreateBanchList] = useState(true);
  const [createBranchData, setCreateBranchData] = useState([]);
  const [update, setUpdate] = useState(1);

  const [rateMasterUpdate, setRateMasterUpdate] = useState(1);
  const [updateAgentMaster, setUpdateAgentMaster] = useState(1);
  const [cityUpdate, setCityUpdate] = useState(1);
  const [cnoteUpdate, setCnoteUpdate] = useState(1);
  const [createCnoteReload, setCreateCnoteReload] = useState(1);
  const [pincodeLoader, setPincodeLoader] = useState(false)

  /* Module Master */
  const [rollForm, setRollForm] = useState({
    rollName: "",
  });
  const [rollNameError, setRollNameError] = useState({
    rollName: "",
  });

  const [date, setDate] = useState(new Date());

  const handleCreateBranchNameChange = async (cityname) => {
    document.getElementById("createBranchName").value == ""
      ? setBranchError("Enter Branch Name ")
      : setBranchError("");
    setDisableCreateBanchList(true);
    let AreaNameData = await Apiservice.postdata(`/User/list_all_city`, {
      city_name: cityname,
      isNull: false,
      // Hub_id:userData.id,
      Hub_id: userData.Hub_id,
      ApprovedStatus: true,
    });
    console.log(AreaNameData, "AreaNameData");
    if (AreaNameData.error == false) {
      setCreateBranchData(AreaNameData);
    } else {
      console.log("wrong");
    }
  };

  const handleClickCreateBranchName = (city) => {
    document.getElementById("createBranchName").value = city.city_name;
    setDisableCreateBanchList(false);
    setCity(city);
    console.log(city.Frenchises[0].id, "city");
  };

  const [branchError, setBranchError] = useState("");

  useEffect(async () => {
    // setUserData(JSON.parse(localStorage.getItem("userDetails")));
    const localstorege = localStorage.getItem("userDetails");
    const localstoregeParseData = JSON.parse(localstorege);
    const mainData = await localstoregeParseData;
    setEmailUser(localstoregeParseData);

    const one = [mainData.role_masters].map((ele, ind) => {
      setUserData(ele[0].role_name);
    });
  }, []);
  /* Model1 */
  const [showRate, setShowRate] = useState(false);
  const handleCloseRate = () => {
    setShowRate(false);
    setNewRateData({
      ...newRateData,
      dist_from: "",
      dist_to: "",
    });

    setNewRateDataError({
      ...newRateDataError,
      dist_from: "",
      dist_to: "",
    });
  };
  const handleShowRate = () => setShowRate(true);

  /* model2 */
  const [showUser, setShowUser] = useState(false);
  const handleCloseUser = () => setShowUser(false);
  const handleShowUser = () => setShowUser(true);

  /* model3 */
  const [showPincode, setshowPincode] = useState(false);
  const handleClosePincode = () => setshowPincode(false);
  const handleShowPincode = () => setshowPincode(true);

  /* model4 */
  const [showArea, setshowArea] = useState(false);
  const handleCloseArea = () => setshowArea(false);
  const handleShowArea = () => setshowArea(true);

  /* model5 */
  const [showDelivery, setshowDelivery] = useState(false);
  const handleCloseDelivery = () => setshowDelivery(false);
  const handleShowDelivery = () => setshowDelivery(true);

  /* model6 */
  const [showClientagent, setshowClientagent] = useState(false);
  const handleCloseClientagent = () => {
    setshowClientagent(false);
    setAgetntFormError({
      ...agentFormError,
      businessName: "",
      contactPerson: "",
      email: "",
      franchiseRadio: "",
      mobileNumber: "",
      gst: "",
      pan: "",
      state: "",
      district: "",
      password: "",
      description: "",
      address: "",
      city: "",
      pincode: "",
    });
    setAgetntForm({
      ...agentForm,
      businessName: "",
      contactPerson: "",
      email: "",
      franchiseRadio: "",
      mobileNumber: "",
      gst: "",
      pan: "",
      state: "",
      district: "",
      password: "",
      description: "",
      address: "",
      city: "",
      pincode: "",
    });
    setLocalSelectedBranchError("");
    setSuperFranchiseDropdownTrue({
      ...superfranchiseDropdownTrue,
      franchiseTo: "",
    });
    setSuperFranchiseDropdownFalse({
      ...superfranchiseDropdownFalse,
      franchiseTo: "",
    });
    setSuperSelectedBranchError("");
    setBrnachDropdownError("");
  };
  const handleShowClientagent = () => setshowClientagent(true);

  /* model7 */
  const [showCnote, setshowCnote] = useState(false);
  const handleCloseCnote = () => {
    setPincodeDataError("");
    setloader(false);
    setshowCnote(false);
    setcnotedataError({
      ...cnotedata,
      frenchise_id: "",
      quantity: "",
      cash_mode: "",
      amount: "",
      receive_date: "",
      receive_amount: "",
      remarks: "",
      start_no: "",
      end_no: "",
      quantity: "",
    });
    setSelectedBranchName([]);
    setUpdateBranch(updateBranch + 1);
    setCnoteBranchErrors("");
    setCreateCnoteReload(createCnoteReload + 1);
  };
  const handleShowCnote = () => setshowCnote(true);

  /* model8 */
  const [showCitymaster, setshowCitymaster] = useState(false);
  const handleShowCitymaster = () => setshowCitymaster(true);
  const handleCloseCitymaster = () => {
    setshowCitymaster(false);
    setloader(false);
  };

  /* model 12 */
  // const [showRollModel, setRollModell] = useState(false);
  // const handleCloseRollModel = () => setRollModell(false);
  // const handleShowRollModel = () => setRollModell(true);

  /* model 13 */
  const [showModuleModal, setModuleModal] = useState(false);
  const handleCloseModuleModal = () => setModuleModal(false);
  const handleShowModuleModal = () => setModuleModal(true);

  const [superBranchList, setSuperBranchList] = useState([]);
  const [superSelectedBranch, setSuperSelectedBranch] = useState([]);
  const [superSelectedBranchError, setSuperSelectedBranchError] = useState("");

  const [localBranchList, setLocalBranchList] = useState([]);
  const [locaSelectedBranch, setLocalSelectedBranch] = useState([]);
  const [locaSelectedBranchError, setLocalSelectedBranchError] = useState("");

  const [localCityOptions, setLocalCityOptions] = useState([]);
  const [selectedLocalCityOptions, setSelectedLocalCityOptions] = useState([]);
  const [selectedLocalCityOptionsError, setSelectedLocalCityOptionsError] = useState("");

  const [userDetails, setUserDetailse] = useState({});
  const [currentFrenchiseId, setCurrentFrenchiseId] = useState({});

  const [localCityId, setLocalCityId] = useState("");

  /* module dropdown */
  const [moduleData, setModuleData] = useState([]);
  const [branchIdList, setBranchIdList] = useState("");
  const [dataOption, setDataOptions] = useState([]);

  const [defaultRateMaster, setDefaultRateMaster] = useState(true);

  const [localCityOptionsTrue, setLocalCityOptionsTrue] = useState([]);
  const [branchNameDataLocalTrue, setBranchNameDataLocalTrue] = useState([]);
  const [cityIdSuperFalse, setCityIdSuperFalse] = useState([]);

  const [cNotetShowPopup, setCNotetShowPopup] = useState(false);
  const [cNoteResponce, setCnoteResponce] = useState("");
  const [pageReload, setPageReload] = useState(1);

  /* Toast Modal */
  const [showPopupOpen, setShowPopupOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState(null);
  const handleClosePopup = () => {
    setShowPopupOpen(false);
  };
  const handleOkClick = () => {
    setShowPopupOpen(false);
  };

  /* Module Master **/
  const handleChange = (e) => {
    setRollForm({
      ...rollForm,
      ["rollName"]: e.target.value,
    });
    setRollNameError({
      ...rollNameError,
      ["rollName"]: null,
    });
  };

  useEffect(() => {
    if (superSelectedBranch.length != 0) {
      setSuperSelectedBranchError("");
    }
  }, [superSelectedBranch]);

  useEffect(() => {
    if (locaSelectedBranch.length != 0) {
      setLocalSelectedBranchError("");
    }
  }, [locaSelectedBranch]);

  useEffect(() => {
    if (selectedLocalCityOptions.length != 0) {
      setSelectedLocalCityOptionsError("");
    }
  }, [selectedLocalCityOptions]);

  useEffect(() => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    const todayDate = yyyy + "-" + mm + "-" + dd;

    // setDate(todayDate);

    setcnotedata({
      ...cnotedata,
      receive_date: todayDate,
    });

    // var d = new Date();
    // d.getHours(); // => 9
    // d.getMinutes(); // =>  30
    // d.getSeconds();

    // var hh = d.getHours();
    // var mm = d.getMinutes();

    // const todayTime = hh + ":" + mm;
    // setTime(todayTime);
  }, [createCnoteReload]);

  useEffect(() => {
    const userData = localStorage.getItem("userDetails");
    const userParseData = JSON.parse(userData);
    setUserDetailse(userParseData);
  }, []);

  const [rowData, setRowData] = useState(null);
  useEffect(async () => {
    const apiData = await Apiservice.getFranchiseList();
    if (apiData.error == false) {
      setRowData(apiData.data);
    } else {
      console.log("fale");
    }
  }, []);

  useEffect(() => {
    Apiservice.postdata("/booking/get_frenchise")
      .then((response) => {
        if (!response.error) {
          setstate(response.data);
        }
      })
      .catch((err) => {
        toast.warning(err);
      });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (frenchiseRadio === "SUPER") {
        try {
          let cityNameData = await Apiservice.postdata(`/User/not_assigned_lists`);
          if (cityNameData.error === false) {
            const areaData = cityNameData.data.map((ele) => ({
              label: ele.city_name,
              value: ele.city_name,
              id: ele.id,
            }));
            setSuperBranchList(areaData);
          } else {
            console.log("Error fetching data for SUPER");
          }
        } catch (error) {
          console.error("Error fetching data for SUPER:", error);
        }
      }
  
      if (frenchiseRadio === "LOCAL") {
        try {
          const payload = {
            city_name: "",
            isNull: false,
            Hub_type: "SUPER",
          };
          let cityNameData = await Apiservice.postdata(`/User/listCity`, payload);
          if (cityNameData.error === false) {
            const areaData = cityNameData.data.map((ele) => ({
              label: ele.city_name,
              value: ele.city_name,
              id: ele,
            }));
            setLocalBranchList(areaData);
          } else {
            console.log("Error fetching data for LOCAL");
          }
        } catch (error) {
          console.error("Error fetching data for LOCAL:", error);
        }
      }
    };
  
    fetchData();
  }, [ update]);
  

  const [newRateData, setNewRateData] = useState({
    dist_from: "",
    dist_to: "",
  });

  const [newRateDataError, setNewRateDataError] = useState({
    dist_from: "",
    dist_to: "",
  });

  const handleNewRateValues = (type, event) => {
    setNewRateData({ ...newRateData, [type]: event.target.value });
    setNewRateDataError({ ...newRateDataError, [type]: null });
  };

  const handleValidationRate = () => {
    const { dist_from, dist_to } = newRateData;
    const newError = {};
    if (!dist_from) {
      newError.dist_from = "Please enter kilometer from";
    }
    if (!dist_to) {
      newError.dist_to = "Please enter kilometer to";
    }
    return newError;
  };

  const [distanceLoader, setDistanceLoader] = useState(false);

  const addNewRatesApi = () => {
    setRateMasterUpdate(2);
    const handleValidationObject = handleValidationRate();
    if (Object.keys(handleValidationObject).length > 0) {
      setNewRateDataError(handleValidationObject);
    } else {
      setDistanceLoader(true);
      const data = { ...newRateData };
      // data["name"] = data.dist_from + "-" + data.dist_from;

      const payload = {
        dist_from: +data?.dist_from,
        dist_to: +data?.dist_to,
      };
      Apiservice.postdata("/admin/add_distance", payload)
        .then((response) => {
          setDistanceLoader(false);
          if (!response.error) {
            // toast.success("Rate Added Successfuly!");
            setShowPopupOpen(true);
            setModalMessage("Rate Added Successfuly!");
            handleCloseRate();
            childFunc.current();
            setNewRateData({
              ...newRateData,
              dist_from: "",
              dist_to: "",
            });
          } else {
            // toast.error(response.message);
            setDistanceLoader(false);
            setShowPopupOpen(true);
            setModalMessage(response.message);
            handleCloseRate();
            setNewRateData({
              ...newRateData,
              dist_from: "",
              dist_to: "",
            });
          }
        })
        .catch((err) => { });
    }
  };

  const dataoptions = [
    "All Option",
    "Rate Master",
    "TAT File",
    "PinCode Master",
    "Area Master",
    "Delivery partner",
    "Client/Agent",
    "C-note",
  ];

  const [choice, setchoice] = useState(8);
  const handlemenuchange = (e) => {
    let choice = e.target.value;
    switch (choice) {
      case "1":
        setchoice(1);
        break;
      case "2":
        setchoice(2);
        break;
      case "3":
        setchoice(3);
        break;
      case "4":
        setchoice(4);
        break;
      case "5":
        setchoice(5);
        break;
      case "6":
        setchoice(6);
        break;
      case "7":
        setchoice(7);
        break;
      case "8":
        setchoice(8);
        break;
      case "9":
        setchoice(9);
        break;
      case "10":
        setchoice(10);
        break;
      case "11":
        setchoice(11);
        break;
      case "12":
        setchoice(12);
        break;
      case "13":
        setchoice(13);
        break;
      case "14":
        setchoice(14);
        break;
      case "15":
        setchoice(15);
        break;
    }
  };

  useEffect(() => {
    if (choice && choice == 8) {
      setDefaultRateMaster(true);
    } else {
      setDefaultRateMaster(false);
    }
  }, [choice]);

  const [cnotedata, setcnotedata] = useState({
    frenchise_id: "",
    quantity: "",
    cash_mode: "",
    amount: "",
    receive_date: "",
    receive_amount: "",
    remarks: "",
    start_no: "",
    end_no: "",
    // quantity: ""
  });

  const [cnotedataError, setcnotedataError] = useState({
    frenchise_id: "",
    quantity: "",
    cash_mode: "",
    amount: "",
    receive_date: "",
    receive_amount: "",
    remarks: "",
    start_no: "",
    end_no: "",
    quantity: "",
  });

  const [branchData, setBranchData] = useState([]);
  const [branchLoader, setBranchLoader] = useState(false);
  const [selectedBranchName, setSelectedBranchName] = useState([]);
  const [pincodeDataError, setPincodeDataError] = useState([]);

  const [pincodeId, setPincodeId] = useState("");
  const [cnoteBranchErrors, setCnoteBranchErrors] = useState("");
  const [loader, setloader] = useState(false);
  const [updateBranch, setUpdateBranch] = useState(1);

  const handleChangecnote = (event) => {
    const { name, value } = event.target;
    setcnotedata({ ...cnotedata, [name]: value });
    setcnotedataError({ ...cnotedataError, [name]: null });
    if (pincodeId) {
      setCnoteBranchErrors("");
    }
  };

  /******** Branch List ***********/
  useEffect(async () => {
    setBranchLoader(true)
    const detailes = JSON.parse(localStorage.getItem("userDetails"));
    let AreaNameData = await Apiservice.postdata(`/User/list_all_city`, {
      city_name: "",
      isNull: false,
      Hub_id: detailes.Hub_id,
      ApprovedStatus: true,
    });
    if (AreaNameData.error == false) {
      const pincodeDropdown = [];
      const pincodeMaping = AreaNameData.data.map((ele, ind) => {
        pincodeDropdown.push({
          label: ele.city_name,
          value: ele.city_name,
          id: ele,
        });
      });
      setBranchData(pincodeDropdown);
      setBranchLoader(false)
    } else {
      console.log("failue");
      setBranchLoader(false)
    }
  }, [updateBranch]);

  useEffect(() => {
    if (selectedBranchName.length != 0) {
      setPincodeDataError("");
    }
  }, [selectedBranchName]);

  const handleValidationCnote = () => {
    const {
      cash_mode,
      amount,
      receive_date,
      receive_amount,
      remarks,
      start_no,
      end_no,
      quantity,
    } = cnotedata;
    const newErrors = {};

    if (!start_no) {
      newErrors.start_no = "Please enter starting c-note";
    }
    if (!end_no) {
      newErrors.end_no = "Please enter ending c-note";
    } else if (end_no && start_no && +start_no > +end_no) {
      newErrors.end_no = "End number should be more then start number";
    }

    if (!cash_mode) {
      newErrors.cash_mode = "Please select payment mode";
    }
    if (!amount) {
      newErrors.amount = "Please enter amount";
    }
    if (!receive_date) {
      newErrors.receive_date = "Please enter receive date";
    }
    if (!receive_amount) {
      newErrors.receive_amount = "Please enter receive amount";
    }
    // if (!quantity) {
    //   newErrors.quantity = 'Please enter quantity'
    // }
    if (!remarks) {
      newErrors.remarks = "Please enter remarks";
    }
    return newErrors;
  };

  const handleSubmitcnote = async () => {
    if (selectedBranchName.length == 0) {
      setPincodeDataError("Please select option");
    }
    if (!pincodeId) {
      setCnoteBranchErrors("Please select branch name");
    }
    const handleValidationCnoteObject = handleValidationCnote();
    if (Object.keys(handleValidationCnoteObject).length > 0) {
      setcnotedataError(handleValidationCnoteObject);
    } else {
      setloader(true);
      const payload = {
        // frenchise_id: pincodeId?.id?.Frenchises[0].id,
        // frenchise_id: selectedBranchName[0].id?.Frenchises[0].id,
        frenchise_id:
          selectedBranchName[selectedBranchName?.length - 1]?.id?.Frenchises[0]
            ?.id,
        cash_mode: cnotedata.cash_mode,
        amount: cnotedata.amount,
        receive_date: cnotedata.receive_date,
        receive_amount: cnotedata.receive_amount,
        remarks: cnotedata.remarks,
        start_no: +cnotedata.start_no,
        end_no: +cnotedata.end_no,
        city_id: selectedBranchName[selectedBranchName?.length - 1]?.id?.id,
      };
      try {
        const brancAPI = await Apiservice.postdata("/booking/create_cnode", payload);
        if (brancAPI.error == false) {
          setloader(false);
          setcnotedata({
            ...cnotedata,
            frenchise_id: "",
            quantity: "",
            cash_mode: "",
            amount: "",
            receive_date: "",
            receive_amount: "",
            remarks: "",
            start_no: "",
            end_no: "",
            quantity: "",
          });
          setSelectedBranchName([]);
          handleCloseCnote();
          setCnoteUpdate(2);
          setShowPopupOpen(true);
          setModalMessage(brancAPI.message);
          setUpdateBranch(updateBranch + 1);
          setCreateCnoteReload(createCnoteReload + 1);
        } else {
          setloader(false);
          setcnotedata({
            ...cnotedata,
            frenchise_id: "",
            quantity: "",
            cash_mode: "",
            amount: "",
            receive_date: "",
            receive_amount: "",
            remarks: "",
            start_no: "",
            end_no: "",
            quantity: "",
          });
          setSelectedBranchName([]);
          handleCloseCnote();
          setCnoteUpdate(2);
          setShowPopupOpen(true);
          setModalMessage(brancAPI.message);
          setUpdateBranch(updateBranch + 1);
          setCreateCnoteReload(createCnoteReload + 1);
        }
      } catch (err) {
        setloader(false);
        setShowPopupOpen(true);
        setModalMessage("Somthing Wentwrong..");
      }
    }
  };

  const [cityName, setCityName] = useState("");
  const [cityNameError, setCityNameError] = useState("");

  const OnChangeCity = (e) => {
    setCityName(e.target.value);
    setCityNameError("");
  };

  const handleCityCreate = async (e) => {
    setloader(true);
    setCityUpdate(2);
    const frenchise_id = localStorage.getItem("userDetails");
    if (cityName) {
      let payload = {
        city_name: cityName,
        regional_id: JSON.parse(frenchise_id).id,
      };
      Apiservice.postdata("/User/create_city", payload)
        .then((response) => {
          if (!response.error) {
            setloader(false);
            // toast.success("City Created Successfully");
            setShowPopupOpen(true);
            setModalMessage("Branch Created Successfully");
            setshowCitymaster(false);
          } else {
            setloader(false);
            // toast.warning(response.message);
            setShowPopupOpen(true);
            setModalMessage("not Created");
          }
        })
        .catch((err) => {
          toast.warning("Error", err);
        });
      setCityNameError("");
      setCityName("");
    } else {
      setCityNameError("Please enter city name");
      setCityName("");
    }
  };

  /**  Create Client/Agent **/

  const [pinData, setPinData] = useState({
    State: "",
    District: "",
  });
  const [frenchiseRadio, setFrenchiseRadio] = useState("SUPER");
  const [superfranchiseDropdownTrue, setSuperFranchiseDropdownTrue] = useState({
    franchiseTo: "",
  });

  const [superfranchiseDropdownFalse, setSuperFranchiseDropdownFalse] =
    useState({
      franchiseTo: "",
    });

  const [brnachDropdown, setBrnachDropdown] = useState("");
  const [brnachDropdownError, setBrnachDropdownError] = useState("");

  const handleCityfranchise = async (cityname1) => {
    let AreaNameData = await Apiservice.postdata(`/User/listCity`, {
      city_name: cityname1,
      isNull: false,
      Hub_id: userDetails.id,
    });
    if (AreaNameData.error == false) {
    } else {
      console.log("wrong");
    }
  };

  const [showCity, setShowCity] = useState(false);

  const handleLocalFrenchiseClick = () => {
    setFrenchiseRadio("LOCAL");
    setShowCity(true);
    setUpdate(update + 1);
    setAgetntForm({
      ...agentForm,
      franchiseRadio: "LOCAL",
    });
    setAgetntFormError({
      ...agentFormError,
      franchiseRadio: "",
    });
    setSuperSelectedBranchError("");
    setBrnachDropdown("");
    setBrnachDropdownError("");
  };
  const handleSuperFrenchiseClick = () => {
    setFrenchiseRadio("SUPER");
    setShowCity(false);
    setCurrentFrenchiseId(userDetails.id);
    setUpdate(update + 1);
    setAgetntForm({
      ...agentForm,
      franchiseRadio: "SUPER",
    });
    setAgetntFormError({
      ...agentFormError,
      franchiseRadio: "",
    });
    setSuperSelectedBranchError("");
    setBrnachDropdown("");
    setBrnachDropdownError("");
  };

  const [loaderAgent, setloaderAgent] = useState(false);
  const [agentForm, setAgetntForm] = useState({
    businessName: "",
    contactPerson: "",
    email: "",
    franchiseRadio: "",
    mobileNumber: "",
    gst: "",
    pan: "",
    state: "",
    district: "",
    password: "",
    description: "",
    address: "",
    city: "",
    pincode: "",
  });
  const [agentFormError, setAgetntFormError] = useState({
    businessName: "",
    contactPerson: "",
    email: "",
    franchiseRadio: "",
    mobileNumber: "",
    gst: "",
    pan: "",
    state: "",
    district: "",
    password: "",
    description: "",
    address: "",
    city: "",
    pincode: "",
  });

  useEffect(async () => {
    setPincodeLoader(true)
    if (agentForm?.pincode.length == 6) {
      const payload = { pin: agentForm?.pincode };
      const ListPin = await Apiservice.postdata("/User/search_pin", payload);
      if (ListPin?.error == false) {
        setPincodeLoader(false)
        toast.success(ListPin.message);
        setAgetntForm({
          ...agentForm,
          state: ListPin?.data?.State,
          district: ListPin?.data?.District,
        });

        setAgetntFormError({
          ...agentFormError,
          state: "",
          district: "",
        });
      } else {
        console.log("get API is not working");
      }
    } else {
      setAgetntForm({
        ...agentForm,
        state: "",
        district: "",
      });
      setPincodeLoader(false)
    }
  }, [agentForm?.pincode]);

  const handleClientAgent = (event) => {
    if (event.target.name == "pincode" && event.target.value.length == 0) {
      setPinData("");
    }
    setAgetntForm({
      ...agentForm,
      [event.target.name]: event.target.value,
    });
    setAgetntFormError({
      ...agentFormError,
      [event.target.name]: null,
    });
  };

  const handleValidationAgent = () => {
    const newError = {};
    const {
      businessName,
      contactPerson,
      email,
      franchiseRadio,
      mobileNumber,
      gst,
      pan,
      password,
      state,
      district,
      description,
      pincode,
      address,
    } = agentForm;

    const textReg = /[A-Za-z]/;

    if (!businessName) {
      newError.businessName = "Please enter business name";
    } else if (businessName && !textReg.test(businessName)) {
      newError.businessName = "Business name should be text";
    }
    if (!contactPerson) {
      newError.contactPerson = "Please enter contact person name";
    } else if (contactPerson && !textReg.test(contactPerson)) {
      newError.contactPerson = "Business name should be text";
    }
    if (email == "") {
      newError.email = "Please enter email id";
    } else if (!emailValidity(email)) {
      newError.email = "Enter valid email";
    }
    // if (!franchiseRadio) {
    //   newError.franchiseRadio = 'Please select franchiseRadio'
    // }
    if (!mobileNumber) {
      newError.mobileNumber = "Please enter mobile number ";
    } else if (mobileNumber && mobileNumber.length != 10) {
      newError.mobileNumber = "Mobile number should be 10 digits ";
    }
    if (!gst) {
      newError.gst = "Please enter gst number";
    } else if (gst && gst.length != 15) {
      newError.gst = "GST number should be 15 digits";
    }
    if (!pan) {
      newError.pan = "Please enter pan number";
    } else if (pan && pan.length != 10) {
      newError.pan = "PAN number should be 10 digits";
    }
    if (!password) {
      newError.password = "Please enter password ";
    }

    if (!state) {
      newError.state = "Please enter state ";
    }
    if (!district) {
      newError.district = "Please enter district ";
    }
    // if (!description) {
    //   newError.description = "Please enter comments";
    // }
    if (!address) {
      newError.address = "Please enter address";
    }
    if (!pincode) {
      newError.pincode = "Please enter pincode";
    } else if (pincode && pincode.length != 6) {
      newError.pincode = "Pincode should be 6 digits";
    }

    return newError;
  };

  const handleClientAgentSubmit = async () => {
    if (
      frenchiseRadio == "LOCAL" &&
      superfranchiseDropdownTrue?.franchiseTo == ""
    ) {
      if (locaSelectedBranch.length == 0) {
        setLocalSelectedBranchError("Please select franchise to");
      }
    }

    if (frenchiseRadio == "SUPER" && superSelectedBranch.length == 0) {
      if (superSelectedBranch.length == 0) {
        setSuperSelectedBranchError("Please select franchise to");
      }
    }

    if (frenchiseRadio == "LOCAL" && brnachDropdown == "") {
      setBrnachDropdownError("Please select branch name");
    }

    if (frenchiseRadio == "LOCAL" && selectedLocalCityOptions.length == 0) {
      setSelectedLocalCityOptionsError("Please select local branch name");
    }
    const handleValidationAgentObject = handleValidationAgent();
    if (Object.keys(handleValidationAgentObject).length > 0) {
      setAgetntFormError(handleValidationAgentObject);
    } else {
      setloaderAgent(true);
      if (frenchiseRadio == "SUPER") {
        const addClientPayload = {
          Address: agentForm.address || "",
          BusinessName: agentForm.businessName || "",
          ContactPersion: agentForm.contactPerson || "",
          Description: agentForm.description || "",
          District: agentForm.district || "",
          Email: agentForm.email || "",
          GST: agentForm.gst || "",
          PAN: agentForm.pan || "",
          MobileNumber: agentForm.mobileNumber || "",
          Password: agentForm.password || "",
          Pincode: agentForm.pincode || "",
          State: agentForm.district || "",
          city_id:
            superSelectedBranch[superSelectedBranch.length - 1]?.id || "",
          // FrenchiseId: localCityId?.id?.id || '',
          Language: "English-Hindi",
          Hub_type: frenchiseRadio || "",
          Lat: "9.78779",
          Lang: "10.5678",
          Hub_id: userDetails.id,
        };

        const client = await Apiservice.postdata(
          "/User/frenchise_register",
          addClientPayload
        );
        if (client.error == false) {
          setloaderAgent(false);
          setshowClientagent(false);
          setShowPopupOpen(true);
          setModalMessage(client.message);
          setLocalCityId("");
          setAgetntForm({
            ...agentForm,
            businessName: "",
            contactPerson: "",
            email: "",
            franchiseRadio: "",
            mobileNumber: "",
            gst: "",
            pan: "",
            state: "",
            district: "",
            password: "",
            description: "",
            address: "",
            city: "",
            pincode: "",
          });
          setSuperFranchiseDropdownTrue({
            ...superfranchiseDropdownTrue,
            franchiseTo: "",
          });
          setSuperFranchiseDropdownFalse({
            ...superfranchiseDropdownFalse,
            franchiseTo: "",
          });
          setBrnachDropdown("");
        } else {
          setloaderAgent(false);
          setshowClientagent(false);
          setShowPopupOpen(true);
          setModalMessage(client.message);
          setLocalCityId("");
          setAgetntForm({
            ...agentForm,
            businessName: "",
            contactPerson: "",
            email: "",
            franchiseRadio: "",
            mobileNumber: "",
            gst: "",
            pan: "",
            state: "",
            district: "",
            password: "",
            description: "",
            address: "",
            city: "",
            pincode: "",
          });
          setSuperFranchiseDropdownTrue({
            ...superfranchiseDropdownTrue,
            franchiseTo: "",
          });
          setSuperFranchiseDropdownFalse({
            ...superfranchiseDropdownFalse,
            franchiseTo: "",
          });
          setBrnachDropdown("");
        }
      }

      if (frenchiseRadio == "LOCAL") {
        const addClientPayload = {
          Address: agentForm.address || "",
          BusinessName: agentForm.businessName || "",
          ContactPersion: agentForm.contactPerson || "",
          Description: agentForm.description || "",
          District: agentForm?.district || "",
          Email: agentForm.email || "",
          GST: agentForm.gst || "",
          PAN: agentForm.pan || "",
          MobileNumber: agentForm.mobileNumber || "",
          Password: agentForm.password || "",
          Pincode: agentForm.pincode || "",
          State: agentForm?.state || "",
          // city_id: localCityId,
          city_id: selectedLocalCityOptions[selectedLocalCityOptions.length - 1]?.id?.id || "",
          // FrenchiseId: localCityId?.id?.id || '',
          Language: "English-Hindi",
          Hub_type: frenchiseRadio || "",
          Lat: "9.78779",
          Lang: "10.5678",
          Hub_id: locaSelectedBranch[locaSelectedBranch.length - 1]?.id?.Frenchises[0]?.id || "",
        };
        const client = await Apiservice.postdata("/User/frenchise_register", addClientPayload);
        if (client.error == false) {
          setloaderAgent(false);
          setshowClientagent(false);
          setShowPopupOpen(true);
          setModalMessage(client.message);
          setLocalCityId("");
          setAgetntForm({
            ...agentForm,
            businessName: "",
            contactPerson: "",
            email: "",
            franchiseRadio: "",
            mobileNumber: "",
            gst: "",
            pan: "",
            state: "",
            district: "",
            password: "",
            description: "",
            address: "",
            city: "",
            pincode: "",
          });
          setSelectedLocalCityOptions([])
          setLocalSelectedBranch([])
          setBrnachDropdown("");
        } else {
          // toast.error(client.message);
          // setAgentResponce(client.message)
          // setAgentShowPopup(true)
          // setLocalCityId('')
          setloaderAgent(false);
          setshowClientagent(false);
          setShowPopupOpen(true);
          setModalMessage(client.message);
          setLocalCityId("");
          setAgetntForm({
            ...agentForm,
            businessName: "",
            contactPerson: "",
            email: "",
            franchiseRadio: "",
            mobileNumber: "",
            gst: "",
            pan: "",
            state: "",
            district: "",
            password: "",
            description: "",
            address: "",
            city: "",
            pincode: "",
          });
          setBrnachDropdown("");
          setSelectedLocalCityOptions([])
          setLocalSelectedBranch([])
        }
      }


    }
    setUpdateAgentMaster(2);
  };

  const handelCityOpen = () => {
    handleCloseClientagent();
    setchoice(8);
  };

  /* Module Master **/
  const handleValidation = () => {
    const textReg = /[A-Za-z]/;
    const { rollName } = rollForm;
    const newError = {};
    if (!rollName) {
      newError.rollName = "Please Enter Module Name";
    } else if (rollName && !textReg.test(rollName)) {
      newError.rollName = "numbers is not allowed";
    } else if (rollName && rollName.length > 30) {
      newError.rollName = "Roll Name Should be below 30 charecters";
    }
    return newError;
  };

  const handleSubmitModule = async () => {
    const handleValidationObject = handleValidation();
    if (Object.keys(handleValidationObject).length > 0) {
      setRollNameError(handleValidationObject);
    } else {
      const payload = {
        module_name: rollForm.rollName,
      };
      const pincode = await Apiservice.postdata(`/User/create_module`, payload);
      if (pincode.error == false) {
        // toast.success(pincode.message)
        setPageReload(pageReload + 1);
        setShowPopupOpen(true);
        setModalMessage(pincode.message);
        setRollForm({
          ...rollForm,
          rollName: "",
        });
      } else {
        // toast.error(pincode.message)
        setPageReload(pageReload + 1);
        setShowPopupOpen(true);
        setModalMessage(pincode.message);
        setRollForm({
          ...rollForm,
          rollName: "",
        });
      }
    }
  };

  /* Roll Dropdonw */

  const [rollData, setRollData] = useState([]);
  const [rollId, setRollId] = useState("");

  useEffect(async () => {
    const pincode = await Apiservice.postdata(`/User/role_master_list`);
    if (pincode.error == false) {
      const pincodeDropdown = [];
      const pincodeMaping = pincode.data.map((ele, ind) => {
        pincodeDropdown.push({
          label: ele.role_name,
          value: ele.role_name,
          id: ele.id,
        });
      });
      setRollData(pincodeDropdown);
    } else {
      console.log("failue");
    }
  }, []);

  const handleClickRollModule = (e) => {
    setRollId(e);
  };

  /* module dropdown */
  useEffect(async () => {
    const pincode = await Apiservice.postdata(`/User/module_master_list`);
    if (pincode.error == false) {
      const pincodeDropdown = [];
      const pincodeMaping = pincode.data.map((ele, ind) => {
        pincodeDropdown.push({
          label: ele.module_name,
          value: ele.module_name,
          id: ele.id,
        });
      });
      setModuleData(pincodeDropdown);
      setDataOptions(pincodeDropdown);
    } else {
      console.log("failue");
    }
  }, [pageReload]);

  const handleChangeBranchList = (e) => {
    setBranchIdList(e);
  };

  /** Module Submit **/
  const handleModuleSubmit = () => { };
  /*** City ***/
  useEffect(async () => {
    const detailes = JSON.parse(localStorage.getItem("userDetails"));
    let AreaNameData = await Apiservice.postdata(`/User/not_assigned_lists`);
    if (AreaNameData.error == false) {
      const pincodeDropdown = [];
      const pincodeMaping = AreaNameData.data.map((ele, ind) => {
        pincodeDropdown.push({
          label: ele.city_name,
          value: ele.city_name,
          id: ele,
        });
      });
      setLocalCityOptions(pincodeDropdown);
    } else {
      console.log("failue");
    }
  }, []);

  const handleClickCity = (e) => {
    setLocalCityId(e.id.id);
    setBrnachDropdown(e);
    setBrnachDropdownError("");
  };

  useEffect(() => {
    const todayDate = String(date.getDate()).padStart(2, "0");
    const todayMonth = String(date.getMonth() + 1).padStart(2, "0");
    const todayYear = date.getFullYear();
    const one = todayYear + "-" + todayMonth + "-" + todayDate;

    // date.getHours(); // => 9
    // date.getMinutes(); // =>  30
    // d.getSeconds();

    var hh = date.getHours();
    var mm = date.getMinutes();

    const todayTime = hh + ":" + mm;

    setcnotedata({
      ...cnotedata,
      ["receive_date"]: one,
      // ['receive_time']: todayTime,
    });
  }, []);

  const [one, setOne] = useState(0);
  useEffect(() => {
    if (cnotedata.end_no && cnotedata.start_no) {
      var one = cnotedata.end_no - cnotedata.start_no + 1;
      setOne(one);
    } else {
      var one = cnotedata.end_no - cnotedata.start_no;
      setOne(one);
    }
  }, [cnotedata]);

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        transition={Zoom}
        delay={1000}
        limit={1}
      />
      <div className="more-link">
        <h6>
          Master{" "}

        </h6>
      </div>
      <CustomModal
        showPopupOpen={showPopupOpen}
        handleOkClick={handleOkClick}
        handleClosePopup={handleClosePopup}
        modalMessage={modalMessage}
      />
      <Card className="shadow rounded p-2 w-100">
        <div className="master">
          <Row>
            <Col md={6}>
              <div className="leftedge d-flex justify-content-space">
                <Form.Select
                  aria-label="Default select example"
                  size={"sm"}
                  className="selectsizesmall"
                  onChange={(e) => handlemenuchange(e)}
                >
                  <option value="8">Branch Master</option>
                  <option value="15">New Franchise Request</option>
                  <option value="14">New Delivery Boy Request</option>
                  <option value="6">Client/Agent</option>
                  <option value="3">Pincode Master</option>
                  <option value="4">Area Master</option>
                  <option value="5">Delivery Boy Master</option>
                  <option value="9">Franchise Rate Master</option>
                  <option value="7">C-note Master</option>
                  <option value="1">Rate Master</option>
                  <option value="10">complaint Master</option>

                  {/* <option value="2">User Master</option> */}
                  {/* <option value="5">Delivery Partner</option> */}
                  {/* <option value="12">Role Master</option>
                  <option value="13">Module Master</option> */}
                </Form.Select>
                {choice == 2 ? (
                  <Button onClick={handleShowUser}>New User Master</Button>
                ) : (
                  ""
                )}
                {choice == 1 ? (
                  <Button onClick={handleShowRate}>New Rate Master</Button>
                ) : (
                  ""
                )}
                {/* {
                  defaultRateMaster == true ? (
                    <Button onClick={handleShowRate}>New Branch Master</Button>
                  ) : (
                    ""
                  )
                } */}
                {/* {choice == 3 ? (
                  <Button onClick={handleShowPincode}>
                    New Pincode Master
                  </Button>
                ) : (
                  ""
                )} */}
                {/* {choice == 4 ? (
                  <Button onClick={handleShowArea}>New Area Master</Button>
                ) : (
                  ""
                )} */}
                {/* {
                  choice == 5 ? (
                    <Button onClick={handleShowDelivery}>
                      New Delivery Partner
                    </Button>
                  ) : (
                    ""
                  )
                } */}
                {choice == 6 ? (
                  <Button onClick={handleShowClientagent}>
                    New Client/Agent
                  </Button>
                ) : (
                  ""
                )}
                {choice == 7 ? (
                  <Button onClick={handleShowCnote}>New C-note</Button>
                ) : (
                  ""
                )}
                {choice == 8 ? (
                  <Button onClick={handleShowCitymaster}>
                    New Branch Master
                  </Button>
                ) : (
                  ""
                )}
                {
                  // choice == 12 ? (
                  //   <Button
                  //   // onClick={handleShowCitymaster}
                  //   >
                  //     Create Roll Master
                  //   </Button>
                  // ) : (
                  //   ""
                  // )
                }
                {choice == 13 ? (
                  <Button onClick={handleShowModuleModal}>
                    Create Module Master
                  </Button>
                ) : (
                  ""
                )}
              </div>
            </Col>
          </Row>
        </div>
        <hr />
        {choice == 2 ? <Usermaster /> : ""}
        {choice == 1 ? (
          <Ratemaster
            childFunc={childFunc}
            rateMasterUpdate={rateMasterUpdate}
          />
        ) : (
          ""
        )}
        {
          // defaultRateMaster == true ? <Ratemaster childFunc={childFunc} /> : ""
        }
        {choice == 3 ? <Pincodemaster /> : ""}
        {choice == 4 ? <Areamaster /> : ""}
        {choice == 5 ? <MasterDeliveryPartner /> : ""}
        {choice == 6 ? (
          <Clientagent updateAgentMaster={updateAgentMaster} />
        ) : (
          ""
        )}
        {choice == 7 ? <Cnote cnoteUpdate={cnoteUpdate} /> : ""}
        {choice == 8 ? <Citymaster cityUpdate={cityUpdate} /> : ""}
        {choice == 9 ? <FrenchiseRateMaster /> : ""}
        {choice == 10 ? <Complainmaster /> : ""}
        {choice == 11 ? <CNoteNumber /> : ""}
        {choice == 12 ? <RoleMaster /> : ""}
        {choice == 13 ? <ModuleMaster /> : ""}
        {choice == 14 ? <NewDeliveryBoy /> : ""}
        {choice == 15 ? <NewFranchise /> : ""}

        {/* <div className="more-link">
          <h6>Load more</h6>
        </div> */}
      </Card>

      {/* User Master Modal */}
      <Modal
        show={showUser}
        onHide={handleCloseUser}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <h6 className="text-center more-link mt-4">Add New User Master</h6>
        <Modal.Body>
          <Form>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>User Name</Form.Label>
                  <Form.Select size="sm">
                    <option>10km or less</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Select size="sm">
                    <option>1kg or less</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Mobile No</Form.Label>
                  <Form.Select size="sm">
                    <option>10km or less</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Role</Form.Label>
                  <Form.Select size="sm">
                    <option>1kg or less</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Select size="sm">
                    <option>10km or less</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Select size="sm">
                    <option>1kg or less</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Remark</Form.Label>
                  <Form.Control as="textarea" rows={3} />
                </Form.Group>
              </Col>
            </Row>
          </Form>
          <Form.Label>Select Options</Form.Label>
          <Table variant="secondary">
            <tbody>
              {dataoptions.map((opt) => {
                return (
                  <>
                    <tr>
                      <td>
                        <Form.Label>{opt}</Form.Label>
                      </td>
                      <td>
                        <Form.Check
                          inline
                          label="All"
                          name="group1"
                          type="radio"
                          id={`inline-radio-1`}
                        />
                      </td>
                      <td>
                        <Form.Check
                          inline
                          label="Edit"
                          name="group1"
                          type="radio"
                          id={`inline-radio-1`}
                        />
                      </td>
                      <td>
                        <Form.Check
                          inline
                          label="Delete"
                          name="group1"
                          type="radio"
                          id={`inline-radio-1`}
                        />
                      </td>
                      <td>
                        <Form.Check
                          inline
                          label="View Only"
                          name="group1"
                          type="radio"
                          id={`inline-radio-1`}
                        />
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseUser}>
            Cancel
          </Button>
          <Button
            variant="primary"
            className="all-button"
            onClick={handleCloseUser}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Module Master Modal */}
      <Modal
        show={showModuleModal}
        onHide={handleCloseModuleModal}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <h6 className="text-center more-link mt-4">Add New Module Master</h6>
        <Modal.Body>
          <div>
            <div className="col-md-3">
              <h6 class="card-title    my-3 bg-light w-auto py-2 rounded ">
                <FileText /> <b> Create Module Master</b>
              </h6>
            </div>
            <div className="row">
              <div className="col-5">
                <Form.Group>
                  <Form.Label>Module Name</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    value={rollForm.rollName}
                  ></Form.Control>
                </Form.Group>
                <span className="text-danger">{rollNameError.rollName}</span>
              </div>
              <div className="col-2" style={{ marginTop: "30px" }}>
                <button onClick={handleSubmitModule} className="btn btn-danger">
                  Submit
                </button>
              </div>
            </div>
            <div className="row" style={{ marginTop: "20px" }}>
              <div className="col-md-4">
                <label>Roll Name</label>
                <div className="mt-2">
                  <Select
                    options={rollData}
                    onChange={handleClickRollModule}
                    value={rollId}
                  />
                </div>
              </div>
              <div className="col-md-4">
                {/* <label>Module Names</label>
                <div className="mt-2" >
                  <Select
                    options={moduleData}
                    onChange={handleChangeBranchList}
                    value={branchIdList}
                  />
                </div> */}
              </div>
            </div>
          </div>
          <div style={{ marginTop: "30px" }}>
            <Form.Label>Select Options</Form.Label>
            <Table variant="secondary">
              <tbody>
                {dataOption.map((opt) => {
                  return (
                    <>
                      <tr>
                        <td>
                          <Form.Label>{opt.value}</Form.Label>
                        </td>
                        <td>
                          <Form.Check
                            inline
                            label="All"
                            // name="group1"
                            vaue="All"
                            type="radio"
                          // id={`inline-radio-1`}
                          />
                        </td>
                        <td>
                          <Form.Check
                            inline
                            label="Edit"
                            name="group1"
                            type="radio"
                          // id={`inline-radio-1`}
                          />
                        </td>
                        <td>
                          <Form.Check
                            inline
                            label="Delete"
                            name="group1"
                            type="radio"
                          // id={`inline-radio-1`}
                          />
                        </td>
                        <td>
                          <Form.Check
                            inline
                            label="View Only"
                            name="group1"
                            type="radio"
                          // id={`inline-radio-1`}
                          />
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModuleModal}>
            Cancel
          </Button>
          <Button
            variant="primary"
            className="all-button"
            onClick={handleModuleSubmit}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Rate Master Modal */}
      <Modal
        show={showRate}
        onHide={handleCloseRate}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <h6 className="text-center more-link mt-4">Add New Distance</h6>
        <Modal.Body>
          <Form>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Km From</Form.Label>
                  <Form.Control
                    required
                    type="Number"
                    placeholder="Km From"
                    value={newRateData.dist_from}
                    onChange={(e) => handleNewRateValues("dist_from", e)}
                    maxLength={6}
                    onInput={(e) => {
                      if (e.target.value.length > e.target.maxLength)
                        e.target.value = e.target.value.slice(
                          0,
                          e.target.maxLength
                        );
                    }}
                    onWheelCapture={(e) => e.target.blur()}
                    autoComplete="off"
                  />
                  <span className="text-danger">
                    {newRateDataError.dist_from}
                  </span>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group
                  required
                  className="mb-3"
                  controlId="formBasicEmail"
                >
                  <Form.Label>Km To</Form.Label>
                  <Form.Control
                    type="Number"
                    placeholder="Km To"
                    value={newRateData.dist_to}
                    onChange={(e) => handleNewRateValues("dist_to", e)}
                    maxLength={6}
                    onInput={(e) => {
                      if (e.target.value.length > e.target.maxLength)
                        e.target.value = e.target.value.slice(
                          0,
                          e.target.maxLength
                        );
                    }}
                    onWheelCapture={(e) => e.target.blur()}
                    autoComplete="off"
                  />
                  <span className="text-danger">
                    {newRateDataError.dist_to}
                  </span>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseRate}>
            Cancel
          </Button>
          {distanceLoader == true ? (
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          ) : (
            <Button
              variant="primary"
              className="all-button"
              onClick={addNewRatesApi}
              type="submit"
            >
              Submit
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      {/* pin code master  */}
      <Modal
        show={showPincode}
        onHide={handleClosePincode}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <h6 className="text-center more-link mt-4">Add New Pincode Master</h6>
        <Modal.Body>
          <Form>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Pincode</Form.Label>
                  <Form.Select size="sm">
                    <option>Select Pincode</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Product Category</Form.Label>
                </Form.Group>

                <div className="choises action">
                  <label>
                    <input type="checkbox" value="1" />
                    <span>Document</span>
                  </label>
                </div>
                <div className="choises action">
                  <label>
                    <input type="box" value="1" />
                    <span>Food</span>
                  </label>
                </div>
                <div className="choises action">
                  <label>
                    <input type="checkbox" value="1" />
                    <span>Clothes</span>
                  </label>
                </div>
                <div className="choises action">
                  <label>
                    <input type="checkbox" value="1" />
                    <span>Groceries</span>
                  </label>
                </div>
                <div className="choises action">
                  <label>
                    <input type="checkbox" value="1" />
                    <span>Flowers</span>
                  </label>
                </div>
                <div className="choises action">
                  <label>
                    <input type="checkbox" value="1" />
                    <span>Cake</span>
                  </label>
                </div>
                <div className="choises action">
                  <label>
                    <input type="checkbox" value="1" />
                    <span>Parcels</span>
                  </label>
                </div>
                <div className="choises action">
                  <label>
                    <input type="checkbox" value="1" />
                    <span>Others</span>
                  </label>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosePincode}>
            Cancel
          </Button>
          <Button
            variant="primary"
            className="all-button"
            onClick={handleClosePincode}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      {/* area master */}
      <Modal
        show={showArea}
        onHide={handleCloseArea}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <h6 className="text-center more-link mt-4">Add New Area Master</h6>
        <Modal.Body>
          <Form>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>City</Form.Label>
                  <Form.Select size="sm">
                    <option>Select City</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Area</Form.Label>
                  <Form.Control type="text" placeholder="Type Area" size="sm" />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>PinCode</Form.Label>
                  <Form.Select size="sm">
                    <option>Select Pincode</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Verified</Form.Label>
                  <Form.Select size="sm">
                    <option>True</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Service Type</Form.Label>
                  <Form.Select size="sm">
                    <option>Select Pincode</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Product Category</Form.Label>
                  <Form.Select size="sm">
                    <option>True</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Add Remark</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Add Remark"
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseArea}>
            Cancel
          </Button>
          <Button
            variant="primary"
            className="all-button"
            onClick={handleCloseArea}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      {/* New Delivery master  */}
      <Modal
        show={showDelivery}
        onHide={handleCloseDelivery}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <h6 className="text-center more-link mt-4">Add New Delivery Partner</h6>
        <Modal.Body>
          <Form>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Partner Type</Form.Label>
                  <Form.Select size="sm">
                    <option>Partner Type</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control type="text" placeholder="Name" size="sm" />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Mobile No</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="+91-------------"
                    size="sm"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Comission</Form.Label>
                  <Form.Control type="text" placeholder="%" size="sm" />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDelivery}>
            Cancel
          </Button>
          <Button
            variant="primary"
            className="all-button"
            onClick={handleCloseDelivery}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {/* create franhise modal */}
      <Modal
        show={showClientagent}
        onHide={handleCloseClientagent}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <Modal.Header closeButton >
          <h6 className="text-center more-link mt-4">Add New Client/Agent</h6>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Business Name</Form.Label>
                  <Form.Control
                    maxLength={50}
                    type="text"
                    placeholder="Business Name"
                    size="sm"
                    name="businessName"
                    onChange={handleClientAgent}
                    autoComplete="off"
                  />
                  <span className="text-danger">{agentFormError.businessName}</span>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Contact Person Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Name"
                    size="sm"
                    name="contactPerson"
                    onChange={handleClientAgent}
                    autoComplete="off"
                    maxLength={50}
                  />
                  <span className="text-danger"> {agentFormError.contactPerson}</span>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>E-mail</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="E-mail"
                    size="sm"
                    name="email"
                    onChange={handleClientAgent}
                    autoComplete="off"
                  // value={agentForm.email}
                  />
                  <span className="text-danger">{agentFormError.email}</span>
                </Form.Group>
              </Col>
              <Col md={6}>
                <div>
                  <Form.Group className="mb-3">
                    <Form.Label>Franchise Selection Accordingly</Form.Label>
                    <Form.Check
                      inline
                      label="Super Franchise"
                      name="group1"
                      type="radio"
                      onClick={handleSuperFrenchiseClick}
                      checked={frenchiseRadio == "SUPER" && true}
                    />
                    <Form.Check
                      inline
                      label="Local Franchise"
                      name="group1"
                      type="radio"
                      checked={frenchiseRadio == "LOCAL" && true}
                      onClick={handleLocalFrenchiseClick}
                    />
                    <span className="text-danger"></span>
                  </Form.Group>
                </div>
                <span className="text-danger">
                  {agentFormError.franchiseRadio}
                </span>
              </Col>
            </Row>
            {showCity == true && (
              <div>
                <Row>
                  <Col md={6}>
                    <label for="formFile" class="form-label">
                      Select Franchise To
                    </label>
                    <Select
                      isMulti
                      options={localBranchList}
                      onChange={setLocalSelectedBranch}
                      value={
                        locaSelectedBranch[locaSelectedBranch.length - 1] ||
                        null
                      }
                    />
                    <span className="text-danger">
                      {locaSelectedBranchError}
                    </span>
                  </Col>
                  <Col md={3} className="my-2">
                    <Button className="mt-4" onClick={handelCityOpen}>
                      Create New City
                    </Button>
                  </Col>
                </Row>
              </div>
            )}
            {showCity == false && (
              <div>
                <Row>
                  <Col md={6}>
                    <label for="formFile" class="form-label">
                      Select Franchise To
                    </label>
                    <Select
                      isMulti
                      options={superBranchList}
                      onChange={setSuperSelectedBranch}
                      value={
                        superSelectedBranch[superSelectedBranch.length - 1] ||
                        null
                      }
                    />
                    <span className="text-danger">
                      {superSelectedBranchError}
                    </span>
                  </Col>
                  <Col md={3} className="my-2">
                    <Button className="mt-4" onClick={handelCityOpen}>
                      Create New City
                    </Button>
                  </Col>
                </Row>
              </div>
            )}

            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Contact Number</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Contact Number"
                    size="sm"
                    name="mobileNumber"
                    onChange={handleClientAgent}
                    autoComplete="off"
                    maxLength={10}
                    onInput={(e) => {
                      if (e.target.value.length > e.target.maxLength)
                        e.target.value = e.target.value.slice(
                          0,
                          e.target.maxLength
                        );
                    }}
                    onWheelCapture={(e) => e.target.blur()}
                  />
                  <span className="text-danger">
                    {agentFormError.mobileNumber}
                  </span>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Pin Code
                    {" "}
                    {
                      pincodeLoader
                        ?
                        <div class="spinner-border spinner-border-sm" role="status"></div>
                        :
                        ''
                    }
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Pin Code"
                    size="sm"
                    name="pincode"
                    onChange={handleClientAgent}
                    onInput={(e) => {
                      if (e.target.value.length > e.target.maxLength)
                        e.target.value = e.target.value.slice(
                          0,
                          e.target.maxLength
                        );
                    }}
                    maxLength={6}
                    onWheelCapture={(e) => e.target.blur()}
                  />
                  <span className="text-danger">{agentFormError.pincode}</span>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label> District</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="District"
                    size="sm"
                    disabled
                    name="District"
                    value={agentForm.district}
                    // value={pinData ? pinData?.District : ''}
                    onChange={handleClientAgent}
                  />
                  <span className="text-danger">{agentFormError.district}</span>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="State"
                    size="sm"
                    name="state"
                    disabled
                    value={agentForm.state}
                    // value={pinData ? pinData?.State : ''}
                    onChange={handleClientAgent}
                  />
                  <span className="text-danger">{agentFormError.state}</span>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>GST Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="GST Number"
                    size="sm"
                    name="gst"
                    value={agentForm.gst.toLocaleUpperCase()}
                    onChange={handleClientAgent}
                    autoComplete="off"
                    maxLength={15}
                    onInput={(e) => {
                      if (e.target.value.length > e.target.maxLength)
                        e.target.value = e.target.value.slice(
                          0,
                          e.target.maxLength
                        );
                    }}
                  />
                  <span className="text-danger">{agentFormError.gst}</span>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="password"
                    size="sm"
                    name="password"
                    onChange={handleClientAgent}
                    autoComplete="off"
                    maxLength={30}
                    onInput={(e) => {
                      if (e.target.value.length > e.target.maxLength)
                        e.target.value = e.target.value.slice(
                          0,
                          e.target.maxLength
                        );
                    }}
                  />
                  <span className="text-danger">{agentFormError.password}</span>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>PAN Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Pan number"
                    size="sm"
                    name="pan"
                    value={agentForm.pan.toLocaleUpperCase()}
                    onChange={handleClientAgent}
                    autoComplete="off"
                    maxLength={10}
                    onInput={(e) => {
                      if (e.target.value.length > e.target.maxLength)
                        e.target.value = e.target.value.slice(
                          0,
                          e.target.maxLength
                        );
                    }}
                  />
                  <span className="text-danger">{agentFormError.pan}</span>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Address"
                    size="sm"
                    name="address"
                    onChange={handleClientAgent}
                    autoComplete="off"
                    maxLength={100}
                    onInput={(e) => {
                      if (e.target.value.length > e.target.maxLength)
                        e.target.value = e.target.value.slice(
                          0,
                          e.target.maxLength
                        );
                    }}
                  />
                  <span className="text-danger">{agentFormError.address}</span>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              {showCity == true ? (
                <Col md={6}>
                  <diV className="mb-3">
                    <label>Select Branch Name</label>
                    <div style={{ marginTop: "8px" }}>
                      <Select
                        isMulti
                        options={localCityOptions}
                        // onChange={handleClickCity}
                        onChange={setSelectedLocalCityOptions}
                        value={
                          selectedLocalCityOptions[
                          selectedLocalCityOptions.length - 1
                          ] || null
                        }
                      />
                    </div>
                    <span className="text-danger">
                      {selectedLocalCityOptionsError}
                    </span>
                  </diV>
                </Col>
              ) : (
                ""
              )}
            </Row>
            <Row>
              <Col md={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Comments</Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder="Comments"
                    rows={3}
                    name="description"
                    onChange={handleClientAgent}
                    autoComplete="off"
                  />
                </Form.Group>
                <span className="text-danger">
                  {agentFormError.description}
                </span>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        {/* /User/search_pin */}
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseClientagent}>
            Cancel
          </Button>
          <Button
            variant="primary"
            className="all-button"
            onClick={handleClientAgentSubmit}
          >
            Submit{" "}
            {
              loaderAgent ? (
                <Spinner animation="border" variant="Primary" size="sm" />
              ) : ""}
          </Button>

        </Modal.Footer>
      </Modal>
      {/* add new c-note master  */}
      <Modal
        show={showCnote}
        onHide={handleCloseCnote}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <h6 className="text-center more-link mt-4">Add New C-note</h6>
        <Modal.Body>
          <Form>
            <Row>
              {/* <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Label>State</Form.Label>
                  <Form.Select size="sm" name="Type" onChange={handleState}>
                    <option>Choose</option>
                    {state.map((val) => {
                      return (
                        <>
                          <option value={val.State}>{val.State}</option>
                        </>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Label>City</Form.Label>
                  <Form.Select size="sm" name="party" onChange={handleCity}>
                    <option>Choose</option>
                    {city.map((val) => {
                      return (
                        <>
                          <option value={val.District}>{val.District}</option>
                        </>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
              </Col> */}
              {/* <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Franchisee</Form.Label>
                  <Form.Select
                    size="sm"
                    name="frenchise_id"
                    onChange={handleChangecnote}
                  >
                    <option>Choose</option>
                    {franchise.map((val) => {
                      return (
                        <>
                          <option value={val.id}>
                            {val.ContactPersion}({val.BusinessName})
                          </option>
                        </>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
              </Col> */}

              <Col md={6}>
                {/* {  <input
                  autoComplete="off"
                  id="createBranchName"
                  type="text"
                  {...register("cityname", {
                    onChange: (e) => {
                      handleCreateBranchNameChange(e.target.value);
                    },
                    required: "cityname is required",
                  })}
                  class="form-control"
                  size="sm"
                />
                {errors.cityname && (
                  <span className="text-danger">{errors.cityname.message}</span>
                )}
                <span className="text-danger"> {branchError}</span>

                <div className="position-absolute" style={{ zIndex: "1" }}>
                  {createBranchData?.data?.map((ele, ind) => {
                    return (
                      <div key={ind} className="">
                        {disableCreateBanchList == true ? (
                          <Card
                            key={ind}
                            className="w-100 px-5  py-1 "
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClickCreateBranchName(ele)}
                          >
                            {ele.city_name}
                          </Card>
                        ) : (
                          " "
                        )}
                      </div>
                    );
                  })}
                  {createBranchData?.data?.length == 0 ? (
                    <p style={{ color: "red" }}>there is no data</p>
                  ) : (
                    ""
                  )}
                </div>} */}
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Row>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>Starting Cnote Number</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Starting Cnote Number"
                        size="sm"
                        onChange={handleChangecnote}
                        name="start_no"
                        maxLength={10}
                        onWheelCapture={(e) => e.target.blur()}
                        onInput={(e) => {
                          if (e.target.value.length > e.target.maxLength)
                            e.target.value = e.target.value.slice(
                              0,
                              e.target.maxLength
                            );
                        }}
                        autoComplete="off"
                      />
                      <span className="text-danger">
                        {cnotedataError.start_no}
                      </span>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>Ending Cnote Number</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Ending Cnote Number"
                        size="sm"
                        onWheelCapture={(e) => e.target.blur()}
                        onChange={handleChangecnote}
                        name="end_no"
                        maxLength={10}
                        onInput={(e) => {
                          if (e.target.value.length > e.target.maxLength)
                            e.target.value = e.target.value.slice(
                              0,
                              e.target.maxLength
                            );
                        }}
                        autoComplete="off"
                      />
                      <span className="text-danger">
                        {cnotedataError.end_no}
                      </span>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Payment Mode</Form.Label>
                  <Form.Select
                    size="sm"
                    name="cash_mode"
                    onChange={handleChangecnote}
                  >
                    <option value="">Select Payment Mode</option>
                    <option value="Online">Online</option>
                    <option value="Offline">Offline</option>
                  </Form.Select>
                  <span className="text-danger">
                    {cnotedataError.cash_mode}
                  </span>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Amount</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Type Amount"
                    size="sm"
                    onWheelCapture={(e) => e.target.blur()}
                    name="amount"
                    maxLength={10}
                    onChange={handleChangecnote}
                    onInput={(e) => {
                      if (e.target.value.length > e.target.maxLength)
                        e.target.value = e.target.value.slice(
                          0,
                          e.target.maxLength
                        );
                    }}
                    autoComplete="off"
                  />
                  <span className="text-danger">{cnotedataError.amount}</span>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Amount Receive Date</Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="Amount Received"
                    size="sm"
                    onChange={handleChangecnote}
                    name="receive_date"
                    value={cnotedata.receive_date}
                  />
                  <span className="text-danger">
                    {cnotedataError.receive_date}
                  </span>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Receive Amount</Form.Label>
                  <Form.Control
                    type="number"
                    onWheelCapture={(e) => e.target.blur()}
                    placeholder="Type Amount"
                    size="sm"
                    onChange={handleChangecnote}
                    name="receive_amount"
                    maxLength={10}
                    autoComplete="off"
                    onInput={(e) => {
                      if (e.target.value.length > e.target.maxLength)
                        e.target.value = e.target.value.slice(
                          0,
                          e.target.maxLength
                        );
                    }}
                  />
                  <span className="text-danger">
                    {cnotedataError.receive_amount}
                  </span>
                </Form.Group>
              </Col>
              <Col className="col-6">
                <Form.Group className="mb-3">
                  <Form.Label>Quantity</Form.Label>
                  <Form.Control
                    type="text"
                    rows={3}
                    name=""
                    onWheelCapture={(e) => e.target.blur()}
                    value={one && one}
                    autoComplete="off"
                    disabled
                  />
                  {/* <span className="text-danger" id='quantity' >{
                    // cnotedata.start_no > cnotedata.end_no && cnotedata.end_no < cnotedata.start_no == true ? 'quantity should be positive number' : ''

                  }</span> */}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col className="col-6">
                <Form.Group className="mb-3">
                  <Form.Label>Remark</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="remarks"
                    onChange={handleChangecnote}
                  />
                  <span className="text-danger">{cnotedataError.remarks}</span>
                </Form.Group>
              </Col>
              <Col>
                <diV className="mb-3 mt-1">
                  <label>Branch Name</label>
                  {" "}
                  {branchLoader ? (
                    <Spinner
                      animation="border"
                      variant="Primary"
                      size="sm"
                    />
                  ) : (
                    ""
                  )}
                  <Select
                    isMulti
                    options={branchData}
                    onChange={setSelectedBranchName}
                    value={selectedBranchName[selectedBranchName.length - 1]}
                  />
                  <span className="text-danger">{pincodeDataError}</span>
                </diV>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseCnote}>
            Cancel
          </Button>
          <Button
            variant="primary"
            className="all-button"
            onClick={handleSubmitcnote}
          >
            Submit{" "}
            {loader ? (
              <Spinner animation="border" variant="Primary" size="sm" />
            ) : (
              ""
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for City Master */}
      <Modal
        show={showCitymaster}
        onHide={handleCloseCitymaster}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <h6 className="text-center more-link mt-4">Add New Branch Name</h6>
        <Modal.Body>
          <Form>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>New Branch Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Branch Name"
                    size="sm"
                    name="receive_amount"
                    onChange={OnChangeCity}
                    maxLength={20}
                    autoComplete="off"
                  />
                  <span className="text-danger">{cityNameError}</span>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseCitymaster}>
            Cancel
          </Button>
          <Button
            variant="primary"
            className="all-button"
            onClick={handleCityCreate}
          >
            Submit
            {loader ? (
              <Spinner animation="border" variant="Primary" size="sm" />
            ) : (
              ""
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Master;
