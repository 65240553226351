import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import { Circles } from "react-loader-spinner";
import Apiservice from "../../Services/Apiservice";
import Switch from "react-switch";
import { toast, ToastContainer, Zoom } from "react-toastify";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import CustomModal from "../../common/CustomModal";
import { FileText } from "react-bootstrap-icons";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomDeleteModal from "../../common/CustomDeleteModal";


const Citymaster = (props) => {
  const [cityMasterData, setCityMasterData] = useState(null);
  const [reloadPage, setReloadPage] = useState(1);

  /* Toast Modal */
  const [showPopupOpen, setShowPopupOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState(null);

  // delete state
  const [deleteModal, setDeleteModal] = useState({
    loader: false,
    show: false,
    type: "",
    data: {},
  })


  const handleClosePopup = () => {
    setShowPopupOpen(false);
  };
  const handleOkClick = () => {
    setShowPopupOpen(false);
  };

  // useEffect(() => {
  //   fetchData();
  // }, [])

  const fetchData = async () => {
    const payload = {
      isNull: true,
    };
    const citylist = await Apiservice.postdata("/User/listCity", payload);
    if (citylist.error == false) {
      setCityMasterData(citylist.data);
      setRowData(citylist.data);
    } else {
      console.log("get API is not working");
    }
  };

  useEffect(async () => {
    const payload = {
      isNull: true,
    };
    const citylist = await Apiservice.postdata("/User/listCity", payload);
    if (citylist.error == false) {
      setCityMasterData(citylist.data);
      setRowData(citylist.data);
    } else {
      console.log("get API is not working");
    }
  }, [props]);

  const handleSwichChange = async (data) => {
    const payload = {
      city_id: data.id,
    };

    if (data.isActive == 0) {
      payload.status = true;
    }
    if (data.isActive == 1) {
      payload.status = false;
    }
    const updateStatus = await Apiservice.postdata("/User/updateCitymasterStatus", payload);
    if (updateStatus.error == false) {
      // toast.success(updateStatus.message)
      setShowPopupOpen(true);
      setModalMessage(updateStatus.message);
      setReloadPage(reloadPage + 1);
    } else {
      setShowPopupOpen(true);
      setModalMessage(updateStatus.message);
    }
  };

  useEffect(async () => {
    const payload = {
      isNull: true,
    };
    const citylist = await Apiservice.postdata("/User/listCity", payload);
    if (citylist.error == false) {
      setCityMasterData(citylist.data);
      setRowData(citylist.data);
    } else {
      console.log("get API is not working");
    }
    setReloadPage(1);
  }, [reloadPage]);

  const rowHeight = 50;

  const [rowData, setRowData] = useState();

  const [colDefs, setColDefs] = useState([
    {
      headerName: "#",
      valueGetter: "node.rowIndex + 1",
      filter: true,
      lockPosition: true,
    },
    // {
    //   headerName: "Name",
    //   filter: true,
    //   valueGetter: "data.Frenchises[0].ContactPersion",
    // },
    // {
    //   headerName: "Mobile Number",
    //   filter: true,
    //   valueGetter: "data.Frenchises[0].MobileNumber",
    // },
    {
      headerName: "Branch Name",
      filter: true,
      field: "city_name",
    },
    {
      headerName: "Status",
      filter: true,
      cellRendererFramework: (params) => (
        <center>
          <Button
            style={{ width: "150px" }}
            variant={`${params?.data.isActive == 1 ? 'danger' : 'primary'}`}
            size="sm"
            onClick={() => handleSwichChange(params.data)}
          >
            {params?.data.isActive == 1 ? "Reject" : "Approve"}
          </Button>
        </center>
      ),
    },
    {
      // headerName: "Edit",
      // filter: true,
      // cellRendererFramework: (params) => (
      //   <center
      //   // onClick={() => handleClickdata(params)}
      //   >
      //     <FontAwesomeIcon icon={faEdit} className="iconstyle" />
      //   </center>
      // ),
    },
    {
      headerName: "Delete",
      filter: true,
      cellRendererFramework: (params) => (
        <center
          onClick={() => setDeleteModal({ ...deleteModal, show: true, data: params.data, type: "DELETE", deletedmodule: 'Branch Name', deletedname: params?.data?.city_name })}
        >
          <FontAwesomeIcon icon={faTrash} className="iconstyle" />
        </center>
      )
    },
  ]);

  const DefaultColumnSetting = {
    sortable: true,
    filter: true,

    floatingFilter: true,
    flex: 1,
    resizable: true,
    minWidth: 120,
  };

  /******* Delete API Integration ******/
  const hendleDeletSubmitApi = async (data) => {
    setDeleteModal({ ...deleteModal, loader: true })
    const payload = {
      city_id: data?.id,
    };

    let price = await Apiservice.postdata("/booking/deletecity_master", payload);
    if (price.error == false) {
      setDeleteModal({})
      toast.success(price.message)
      setReloadPage(reloadPage + 1);
    } else {
      setDeleteModal({})
      setReloadPage(reloadPage + 1);
      toast.error(price.message)
    }
  };

  const hendleDeleteModal = (data) => {
    setDeleteModal({})
  }

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        transition={Zoom}
        delay={1000}
        limit={1}
      />
      <CustomModal
        showPopupOpen={showPopupOpen}
        handleOkClick={handleOkClick}
        handleClosePopup={handleClosePopup}
        modalMessage={modalMessage}
      />

      {/* delete modal */}
      <CustomDeleteModal
        hendleDeleteModal={hendleDeleteModal}
        deleteModal={deleteModal}
        hendleDeletSubmitApi={hendleDeletSubmitApi}
      ></CustomDeleteModal>
      <div className="card w-100 my-3 p-3">
        <div class="card-body pt-0">
          {/* <Container> */}
          <div className="col-md-3">
            <h6 class="card-title    my-3 bg-light w-auto py-2 rounded ">
              <FileText /> <b>Branch Master</b>
            </h6>
          </div>
          <Row>
            {cityMasterData ? (
              <Col>
                <div
                  className="ag-theme-alpine"
                  style={{ height: "70vh", width: "100%" }}
                >
                  <AgGridReact
                    rowHeight={rowHeight}
                    // columnDefs={columns}
                    columnDefs={colDefs}
                    defaultColDef={DefaultColumnSetting}
                    pagination={true}
                    paginationPageSize={10}
                    // onGridReady={onGridReady}
                    rowData={rowData}
                  />{" "}
                </div>
              </Col>
            ) : (
              <div style={{ marginLeft: "550px", marginTop: "100px" }}>
                <Circles
                  height="30"
                  width="30"
                  color="grey"
                  ariaLabel="loading"
                />
              </div>
            )}
          </Row>
          {/* </Container> */}
        </div>
      </div>
    </>
  );
};

export default Citymaster;
