import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Col, Row, Table, Button, Modal, Form, Card, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import Apiservice from "../../Services/Apiservice";
import { Circles } from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import Select from "react-select";
import CustomModal from "../../common/CustomModal";
import { FileText } from "react-bootstrap-icons";
import CustomDeleteModal from "../../common/CustomDeleteModal";

const Clientagent = (props) => {
  const [tableData, setTableData] = useState(null);
  const [show, setShow] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [load, setLoad] = useState(false);
  const [changePassLoder, setChangePassLoder] = useState(false)

  /* Toast Modal */
  const [showPopupOpen, setShowPopupOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState(null);
  const [update, setUpdate] = useState(1);


  // const [frenchiseRadio, setFrenchiseRadio] = useState("SUPER");
  const [superfranchiseDropdownTrue, setSuperFranchiseDropdownTrue] = useState({ franchiseTo: "" });
  const [superfranchiseDropdownFalse, setSuperFranchiseDropdownFalse] = useState({ franchiseTo: "" });
  const [updateClientAgent, setUpdateClientAgent] = useState({
    businessName: "",
    contactPerson: "",
    email: "",
    mobileNumber: "",
    gst: "",
    pan: "",
    state: "",
    district: "",
    password: "",
    description: "",
    address: "",
    city: "",
    pincode: "",
    show: false,
    loader: false,
    franchiseRadio: "",
    showCity: "SUPER",
    submitLoader: false,
    _id: ""
  })

  const [superBranchList, setSuperBranchList] = useState([]);
  const [superSelectedBranch, setSuperSelectedBranch] = useState([]);
  const [brnachDropdown, setBrnachDropdown] = useState("");
  const [currentFrenchiseId, setCurrentFrenchiseId] = useState({});
  const [userData, setUserData] = useState("");
  const [localBranchList, setLocalBranchList] = useState([]);
  const [locaSelectedBranch, setLocalSelectedBranch] = useState([]);
  const [userDetails, setUserDetailse] = useState({});

  const [localCityOptions, setLocalCityOptions] = useState([]);
  const [selectedLocalCityOptions, setSelectedLocalCityOptions] = useState([]);
  const [selectedLocalCityOptionsError, setSelectedLocalCityOptionsError] = useState("");

  // super franchise update
  const [superBranchListData, setSuperBranchListData] = useState([])

  // delete state
  const [deleteModal, setDeleteModal] = useState({
    loader: false,
    show: false,
    type: "",
    data: {},
  })


  const handelCityOpen = () => {
    // handleCloseClientagent();
    // setchoice(8);
  };


  useEffect(async () => {
    const localstorege = localStorage.getItem("userDetails");
    const localstoregeParseData = JSON.parse(localstorege);
    setUserDetailse(localstoregeParseData)
    const mainData = await localstoregeParseData;
    setEmailUser(localstoregeParseData);
    const one = [mainData.role_masters].map((ele, ind) => {
      setUserData(ele[0].role_name);
    });
  }, []);

  const [emailUser, setEmailUser] = useState([]);
  const [showCity, setShowCity] = useState(false);
  const handleLocalFrenchiseClick = () => {
    // setFrenchiseRadio("LOCAL");
    setUpdateClientAgent({
      ...updateClientAgent,
      franchiseRadio: 'LOCAL',
      showCity: 'LOCAL'
    })
    // setShowCity(true);
    setUpdate(update + 1);
    // setUpdateClientAgent({
    //   ...updateClientAgent,
    //   franchiseRadio: "LOCAL",
    // });
    setBrnachDropdown("");
  };
  const handleSuperFrenchiseClick = () => {
    // setFrenchiseRadio("SUPER");
    setUpdateClientAgent({
      ...updateClientAgent,
      franchiseRadio: 'SUPER',
      showCity: 'SUPER'
    })
    setShowCity(false);
    setCurrentFrenchiseId(userDetails.id);
    setUpdate(update + 1);
    // setUpdateClientAgent({
    //   ...updateClientAgent,
    //   franchiseRadio: "SUPER",
    // });
    setBrnachDropdown("");
  };

  useEffect(async () => {
    if (updateClientAgent.showCity == "SUPER") {
      let cityNameData = await Apiservice.postdata(`/User/not_assigned_lists`);
      if (cityNameData.error == false) {
        const areaData = [];
        const areaMapData = cityNameData.data.map((ele, ind) => {
          areaData.push({
            label: ele.city_name,
            value: ele.city_name,
            id: ele.id,
          });
        });
        setSuperBranchList(areaData);
      } else {
        console.log("wrong");
      }
    }

    if (updateClientAgent.showCity == "LOCAL") {
      const payload = {
        city_name: "",
        isNull: false,
        Hub_type: "SUPER",
      };
      let cityNameData = await Apiservice.postdata(`/User/listCity`, payload);
      if (cityNameData.error == false) {
        const areaData = [];
        const areaMapData = cityNameData.data.map((ele, ind) => {
          areaData.push({
            label: ele.city_name,
            value: ele.city_name,
            id: ele,
          });
        });
        setLocalBranchList(areaData);
      } else {
        console.log("wrong");
      }
    }
  }, [update]);

  /*** City ***/
  useEffect(async () => {
    const detailes = JSON.parse(localStorage.getItem("userDetails"));
    setUserDetailse(detailes)
    let AreaNameData = await Apiservice.postdata(`/User/not_assigned_lists`);
    if (AreaNameData.error == false) {
      const pincodeDropdown = [];
      const pincodeMaping = AreaNameData.data.map((ele, ind) => {
        pincodeDropdown.push({
          label: ele.city_name,
          value: ele.city_name,
          id: ele,
        });
      });
      setLocalCityOptions(pincodeDropdown);
    } else {
      console.log("failue");
    }
  }, []);

  const handleClientAgent = (event) => {
    if (event.target.name == "pincode") {
      setUpdateClientAgent({ ...updateClientAgent, pincode: event.target.value })
      if (event.target.value.length != 6) {
        setUpdateClientAgent({
          ...updateClientAgent,
          pincode: event.target.value,
          state: "",
          district: ""
        })
      } else {
        handleGetPincode(event.target.value)
      }
    } else {
      setUpdateClientAgent({ ...updateClientAgent, [event.target.name]: event.target.value });
    }
  };

  const handleGetPincode = async (pincode) => {
    if (pincode.length == 6) {
      setUpdateClientAgent({ ...updateClientAgent, pincode: pincode, loader: true })
      const payload = { pin: pincode };
      const ListPin = await Apiservice.postdata("/User/search_pin", payload);
      if (ListPin?.error == false) {
        setUpdateClientAgent({
          ...updateClientAgent,
          pincode: pincode,
          state: ListPin?.data?.State,
          district: ListPin?.data?.District,
          loader: false
        });
      } else {
        setUpdateClientAgent({
          ...updateClientAgent,
          state: "",
          district: "",
          loader: false
        });
      }
    }
  }


  const handleClosePopup = () => {
    setShowPopupOpen(false);
  };
  const handleOkClick = () => {
    setShowPopupOpen(false);
  };

  const handleCityData = async (cityname, ind) => {
    const userDetailes = localStorage.getItem("userDetails");
    const userDetailesParse = JSON.parse(userDetailes);
    setUserDetailse(userDetailesParse)
    setDisableOutwardCity(true);
    let AreaNameData = await Apiservice.postdata(`/User/listCity`, {
      city_name: cityname,
      isNull: false,
      Hub_id: userDetailesParse.id,
    });
    if (AreaNameData.error == false) {
      setOutwardCityData(AreaNameData);
    } else {
      console.log("wrong");
    }
  };

  const fetchData = async () => {
    const Listclient = await Apiservice.postdata("/booking/get_frenchise");
    if (Listclient.error == false) {
      // toast.success(Listclient.message)
      setTableData(Listclient.data);
      setRowData(Listclient.data);
    } else {
      console.log("get API is not working");
    }
  };

  useEffect(async () => {
    const Listclient = await Apiservice.postdata("/booking/get_frenchise");
    if (Listclient.error == false) {
      setTableData(Listclient.data);
      setRowData(Listclient.data);
    } else {
      console.log("get API is not working");
    }
  }, [props]);

  useEffect(() => {
    fetchData();
  }, []);

  const approveDisapprove = async (data) => {
    setLoad(true);
    const one = localStorage.getItem("userDetails");
    setUserDetailse(JSON.parse(one))
    const Payload = {
      ApprovedBy: JSON.parse(one).ContactPersion,
      User_Id: data?.id,
      ApprovedStatus: !data?.ApprovedStatus,
      Register_With: "MOXD",
      RegisterType: "MOXD",
    };
    const listGet = await Apiservice.postdata("/User/approve_franchise", Payload);
    if (listGet.error == false) {
      setShowPopupOpen(true);
      setModalMessage(listGet.message);
      setLoad(false);
      fetchData();
    } else {
      setShowPopupOpen(true);
      setModalMessage(listGet.message);
      setLoad(false);
    }
  };

  const onNewPasswordClick = (data) => {
    setPhone(data?.MobileNumber);
    setShow(!show);
  };

  const updatePassword = async () => {
    setChangePassLoder(true)
    if (password === "") {
      setPasswordError("Password is compulsory");
    } else {
      setPasswordError("");
    }
    if (confirmPassword === "" || confirmPassword !== password) {
      setConfirmPasswordError("password should match as above");
    } else {
      setConfirmPasswordError("");
    }
    if (
      password !== "" &&
      phone !== "" &&
      confirmPassword !== "" &&
      confirmPassword === password
    ) {
      const payload = {
        MobileNumber: phone,
        NewPassword: password,
      };
      const postPassword = await Apiservice.postdata("/User/NewPassword", payload);
      if (postPassword.error == false) {
        setShowPopupOpen(true);
        setModalMessage(postPassword.message);
        setPassword("");
        setConfirmPassword("");
        setPhone("");
        fetchData();
        setShow(!show);
        setChangePassLoder(false)
      } else {
        setShow(!show);
        setChangePassLoder(false)
      }
    }
  };

  const handleClose = () => setShow(false);
  const updateCity = async (e) => {
    const Payload = {
      city_id: city.id,
      frenchise_id: e.id,
    };
    const listGet = await Apiservice.postdata("/User/updatecityId", Payload);
    if (listGet.error == false) {
      setShowPopupOpen(true);
      setModalMessage(listGet.message);
      console.log(listGet.data, "111");
    } else {
      console.log("get API is not working");
    }
  };

  const [outwardCityData, setOutwardCityData] = useState([]);
  const [disableOutwardCity, setDisableOutwardCity] = useState(true);
  const [city, setCity] = useState([]);

  const handleClickCity = (city) => {
    document.getElementById("inputData").value = city.city_name;
    setDisableOutwardCity(false);
    setCity(city);
  };


  const DefaultColumnSetting = {
    sortable: true,
    filter: true,
    floatingFilter: true,
    flex: 1,
    resizable: true,
    minWidth: 120,
  };

  const rowHeight = 50;
  const [rowData, setRowData] = useState();
  const [colDefs, setColDefs] = useState([
    {
      headerName: "#",
      valueGetter: "node.rowIndex + 1",
      filter: true,
      lockPosition: true,
    },
    {
      headerName: "Name",
      filter: true,
      field: "ContactPersion",
    },
    {
      headerName: "Business Name",
      filter: true,
      field: "BusinessName",
    },
    {
      headerName: "Franchise Type",
      filter: true,
      field: "Hub_type",
    },
    {
      headerName: "GST Number",
      filter: true,
      field: "GST",
    },
    {
      headerName: "PAN Number",
      filter: true,
      field: "Pan_Number",
    },
    {
      headerName: "Mobile No",
      filter: true,
      field: "MobileNumber",
    },
    {
      headerName: "Email",
      filter: true,
      field: "Email",
    },
    {
      headerName: "Branch Name",
      filter: true,
      valueGetter: "data.city_masters[0].city_name",
    },
    {
      headerName: "District",
      filter: true,
      field: "District",
    },
    {
      headerName: "State",
      filter: true,
      field: "State",
    },
    {
      headerName: "Address",
      filter: true,
      field: "Address",
    },
    {
      headerName: "Pincode",
      filter: true,
      field: "Pincode",
    },
    {
      headerName: "Description",
      filter: true,
      field: "Description",
    },
    {
      headerName: "Update Status",
      filter: true,
      cellRendererFramework: (params) => (
        <center>
          {" "}
          <Button
            className="w-100"
            variant={!params?.data?.ApprovedStatus ? 'primary' : 'danger'}
            size="sm"
            onClick={() => approveDisapprove(params.data)}
          >
            {!params?.data?.ApprovedStatus ? (
              load ? (
                <Spinner animation="border" variant="Primary" size="sm" />
              ) : (
                "Approve"
              )
            ) : load ? (
              <Spinner animation="border" variant="Primary" size="sm" />
            ) : (
              "Reject"
            )}
          </Button>
        </center>
      ),
    },
    {
      headerName: "New Password",
      filter: true,
      cellRendererFramework: (params) => (
        <center>
          {" "}
          <Button
            variant="primary"
            size="sm"
            onClick={() => onNewPasswordClick(params.data)}
          >
            New password
          </Button>
        </center>
      ),
    },
    {
      headerName: "Edit",
      filter: true,
      cellRendererFramework: (params) => (
        <center>
          <FontAwesomeIcon
            onClick={() => handleUpdateFranchise(params.data)}
            icon={faEdit}
            className="iconstyle"
          />
        </center>
      ),
    },
    // {
    // headerName: "Delete",
    // filter: true,
    // cellRendererFramework: (params) => (
    //   <center
    //     onClick={() => setDeleteModal({ ...deleteModal, show: true, data: params.data, type: "DELETE", deletedmodule: 'Franchise', deletedname: params?.data?.city_masters[0]?.city_name })}
    //   >
    //     <FontAwesomeIcon icon={faTrash} className="iconstyle" />
    //   </center>
    // ),
    // },
  ]);

  /******* update Detailes *******/
  useEffect(async () => {
    let AreaNameData = await Apiservice.postdata(`/User/listCity`, {
      city_name: "",
      isNull: true,
    });
    if (AreaNameData.error == false) {
      const areaData = [];
      const areaMapData = AreaNameData.data.map((ele, ind) => {
        areaData.push({
          label: ele.city_name,
          value: ele.city_name,
          id: ele,
        });
      });
      setAreaOptionDataName(areaData);
    } else {
      console.log("wrong");
    }
  }, []);

  const [editForm, setEditForm] = useState({
    name: "",
    bussinessName: "",
    mobile: "",
    pincode: "",
  });
  const [editFormError, setEditFormError] = useState("");
  const [BranchNameData, setBranchNameData] = useState("");
  const [areaOptionDataName, setAreaOptionDataName] = useState([]);

  const handleEditForm = (ele) => {
    setEditForm({ ...editForm, [ele.target.name]: ele.target.value });
  };
  const [idData, setIdData] = useState("");

  const handleGetSuperFranchise = async (data) => {
    // let AreaNameData = await Apiservice.postdata(`/User/listCity`, {
    //   city_name: '',
    //   isNull: false,
    //   Hub_id: data?.Hub_id,
    //   ApprovedStatus: true,
    // });
    // if (AreaNameData.error == false) {
    //   const pincodeDropdown = [];
    //   const pincodeMaping = AreaNameData.data.map((ele, ind) => {
    //     pincodeDropdown.push({
    //       label: ele.city_name,
    //       value: ele.city_name,
    //       id: ele.id,
    //     });
    //   });
    //   setSuperBranchListData(pincodeDropdown);
    // } else {
    //   console.log("failue");
    // }

    let AreaNameData = await Apiservice.postdata(`/admin/super_franchise`, {
      Hub_id: data?.Hub_id,
    });
    if (AreaNameData.error == false) {
      const pincodeDropdown = [{
        label: AreaNameData?.data?.city_masters[0].city_name,
        value: AreaNameData?.data?.city_masters[0].city_name,
      }]
      setSuperBranchListData(pincodeDropdown);
    } else {
      console.log("failue");
    }
  }

  const handleUpdateFranchise = (data) => {
    handleGetSuperFranchise(data)
    setUpdateClientAgent({
      ...updateClientAgent,
      businessName: data.BusinessName,
      contactPerson: data.ContactPersion,
      email: data.Email,
      franchiseRadio: "",
      mobileNumber: data.MobileNumber,
      gst: data.GST,
      pan: data.Pan_Number,
      state: data.State,
      district: data.District,
      description: data.Description,
      address: data.Address,
      city: { id: data.city_masters[0].id, value: data.city_masters[0].city_name, label: data.city_masters[0].city_name },
      pincode: data.Pincode,
      show: true,
      franchiseRadio: data.Hub_type,
      showCity: data.Hub_type,
      franchise_id: data.id,
      // loader: false
      _id: data.id
    })
  };

  const handleEditFormClose = () => {
    setEditForm({
      ...editForm,
      name: "",
      bussinessName: "",
      mobile: "",
      pincode: "",
    });
  };

  const handleBranchName = (e) => {
    setBranchNameData(e?.id?.Frenchises[0]);
  };

  // update franchise
  const handleUpdateClientAgentSubmit = async () => {
    if (updateClientAgent.franchiseRadio == "SUPER") {
      setUpdateClientAgent({ ...updateClientAgent, submitLoader: true })
      const addClientPayload = {
        Address: updateClientAgent.address || "",
        BusinessName: updateClientAgent.businessName || "",
        ContactPersion: updateClientAgent.contactPerson || "",
        Description: updateClientAgent.description || "",
        District: updateClientAgent.district || "",
        Email: updateClientAgent.email || "",
        GST: updateClientAgent.gst || "",
        PAN: updateClientAgent.pan || "",
        MobileNumber: updateClientAgent.mobileNumber || "",
        Pincode: updateClientAgent.pincode || "",
        State: updateClientAgent.district || "",
        // city_id: superSelectedBranch[superSelectedBranch.length - 1]?.id || "",
        id: updateClientAgent.franchise_id || '',
        Language: "English-Hindi",
        // Hub_type: updateClientAgent?.franchiseRadio || "",
        Lat: "9.78779",
        Lang: "10.5678",
        // Hub_id: userDetails.id,
      };
      const client = await Apiservice.postdata("/User/update_frenchise", addClientPayload);
      if (client.error == false) {
        setModalMessage(client.message);
        setBrnachDropdown("");
        setModalMessage(client.message);
        setUpdateClientAgent({ ...updateClientAgent, submitLoader: false, show: false })
        fetchData()
      } else {
        setModalMessage(client.message);
        setUpdateClientAgent({ ...updateClientAgent, submitLoader: false, show: false })
        fetchData()
      }
    }

    if (updateClientAgent?.franchiseRadio == "LOCAL") {
      setUpdateClientAgent({ ...updateClientAgent, submitLoader: true })
      const addClientPayload = {
        Address: updateClientAgent.address || "",
        BusinessName: updateClientAgent.businessName || "",
        ContactPersion: updateClientAgent.contactPerson || "",
        Description: updateClientAgent.description || "",
        District: updateClientAgent?.district || "",
        Email: updateClientAgent.email || "",
        GST: updateClientAgent.gst || "",
        PAN: updateClientAgent.pan || "",
        MobileNumber: updateClientAgent.mobileNumber || "",
        Pincode: updateClientAgent.pincode || "",
        State: updateClientAgent?.state || "",
        id: updateClientAgent.franchise_id || '',
        // city_id: selectedLocalCityOptions[selectedLocalCityOptions.length - 1]?.id
        //     ?.id || "",
        // FrenchiseId: localCityId?.id?.id || '',
        Language: "English-Hindi",
        // Hub_type: updateClientAgent?.franchiseRadio || "",
        Lat: "9.78779",
        Lang: "10.5678",
        // Hub_id:
        //   locaSelectedBranch[locaSelectedBranch.length - 1]?.id?.Frenchises[0]
        //     ?.id || "",
      };
      const client = await Apiservice.postdata("/User/update_frenchise", addClientPayload);
      if (client.error == false) {
        setModalMessage(client.message);
        setUpdateClientAgent({ ...updateClientAgent, submitLoader: false, show: false })
        fetchData()
      } else {
        setModalMessage(client.message);
        setUpdateClientAgent({ ...updateClientAgent, submitLoader: false, show: false })
        fetchData()
      }
    }
  }

  const handleEditFormSave = async () => {
    const one = localStorage.getItem("userDetails");
    const parseData = JSON.parse(one);
    setUserDetailse(parseData)
    const payload = { id: idData };
    if (editForm.name != "") {
      payload.ContactPersion = editForm.name;
    }
    if (editForm.mobile != "") {
      payload.MobileNumber = editForm.mobile;
    }
    if (editForm.bussinessName != "") {
      payload.BusinessName = editForm.bussinessName;
    }
    if (editForm.pincode != "") {
      payload.Pincode = editForm.pincode;
    }
    if (BranchNameData != "") {
      payload.city_id = BranchNameData.id;
    }

    const ApiData = await Apiservice.postdata("/User/update_frenchise", payload);
    if (ApiData.error == false) {
      // toast.success(ApiData.message)
      setShowPopupOpen(true);
      setModalMessage(ApiData.message);
      setShowUpdate(false);
      fetchData();
    } else {
      // toast.error(ApiData.message)
      setShowPopupOpen(true);
      setModalMessage(ApiData.message);
      setShowUpdate(false);
      fetchData();
    }
  };


  const hendleDeleteModal = () => {
    setDeleteModal({})
  }

  // franchise delete
  const hendleDeletSubmitApi = async (data) => {
    setDeleteModal({ ...deleteModal, loader: true })
    const payload = { franchise_id: data.id };
    let deleteFranchise = await Apiservice.postdata("/User/delete_frenchise", payload);
    if (deleteFranchise.error == false) {
      setDeleteModal({})
      toast.success(deleteFranchise.message)
      fetchData();
    } else {
      setDeleteModal({})
      toast.error(deleteFranchise.message)
      fetchData();
    }
  }

  return (
    <>
      {/* <div className="more-link">
        <h6>Client/Agent</h6>
      </div> */}
      <div className="card w-100 my-3 p-3">
        <div class="card-body pt-0">
          <div className="col-md-3">
            <h6 class="card-title    my-3 bg-light w-auto py-2 rounded ">
              <FileText /> <b>Clien/Agent Master</b>
            </h6>
          </div>
          <div>
            <CustomModal
              showPopupOpen={showPopupOpen}
              handleOkClick={handleOkClick}
              handleClosePopup={handleClosePopup}
              modalMessage={modalMessage}
            />
          </div>
          {/* delete modal */}
          <CustomDeleteModal
            hendleDeleteModal={hendleDeleteModal}
            deleteModal={deleteModal}
            hendleDeletSubmitApi={hendleDeletSubmitApi}
          ></CustomDeleteModal>
          {/* <Container> */}
          <Row>
            {tableData ? (
              <Col>
                <div
                  className="ag-theme-alpine"
                  style={{ height: "70vh", width: "100%" }}
                >
                  <AgGridReact
                    rowHeight={rowHeight}
                    // columnDefs={columns}
                    columnDefs={colDefs}
                    defaultColDef={DefaultColumnSetting}
                    pagination={true}
                    paginationPageSize={10}
                    // onGridReady={onGridReady}
                    rowData={rowData}
                  />{" "}
                </div>
              </Col>
            ) : (
              <div style={{ marginLeft: "550px", marginTop: "100px" }}>
                <Circles
                  height="30"
                  width="30"
                  color="grey"
                  ariaLabel="loading"
                />
              </div>
            )}
          </Row>
          {/* </Container> */}
          <div>
            <Modal show={show} onHide={setShow}>
              <Modal.Header closeButton>
                <Modal.Title>Create new Password</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Mobile Number</Form.Label>
                    <Form.Control
                      type="number"
                      autoFocus
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      disabled
                    />
                  </Form.Group>

                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>New Password</Form.Label>
                    <Form.Control
                      type="password"
                      autoFocus
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                    />
                    <span className="text-danger">{passwordError}</span>
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                      type="password"
                      autoFocus
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      value={confirmPassword}
                    />
                    <span className="text-danger">{confirmPasswordError}</span>
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={() => updatePassword()}>
                  Save Password{" "}
                  {changePassLoder ? (
                    <Spinner
                      animation="border"
                      variant="Primary"
                      size="sm"
                    />
                  ) : (
                    ""
                  )}
                </Button>
                <Button variant="danger" onClick={() => handleClose()}>
                  cancel
                </Button>
              </Modal.Footer>
            </Modal>
          </div>

          <div>
            <Modal show={showUpdate} onHide={setShowUpdate}>
              <Modal.Header closeButton>
                <Modal.Title>Update Franchise Details</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={editForm.name}
                      name="name"
                      onChange={handleEditForm}
                      autoComplete="off"
                    />
                  </Form.Group>

                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Business Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="bussinessName"
                      onChange={handleEditForm}
                      value={editForm.bussinessName}
                      autoComplete="off"
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Mobile Number</Form.Label>
                    <Form.Control
                      type="number"
                      onChange={handleEditForm}
                      name="mobile"
                      value={editForm.mobile}
                      autoComplete="off"
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Pincode</Form.Label>
                    <Form.Control
                      type="text"
                      name="pincode"
                      onChange={handleEditForm}
                      value={editForm.pincode}
                      autoComplete="off"
                    />
                  </Form.Group>
                  <div className="">
                    <label>Branch Name</label>
                    <div className="mt-2">
                      <Select
                        options={areaOptionDataName}
                        onChange={(e) => handleBranchName(e)}
                      />
                    </div>
                  </div>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="danger" onClick={() => handleEditFormClose()}>
                  Cancel
                </Button>
                <Button variant="primary" onClick={() => handleEditFormSave()}>
                  Update
                </Button>
              </Modal.Footer>
            </Modal>
          </div>

          {/* update client/agent */}
          <Modal
            show={updateClientAgent.show}
            onHide={() => setUpdateClientAgent({})}
            backdrop="static"
            keyboard={false}
            centered
            size="lg"
          >
            <Modal.Header closeButton >
              <h6 className="text-center more-link mt-4">Update Client/Agent</h6>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Business Name</Form.Label>
                      <Form.Control
                        maxLength={50}
                        type="text"
                        placeholder="Business Name"
                        size="sm"
                        name="businessName"
                        onChange={handleClientAgent}
                        autoComplete="off"
                        value={updateClientAgent.businessName}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Contact Person Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Name"
                        size="sm"
                        name="contactPerson"
                        onChange={handleClientAgent}
                        autoComplete="off"
                        maxLength={50}
                        value={updateClientAgent.contactPerson}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>E-mail</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="E-mail"
                        size="sm"
                        name="email"
                        onChange={handleClientAgent}
                        autoComplete="off"
                        value={updateClientAgent.email}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <div>
                      <Form.Group className="mb-3">
                        <Form.Label>Franchise Selection Accordingly</Form.Label>
                        <Form.Check
                          inline
                          label="Super Franchise"
                          name="group1"
                          type="radio"
                          disabled
                          onClick={handleSuperFrenchiseClick}
                          checked={updateClientAgent.franchiseRadio == "SUPER" && true}
                        />
                        <Form.Check
                          inline
                          label="Local Franchise"
                          name="group1"
                          type="radio"
                          disabled
                          checked={updateClientAgent.franchiseRadio == "LOCAL" && true}
                          onClick={handleLocalFrenchiseClick}
                        />
                      </Form.Group>
                    </div>
                  </Col>
                </Row>
                {
                  // updateClientAgent.showCity == "LOCAL" && (
                  //   <div>
                  //     <Row>
                  //       <Col md={6}>
                  //         <label for="formFile" class="form-label">
                  //           Select Franchise To
                  //         </label>
                  //         <Select
                  //           isDisabled
                  //           isMulti
                  //           options={localBranchList}
                  //           onChange={setLocalSelectedBranch}
                  //           // value={data}
                  //           value={
                  //             locaSelectedBranch[locaSelectedBranch.length - 1] ||
                  //             null
                  //           }
                  //         />
                  //       </Col>
                  //       <Col md={3} className="my-2">
                  //         <Button className="mt-4" onClick={handelCityOpen}>
                  //           Create New City
                  //         </Button>
                  //       </Col>
                  //     </Row>
                  //   </div>
                  // )
                }
                {
                  // updateClientAgent.showCity == "SUPER" && (
                  //   <div>
                  //     <Row>
                  //       <Col md={6}>
                  //         <label for="formFile" class="form-label">
                  //           Select Franchise To
                  //         </label>
                  //         <Select
                  //           isMulti
                  //           isDisabled
                  //           options={superBranchList}
                  //           onChange={setSuperSelectedBranch}
                  //           // value={data}
                  //           value={superSelectedBranch[superSelectedBranch.length - 1] || null}
                  //         />
                  //       </Col>
                  //       <Col md={3} className="my-2">
                  //         <Button className="mt-4" onClick={handelCityOpen}>
                  //           Create New City
                  //         </Button>
                  //       </Col>
                  //     </Row>
                  //   </div>
                  // )

                  updateClientAgent.showCity == "SUPER" ? (
                    <Col md={6}>
                      <diV className="mb-3">
                        <label>Select Branch Name</label>
                        <div style={{ marginTop: "8px" }}>
                          <Select
                            isMulti
                            isDisabled
                            options={localCityOptions}
                            // onChange={handleClickCity}
                            onChange={setSelectedLocalCityOptions}
                            // value={
                            //   selectedLocalCityOptions[
                            //   selectedLocalCityOptions.length - 1
                            //   ] || null
                            // }
                            value={updateClientAgent?.city}
                          />
                        </div>
                        <span className="text-danger">{selectedLocalCityOptionsError} </span>
                      </diV>
                    </Col>
                  ) : (
                    ""
                  )
                }
                {
                  updateClientAgent.showCity == "LOCAL" && (
                    <div>
                      <Row>
                        <Col md={6}>
                          <label for="formFile" class="form-label">
                            Select Franchise To
                          </label>
                          <Select
                            isDisabled
                            isMulti
                            value={superBranchListData[superBranchListData.length - 1]}
                          // value={locaSelectedBranch[locaSelectedBranch.length - 1] || null}
                          />
                        </Col>
                        <Col md={3} className="my-2">
                          <Button className="mt-4" onClick={handelCityOpen}>
                            Create New City
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  )
                }
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Contact Number</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Contact Number"
                        size="sm"
                        name="mobileNumber"
                        onChange={handleClientAgent}
                        autoComplete="off"
                        maxLength={10}
                        onInput={(e) => {
                          if (e.target.value.length > e.target.maxLength)
                            e.target.value = e.target.value.slice(
                              0,
                              e.target.maxLength
                            );
                        }}
                        onWheelCapture={(e) => e.target.blur()}
                        value={updateClientAgent.mobileNumber}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <div className="d-flex align-items-center" >
                      <div className="col-11 mb-3">
                        <Form.Group>
                          <Form.Label>Pin Code</Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Pin Code"
                            size="sm"
                            name="pincode"
                            // disabled
                            onChange={handleClientAgent}
                            onInput={(e) => {
                              if (e.target.value.length > e.target.maxLength)
                                e.target.value = e.target.value.slice(
                                  0,
                                  e.target.maxLength
                                );
                            }}
                            maxLength={6}
                            onWheelCapture={(e) => e.target.blur()}
                            value={updateClientAgent.pincode}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-1 p-2" >{updateClientAgent.loader && <Spinner className="" animation="border" variant="Primary" size="sm" />} </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label> District</Form.Label>
                      <Form.Control
                        type="text"
                        disabled
                        placeholder="District"
                        size="sm"
                        name="District"
                        onChange={handleClientAgent}
                        value={updateClientAgent.district}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>State</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="State"
                        size="sm"
                        name="state"
                        disabled
                        value={updateClientAgent.state}
                        onChange={handleClientAgent}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>GST Number</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="GST Number"
                        size="sm"
                        name="gst"
                        onChange={handleClientAgent}
                        autoComplete="off"
                        maxLength={15}
                        onInput={(e) => {
                          if (e.target.value.length > e.target.maxLength)
                            e.target.value = e.target.value.slice(
                              0,
                              e.target.maxLength
                            );
                        }}
                        value={updateClientAgent.gst ? updateClientAgent.gst.toUpperCase() : ''}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>PAN Number</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Pan number"
                        size="sm"
                        name="pan"
                        onChange={handleClientAgent}
                        autoComplete="off"
                        maxLength={10}
                        onInput={(e) => {
                          if (e.target.value.length > e.target.maxLength)
                            e.target.value = e.target.value.slice(
                              0,
                              e.target.maxLength
                            );
                        }}
                        value={updateClientAgent?.pan ? updateClientAgent?.pan.toUpperCase() : ''}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Address</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Address"
                        size="sm"
                        name="address"
                        onChange={handleClientAgent}
                        autoComplete="off"
                        maxLength={100}
                        onInput={(e) => {
                          if (e.target.value.length > e.target.maxLength)
                            e.target.value = e.target.value.slice(
                              0,
                              e.target.maxLength
                            );
                        }}
                        value={updateClientAgent.address}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  {
                    updateClientAgent?.showCity == "LOCAL" ? (
                      <Col md={6}>
                        <diV className="mb-3">
                          <label>Select Branch Name</label>
                          <div style={{ marginTop: "8px" }}>
                            <Select
                              isMulti
                              isDisabled
                              options={localCityOptions}
                              // onChange={handleClickCity}
                              onChange={setSelectedLocalCityOptions}
                              // value={
                              //   selectedLocalCityOptions[
                              //   selectedLocalCityOptions.length - 1
                              //   ] || null
                              // }
                              value={updateClientAgent?.city}
                            />
                          </div>
                          <span className="text-danger"> {selectedLocalCityOptionsError}</span>
                        </diV>
                      </Col>
                    ) : (
                      ""
                    )
                  }
                </Row>
                <Row>
                  <Col md={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Comments</Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Comments"
                        rows={3}
                        name="description"
                        onChange={handleClientAgent}
                        autoComplete="off"
                        value={updateClientAgent.description}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setUpdateClientAgent({})}>Cancel</Button>
              <Button
                variant="primary"
                className="all-button"
                onClick={handleUpdateClientAgentSubmit}
              >
                Update
                {
                  updateClientAgent.submitLoader ? (
                    <Spinner animation="border" variant="Primary" size="sm" />
                  ) : (
                    " "
                  )
                }
              </Button>

            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default Clientagent;
