import React, { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";

const Quotation = () => {
  return (
    <>
      <Container>
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Branch Type</Form.Label>
              <Form.Select size="sm">
                <option>Branch</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Billing Name</Form.Label>
              <Form.Select size="sm">
                <option>Branch</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Crossing Type</Form.Label>
              <Form.Select size="sm">
                <option>Branch</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Button className="all-button " style={{ marginTop: "30px" }}>
              Some Button
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Quotation;
