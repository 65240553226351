import React, { useState, useEffect } from "react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Stylecareer.css";
import { faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import { Button, Col, Container, Modal, Row, Tab, Tabs, Table } from "react-bootstrap";
import Apiservice from "../../Services/Apiservice";
import Switch from "react-switch";

const Franchise = () => {

  const [franchiseList, setFranchiseList] = useState([]);

  useEffect(() => {
    getFranchiseList();
  }, [])

  const getFranchiseList = async () => {
    let data = await Apiservice.getFranchiseList();
    if (!data.error) {
      // console.log("getting the grancjise list", data.data)
      setFranchiseList(data.data)
    } else {
      console.log("teh error is", data)
    }
  }

  const handleSwichChange = async (ev, e) => {
    console.log("the vent is",ev, e);
    let user
    if (!ev) {
      user = {
        "ApprovedBy": "Admin",
        "User_Id": e.id,
        "ApprovedStatus": false,
        "Register_With": "MOXD",
        "RegisterType": "ZONE"
      }
    } else {
      user = {
        "ApprovedBy": "Admin",
        "User_Id": e.id,
        "ApprovedStatus": true,
        "Register_With": "MOXD",
        "RegisterType": "ZONE"
      }
    }
    let data = await Apiservice.changeTheFranchiseActiveStatus(user);
    if (!data.error) {
      console.log("the data uis", data)
      getFranchiseList();
    } else {
      console.log("ERROR", data)
    }
  }

  return (
    <>
      <Row>
        <Col>
          <table hover size="sm" className="table table-bordered table-hover mt-5 text-center" >
            <thead>
              <tr>
                <td className="table-headings">#</td>
                <td className="table-headings">State</td>
                <td className="table-headings">District</td>
                <td className="table-headings">Business Name</td>
                <td className="table-headings">Name</td>
                <td className="table-headings">Aproved</td>
              </tr>
            </thead>
            <tbody>
              {
                franchiseList.map((e, i) => {
                  return (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{e.State}</td>
                      <td>{e.District}</td>
                      <td>{e.BusinessName}</td>
                      <td>{e.ContactPersion}</td>
                      <td>
                        <Switch onChange={(ev) => handleSwichChange(ev, e)} checked={e.ApprovedStatus} />
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </Col>
      </Row>
      <Modal
        // show={showComplain}
        backdrop="static"
        keyboard={false}
        centered
        size="xl"
      >
        <Modal.Body>
          <h1>This is popup</h1>
        </Modal.Body>

      </Modal>
    </>
  );
};

export default Franchise;
