import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Modal, Row, Table, Form, Spinner } from "react-bootstrap";
import { FileText } from "react-bootstrap-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import CreatableSelect from "react-select/creatable";
import { ActionMeta, OnChangeValue } from "react-select";
import { ToastContainer, toast, Zoom } from "react-toastify";
import { useForm } from "react-hook-form";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { Link } from "react-router-dom";
import DateTimePicker from "react-datetime-picker";
import Apiservice from "../../Services/Apiservice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faRupeeSign,
  faSearch,
  faSync,
} from "@fortawesome/free-solid-svg-icons";
import CustomButton from "../../common/CustomButton";
import { useReactToPrint } from "react-to-print";


export const productsGenerator = (quantity) => {
  const items = [];
  for (let i = 0; i < quantity; i++) {
    items.push({ id: i, name: `Item name ${i}`, price: 2100 + i });
  }
  return items;
};

const products = productsGenerator(100);

const columns = [
  {
    dataField: "id",
    text: "Product ID",
    sort: true,
  },
  {
    dataField: "name",
    text: "Product Name",
    sort: true,
  },
  {
    dataField: "price",
    text: "Product Price",
  },
];

/** validation **/
function AllLocalInWardReport() {
  const [ogmNumber, setOgmNumber] = useState("");
  const [value, onChange] = useState(new Date());
  const [inwardTable, setInwardTable] = useState([]);

  const [ogmList, setOgmList] = useState([]);
  const [cNodedata, setCNoteData] = useState({});
  const [inOutList, setInOutList] = useState([]);
  const [ogmDetialsPop, setOgmDetailsPop] = useState(false);
  const [totalWeight, setTotalWeight] = useState("");
  const [disableInput, setDisableInput] = useState([]);
  const [acceptCount, setAcceptCount] = useState([]);
  const [cnote, setCnote] = useState([]);
  const [cnoteNumber, setCNoteNumber] = useState([]);
  const [textArea, setTextArea] = useState([]);
  const [textArea1, setTextArea1] = useState([]);
  const [ogmNumberFilter, setOgmNumberFilter] = useState("");
  const [cnotevisibile, setCnotevisibile] = useState("hidden");
  const [rowData, setRowData] = useState()
  const [subDateLoader, setSubDateLoader] = useState(false)



  const [userData, setUserData] = useState();
  const [outwardListPrintingData, setOutwardListPrintingData] = useState([])
  // const [rowData, setRowData] = useState()
  const [dateOne, setDate] = useState("");
  const [cNoteFilterFromTo, setCNoteFilterFromTo] = useState({
    from: "",
    to: ""
  })
  const [dateFieldErrorFrom, setDateFieldErrorFrom] = useState('')
  const [dateFieldErrorTo, setDateFieldErrorTo] = useState('')


  const main = {
    width: "100%",
    marginRight: "auto",
    marginLeft: "8px",
  };
  const bigEle = {
    border: "2px solid black",
  };
  const componentRef = useRef();
  const handelprint = useReactToPrint({ content: () => componentRef.current });


  useEffect(() => {
    window.scrollTo(0, 0);
    getInOutList();
    let user = JSON.parse(localStorage.getItem("userDetails"));
    setUserData(user)
  }, []);

  useEffect(() => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    const todayDate = yyyy + "-" + mm + "-" + dd;

    // setDate(todayDate);

    setCNoteFilterFromTo({
      ...cNoteFilterFromTo,
      from: todayDate,
      to: todayDate
    })

    // var d = new Date();
    // d.getHours(); // => 9
    // d.getMinutes(); // =>  30
    // d.getSeconds();

    // var hh = d.getHours();
    // var mm = d.getMinutes();

    // const todayTime = hh + ":" + mm;
    // setTime(todayTime);
  }, []);

  const onchangedate = (event) => {
    setCNoteFilterFromTo({ ...cNoteFilterFromTo, [event.target.name]: event.target.value })
  }

  const handelFromToSubmit = async (e) => {
    e.preventDefault()

    if (cNoteFilterFromTo.from != '' && cNoteFilterFromTo.to != '') {
      const payload = {
        dateFrom: cNoteFilterFromTo.from == '' ? dateOne : cNoteFilterFromTo.from,
        dateTo: cNoteFilterFromTo.to == '' ? dateOne : cNoteFilterFromTo.to,
        type: "local-inward",
      }
      setSubDateLoader(true)
      const cnoteData = await Apiservice.postdata("/booking/inward_outward_list", payload)
      if (cnoteData.error == false && cnoteData.data) {
        setSubDateLoader(false)
        setRowData(cnoteData?.data)
        toast.success(cnoteData.message)
      }
      else {
        setSubDateLoader(false)
        toast.error(cnoteData.message)
      }
    } else {

      if (cNoteFilterFromTo.from == '') {
        setDateFieldErrorFrom('please select from date')
      }

      if (cNoteFilterFromTo.to == '') {
        setDateFieldErrorTo('please select to date')
      }

    }
  }


  const getInOutList = async () => {
    let user = await JSON.parse(localStorage.getItem("userDetails"));
    let data = {
      // id: user.id,
      // status: "inward",
      type: "local-inward",
    };
    // const res = await Apiservice.postdata("/booking/inwordListsss",data);
    // let res = await Apiservice.inOutList(data);
    const res = await Apiservice.postdata("/booking/inward_outward_list", data);
    if (!res.error) {
      setInOutList(res.data);
      setRowData(res.data);
    } else {
      console.log("something went");
    }
  };

  const rowHeight = 50;
  const DefaultColumnSetting = {
    sortable: true,
    filter: true,

    floatingFilter: true,
    flex: 1,
    resizable: true,
    minWidth: 120,
  };

  const [colDefs, setColDefs] = useState([
    {
      headerName: "Sl.No",
      valueGetter: "node.rowIndex + 1",
      filter: true,
      lockPosition: true,
    },
    {
      headerName: "OGM_NO",
      filter: true,
      field: "ogm_no",
      cellRenderer: 'ButtonCellRenderer'
    },
    // {
    //   headerName: "BusinessName",
    //   filter: true,
    //   valueGetter: "data.Frenchises[0].BusinessName",
    // },
    // {
    //   headerName: "MobileNumber",
    //   filter: true,
    //   valueGetter: "data.Frenchises[0].MobileNumber",
    // },
    {
      headerName: "BusinessName",
      filter: true,
      cellRendererFramework: (params) => (<center>
        <p>{params?.data?.Frenchises ? params?.data?.Frenchises[0]?.BusinessName : params?.data?.Frenchise?.BusinessName}</p>
      </center>)
    },
    {
      headerName: "MobileNumber",
      filter: true,
      cellRendererFramework: (params) => (<center>
        <p>{params?.data?.Frenchises ? params?.data?.Frenchises[0]?.MobileNumber : params?.data?.Frenchise?.MobileNumber}</p>
      </center>)
    },
    {
      headerName: "Type",
      filter: true,
      field: "type",
    },
    {
      headerName: "Status",
      filter: true,
      field: "order_status",
    },
  ]);

  const getRowStyle = (params) => {
    if (params?.data?.order_status == 'success') {
      return {
        color: 'white',
        background: '#20c997'
      };
    }
    else {
      return {
        color: 'white',
        background: '#d63384'
      };
    }
  };

  function ButtonCellRenderer(props) {
    const onClick = async () => {
      const { data } = props;
      const payload = {
        OGM_no: data?.ogm_no,
      };
      const getOGMOutwardList = await Apiservice.postdata("/booking/tracking_by_id", payload);
      if (getOGMOutwardList.error == false) {
        setOutwardListPrintingData(getOGMOutwardList.data)
        handelprint();
      } else {
        console.log('iiiiiiiiii');
      }

    };
    return <b style={{ color: "blue" }} onClick={onClick}>{props.data.ogm_no}</b>;
  }



  const cellClickedHandler = async (e) => {
    const payload = {
      OGM_no: e?.data?.ogm_no,
    };
    const getOGMOutwardList = await Apiservice.postdata("/booking/tracking_by_id", payload);
    if (getOGMOutwardList.error == false) {
      setOutwardListPrintingData(getOGMOutwardList.data)
      handelprint();
    } else {
      console.log('iiiiiiiiii');
    }
  }

  useEffect(() => {
    if (cNoteFilterFromTo.from != '') {
      setDateFieldErrorFrom('')
    }
    if (cNoteFilterFromTo.to != '') {
      setDateFieldErrorTo('')
    }
  }, [cNoteFilterFromTo])

  const handleDateClear = () => {
    const todayDate = '' + "-" + '' + "-" + '';
    setDate(todayDate);
    setCNoteFilterFromTo({
      ...cNoteFilterFromTo,
      from: "",
      to: ""
    })
    setDateFieldErrorFrom('')
    setDateFieldErrorTo('')
  }


  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        transition={Zoom}
        delay={1000}
        limit={1}
      />
      <div className="my-3">
        <div className="card w-100 my-3">
          <div class="card-body pt-0">
            <div className="row">
              <h6 class="card-title    my-3 bg-light w-auto py-2 rounded ">
                <FileText /> <b>All Local Inward Admin</b>
              </h6>
            </div>

            <form onSubmit={handelFromToSubmit} >
              <div className="row">
                <div className="col-md-4" >
                </div>
                <div className="col-md-8" >
                  <div className="row" >
                    <div className="col-md-4">
                      {" "}
                      <Form.Label>From Date</Form.Label>
                      <Form.Control
                        type="date"
                        value={cNoteFilterFromTo.from == '' ? dateOne : cNoteFilterFromTo.from}
                        onChange={onchangedate}
                        name="from"
                        format="DD/MM/YYYY"
                      />
                      <span className="text-danger" >{dateFieldErrorFrom}</span>

                    </div>

                    <div className="col-md-4">
                      <Form.Label>To Date</Form.Label>
                      <Form.Control
                        type="date"
                        value={cNoteFilterFromTo.to == '' ? dateOne : cNoteFilterFromTo.to}
                        onChange={onchangedate}
                        name="to"
                        format="DD/MM/YYYY"
                      />
                      <span className="text-danger" >{dateFieldErrorTo}</span>
                    </div>
                    <div className="col-md-4 mt-3">
                      <button
                        className="btn btn-danger rounded mx-3  text-white mt-3"
                        type="reset"
                        onClick={handleDateClear}
                      >
                        Clear
                      </button>
                      <button
                        className="btn btn-success rounded mx-3  text-white mt-3"
                        // onClick={handelFromToSubmit}
                        type='submit'
                      >
                        Submit{" "}
                        {subDateLoader ? (
                          <Spinner
                            animation="border"
                            variant="Primary"
                            size="sm"
                          />
                        ) : (
                          ""
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>


            <div
              className="ag-theme-alpine"
              style={{ height: "70vh", width: "100%", marginTop: '30px' }}
            >
              <AgGridReact
                rowHeight={rowHeight}
                // columnDefs={columns}
                columnDefs={colDefs}
                defaultColDef={DefaultColumnSetting}
                pagination={true}
                paginationPageSize={10}
                // onGridReady={onGridReady}
                rowData={rowData}
                getRowStyle={getRowStyle}
                // onCellClicked={cellClickedHandler}
                frameworkComponents={{
                  ButtonCellRenderer
                }}
              />{" "}
            </div>
          </div>
        </div>
      </div>

      {/* From and To Address */}
      <div>
        {
          <div
            ref={componentRef}
            className="container-fluid print-div"
            style={main}
          >
            {
              <div className="p-2 m-0" style={bigEle}>

                <div>
                  {
                    outwardListPrintingData && outwardListPrintingData[0] && outwardListPrintingData[0].OGMs
                      ?
                      <div className="row m-1" style={{ border: '2px solid black', borderRadius: "5px" }} >
                        <div className="col" >
                          <div>
                            <div className="mt-3 mb-1" >
                              <b className="reports-origin-title">Origin</b>
                            </div>
                            <b className="reports-titles" >Branch Name:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.OGMs[0]?.order_trackings[0].FranchiseFrom?.Frenchises[0].city_masters[0].city_name}</b><br></br>
                            <b className="reports-titles"  >Address:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.OGMs[0]?.order_trackings[0].FranchiseFrom?.Frenchises[0].Address}</b><br></br>
                            <b className="reports-titles"  >Pincode:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.OGMs[0]?.order_trackings[0].FranchiseFrom?.Frenchises[0].Pincode}</b><br></br>
                            <b className="reports-titles"  >State Name:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.OGMs[0]?.order_trackings[0].FranchiseFrom?.Frenchises[0].State}</b><br></br>
                            <b className="reports-titles"  >Mobile No:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.OGMs[0]?.order_trackings[0].FranchiseFrom?.Frenchises[0].MobileNumber}</b><br></br>
                          </div>
                        </div>
                        <div className="col"  >
                          <div>
                            <div className="mt-3 mb-1" >
                              <b className="reports-origin-title">Destination</b>
                            </div>
                            <b className="reports-titles"  >Branch Name:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.OGMs[0]?.order_trackings[0].FranchiseTo?.Frenchises[0].city_masters[0].city_name}</b><br></br>
                            <b className="reports-titles"  >Address:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.OGMs[0]?.order_trackings[0].FranchiseTo?.Frenchises[0].Address}</b><br></br>
                            <b className="reports-titles" >Pincode:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.OGMs[0]?.order_trackings[0].FranchiseTo?.Frenchises[0].Pincode}</b><br></br>
                            <b className="reports-titles" >State Name:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.OGMs[0]?.order_trackings[0].FranchiseTo?.Frenchises[0].State}</b><br></br>
                            <b className="reports-titles"  >Mobile No:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.OGMs[0]?.order_trackings[0].FranchiseTo?.Frenchises[0].MobileNumber}</b><br></br>
                          </div>
                        </div>
                      </div>
                      :
                      <div className="row m-1" style={{ border: '2px solid black', borderRadius: "5px" }} >
                        <div className="col" >
                          <div>
                            <div className="mt-3 mb-1" >
                              <b className="reports-origin-title">Origin</b>
                            </div>
                            <b className="reports-titles" >Branch Name:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.order_trackings[0].FranchiseFrom?.Frenchises[0].city_masters[0].city_name}</b><br></br>
                            <b className="reports-titles"  >Address:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.order_trackings[0].FranchiseFrom?.Frenchises[0].Address}</b><br></br>
                            <b className="reports-titles"  >Pincode:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.order_trackings[0].FranchiseFrom?.Frenchises[0].Pincode}</b><br></br>
                            <b className="reports-titles"  >State Name:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.order_trackings[0].FranchiseFrom?.Frenchises[0].State}</b><br></br>
                            <b className="reports-titles"  >Mobile No:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.order_trackings[0].FranchiseFrom?.Frenchises[0].MobileNumber}</b><br></br>
                          </div>
                        </div>
                        <div className="col"  >
                          <div>
                            <div className="mt-3 mb-1" >
                              <b className="reports-origin-title">Destination</b>
                            </div>
                            <b className="reports-titles"  >Branch Name:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.order_trackings[0].FranchiseTo?.Frenchises[0].city_masters[0].city_name}</b><br></br>
                            <b className="reports-titles"  >Address:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.order_trackings[0].FranchiseTo?.Frenchises[0].Address}</b><br></br>
                            <b className="reports-titles" >Pincode:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.order_trackings[0].FranchiseTo?.Frenchises[0].Pincode}</b><br></br>
                            <b className="reports-titles" >State Name:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.order_trackings[0].FranchiseTo?.Frenchises[0].State}</b><br></br>
                            <b className="reports-titles"  >Mobile No:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.order_trackings[0].FranchiseTo?.Frenchises[0].MobileNumber}</b><br></br>
                          </div>
                        </div>
                      </div>
                  }
                </div>
                {
                  outwardListPrintingData && outwardListPrintingData[0] && outwardListPrintingData[0].OGMs
                    ?
                    <div className="row m-3">
                      <div className="col" >
                        <b className="reports-titles" >OGM Number:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.OGMs[0]?.OGM_no}</b>
                      </div>
                      <div className="col" >
                        <b className="reports-titles" >Manifest Date:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.OGMs[0]?.order_trackings[0].updatedAt}</b>
                      </div>
                    </div>
                    :
                    <div className="row m-3">
                      <div className="col" >
                        <b className="reports-titles" >OGM Number:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.OGM_no}</b>
                      </div>
                      <div className="col" >
                        <b className="reports-titles" >Manifest Date:</b><b>{" "}{outwardListPrintingData.length && outwardListPrintingData[0]?.order_trackings[0].updatedAt}</b>
                      </div>
                    </div>
                }
                <div style={{ marginTop: "10px" }}>
                  <div className="row" style={{ marginLeft: "0px" }}>
                    <h6 class="card-title my-3 bg-light w-auto py-2 rounded ">
                      <FileText size={25} /> <b className="reports-titles" >Courier Details</b>
                    </h6>
                  </div>
                  <Table
                    className="m-0 text-center"
                    style={{ border: "2px solid black" }}
                    bordered
                    hover
                  >
                    <thead
                      style={{
                        backgroundColor: "#5f7780",
                        color: "#FFFFFF",
                        fontSize: "14px",
                      }}
                    >
                      <tr>
                        <th>Sl.No</th>
                        {/* <th>OGM Number</th> */}
                        <th>C-note Number</th>
                        <th>Order Id</th>
                        <th>Weight</th>
                        <th>No of Pcs</th>
                        <th>Delivery District</th>
                        <th>Delivery State</th>
                        <th>Delivery Pincode</th>
                        <th>Remarks</th>
                      </tr>
                    </thead>
                    <tbody style={{ fontSize: "14px" }}>
                      {
                        outwardListPrintingData.length && (Object.keys(outwardListPrintingData[0]).includes('OGMs')) == true &&
                        outwardListPrintingData.map((ele, ind) => {
                          return <tr>
                            <td>{ind + 1}</td>
                            {/* <td>{ele.OGMs[0]?.OGM_no || 'null'}</td> */}
                            <td>{ele.OGMs[0]?.c_node_no || 'null'}</td>
                            <td>{ele?.order_id || 'null'}</td>
                            <td>{ele?.weight || 'null'}</td>
                            <td>{ele?.no_pcs || 'null'}</td>
                            <td>{ele.OGMs[0]?.order_trackings[0]?.FranchiseTo?.Frenchises[0]?.District || 'null'}</td>
                            <td>{ele.OGMs[0]?.order_trackings[0]?.FranchiseTo?.Frenchises[0]?.State || 'null'}</td>
                            <td>{ele.OGMs[0]?.order_trackings[0]?.FranchiseTo?.Frenchises[0]?.Pincode || 'null'}</td>
                            <td>{ele?.remarks || 'null'}</td>
                          </tr>
                        })
                      }

                      {
                        outwardListPrintingData.length && (Object.keys(outwardListPrintingData[0]).includes('OGMs')) != true &&
                        outwardListPrintingData.map((ele, ind) => {
                          return <tr>
                            <td>{ind + 1}</td>
                            {/* <td>{ele?.OGM_no || 'null'}</td> */}
                            <td>{ele?.c_node_no || 'null'}</td>
                            <td>{ele?.order_id || 'null'}</td>
                            <td>{ele?.weight || 'null'}</td>
                            <td>{ele?.no_pcs || 'null'}</td>
                            <td>{ele?.order_trackings[0]?.FranchiseTo?.Frenchises[0]?.District || 'null'}</td>
                            <td>{ele?.order_trackings[0]?.FranchiseTo?.Frenchises[0]?.State || 'null'}</td>
                            <td>{ele?.order_trackings[0]?.FranchiseTo?.Frenchises[0]?.Pincode || 'null'}</td>
                            <td>{ele?.remarks || 'null'}</td>
                          </tr>
                        })
                      }

                    </tbody>
                  </Table>
                </div>
                <div style={{ textAlign: 'end' }} >
                  <b style={{ fontSize: '15px', marginRight: '10px' }} >POWERED BY MOXD</b>
                </div>
              </div>
            }
          </div>
        }
      </div>


    </div>
  );
}

export default AllLocalInWardReport;
