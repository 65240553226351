import React from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";

const Bookingreport = () => {
  return (
    <div style={{display:'none'}} >
      <Container>
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>From Date</Form.Label>
              <Form.Control type="date" placeholder="" size="sm" />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>To Date</Form.Label>
              <Form.Control type="date" placeholder="" size="sm" />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Select Sub-Branch/Franchise</Form.Label>
              <Form.Select size="sm">
                <option>Branch</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Select Booking Branch</Form.Label>
              <Form.Select size="sm">
                <option>Branch</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Select Product</Form.Label>
              <Form.Select size="sm">
                <option>Branch</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Select Pay Mode</Form.Label>
              <Form.Select size="sm">
                <option>Branch</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Select Mode</Form.Label>
              <Form.Select size="sm">
                <option>Branch</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Select Booking</Form.Label>
              <Form.Select size="sm">
                <option>Branch</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Select Party</Form.Label>
              <Form.Select size="sm">
                <option>Select Employee</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Associates</Form.Label>
              <Form.Select size="sm">
                <option>Select Employee</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Select State</Form.Label>
              <Form.Select size="sm">
                <option>Select Employee</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Select Destination</Form.Label>
              <Form.Select size="sm">
                <option>Select Employee</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={4} className="d-flex justify-content-between">
            <Button className="all-button">Search</Button>
            <Button className="all-button">Export to Excell</Button>
            <Button className="all-button">Export to CSV</Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Bookingreport;
