import React, { useEffect, useState } from "react";

import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { FileText } from "react-bootstrap-icons";
import Apiservice from "../../Services/Apiservice";
import { AgGridReact } from "ag-grid-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer, Zoom } from "react-toastify";
import axios from "axios";

function Notification() {

  const [dateOne, setDate] = useState("");
  const [submitDateLoader, setSubmitDateLoader] = useState(false)
  const [submitCouponCodeLoader, setSubmitCouponCodeLoader] = useState(false)
const [franchiseList , setFranchiseList] = useState([])
const [userData, setUserData] = useState("");

useEffect(async () => {
  // setUserData(JSON.parse(localStorage.getItem("userDetails")));

  const localstorege = localStorage.getItem("userDetails");
  const localstoregeParseData = JSON.parse(localstorege);
  // setEmailUser(localstoregeParseData);
  // console.log(localstoregeParseData, "Login User Data");
  const mainData = await localstoregeParseData;

  const one = [mainData.role_masters].map((ele, ind) => {
    setUserData(ele[0]);
  });
}, []);



  const getFranchiseList = async () => {
    try {
      const payload = {
        city_name: "",
      isNull: false,
      };
  
      const response = await axios.post('https://moxd-api.moshimoshi.cloud/api/User/listCity ', payload);
      setFranchiseList(response.data.data)
  
    } catch (error) {
      console.error('Error making POST request:', error);
    }
  }
  


  const options = [
    { value: "", text: "--Choose an option--" },
    { value: "allfranchise", text: "allfranchise" },
    { value: "franchise", text: "franchise" },
  ];
  const [selected, setSelected] = useState(options[0].value ? options[0].value : "visible");
  const [show, setShow] = useState(false);


  useEffect(() => {
    if (selected == 'percentage') {
      setFormList({
        ...formList,
        ['selectDropdown']: selected,
        ['amount']: ''
      })
      setFormListError({
        ...formListError,
        ['selectDropdown']: null,
        ['amount']: null
      })
    }

    if (selected == 'amount') {
      setFormList({
        ...formList,
        ['selectDropdown']: selected,
        ['percentage']: ''

      })
      setFormListError({
        ...formListError,
        ['selectDropdown']: null,
        ['percentage']: null

      })
    }
  }, [selected])




  const [formList, setFormList] = useState({    
    franchise: "",
    message: "",
    selectDropdown: selected == 'visible' ? "" : selected,
    time:""
  });

  const [formListError, setFormListError] = useState({    
    franchise: "",
    Franchise: "",
    type: "",    
  });

  // Format it as "YYYY-MM-DD HH:MM:SS"


 useEffect(() => {
  getFranchiseList();

}, []);
  const [rowData, setRowData] = useState()
  const [loader, setLoader] = useState(false)
  const [pageReload, setPageReload] = useState(1);
  const [dateReload, setDateReload] = useState(1)

  /** Date States**/
  const [cNoteFilterFromTo, setCNoteFilterFromTo] = useState({
    from: "",
    to: ""
  })
  const [dateFieldErrorFrom, setDateFieldErrorFrom] = useState('')
  const [dateFieldErrorTo, setDateFieldErrorTo] = useState('')


  const handleClose = () => {
    setShow(false);
    setFormList({
      ...formList,
      franchise: "",
    Franchise: "",
    type: "",    
    message: "",    
    time: "",    
    selectDropdown: selected == 'visible' ? "" : selected
    })
    setFormListError({
      ...formListError,
      franchise: "",
    Franchise: "",
    type: "",    
    message: "",    
    time: "",    
    selectDropdown: selected == 'visible' ? "" : selected
    })
    setDateReload(dateReload + 1)
    setPageReload(pageReload + 1)
  }
  const handleShow = () => setShow(true);

  const handleChange = (event) => {            
    const selectedValue = event.target.value; // Get the selected value from the event
    setSelected(selectedValue);
    setFormList({...formList ,
      ["type"]:event.target.value,      
    })
    setFormListError({
      ...formListError,
      [event.target.name]: null
    })
  };


  useEffect(() => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    const todayDate = yyyy + "-" + mm + "-" + dd;

    // setDate(todayDate);

    setFormList({
      ...formList,
      startingDate: todayDate,
      endingDate: todayDate
    })
  }, [dateReload]);

  useEffect(() => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    const todayDate = yyyy + "-" + mm + "-" + dd;

    // setDate(todayDate);

    setCNoteFilterFromTo({
      ...cNoteFilterFromTo,
      from: todayDate,
      to: todayDate
    })   
  }, []);




  // const onchangedate = (event) => {
  //   setCNoteFilterFromTo({ ...cNoteFilterFromTo, [event.target.name]: event.target.value })
  // }
  // const handelFromToSubmit = async (e) => {
  //   e.preventDefault()
  //   if (cNoteFilterFromTo.from != '' && cNoteFilterFromTo.to != '') {
  //     setSubmitDateLoader(true)
  //     const payload = {
  //       dateFrom: cNoteFilterFromTo.from == '' ? dateOne : cNoteFilterFromTo.from,
  //       dateTo: cNoteFilterFromTo.to == '' ? dateOne : cNoteFilterFromTo.to
  //     }
  //     const cnoteData = await Apiservice.postdata("/admin/get_all_notification", payload)
  //     if (cnoteData.error == false && cnoteData.data.length != 0) {
  //       setSubmitDateLoader(false)
  //       setRowData(cnoteData?.data)
  //       toast.success(cnoteData.message)
  //     }
  //     else {
  //       setSubmitDateLoader(false)
  //       toast.error('No data was found within this date range')
  //       setRowData(null)

  //     }
  //   } else {

  //     if (cNoteFilterFromTo.from == '') {
  //       setDateFieldErrorFrom('please select from date')
  //     }

  //     if (cNoteFilterFromTo.to == '') {
  //       setDateFieldErrorTo('please select to date')
  //     }

  //   }
  // }


  const handelOnChange = (event) => {   
  const {name , value}  = event.target
    setFormList({...formList,
      [name]: value
    })
    setFormListError({
      ...formListError,
      [name]: null
    })
  };

  useEffect(() => {
    async function fetchData() {
      const payload = {
        franchiseId: userData?.id,
      };
  
      if (userData.role_name === "Admin") {
        const data = await Apiservice.postdata("/admin/get_all_notification");
        if (data.error === false) {
          setRowData(data.data);
        } else {
          console.log("get API is not working");
        }
      } else {
        const data = await Apiservice.postdata("/franchise/notification", payload);
        if (data.error === false) {
          setRowData(data.data);
        } else {
          console.log("get API is not working");
        }
        // Handle the data you receive when not an admin as needed.
      }
    }
  
    fetchData(); // Call the async function to fetch data
  
  }, [pageReload, userData]);
  
  const handelSubmit = async () => {
    // Check if the "message" field is empty
    if (!formList.message.trim()) {
      setFormListError({
        ...formListError,
        message: 'Message is required',
      });
      return; // Stop further execution
    }
  
    // Check if the "type" field is empty or not valid
    if (!formList.type || !options.find(option => option.value === formList.type)) {
      setFormListError({
        ...formListError,
        selectDropdown: 'Please select a valid type',
      });
      return; // Stop further execution
    }
  
    // Reset error messages if fields are valid
    setFormListError({
      message: '',
      selectDropdown: '',
    });
  
    // Rest of your submission logic
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const hours = String(currentDate.getHours()).padStart(2, '0');
    const minutes = String(currentDate.getMinutes()).padStart(2, '0');
    const seconds = String(currentDate.getSeconds()).padStart(2, '0');
    const formattedTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    
    
    
    
    
    let payload = {
      isActive: true,
      type: formList.type,
      message: formList.message,
      time: formattedTime,
    };
  
    if (formList.type === 'franchise') {
      payload.franchiseId = "f6c3d887-4b37-45d9-9c50-f3965590411f";
    }
  
   

    
    const data = await Apiservice.postdata("/admin/create_notification", payload);
    if (data.error === false) {
      handleClose();
      setDateReload(dateReload + 1);
      setPageReload(pageReload + 1);
      setFormListError({})
      toast.success(data.message);
    } else {
      setDateReload(dateReload + 1);
      setPageReload(pageReload + 1);
      toast.error(data.message);
    }
  };
  



  const rowHeight = 50;
  const DefaultColumnSetting = {
    sortable: true,
    filter: true,

    floatingFilter: true,
    flex: 1,
    resizable: true,
    minWidth: 120,
  };

  const columns = [
    {
      headerName: "Sl.No",
      valueGetter: "node.rowIndex + 1",
      filter: false,
      lockPosition: true,
    },
    {
      headerName: "Notification Message",
      filter: true,
      field: "message",
    },

    {
      headerName: "Active",
      filter: true,
      field: "isActive",
      hide: userData.role_name !== 'Admin',

    },
    {
      headerName: "franchise type",
      filter: true,
      field: "type",
      hide: userData.role_name !== 'Admin',

    },    
    {
      headerName: "time",
      filter: true,
      field: "time",
      
    },
    {
      headerName: "Delete",
      filter: true,
      cellRendererFramework: (params) => (
        <center onClick={() => handelDelete(params.data)}>
          <FontAwesomeIcon icon={faTrash} className="iconstyle" />
        </center>
      ),
      hide: userData.role_name !== 'Admin',
    },

  ];


  /******* Delete API Integration ******/
  const [smShow, setSmShow] = useState();
  const [deleteId, setDeleteId] = useState();


  const handelDelete = async (dataID) => {
    setSmShow(true);
    setDeleteId(dataID.id);

  };
  const handleCancelClear = () => {
    setSmShow(false);
  };
  const onClicksucess = async () => {
    setLoader(true)
    const payload = {
      id: deleteId
    }
    const data = await Apiservice.postdata("/admin/delete_notification", payload);
    if (data.error == false) {
      setLoader(false)
      setSmShow(false);
      setPageReload(pageReload + 1);
      toast.success(data.message)
    } else {
      setSmShow(false);
      setPageReload(pageReload + 1);
      setLoader(false)
      toast.error(data.message)
    }
  };



  useEffect(() => {
    if (cNoteFilterFromTo.from != '') {
      setDateFieldErrorFrom('')
    }
    if (cNoteFilterFromTo.to != '') {
      setDateFieldErrorTo('')
    }
  }, [cNoteFilterFromTo])


  const handleDateClear = () => {
    const todayDate = '' + "-" + '' + "-" + '';
    setDate(todayDate);
    setCNoteFilterFromTo({
      ...cNoteFilterFromTo,
      from: "",
      to: ""
    })
  }

  return (
    <div>
    <ToastContainer
      position="top-center"
      autoClose={2000}
      hideProgressBar
      transition={Zoom}
      delay={1000}
      limit={1}
    />
    <div>
      <div className="my-3">
        <div className="card w-100 my-3 p-3">
          <div class="card-body pt-0">
            <div className="row ">
              <div className="col-md-2">
                <h6 class="card-title   bg-light w-auto py-2 rounded ">
                  <FileText /> <b>Notification List</b>
                </h6>
              </div>
              {userData?.role_name === "Admin" &&
              <div className="col-md-10 text-end">
                <Button
                  variant="danger"
                  className="mx-2 rounded"
                  onClick={handleShow}
                >
                  Add Notification
                </Button>
              </div>
              }
           
            </div>
          </div>
          
          {/* <div className='col' >
            <form onSubmit={handelFromToSubmit} >
              <div className="row">
                <div className="col-md-4" >
                </div>
                <div className="col-md-8" >
                  <div className="row" >
                    <div className="col-md-4">
                      {" "}
                      <Form.Label>From Date</Form.Label>
                      <Form.Control
                        type="date"
                        value={cNoteFilterFromTo.from == '' ? dateOne : cNoteFilterFromTo.from}
                        onChange={onchangedate}
                        name="from"
                        format="DD/MM/YYYY"
                      />
                      <span className="text-danger" >{dateFieldErrorFrom}</span>

                    </div>

                    <div className="col-md-4">
                      <Form.Label>To Date</Form.Label>
                      <Form.Control
                        type="date"
                        value={cNoteFilterFromTo.to == '' ? dateOne : cNoteFilterFromTo.to}
                        onChange={onchangedate}
                        name="to"
                        format="DD/MM/YYYY"
                      />
                      <span className="text-danger" >{dateFieldErrorTo}</span>
                    </div>
                    <div className="col-md-4 mt-3">
                      <button
                        className="btn btn-danger rounded mx-3  text-white mt-3"
                        type="reset"
                        onClick={handleDateClear}
                      >
                        Clear
                      </button>
                      <button
                        className="btn btn-success rounded mx-3  text-white mt-3"
                        // onClick={handelFromToSubmit}
                        type='submit'
                      >
                        Submit{" "}
                        {submitDateLoader ? (
                          <Spinner
                            animation="border"
                            variant="Primary"
                            size="sm"
                          />
                        ) : (
                          ""
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div> */}


          <div
            className="ag-theme-alpine"
            style={{ height: "70vh", width: "100%", marginTop: '30px' }}
          >
            <AgGridReact
              rowHeight={rowHeight}
              columnDefs={columns}
              defaultColDef={DefaultColumnSetting}
              pagination={true}
              paginationPageSize={10}
              enableCellChangeFlash={true}
              // onGridReady={onGridReady}
              rowData={rowData}
            />
          </div>

        </div>
      </div>
    </div>

    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Create Notification</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
  <Form.Label>Notification Message</Form.Label>
  <Form.Control
    type="text"
    placeholder="Message"
    name="message"
    value={formList.message}
    onChange={handelOnChange}
    autoComplete="off"
  />
  <span className="text-danger">{formListError.message}</span>
</Form.Group>

<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
  <Form.Label>Select Type</Form.Label>
  <Form.Select size="sm" value={selected} onChange={handleChange} name="type">
    {options.map((option) => (
      <option key={option.type} value={option.type}>
        {option.text}
      </option>
    ))}
  </Form.Select>
  <span className="text-danger">{formListError.selectDropdown}</span>
</Form.Group>

{formList.type === 'franchise' && (
  <Form.Group className="mb-3" controlId="exampleForm.ControlSelect1">
    <Form.Label>Franchise</Form.Label>
    <Form.Select
      name="franchise"
      onChange={handelOnChange}
      autoComplete="off"
    >
      <option value="">Select an option</option>
      {franchiseList.map((e) => (
        
        <option value={e?.Frenchises[0]?.id}>{e.city_name} {console.log(e)}</option>
      ))}
    </Form.Select>
    <span className="text-danger">{formListError.franchise}</span>
  </Form.Group>
)}
         
        </Form>
      </Modal.Body>
      <Modal.Footer className="m-auto">
        <Button variant="danger" className="rounded">
          Clear
        </Button>
        <Button variant="primary" onClick={handelSubmit} className="rounded">
          Submit{" "}
          {submitCouponCodeLoader ? (
            <Spinner
              animation="border"
              variant="Primary"
              size="sm"
            />
          ) : (
            ""
          )}
        </Button>
      </Modal.Footer>
    </Modal>


    <Modal show={smShow} onHide={() => setSmShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Are You Confirm to Delete Notification Code</Modal.Title>
      </Modal.Header>
      <Modal.Footer className="m-auto">
        <Button
          variant="danger"
          className=" rounded"
          onClick={handleCancelClear}
        >
          Cancel
        </Button>
        <Button
          variant="success"
          className=" rounded"
          onClick={onClicksucess}
        >
          Delete
          {
            loader &&
            <Spinner animation="border" variant="Primary" size="sm" />
          }
        </Button>
      </Modal.Footer>
    </Modal>
  </div >
);
};


export default Notification