import React, { useEffect, useState } from "react";
import '../../Style/Ogm.css';

import {
    Button,
    Card,
    Col,
    Form,
    Modal,
    Row,
    Spinner,
    Table,
} from "react-bootstrap";
import '../../Style/Ogm.css';

import Apiservice from "../../Services/Apiservice";

const Outvert = () => {

    const [stateList, setStateList] = useState([]);
    const [distList, setDistList] = useState([]);
    const [fraList, setFraList] = useState([]);
    const [ogmList, setOgmList] = useState([]);
    const [inOutList, setInOutList] = useState([]);
    const [fraData, setFraData] = useState({
        state: '',
        district: '',
        to_franch: '',
        ogm_id: ''
    })
    const [selectedState, setSelectedState]=useState()

    useEffect(() => {
        GetStateList();
        getOgmList();
        getFraList();
        getInOutList();
    }, [])

    // useEffect(()=>{
    //     GetDisList()
    // },[fraData])

    const GetStateList = async () => {
        let sattes = await Apiservice.getStateList();
        if (!sattes.error) {
            setStateList(sattes.data)
        } else {
            // toast.error("Something Went Wrong")
        }
    }




    const GetDisList = async (e) => {
        setFraData({ ...fraData, state: e.target.value })
        setSelectedState(e.target.value)
        // const index = e.target.selectedIndex;
        // const el = e.target.childNodes[index];
        // const option = el.getAttribute('id');

        if (e.target.value) {
            let dist = await Apiservice.postdata('/User/get_city', { state_id:e.target.value  });
            if (!dist.error) {
                setDistList(dist.data)
            } else {
                // toast.error("Something Went Wrong")
            }
        }

    }


    const selectDistrict = (e) => {
        let data = {
            state: selectedState,
            district: e.target.value
        }
        getFraList(data);
    }
    const getFraList = async (data) => {
        console.log(data, 'data');
        // let data = {
        //     "ApprovedStatus": true
        // }
        let fra = await Apiservice.getFranchiseList(data);
        console.log(fra, 'fra');
        if (!fra.error) {
            setFraList(fra.data)
        } else {
            console.log("something went wrong");
        }
    }

    const getOgmList = async () => {
        let user = JSON.parse(localStorage.getItem('userDetails'));
        let ogmList = await Apiservice.getOgmList({
            "frenchise_id": user.id
        });
        if (!ogmList.error) {
            setOgmList(ogmList.data)
        } else {
            console.log("something went wrong")
        }
    }

    const handleFranch = (e) => {
        setFraData({ ...fraData, to_franch: e.target.value })
    }

    const outword = async () => {
        let user = JSON.parse(localStorage.getItem('userDetails'));
        let data = {
            ogm_no: fraData.ogm_id,
            frenchise_id_from: user.id,
            frenchise_id_to: fraData.to_franch
        }
        let res = await Apiservice.outWorldApi(data);
        if (!res.error) {
            console.log("the outworld is done");
            getInOutList();
        } else {
            console.log("something went")
        }
    }

    const getInOutList = async () => {
        let user = JSON.parse(localStorage.getItem('userDetails'));
        let data = {
            id: user.id,
            status: "outward"
        }
        let res = await Apiservice.inOutList(data);
        if (!res.error) {
            setInOutList(res.data);
        } else {
            console.log("something went")
        }
    }

    const handleSelectOgm = (e) => {
        setFraData({ ...fraData, ogm_id: e.target.value })
    }


    return (
        <>
            <Card className="shadow rounded p-2 w-100">
                <div className="master">
                    <Row>
                        <Col md={12}>
                            <div className="rightedge d-flex">
                                <Form.Select
                                    aria-label="Default select example"
                                    size={"sm"}
                                    className="selectsizesmall"
                                    onChange={GetDisList}
                                >
                                    <option>Select State</option>
                                    {
                                        stateList.map(e => {
                                            return <option key={e.id} id={e.id} value={e.State}>{e.State}</option>
                                        })
                                    }
                                </Form.Select>

                                <Form.Select
                                    aria-label="Default select example"
                                    size={"sm"}
                                    className="selectsizesmall"
                                    onChange={selectDistrict}
                                >
                                    <option>Select District</option>
                                    {
                                        distList.map(e => {
                                            return <option key={e.id} value={e.District}>{e.District}</option>
                                        })
                                    }
                                </Form.Select>
                                <Form.Select
                                    aria-label="Default select example"
                                    size={"sm"}
                                    className="selectsizesmall"
                                    onChange={handleFranch}
                                >
                                    <option>Select Frenchise</option>
                                    {
                                        fraList.map(e => {
                                            return <option key={e.id} value={e.id}>{e.BusinessName}</option>
                                        })
                                    }
                                </Form.Select>
                                <Form.Select
                                    aria-label="Default select example"
                                    size={"sm"}
                                    className="selectsizesmall"
                                    onChange={handleSelectOgm}
                                >
                                    <option>Select OGM</option>
                                    {
                                        ogmList.map(e => {
                                            return <option key={e.id} value={e.OGM_no}>{e.OGM_no}</option>
                                        })
                                    }
                                </Form.Select>
                            </div>
                        </Col>
                    </Row>
                    <div className="assign-button-container">
                        <button onClick={outword} className="CreateOgm-button">Assign Ogm</button>
                    </div>
                </div>
                <div className="outward-list-container">
                    <p>Outward Assign List</p>
                    <Table hover size="sm"  className="text-center">
                        <thead>
                            <tr>
                                <td className="table-headings">#</td>
                                <td className="table-headings">OGM Number</td>
                                <td className="table-headings">Business Name</td>
                                <td className="table-headings">Mobile Number</td>
                                <td className="table-headings">Status</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                inOutList.map((e, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{i + 1}</td>
                                            <td>{e.ogm_no}</td>
                                            <td>{e["Frenchises.BusinessName"]}</td>
                                            <td>{e["Frenchises.MobileNumber"]}</td>
                                            <td>{e.order_status}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </div>
            </Card>
        </>
    )
}

export default Outvert